import React, {useState} from 'react'
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Alert,AlertIcon, AlertTitle, } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { setFormModal } from '../redux/slices/modalSlice';
import {fetchApprovalWorkflow} from '../redux/slices/approvalWorkFlowSlice';
import {setloadTost} from "../redux/slices/toastSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const EditApprovalWorkflow = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const roles = useSelector((state) => state.userMgtRole?.roles?.data);
  const settingsData = useSelector((state) => state?.approvalSettings?.approvalSettings?.data);
  const editApprovalWorkflow = useSelector((state) => state?.approvalWorkFlow?.editApprovalWorkflow);
  console.log('editApprovalWorkflow', editApprovalWorkflow, roles );

  const initialValues = {
    approvalName: editApprovalWorkflow?.approvalSettingId ||'',
    approvalLevel: editApprovalWorkflow?.approvalLevel ||'',
    assignRole: editApprovalWorkflow?.roleId ||'',
    // approvalPoint: editApprovalWorkflow?.approvalPoint ||'',
    // membershipGrade: editApprovalWorkflow?.membershipCategory ||'',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        approvalSettingId: values?.approvalName,
        approvalLevel: parseInt(values?.approvalLevel, 10),
        roleId: values?.assignRole,
        // approvalPoint: values?.approvalPoint,
        // membershipCategory: values?.membershipGrade,
        isActive: true,
      }

      const response = await httpRequest(
        `${BASE_URL}/v2/ApprovalWorkflow/${editApprovalWorkflow?.id}/update`,
        "post",
        formData,
      );
      // console.log('response', response);

      if (response?.isSuccess === true) {
        setError('');
        setLoading(false);
        dispatch(fetchApprovalWorkflow());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Approval workflow Updated', description: ` ${editApprovalWorkflow?.approvalSettingName} Approval workflow updated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setLoading(false);
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to update approval settings");
      }
      
    } catch (error) {
      setError(error?.message || "Failed to update approval settings, please check your network");
    } finally {
      setLoading(false);
    }
    
  }
  
  return (
    <>
     {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>
            <FormControl
              mt={4}
              isInvalid={errors.approvalName && touched.approvalName}
              color="#121212"
            >
              <FormLabel htmlFor="approvalName" fontSize={"16px"}>
                Approval Name
              </FormLabel>
              <Select
                name="approvalName"
                placeholder="Select Here"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.approvalName}
              >
                {settingsData &&
                  settingsData?.map((data, index) => (
                    <option value={data?.id} key={index}>
                      {data?.name}
                    </option>
                  ))}
              </Select>
              {touched.approvalName && errors.approvalName && (
                <FormErrorMessage>{errors.approvalName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.approvalLevel && touched.approvalLevel}
              color="#121212"
            >
              <FormLabel htmlFor="approvalLevel" fontSize={"16px"}>
                Approval Level
              </FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={"#F3F3F3"}
                name="approvalLevel"
                focusBorderColor="#ffffff"
                borderRadius={"0"}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.approvalLevel}
              />
              {touched.approvalLevel && errors.approvalLevel && (
                <FormErrorMessage>{errors.approvalLevel}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.assignRole && touched.assignRole}
              color="#121212"
            >
              <FormLabel htmlFor="assignRole" fontSize={"16px"}>
                Assign Role
              </FormLabel>
              <Select
                name="assignRole"
                onBlur={handleBlur}
                // placeholder="Select Here"
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.assignRole}
              >
                {roles &&
                  roles?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </Select>
              {touched.assignRole && errors.assignRole && (
                <FormErrorMessage>{errors.assignRole}</FormErrorMessage>
              )}
            </FormControl>
            {/* <FormControl mt={4} isInvalid={errors.approvalPoint && touched.approvalPoint} color="#121212">
              <FormLabel htmlFor='approvalPoint' fontSize={'16px'}>Approval Point</FormLabel>
              <Select
                name="approvalPoint"
                placeholder="Select Here"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.approvalPoint}
              >

                <option value='OnRegistration'>On Registration</option>
                <option value='OnMembershipUpgrade'>On Membership Upgrade</option>
                <option value='OnExamCenterChange'>On Exam Center Change</option>
              </Select>
              {touched.approvalPoint && errors.approvalPoint && (
                <FormErrorMessage>{errors.approvalPoint}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={errors.membershipGrade && touched.membershipGrade}
              color="#121212"
            >
              <FormLabel htmlFor="membershipGrade" fontSize={"16px"}>
                Memebership Grade
              </FormLabel>
              <Select
                name="membershipGrade"
                placeholder="Select Here"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.membershipGrade}
              >
                <option value="Student">Student</option>
                <option value="NYSC">NYSC</option>
                <option value="Associate">Associate</option>
                <option value="Fellow">Fellow</option>
              </Select>
              {touched.membershipGrade && errors.membershipGrade && (
                <FormErrorMessage>{errors.membershipGrade}</FormErrorMessage>
              )}
            </FormControl> */}
            <Flex justifyContent={"center"} alignItems={"center"} gap={5}>
              <Button
                mt={10}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                width={"118px"}
                background={"#D11A2A"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Edit
              </Button>
              <Button
                mt={10}
                fontSize={"14px"}
                fontWeight={"500"}
                lineHeight={"16px"}
                width={"118px"}
                background={""}
                _hover={{ background: " " }}
                color={"#8F9BB3"}
                onClick={() => {
                  dispatch(setFormModal({ status: false, data: null }));
                }}
                loadingText="Saving ..."
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditApprovalWorkflow