import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useState } from 'react';
import PendingTable from './PendingTable';
import ApprovalTable from './ApprovalTable';
import DeclinedTable from './DeclinedTable';
import { useEffect } from 'react';
import {fetchMemberUpgradeApps} from "../redux/slices/membershipUpgradeSlice";
import { useDispatch, useSelector } from 'react-redux';

function MembershipUpgradeTab() {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [status, setStatus] = useState("Pending");
    const handleTabChange = (index) => {
        setSelectedTab(index);
        setStatus(index === 0 ? "Pending" : index === 1 ? "Approved" : "Declined");
    };

    useEffect(() =>{
        dispatch(fetchMemberUpgradeApps(status));
    }, [dispatch, status]);
    const memberUpgradeApps = useSelector((state) => state?.membershipUpgrade?.memberUpgradeApps);
    const isloading = useSelector((state) => state?.membershipUpgrade?.loading);


    return (
        <Tabs variant='unstyled'>
            <TabList gap={3} mb={3}>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(0)}
                    style={selectedTab === 0 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Pending
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(1)}
                    style={selectedTab === 1 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Approved
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(2)}
                    style={selectedTab === 2 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Declined
                </Tab>
            </TabList>
            <TabPanels bgColor={'white'} minH={'100vh'}>
                <TabPanel>
                   <PendingTable memberUpgradeApps={memberUpgradeApps} isloading={isloading} />
                </TabPanel>
                <TabPanel>
                    <ApprovalTable memberUpgradeApps={memberUpgradeApps} isloading={isloading} />
                </TabPanel>
                <TabPanel>
                   <DeclinedTable memberUpgradeApps={memberUpgradeApps} isloading={isloading}/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default MembershipUpgradeTab;
