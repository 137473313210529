import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import ExamApplicantListingTable from '../components/ExamApplicantListingTable';
import { useDispatch, useSelector } from 'react-redux';
import { fecthExamYear } from '../redux/slices/examMgtSlice';
import { fetchExamDiet, fetchExamPeriod } from '../redux/slices/examDietPeriodSlice';
import { fetchExamApplicantReport } from '../redux/slices/report2Slice';
import { setloadTost } from '../redux/slices/toastSlice';
import { fetchExamCentre } from '../redux/slices/examCentreSlice';

function ExamApplicantReport() {
    const dispatch = useDispatch();

    const [ExamPeriodId, setExamPeriodId] = useState('');

    const examDietData = useSelector((state) => state?.examDietPeriod?.examDiet?.data);
    const examPeriodData = useSelector((state) => state?.examDietPeriod?.examPeriod?.data);
    const examCentresData = useSelector((state) => state?.examCentre?.examCentre?.data);
    const examApplicantReport = useSelector((state) => state?.report2?.examApplicantReport?.data)
    console.log('examApplicantReport', examApplicantReport)


    useEffect(() => {
        dispatch(fecthExamYear());
        dispatch(fetchExamDiet());
        dispatch(fetchExamCentre())
    }, [dispatch]);

    const initialValues = {
        examCentre: '',
        examDiet: '',
        examYear: '',
    };

    const handleSubmit = async (values, actions) => {
        const response = await dispatch(fetchExamApplicantReport({ ExamPeriodId: ExamPeriodId, ExamCentreId: values?.examCentre, startDate: values?.startDate, endDate: values?.endDate, approvalReportCategory: values?.category }));
        if (response?.payload?.isSuccess === false) {
            dispatch(setloadTost({ title: 'Error', description: response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to get report", status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
        } else {
            dispatch(setloadTost({ title: 'Success', description: `report generated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            actions.resetForm();
        }
    }
    return (
        <>
            <Box bgColor={'white'} height={["850px", "", '370px']} px={'30px'} py={'20px'} mb={5}>
                <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // validationSchema={approvalReportSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                        errors, touched, values, handleBlur, handleChange, setFieldValue
                    }) => (

                        <Form>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.diet && touched.diet} color="#121212">
                                    <FormLabel htmlFor='diet' fontSize={'16px'}> Exam Diet</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="diet"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            const selectedValue = event.target.value; setFieldValue("examDiet", selectedValue);
                                            console.log('selectedValue', selectedValue);
                                            dispatch(fetchExamPeriod(selectedValue));
                                        }}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        placeholder='Select Diet'
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.diet}
                                    >
                                        {
                                            examDietData?.map((item, index) => (
                                                <option key={index} value={item?.id}>{item?.name}</option>
                                            ))
                                        }
                                    </Select>
                                    {touched.diet && errors.diet && (
                                        <FormErrorMessage>{errors.diet}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.examYear && touched.examYear} color="#121212">
                                    <FormLabel htmlFor='examYear' fontSize={'16px'}>Exam Year</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="examYear"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            const selectedValue = event.target.value; setFieldValue("examYear", selectedValue);
                                            console.log('selectedValueExam', selectedValue);
                                            setExamPeriodId(selectedValue)
                                        }}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        placeholder='Select Year'
                                        value={values.examYear}
                                    >
                                        {examPeriodData?.map((item, index) => (
                                            <option key={index} value={item?.id}>{item?.year}</option>
                                        ))}
                                    </Select>
                                    {touched.examYear && errors.examYear && (
                                        <FormErrorMessage>{errors.examYear}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.examCentre && touched.examCentre} color="#121212">
                                    <FormLabel htmlFor='examCentre' fontSize={'16px'}>Exam Centre</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="examCentre"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        placeholder='Select exam centre'
                                        value={values?.examCentre}
                                    >
                                        {
                                            examCentresData?.map((item, index) => (
                                                <option key={index} value={item?.id}>{item?.address},{item?.name}</option>

                                            ))
                                        }
                                    </Select>
                                    {touched.examCentre && errors.examCentre && (
                                        <FormErrorMessage>{errors.examCentre}</FormErrorMessage>
                                    )}
                                </FormControl>

                            </Flex>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                                    color="#121212" fontFamily={'inter'}>
                                    <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                                    <Input
                                        height={'52px'}
                                        type="date"
                                        placeholder="Pick a Date"
                                        borderRadius={"0"}
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="startDate"
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.startDate}
                                    />
                                    {touched.startDate && errors.startDate && (
                                        <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                                    color="#121212" fontFamily={'inter'}>
                                    <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                                    <Input
                                        height={'52px'}
                                        type="date"
                                        placeholder="Pick a Date"
                                        borderRadius={"0"}
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="endDate"
                                        cursor={"pointer"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.endDate}
                                    />
                                    {touched.endDate && errors.endDate && (
                                        <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                            <Flex justifyContent={'flex-end'} alignItems={'center'}>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                    width={"118px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                // isLoading={loading}
                                // loadingText="Saving ..."
                                >
                                    Fetch Report
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
            {
                examApplicantReport ? (
                    <ExamApplicantListingTable examApplicantReport={examApplicantReport} />
                ) : null
            }
        </>
    )
}

export default ExamApplicantReport