import React from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const MembershipPieChart = ({ membershippieChartData = {} }) => {
    const colors = ["#222B45", "#FFC34F", "#953B8B", "#F07E8D", "#416EFC"];

    // Define chart data with a fallback of 0.1 for zero values to ensure all slices appear
    const chartData = [
        { name: "Associate", value: membershippieChartData.totalAssociate || 0.1 },
        { name: "Fellow", value: membershippieChartData.totalFellow || 0.1 },
        { name: "Graduate", value: membershippieChartData.totalGraduate || 0.1 },
        { name: "NYSC", value: membershippieChartData.totalNYSC || 0.1 },
        { name: "Student", value: membershippieChartData.totalStudent || 0.1 },
    ];

    // Calculate the total number of upgrades
    const sumTotal = chartData.reduce((acc, data) => acc + (data.value > 0.1 ? data.value : 0), 0);
    const RADIAN = Math.PI / 180;

    // Render labels centered in each slice
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const displayValue = chartData[index].value > 0.1 ? chartData[index].value : 0;

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={12}
                fontWeight="bold"
            >
                {displayValue}
            </text>
        );
    };

    return (
        <Box bg={'#FFFFFF'} borderRadius={'20px'} p={5} h={'100%'}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex flexDirection={'column'}>
                    <Text color={'#4D4D4D'} fontSize={'16px'} fontWeight={'500'}>
                        Member Upgrade Per Category
                    </Text>
                    <Text color={'#4D4D4D'} fontSize={'10px'} fontWeight={'400'}>
                        {sumTotal} Total Upgrades
                    </Text>
                </Flex>
            </Flex>
            <Flex w="100%" flexDirection={'column'} alignItems="center">
                <ResponsiveContainer width="100%" height={250}>
                    <PieChart>
                        <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            innerRadius={45}
                            outerRadius={85}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <Divider orientation='horizontal' border={`1px solid #C6C6C666`} />
                <Flex direction="column" py={5} gap={2}>
                    {chartData.map((item, index) => (
                        <Flex alignItems={'center'} justifyContent={'space-between'} key={index}>
                            <Flex alignItems={'center'} gap={2}>
                                <Box bg={colors[index % colors.length]} h={2} w={4} borderRadius="3px" />
                                <Text fontSize="12px" color="#000000B2" fontWeight={'700'}>
                                    {item.name}
                                </Text>
                            </Flex>
                            <Text color={'#717171'} fontSize={'12px'} fontWeight={'500'}>
                                {item.value > 0.1 ? item.value : 0} Registered members
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Box>
    );
};

export default MembershipPieChart;
