import React, { useCallback, useEffect, useState } from 'react'
import { Alert, AlertIcon, AlertTitle, Box, Flex, FormControl, Menu, MenuButton, MenuItem, MenuList, Select, Text } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { setFormModal } from '../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import Create from '../components/layout/Create';
import { fetchFees, fetchFeeType, setEditFees } from '../redux/slices/feeMgtSlice';
import { checkPermissions } from '../components/CheckPermission';
import { clearsearchFilter } from '../redux/slices/searchFilterSlice';
import { BASE_URL } from '../config/settings';
import httpRequest from '../utils/httpRequest';
import { setloadTost } from '../redux/slices/toastSlice';
import DotLoader from '../components/loaders/DotLoader';


function FeeItems() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllFees = useCallback( async() => {
    setError("");
    setLoading(true);
    try {
      const response = await dispatch(fetchFees());
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Fee Type, please check your request/network");
      }
      
    } catch (error) {
      setLoading(false);
      setError(error?.payload?.message || "Failed to get Fee Type, please check your network");
    }finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllFees();
    dispatch(fetchFeeType())
  }, [dispatch, getAllFees]);


  const feeItems = useSelector((state) => state?.feeMgt?.fees?.data);

  const addFeePermission = checkPermissions( 'Fee Management', 'Fee', 'Create', 'Granted' );

  const editFeePermission = checkPermissions( 'Fee Management', 'Fee', 'Edit', 'Granted' );

  const deleteFeePermission = checkPermissions( 'Fee Management', 'Fee', 'Delete', 'Granted' );

  const handleEdit = (item) => {
    dispatch(setEditFees(item))
    dispatch(setFormModal({status:true, title: "Edit Fee", type:"editFee"}));
  }

  const handleDelete = async (item) => {
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Fee/${item?.id}/delete`,
        "post",
      );
      if (response?.isSuccess === true) {
        setError('');
        dispatch(fetchFees());
        dispatch(setloadTost({ title: 'Fee Deleted', description: 'Fee deleted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete fee");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to delete fee, please check your network");
    }
  }

  const handleChangeStatus = async (item) => {
    setError('');
    try{
      if(item?.isActive === true){
        const response = await httpRequest(
          `${BASE_URL}/v2/Fee/${item?.id}/status?active=false`,
          'put',
        );
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchFees());
          dispatch(setloadTost({ title: 'Fee Deactivated', description: `${item?.feeName} Deactivated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to deactivate Fee");
        }
      }else{
        const response = await httpRequest(
          `${BASE_URL}/v2/Fee/${item?.id}/status?active=true`,
          'put',
        );
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchFees());
          dispatch(setloadTost({ title: 'Fee Activated', description: `${item?.feeName}  Activated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Activate Fee");
        }
      }

    }catch{
      setError( error?.response?.message ||"Request Failed");
    }
  }

  const createData = {
    onClick: () => { dispatch(setFormModal({ status: true, title: "Add Fee", type: "addFee", })); },
    createText: "Add Fee ",
  }


  const tableProps = {
    data: feeItems,
    title: {
      feeName: "Fee Name",
      feeFrequency: "Fee Frequency",
      feeType: "Fee Type",
      memberCategory: "Member Category",
      examCategory: "Exam Category",
      amount: "Amount",
      feeItemsStatus: "Status",
      Details: "",
    },
    dataKeys: [
      "feeName",
      "feeFrequency",
      "feeType",
      "memberCategory",
      "examCategory",
      "amount",
      "feeItemsStatus",
      "Details",
    ],
    transformRow: (item) => ({
      feeName: item?.feeName,
      feeFrequency: item?.frequency,
      feeType: item?.feeType,
      memberCategory: item?.memberCategory,
      examCategory:item?.examCategory,
      amount: item?.amount,
      feeItemsStatus: item && <Box color={item?.isActive? 'green.500' : '#D11A2A'}>{item?.isActive? 'Active' : 'Inactive'}</Box>,
      Details: item && (
        <Menu px={'10px'}>
          {
            !editFeePermission && !deleteFeePermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
          <MenuList >
            {
              editFeePermission? (
                <MenuItem
                  onClick={() => handleEdit(item)}>Edit</MenuItem>
              ) : null
            }
             {
              editFeePermission? (
                <MenuItem onClick={() => handleChangeStatus(item)}>{item?.isActive === true ? "Deactivate Fee" : "Activate Fee"}</MenuItem>
              ) : null
            }
            {
              deleteFeePermission? (
                <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      ),
    }),
  };
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Fee Items</Text>
        <Flex gap={[3, null, null]} flexDirection={["column", "column", "row"]} justifyContent={'center'} alignItems={'center'} >
         {
          addFeePermission? (
            <Create {...createData} />
          ) : null
         }
          <Flex alignItems={'center'}>
            <FormControl w={'100px'}>
              <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
              </Select>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ):(
          <>
            {
            error ? (
              <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
              </Alert>
              ) 
              : (<CustomTable {...tableProps} />)
            }
          </>
        )
      }
    </Box>
  )
}

export default FeeItems