import React from 'react';
import Lottie from "lottie-react";
import paymentSuccess from "../Assets/lotties/Animation-payment-success.json";
import { Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';

const PaymentSuccessModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOkay = () => {
    dispatch(setFormModal({ status: false, data: null }));
    navigate('/mydues');
  }

  return (
    <Flex flexDirection={"column"} justifyContent={'center'} alignItems={"center"} gap={5} py={'50px'}>
      <Lottie animationData={paymentSuccess} autoPlay={true} loop={true} style={{ width: "150px", height: "150px" }} />
      <Text color={"#515151"} fontWeight={"700"} fontSize={"24px"} lineHeight={"36.7px"}>Payment Successful</Text>
      <Text textDecoration={"underline"} color={"#D11A2A"} fontWeight={"700"} fontSize={"14px"} lineHeight={"21.41px"}>DOWNLOAD RECEIPT</Text>
      <Button size={'sm'} bgColor={'#D11A2A'} color={'white'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16px'} w={'137px'} 
        _hover={{backgroundColor: ""}} _active={{backgroundColor: ""}} onClick={handleOkay}
      >Okay</Button>

    </Flex>
  )
}

export default PaymentSuccessModal;