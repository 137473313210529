import React from 'react';
import { Box, Flex, Text, Divider, useBreakpointValue } from '@chakra-ui/react';


const DetailsCard = ({details}) => {
        const orientation = useBreakpointValue({base:'horizontal', sm:'horizontal', md:'horizontal', lg:'vertical' });

    return (
        <Box bg={'#FFFFFF'} borderRadius={'20px'} >
            <Flex gap={5} flexDirection={['column', 'column', 'row']} justifyContent={'center'} alignItems={'center'} px={7} h={['100%','100%','222px']} py={['20px', '20px', '0px']}>
                {
                    details?.map((detail, index) => (
                        <React.Fragment key={index}>
                            <Box bg={detail?.bgcolor} p={2} borderRadius={'10px'}>
                                {detail?.icon}
                            </Box>
                            <Flex flexDirection={'column'} alignItems={['center', 'center', 'flex-start']}>
                                <Text color={'#4D4D4D'} fontSize={'14px'} fontWeight={'500'} letterSpacing={'-0.154px'}>{detail?.name}</Text>
                                <Text color={'#000000'} fontSize={'16px'} fontWeight={'700'}>{detail?.number}</Text>
                            </Flex>
                            {index !== details?.length - 1 && <Divider orientation= {orientation} border={'1px solid #C6C6C666'} h={[null, null, null, '158px']}  />}
                        </React.Fragment>
                    ))
                }
            </Flex>
        </Box>
    );
};

export default DetailsCard;
