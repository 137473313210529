import React , {useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Flex, Input, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {addSubjectSchema} from "../utils/formValidationSchema";
import {createExamSubject, fetchExamSubject} from "../redux/slices/examCategorySubjectSlice";
import {setloadTost} from "../redux/slices/toastSlice";

const AddSubject = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const examCategoryDetails = useSelector((state) => state?.examCategorySubject?.examCategoryDetails);
    
  const initialValues = {
    subjectName: '',
    subjectCode: '',
  };
  
    const handleSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    setError("");
    try {
      const formData = {
        name: values?.subjectName,
        code: values?.subjectCode,
      }

      const response = await dispatch(createExamSubject({arg: formData, id:examCategoryDetails?.id }));
      // console.log(response);
      if (response.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(fetchExamSubject(examCategoryDetails?.id))
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Subject Created', description: `${values?.subjectName} subject created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      }else{
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create subject");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to create subject, please check your network");
    }finally{
      setLoading(false);
    }

    }

  return (
  <>
  {
    error &&(
    <Alert status="error" className="mt-3" mb={3}>
        <AlertIcon />
        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
    </Alert>
    )
    }
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={addSubjectSchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
       <FormControl mt={4} isInvalid={errors.subjectName && touched.subjectName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='subjectName' fontSize={"16px"}>Subject Name</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="subjectName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.subjectName}
          />
          {touched.subjectName && errors.subjectName && (
            <FormErrorMessage>{errors.subjectName}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.subjectCode && touched.subjectCode}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='subjectCode' fontSize={"16px"}>Subject Code</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="subjectCode"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.subjectCode}
          />
          {touched.subjectCode && errors.subjectCode && (
            <FormErrorMessage>{errors.subjectCode}</FormErrorMessage>
          )}
        </FormControl>
        
        <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={"#D11A2A"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={loading}
            loadingText="Saving ..."
          >
            Add
          </Button>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={""}
            _hover={{ background: " " }}
            color={"#8F9BB3"}
            onClick={() => {
              dispatch(setFormModal({ status: false, data: null }));
            }}
            loadingText="Saving ..."
          >
            Cancel
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
  </>
  )
}

export default AddSubject;