import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Alert, AlertIcon, AlertTitle, Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {fetchBioData, setBioDataDetail} from "../redux/slices/membershipManagementSlice";


const UpdateBiodata = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const navigate = useNavigate();

  const initialValues = {
    memberNumber: '',
    memberCategory: '',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchBioData({memberNumber:values?.memberNumber, memberCategory:values?.memberCategory}));
      if (response?.payload?.isSuccess === true) {
          setLoading(false);
          dispatch(setBioDataDetail(response?.payload));
          navigate('/report/memberreport/member-profile');
      } else {
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to fetch Biodata");
      }
    } catch (error) {
      setError(error?.payload?.message || "Failed to fetch Biodata, please check your network");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {
        error && (
          <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
          </Alert>
        )
      }
      <Box bgColor={'white'} height={["500px", "", '220px']} px={'30px'} py={'20px'} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            errors, touched, values, handleBlur, handleChange
          }) => (

            <Form>
              <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={8}>
                <FormControl mt={4} isInvalid={errors.memberNumber && touched.memberNumber}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='memberNumber' fontSize={"16px"}>Member Number</FormLabel>
                  <Input
                    height={'52px'}
                    type="text"
                    placeholder="Enter Here"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="memberNumber"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.memberNumber}
                  />
                  {touched.memberNumber && errors.memberNumber && (
                    <FormErrorMessage>{errors.memberNumber}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.memberCategory && touched.memberCategory} color="#121212">
                  <FormLabel htmlFor='memberCategory' fontSize={'16px'}>Member Category</FormLabel>
                  <Select
                    height={'52px'}
                    name="memberCategory"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Select Here'
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.memberCategory}
                  >
                    <option value='Student'>Student</option>
                    <option value='NYSC'>NYSC</option>
                    <option value='Graduate'>Graduate</option>
                    <option value='Associate'>Associate</option>
                    <option value='Fellow'>Fellow</option>
                    <option value='Retiree'>Retiree</option>
                  </Select>
                  {touched.memberCategory && errors.memberCategory && (
                    <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <Button
                    mt={12} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                    width={"210px"} h={'50px'}
                    background={"#D11A2A"}
                    _hover={{ background: " " }}
                    _active={{ background: " " }}
                    color={"#FFFFFF"}
                    type="submit"
                    isLoading={loading}
                    loadingText="Fetching ..."
                  >
                    Fetch Report
                  </Button>
                </FormControl>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default UpdateBiodata;