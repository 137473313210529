import React from "react";
import { Box, Flex, Text, Select } from "@chakra-ui/react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { registeredStudents } from "../data/mock";
import CustomTable from '../components/CustomTable';
import { MdOutlineFileUpload } from "react-icons/md";


const RegisteredStudents = () => {
    const navigate = useNavigate();
    const tableProps = {
        data: registeredStudents,
        title: {
            studentName: "Student Name",
            registrationNumber: "Registration Number",
            dateRegistered: "Date Registered",
        },
        dataKeys: [
            "studentName",
            "registrationNumber",
            "dateRegistered"
        ],
        transformRow: (item) => ({
            studentName: item.studentName,
            registrationNumber: item.registrationNumber,
            dateRegistered: item.dateRegistered,
        }),
    };
    const list = [
        { title: 'Centre Name:', description: 'Lagos' },
        { title: 'Centre Code:', description: 'LAG' },
        { title: 'Centre Address:', description: 'Centre of Secretaries and development Surulere, Lagos.' },
    ]
    return (
        <Box h={'100%'} >
            <Box bgColor={'white'}>
                <Flex justifyContent={'flex-start'} bgColor={'#F9F8F8'} p={15} h={'70px'} alignItems={'center'} gap={'10px'} mt={'20px'} borderBottom={'1px solid #F4C8CA'}
                    cursor={'pointer'} onClick={() => navigate(-1)}>
                    <IoArrowBackCircle color="rgba(216, 153, 31, 1)" size={'30px'} />
                    <Text color={'rgba(34, 43, 69, 0.5)'} fontSize={'18px'}>Exam Centre/ Lagos</Text>
                </Flex>
                <Text color={'#222B45'} fontSize={'14px'} fontWeight={700} bgColor={'#D734390A'} h={'50px'} pt={4} pl={'51px'} mb={5}>CENTRE DETAILS</Text>
                {
                    list?.map((item, index) => (
                        <Box bgColor={'white'}>
                            <Flex key={index} alignItems={'center'} px={'50px'} pb={5}>
                                <Text color={'rgba(81, 81, 101, 0.5)'} fontSize={'16px'} fontWeight={700} w={'15%'}>
                                    {item.title}
                                </Text>
                                <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'14px'} fontWeight={700} w={'85%'}>
                                    {item.description}
                                </Text>
                            </Flex>
                        </Box>
                    ))
                }
            </Box>
            <Box mt={'20px'} bgColor={'white'}>
                <Flex flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
                    <Text fontSize={'16px'} fontWeight={'700'} color={'#121212'} opacity={'0.5'} >Registered Students</Text>
                    <Flex alignItems={'center'} justifyContent={'center'} gap={'20px'} mt={3}>
                        <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </Select>
                        <Flex alignItems={'center'} _hover={{ color: '#D11A2A' }} cursor={'pointer'}>
                            <MdOutlineFileUpload />
                            <Text fontSize={'14px'} fontWeight={500} color={'#404040'} w={'100px'} _hover={{ color: '#D11A2A' }}>EXPORT</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <CustomTable {...tableProps} />
            </Box>
        </Box>
    )
}

export default RegisteredStudents;