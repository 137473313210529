import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import TransactionLogTable from "../components/TransactionLogTable";

const TransactionLogReport = () => {
  const [toggleReport, setToggleReport] = useState(false);

  const initialValues = {
    membershipNo: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values) => {
    setToggleReport(!toggleReport);
  };
  return (
    <>
      <Box
        bgColor={"white"}
        height={["550px", "", "270px"]}
        px={"30px"}
        py={"20px"}
        pb={"10px"}
      >
        <Text color={"#3A3541DE"} fontSize={"16px"} fontWeight={500}>
          Search Filters
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={paymentSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl
                  mt={4}
                  isInvalid={errors.membershipNo && touched.membershipNo}
                  color="#121212"
                >
                  <FormLabel htmlFor="membershipNo" fontSize={"16px"}>
                    Membership No
                  </FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="membershipNo"
                    focusBorderColor="#ffffff"
                    borderRadius={"0"}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.membershipNo}
                  />
                  {touched.membershipNo && errors.membershipNo && (
                    <FormErrorMessage>{errors.membershipNo}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.startDate && touched.startDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="startDate" fontSize={"16px"}>
                    Start Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.endDate && touched.endDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="endDate" fontSize={"16px"}>
                    End Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl></FormControl>
                <FormControl></FormControl>
              </Flex>
              <Flex justifyContent={"flex-end"} alignItems={"center"}>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  // isLoading={loading}
                  // loadingText="Saving ..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {toggleReport ? <TransactionLogTable /> : null}
    </>
  );
};

export default TransactionLogReport;
