import React from 'react';
import { Tab, TabList, TabPanel, Tabs, TabPanels } from '@chakra-ui/react';
import PersonalInformation from './PersonalInformation';
import EmploymentInformation from './EmploymentInformation';
import QualificationsInformation from './QualificationsInformation';
import DocumentsUpload from './DocumentsUpload';
import MarriageRefereeInfo from './MarriageRefereeInfo';
import { useSelector } from 'react-redux';

const IndividualDetails = () => {
    const individualDetails = useSelector((state) => state.modal.formModal?.data);
  return (
    <>
    <Tabs variant='unstyled' pt={5}>
        <TabList gap={'20px'} pb={4} 
            sx={{
                overflowY: "scroll",
                scrollbarWidth: "none", 
                msOverflowStyle: "none", 
                "-ms-overflow-style": "none", 
                "&::-webkit-scrollbar": {
                    display: "none", 
                },
            }}
            overflowY={"auto"}
        >
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Personal Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Employment Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Qualifications Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Marriage/Referee Info</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']}  borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Documents Upload</Tab>
        </TabList>

        <TabPanels bg={'#FFFFFF'} >
            <TabPanel>
                <PersonalInformation individualDetails={individualDetails} />
            </TabPanel>
            <TabPanel>
                <EmploymentInformation individualDetails={individualDetails} />
            </TabPanel>
            <TabPanel>
                <QualificationsInformation individualDetails={individualDetails} />
            </TabPanel>
            <TabPanel>
                <MarriageRefereeInfo individualDetails={individualDetails} />
            </TabPanel>
            <TabPanel>
                <DocumentsUpload individualDetails={individualDetails} />
            </TabPanel>
        </TabPanels>
    </Tabs>
    </>
  )
}

export default IndividualDetails;