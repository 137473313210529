import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { examResultSchema } from '../utils/formValidationSchema';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Select, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamCategory } from '../redux/slices/examCategorySubjectSlice';
import { fecthExamYear } from '../redux/slices/examMgtSlice';
import { fetchExamDiet, fetchExamPeriod } from '../redux/slices/examDietPeriodSlice';

const ViewExamResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const examDietData = useSelector((state) => state?.examDietPeriod?.examDiet?.data)
  const examCategoryData = useSelector((state) => state?.examCategorySubject?.examCategory?.data);
  const examPeriodData = useSelector((state) => state?.examDietPeriod?.examPeriod?.data)
  console.log('examPeriodData', examPeriodData)
  console.log('examCategoryData', examCategoryData)

  console.log('examDiet', examDietData)

  useEffect(() => {
    dispatch(fecthExamYear());
    dispatch(fetchExamDiet());
    dispatch(fetchExamCategory(1000))
  }, [dispatch]);


  const initialValues = {
    examCategory: '',
    examyear: '',
    examDiet: '',
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    navigate(`/exammanagement/viewexamresult/memberexamstatement?ExamCategory=${values?.examCategory}&ExamYear=${values?.examyear}`);
  }

  return (
    <>
      <Box bgColor={'white'} height={['500px', '', '270px']} px={'30px'} py={'20px'} mt={1} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500} lineHeight={'32px'} letterSpacing={'0.15px'}>
          Exam Result
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={examResultSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <Flex flexDirection={['column', 'column', 'row']} alignItems={'center'} gap={3}>
                <FormControl mt={4} isInvalid={errors.examDiet && touched.examDiet} color="#121212">
                  <FormLabel htmlFor='examDiet' fontSize={'16px'}>Exam Diet</FormLabel>
                  <Select
                    height={'52px'}
                    name="examDiet"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const selectedValue = event.target.value; setFieldValue("examDiet", selectedValue);
                      console.log('selectedValue', selectedValue);
                      dispatch(fetchExamPeriod(selectedValue));
                    }}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    placeholder='Select Diet'
                    value={values.examDiet}
                  >

                    {
                      examDietData?.map((item, index) => (
                        <option key={index} value={item?.id}>{item?.name}</option>
                      ))
                    }
                  </Select>
                  {touched.examDiet && errors.examDiet && (
                    <FormErrorMessage>{errors.examDiet}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl mt={4} isInvalid={errors.examyear && touched.examyear} color="#121212">
                  <FormLabel htmlFor='examyear' fontSize={'16px'}>Exam Year</FormLabel>
                  <Select
                    height={'52px'}
                    name="examyear"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    placeholder='Select Year'
                    value={values.examyear}
                  >
                    { examPeriodData?.map((item, index) => (
                      <option key={index} value={item?.id}>{item?.year}</option>
                    ))}
                  </Select>
                  {touched.examyear && errors.examyear && (
                    <FormErrorMessage>{errors.examyear}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.examCategory && touched.examCategory}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='examCategory' fontSize={"16px"}>Exam Category</FormLabel>
                  <Select
                    name="examCategory"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    placeholder='Select Exam Category'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examCategory}
                  >
                    {examCategoryData?.map((item) => (
                      <option key={item?.id} value={item?.id}>{item?.name}</option>
                    ))}
                  </Select>
                  {touched.examCategory && errors.examCategory && (
                    <FormErrorMessage>{errors.examCategory}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>

              <Flex justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  mt={10}
                  fontSize={'14px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  _active={{ background: "" }}
                  // isLoading={loading}
                  loadingText="Fetching..."
                >
                  Fetch Result
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default ViewExamResult;