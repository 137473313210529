import React, { useState } from 'react';
import { Form, Formik } from "formik";
import { Box, Flex,Text, FormControl, FormErrorMessage, FormLabel, Input, Button, Image, InputGroup, InputLeftElement, InputRightElement, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import changePassword from "../Assets/Images/change-password-img.svg";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { BsFillKeyFill } from "react-icons/bs";
import {changePasswordSchema} from "../utils/formValidationSchema";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {setloadTost} from "../redux/slices/toastSlice";
import { useDispatch, useSelector } from 'react-redux';

const ProfileChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const user = useSelector((state) => state.auth?.userProfile);
    const member = useSelector((state) => state.auth?.memberProfile);

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    
    const handleSubmit = async (values, actions) => {
        let response;
        setError("");
        setLoading(true);
        try {
            const formData = {
                currentPassword: values?.currentPassword,
                newPassword: values?.newPassword,
            };

            if(member?.isSuccess === true){
                response = await httpRequest(
                    `${BASE_URL}/v2/MembershipManagement/change-password`,
                    "put",
                    formData
                );
                
            }else if(user?.isSuccess === true){
                response = await httpRequest(
                    `${BASE_URL}/v2/Administrative/change-password`,
                    "put",
                    formData
                );
            }
            if(response.isSuccess === true){
                setLoading(false);
                dispatch(setloadTost({ title: 'Password Changed', description: 'Password has been changed successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
                actions.resetForm();  // Reset the form fields
            }else{
                setLoading(false);
                setError(response?.errors?.map((error) => error) || response?.error?.description || "Failed to change password");
            }
    
        } catch (error) {
            setError(error?.payload?.message || "Failed to change password, please check your network");
        }finally{
            setLoading(false);
        }
    }

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={5}>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
            <Flex alignItems={'center'} gap={'20px'} onClick={() => navigate(-1)} >
                <Box bg={'#F9A36238'} rounded={'full'} p={1}>
                    <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                </Box>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>Change Password </Text>
            </Flex>
        </Flex>
        {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
      }
        <Flex justifyContent={'center'} alignItems={'center'} pt={12} pb={5}>
            <Image src={changePassword} alt="change password" />
        </Flex>

        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
            {({ errors, touched, values, handleBlur, handleChange }) => (
                <Form>
                    <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={[null,null, 10]}>
                        <FormControl mt={4} isInvalid={errors.currentPassword && touched.currentPassword}
                            color="#121212" fontFamily={'inter'}>
                        <FormLabel htmlFor='currentPassword' fontSize={"16px"}>Current Password</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                            <BsFillKeyFill style={{color: '#ABB1BB', transform: 'rotate(180deg) scaleY(-1)'}} />
                            </InputLeftElement>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                name="currentPassword"
                                focusBorderColor="#D1D5DB"
                                borderRadius={'8px'}
                                borderColor={"#D1D5DB"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#D1D5DB" }}
                                type={show? "text" : "password"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.currentPassword} 
                            />
                            <InputRightElement>
                            {
                                show? (
                                    <IoMdEyeOff style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow(!show)} />
                                ) : (
                                    <IoEye style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow(!show)} />
                                )
                                }
                            </InputRightElement>
                        </InputGroup>
                        {touched.currentPassword && errors.currentPassword && (
                            <FormErrorMessage>{errors.currentPassword}</FormErrorMessage>
                        )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.newPassword && touched.newPassword}
                            color="#121212" fontFamily={'inter'}>
                        <FormLabel htmlFor='newPassword' fontSize={"16px"}>New Password</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                            <BsFillKeyFill style={{color: '#ABB1BB', transform: 'rotate(180deg) scaleY(-1)'}} />
                            </InputLeftElement>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                name="newPassword"
                                focusBorderColor="#D1D5DB"
                                borderRadius={'8px'}
                                borderColor={"#D1D5DB"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#D1D5DB" }}
                                type={show2? "text" : "password"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.newPassword} 
                            />
                            <InputRightElement>
                            {
                                show2? (
                                    <IoMdEyeOff style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow2(!show2)} />
                                ) : (
                                    <IoEye style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow2(!show2)} />
                                )
                                }
                            </InputRightElement>
                        </InputGroup>
                        {touched.newPassword && errors.newPassword && (
                            <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                        )}
                        </FormControl>
                    </Flex>
                    <FormControl mt={4} isInvalid={errors.confirmPassword && touched.confirmPassword}
                            color="#121212" fontFamily={'inter'}>
                        <FormLabel htmlFor='confirmPassword' fontSize={"16px"}>Confirm New Password</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                            <BsFillKeyFill style={{color: '#ABB1BB', transform: 'rotate(180deg) scaleY(-1)'}} />
                            </InputLeftElement>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                name="confirmPassword"
                                focusBorderColor="#D1D5DB"
                                borderRadius={'8px'}
                                borderColor={"#D1D5DB"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#D1D5DB" }}
                                type={show3? "text" : "password"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.confirmPassword} 
                            />
                            <InputRightElement>
                            {
                                show3? (
                                    <IoMdEyeOff style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow3(!show3)} />
                                ) : (
                                    <IoEye style={{color: '#ABB1BB', cursor: 'pointer'}} onClick={() => setShow3(!show3)} />
                                )
                                }
                            </InputRightElement>
                        </InputGroup>
                        {touched.confirmPassword && errors.confirmPassword && (
                            <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                        )}
                    </FormControl>
                    <Flex  justifyContent={'center'} alignItems={"center"} gap={10}>
                        <Button
                            mt={10} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'}
                            width={"118px"}
                            background={""}
                            _hover={{ background: " " }}
                            color={"#D11A2A"}
                            border={'1px solid #D11A2A'}
                            loadingText="Saving ..."
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </Button>
                        <Button
                            mt={10} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'}
                            width={"118px"}
                            background={"#D11A2A"}
                            _hover={{ background: " " }}
                            color={"#FFFFFF"}
                            type="submit"
                            isLoading={loading}
                            loadingText="Saving ..."
                            >
                            Save Changes
                        </Button>
                    </Flex>
                
                </Form>
            )}
        </Formik>
    </Box>
  )
}

export default ProfileChangePassword;