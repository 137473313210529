import React, { useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Select, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import ReportTable from '../components/ReportTable';

function Report() {
    const [toggleReport, setToggleReport] = useState(false);

    const initialValues = {
        programmeName: '',
        membershipType: '',
    };

    const handleSubmit = async (values) => {
        setToggleReport(!toggleReport)
    }
    return (
        <>
            <Box bgColor={'white'} height={["550px", "", '270px']} px={'30px'} py={'20px'} pb={'10px'} >
                <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // validationSchema={approvalReportSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                        errors, touched, values, handleBlur, handleChange
                    }) => (

                        <Form>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.programmeName && touched.programmeName} color="#121212">
                                    <FormLabel htmlFor='programmeName' fontSize={'16px'}>Programme Name</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="programmeName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.programmeName}
                                    > <option value='active'>active</option>
                                        <option value='inactive'>inactive</option>
                                    </Select>
                                    {touched.programmeName && errors.programmeName && (
                                        <FormErrorMessage>{errors.programmeName}</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl mt={4} isInvalid={errors.membershipType && touched.membershipType} color="#121212">
                                    <FormLabel htmlFor='membershipType' fontSize={'16px'}>Membership Type</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="membershipType"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.membershipType}
                                    >
                                        <option value='active'>active</option>
                                        <option value='inactive'>inactive</option>
                                    </Select>
                                    {touched.membershipType && errors.membershipType && (
                                        <FormErrorMessage>{errors.membershipType}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                    width={"498px"}
                                    height={'50px'}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                // isLoading={loading}
                                // loadingText="Saving ..."
                                >
                                    Fetch Report
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
            {
                toggleReport ? (<ReportTable />) : null
            }
        </>
    )
}

export default Report