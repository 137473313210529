import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  mailNotification: [],
};

export const createMailNotification = createAsyncThunk(
  "mailNotification/createMailNotification",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/MailNotification`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const mailNotificationSlice = createSlice({
  name: "mailNotification",
  initialState,
  extraReducers: (builder) => {
    // create mail notification:
    builder.addCase(createMailNotification.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createMailNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createMailNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  },
});

export default mailNotificationSlice.reducer;