import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text, Button, Menu, MenuButton, MenuItem, MenuList, Alert,AlertIcon, AlertTitle  } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import CustomTable from '../components/CustomTable';
import { PiExport } from "react-icons/pi";
import {BsThreeDotsVertical} from 'react-icons/bs';
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {fetchExamCentre, setEditExamCentre} from "../redux/slices/examCentreSlice";
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";

const ExamCenters = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllExamCentre = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchExamCentre());
      // console.log('examCentre response', response);
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Centre, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Exam Centre, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllExamCentre();
  }, [dispatch, getAllExamCentre]);

  const addCentrePermission = checkPermissions( 'Exam Setup', 'Exam Centres', 'Create', 'Granted' );
  const editCentrePermission = checkPermissions( 'Exam Setup', 'Exam Centres', 'Edit', 'Granted' );
  const deleteCentrePermission = checkPermissions( 'Exam Setup', 'Exam Centres', 'Delete', 'Granted' );
  const examCentreData = useSelector((state) => state?.examCentre?.examCentre?.data);
  
  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Add Centre  ", type:"addCentre"})); },
    createText: "Add Centre",
  }
  const handleEdit = (item) => {
    dispatch(setEditExamCentre(item));
    dispatch(setFormModal({status:true, title: "Edit Centre", type:"editCentre"}));
  }

  const handleDelete = async (item) => {
    // console.log(item);
    setError('');
   try {
      const response = await httpRequest(`${BASE_URL}/v2/ExamCentre/${item?.id}/delete`,
      'post', 
    );
    // console.log(response);
    if(response?.isSuccess === true){
      setError('');
      dispatch(fetchExamCentre());
      dispatch(setloadTost({ title: 'Exam Centre Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }else{
      setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete Exam Centre");
    }
    
   } catch (error) {
    setError(error?.response?.message || "Failed to delete Exam Centre");
   }

}

  const tableProps = {
    data: examCentreData ,
    title: {
      centreName: "Centre Name",
      centreCode:"Centre Code",
      address:"Address",
      Action:'',
    },
    dataKeys: [
      "centreName",
      "centreCode",
      "address",
      "Action",
    ],
    
    transformRow: (item) => ({
      centreName: <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
      centreCode: <Box style={{cursor:'pointer'}}>{item?.code}</Box>,
      address: <Box style={{cursor:'pointer'}}>{item?.address}</Box>,
      Action: item &&  (
        <Menu>
          {
            !editCentrePermission && !deleteCentrePermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            )
          }
         
          <MenuList >
            {
              editCentrePermission ? (
                <MenuItem onClick={() => handleEdit(item)}> Edit </MenuItem>
              ) : null
            }
            {
              deleteCentrePermission? (
                <MenuItem onClick={() => handleDelete(item)}> Delete </MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      )
    }),
};

  return (
     <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Exam Centre</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
        {
          addCentrePermission ? (
            <Create {...createData} />
          ) : null
        }
        <SortBy />
        <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
          fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
        >
          Export
        </Button>
      </Flex>
    </Flex>
    {
      loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ): (
        <>
          {
          error ? (
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            ) 
            : (<CustomTable {...tableProps} />)
          }
        </>
      )
    }
  </Box>
  )
}

export default ExamCenters;