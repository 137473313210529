import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ApproveDecline from './ApproveDecline';

const EmploymentInformation = ({individualDetails}) => {
  const tagData = [
    {title:"Company Name:", discription:individualDetails?.employmentInformation?.name},
    {title:"Job Title:", discription:individualDetails?.employmentInformation?.jobTitle},
    {title:"Department:", discription:individualDetails?.employmentInformation?.department},
    {title:"Country:", discription:individualDetails?.employmentInformation?.country},
    {title:"Email Address:", discription:individualDetails?.employmentInformation?.email},
    {title:"Primary Address:", discription:individualDetails?.employmentInformation?.primaryAddress},
    {title:"Address", discription:individualDetails?.employmentInformation?.address},
    {title:"Telephone:", discription:individualDetails?.employmentInformation?.phoneNumber},
    {title:"Postal Code:", discription:individualDetails?.employmentInformation?.postalCode},
  ]

  // const tagData2 = [
  //   {title:"Title Of Appointment:", discription:individualDetails?.fullName},
  //   {title:"Held From:", discription:individualDetails?.DoB},
  //   {title:"To:", discription:individualDetails?.gender},
  //   {title:"Staff Under Personal Control:", discription:individualDetails?.DoA},
  //   {title:"Grade of Post:", discription:individualDetails?.An},

  // ]

  return (
    <>
      <Box h={'60vh'}
        // sx={{
        //   overflowY: "scroll",
        //   scrollbarWidth: "none", 
        //   msOverflowStyle: "none", 
        //   "-ms-overflow-style": "none", 
        //   "&::-webkit-scrollbar": {
        //     display: "none", 
        //   },
        // }}
        // overflowY={"auto"}
        overflow={'scroll'}
      >
      {/* <Text color={'#121212'} fontWeight={'600'} fontSize={'18px'} fontFamily={'roboto'} lineHeight={'18.9px'} textDecoration={'underline'}>Present Appointment Of Applicant</Text> */}
      {
      tagData?.map((data, index) =>(
      <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
      
      >
        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
        <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
      </Flex>
      ))
      }
      {/* <Text color={'#121212'} fontWeight={'600'} fontSize={'18px'} fontFamily={'roboto'} lineHeight={'18.9px'} textDecoration={'underline'}>Earlier Appointment in Same Organization</Text> */}
      {/* {
      tagData2?.map((data, index) =>(
      <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
      
      >
        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
        <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
      </Flex>
      ))
      } */}
      <ApproveDecline individualDetails={individualDetails} />
      </Box>
    </>
  )
}

export default EmploymentInformation;