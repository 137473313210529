import React, { useState } from 'react';
import { Checkbox, Box, Button, Flex, Text } from '@chakra-ui/react';
import {studentdashTableData} from "../data/mock";
import CustomTable from '../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import {setMyDuesDetails} from '../redux/slices/myDuesSlice';
import { useDispatch } from 'react-redux';

const MyDues = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkDues, setCheckDues] = useState(true);

    const handleMakePayment = () => {
        if (selectedItems.length > 0) {
            setCheckDues(true);
            dispatch(setMyDuesDetails(selectedItems));
            navigate("/mydues/paymentdetails");
        }else{
            setCheckDues(false);
        }
    };
    const handleCheckboxChange = (item, isChecked) => {
        if (isChecked) {
            setSelectedItems(prevSelectedItems => [...prevSelectedItems, item]);
        } else {
            setSelectedItems(prevSelectedItems =>
                prevSelectedItems.filter(selectedItem => selectedItem !== item)
            );
        }
    };

    const totalOutstandingFee = () => {
        let total = 0;
        if(selectedItems.length > 0){
            selectedItems.forEach(item => {
                total += parseInt(item?.amountOwed, 10);
            });
        }else{
            return 0;
        }
        return total;
    }

    const tableProps = {
        data:studentdashTableData,
        title: {
            checkbox: "",
            feeName: "Fee Name",
            year: "Fee Year",
            amountOwed: " Amount Owed",
        },
        dataKeys: [
          "checkbox",
          "feeName",
          "year",
          "amountOwed",
        ],
        sn: false,
        showPagination: false,
        transformRow: (item) => ({
            checkbox: item &&  (
                <Checkbox colorScheme='green' onChange={(e) => handleCheckboxChange(item, e.target.checked)} ></Checkbox>
            ),
            feeName: <Box style={{ cursor: "pointer" }}>{item?.feeName}</Box>,
            year: <Box style={{ cursor: "pointer" }}>{item?.year}</Box>,
            amountOwed: <Box style={{ cursor: "pointer" }}>{item?.amountOwed}</Box>,
        }),
    };

  return (
    <Box py={'20px'} minH={'100vh'} >
        <Flex flexDirection={'column'} gap={3} bgColor={'#D7343926'} px={'40px'} py={'20px'}>
            <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >My Dues</Text>
            <Text color={'#D11A2A'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'} fontStyle={'italic'}>Please select at least one dues entry to proceed</Text>
        </Flex>
        <Flex flexDirection={'column'} gap={5} bgColor={'#FFFFFF'} px={'40px'} py={'20px'}>
            <Flex alignItems={'center'} gap={10}>
                <Text color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'}>Total Outstanding Fee</Text>
                <Button size={'sm'} boxShadow={'lg'} px={5} bgColor={'#005E39'} color={'#FFFFFF'} fontWeight={'500'} fontSize={'14px'} lineHeight={'21.41px'} borderRadius={'5px'}
                _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
                >{totalOutstandingFee()}</Button>
            </Flex>
            <Flex flexDirection={'column'} alignItems={'center'} alignSelf={'flex-end'}>
                <Button mb={3} px={8}  bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
                    onClick={handleMakePayment}
                >Make Payment</Button> 
                {!checkDues && <Text color={'#D11A2A'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'} fontStyle={'italic'}>Please select at least one dues entry to proceed</Text>}
            </Flex>
        </Flex>
        <Flex mt={5} flexDirection={'column'} gap={3} bgColor={'#FFFFFF'} px={'40px'} py={'20px'}>
            <Text color={'#222B45'} opacity={'0.5'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'}>Outstanding Dues</Text>
            <CustomTable {...tableProps} />
        </Flex>
    </Box>
  )
}

export default MyDues;