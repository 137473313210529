import { Button, Drawer,
  DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, List, ListItem, Box,
  Flex, ListIcon, Avatar, Text, Menu, MenuButton, MenuItem, MenuList
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import React, {useState, useEffect} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { generateMenu, menu2} from './sideBarLinks';
import { useSelector, useDispatch } from "react-redux";
import { IoLogOut } from "react-icons/io5";
import { removeUser } from "../../redux/slices/authSlice";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa6";
import {File_URL} from "../../config/settings";

const MobileSidebar = ({ isOpen, btnRef, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { pathname } = useLocation();
  const [isCollapsed] = useState(false);
  const auth = useSelector((state) => state?.auth?.userProfile);
  const member = useSelector((state) => state.auth?.memberProfile);
  const userType = useSelector((state) => state.auth?.user?.userType);
  const [menu, setMenu] = useState('');
  const Permissions = useSelector((state) => state?.authPermissions?.Permissions);
  const profile = {
    firstName: auth?.firstName  || member?.firstName ,
    lastName: auth?.lastName || member?.lastName,
    role: auth?.role || member?.memberCategory,
  }

  useEffect(() => {
    if(userType === "Administrative"){
      if(Permissions){
        if (Array.isArray(Permissions)) {
          const result = generateMenu(Permissions);
          setMenu(result);
        } else if (Permissions) {
          // Convert Permissions into an array
          const result = generateMenu([Permissions]);
          setMenu(result);
        }
      }
    }else if(userType === "Member" ){
      setMenu(menu2);
    }

  }, [Permissions, userType]);
  
  const handleMenuItemClick = (name) => {
    if (selectedMenu === name) {
      setSelectedMenu(null); 
    } else {
      setSelectedMenu(name);
      onClose();
    }
  };

  const handleDropdownClick = (name) => {
    if (selectedMenu === name) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(name); 
    }
  };

  const signOut = () => {
    dispatch(removeUser())
    localStorage.removeItem("persist:root");
    navigate("/")
  }

  const handleProfile = () => {
    navigate("profile");
    onClose();
  }
  
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor={"#FFFFFF"} color={"rgba(30, 30, 30, 0.5)"}>
          <DrawerHeader>
            <Flex
              alignItems={"center"}
              gap={4}
              cursor={"pointer"}
            >
              <Avatar
                name={profile?.firstName + " " + profile?.lastName}
                src={`${File_URL}${auth?.profileImg}`}
                border={"1.5px solid #D73439"}
                color={"#D73439"}
              />

              <Flex direction={"column"} lineHeight={1}>
                <Menu>
                  <MenuButton as={Button} rightIcon={<FaChevronDown />} minW={"auto"} bg={"none"}
                  _hover={{ bg: "none" }} justifyContent={"start"} _active={{ bg: "none" }}
                  sx={{ "& > span:first-child": { flexGrow: 0 } }}  >
                    <Text fontWeight={"500"}>{profile?.firstName + " " + profile?.lastName}</Text>
                  <Text fontSize={"sm"} color="#847F7F"> {profile?.role}</Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </DrawerHeader>

          <DrawerBody px={0}>
            <List
              mt={5}
              spacing={5}
              alignSelf={"stretch"}
              sx={{
                "& svg": {
                  width: "1.5rem",
                  height: "1.5rem",
                },
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0px", 
                },
                scrollbarWidth: "none", 
              }}
            >
              {menu && menu?.map((section) => (
          <React.Fragment key={section?.heading}>
            <Text px={5} fontWeight={'400'} fontSize={'12px'} color="rgba(30, 30, 30, 0.5)">{section.heading}</Text>
            {section?.items?.map(({ name, link, icon, submenu }) => {
            return !submenu ? (
              <ListItem
                key={name}
                as={NavLink}
                to={link}
                py={3}
                display={"flex"}
                justify={"center"}
                position="relative"
                onClick={() => handleMenuItemClick(name)}
              >
                {({ isActive }) => (
                  <Flex
                    alignItems={"center"}
                    color={pathname.includes(link) ? "#D11A2A" : "rgba(30, 30, 30, 0.5)"}
                    minW={"80%"}
                    gap={3}
                    px={5}
                  >
                    <Box
                      position={"absolute"}
                      left={0}
                      width={"full"}
                      height={"full"}
                      zIndex={-1}
                    />
                    <ListIcon
                      as={icon}
                      m={0}
                      opacity={isCollapsed && !isActive && 0.5}
                    />
                    <Box as={"span"} flexGrow={1} >
                      {name}
                    </Box>
                    
                  </Flex>
                )}
              </ListItem>
              
            ):
            (
              <React.Fragment key={name}>
                <ListItem
                  key={name}
                  cursor={"pointer"}
                  py={3}
                  display={"flex"}
                  position="relative"
                  onClick={() => handleDropdownClick(name)}
                  mt={[1, 2, 5]}
                >
                  <Box w="full">
                    <Flex alignItems={"center"} minW={"80%"} gap={3} px={5}>
                      {pathname.includes("user-management") ||
                      pathname.includes("Subscription Management") ||
                      pathname.includes("Approval") ||
                      pathname.includes("Report") ||
                      pathname.includes("Exam Setup")
                      ? (
                        <Box
                          position={"absolute"}
                          left={0}
                          width={"full"}
                          height={"full"}
                          zIndex={1}
                        />
                      ): null}
                      <ListIcon
                        zIndex={2}
                        flexShrink={0}
                        flexGrow={0}
                        as={icon}
                        m={0}
                        color={selectedMenu === name ? "#D11A2A" : null}
                      />
                      <Text zIndex={2} as={"span"} flexGrow={1} color={selectedMenu === name ? "#D11A2A" : null} >
                        {name}
                      </Text>
                      {
                        selectedMenu === name ? (
                          <BiCaretUp style={{ marginLeft: "auto", zIndex: 1 }} />
                          ): (
                          <BiCaretDown style={{ marginLeft: "auto", zIndex: 1 }} />
                        )
                      }
                    </Flex>
                  </Box>
                </ListItem>
                {selectedMenu === name ? (
                  <Flex direction={"column"}>
                    {submenu?.map(({ name, link }) => {
                      return (
                        <Box
                          pl={9}
                          onClick={onClose}
                          as={NavLink}
                          to={link}
                          py={2}
                          color={pathname.includes(link) ? "#D11A2A" : "rgba(30, 30, 30, 0.5)"}
                        >
                          {({ isActive }) => (
                            <Flex h="full">
                              {isActive && (
                                <Box
                                  position={"absolute"}
                                  left={0}
                                  width={"full"}
                                  height={6}
                                  zIndex={1}
                                />
                              )}
                              {name}
                            </Flex>
                          )}
                        </Box>
                      );
                    })}
                  </Flex>
                ) : null}
              </React.Fragment>
            );
            })}
          </React.Fragment>
        ))}
        <Flex px={5} gap={5} cursor={'pointer'} onClick={signOut}>
          <IoLogOut style={{height:"30px", width:'30px'}} />
          <Text  fontWeight={'500'} >Logout</Text>
        </Flex>
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileSidebar;