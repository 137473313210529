import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, FormControl, FormErrorMessage, FormLabel, Input, Divider, Select, Grid, GridItem, Button, Checkbox } from '@chakra-ui/react';
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
// import { studtMarriageRefereeInformationAppFormSchema } from '../utils/formValidationSchema';
import { setMarriageRefInfo } from "../redux/slices/studentRegistrationSlice";
import { setloadTost } from "../redux/slices/toastSlice";
import * as yup from "yup";

const StudtMarriageRefereeInformationAppForm = ({ handlePrevious, handleSaveRegLater, handleSubmit, handleEdit, loading, saveloading }) => {
  const dispatch = useDispatch();
  const registeredStudents = useSelector((state) => state?.studentRegistration?.registerStudent?.data);
  const marriageRefInfo = useSelector((state) => state?.studentRegistration?.marriageRefInfo);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileName2, setUploadedFileName2] = useState('');

  const initialValues = {
    maritalStatus: marriageRefInfo?.maritalStatus || registeredStudents?.marriageInfo?.maritalStatus || '',
    marriageCertfile: marriageRefInfo?.marriageCertfile || registeredStudents?.marriageInfo?.marriageCertificate || '',
    RefFullName: marriageRefInfo?.RefFullName || registeredStudents?.refereeInfo?.fullName || '',
    RefMembershipNumber: marriageRefInfo?.RefMembershipNumber || registeredStudents?.refereeInfo?.membershipId || '',
    occupation: marriageRefInfo?.occupation || registeredStudents?.refereeInfo?.jobTitle || '',
    RefPhoneNumber: marriageRefInfo?.RefPhoneNumber || registeredStudents?.refereeInfo?.phoneNumber || '',
    RefEmailAddress: marriageRefInfo?.RefEmailAddress || registeredStudents?.refereeInfo?.email || '',
    refereeQualifications: marriageRefInfo?.refereeQualifications || registeredStudents?.refereeInfo?.qualification || '',
    dataProtectionCertify: marriageRefInfo?.dataProtectionCertify || false,
    receiveInformation: marriageRefInfo?.receiveInformation || false,
  };
  useEffect(() => {
    if (registeredStudents?.marriageInfo?.marriageCertificate) {
      const fileName = registeredStudents?.marriageInfo?.marriageCertificate.split('/').pop(); // Extract file name from URL
      setUploadedFileName(fileName);
    }
  }, [registeredStudents?.marriageInfo?.marriageCertificate, initialValues?.marriageCertfile]);
  const handleMarariageStatus = async (values) => {
    dispatch(setMarriageRefInfo(values))
    if (values.dataProtectionCertify === true && values.receiveInformation === true) {
      if (registeredStudents) {
        handleEdit(values);
      } else {
        handleSubmit(values);
      }
    } else {
      dispatch(setloadTost({ title: 'Empty Fields', description: 'Please accept the Data protection checkboxes', status: 'warning', duration: 3000, isClosable: true, position: 'top' }));
    }
  };
  const handleBackBTN = async (values) => {
    dispatch(setMarriageRefInfo(values));
    handlePrevious();
  };

  const studtMarriageRefereeInformationAppFormSchema = yup.object().shape({
    maritalStatus: yup.string().required('Marital status is required'),
    marriageCertfile: yup.mixed().test(
      "fileType",
      "Unsupported file format, Only png, jpg , jpeg, pdf files are allowed",
      function (value) {
        const passportExists = registeredStudents?.marriageInfo?.marriageCertificate;
        if (!passportExists && uploadedFileName && value) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
          return supportedFormats.includes(value.type);
        } else if (uploadedFileName2 && value && passportExists) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
      .test('fileSize', 'File too large', function (value) {
        const passportExists = registeredStudents?.marriageInfo?.marriageCertificate;
        if (!passportExists && value) {
          return value.size <= 500000;
        } else if (uploadedFileName2) {
          return value.size <= 500000;
        }
        return true;
      }),
    RefFullName: yup.string().required('Full name is required'),
    RefMembershipNumber: yup.string().required('Membership number is required'),
    occupation: yup.string().required('Occupation is required'),
    RefPhoneNumber: yup.string().required('Phone number is required'),
    RefEmailAddress: yup.string().required('Email address is required'),
    refereeQualifications: yup.string().required('Referee qualifications is required'),
    dataProtectionCertify: yup.string().required('Data protection certify is required'),
    receiveInformation: yup.string().required('Receive information is required'),
  });

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={studtMarriageRefereeInformationAppFormSchema}
        onSubmit={(values) => {
          handleMarariageStatus(values)
        }}
      >
        {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
          <Form style={{ width: "100%" }}>
            <Box px={['30px', '40px', '40px', '50px']}>
              <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Marriage Information</Text>
              <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Marriage Cerficate Upload if applicable.allowed(JPEG,PDF,PNG.OF 500KB MAX)</Text>
              <Flex flexDirection={["column", "column", "row", "row"]} gap={5} >
                <FormControl mt={4} isInvalid={errors.maritalStatus && touched.maritalStatus} width={["100%", "100%", "60%", "70%"]}
                  color="#121212" fontFamily={'inter'} >
                  <FormLabel htmlFor='maritalStatus' fontSize={"16px"}>Marital Status</FormLabel>
                  <Select
                    name="maritalStatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"8px"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.maritalStatus}
                  >

                    <option value='Single'>Single</option>
                    <option value='Married'>Married</option>
                    <option value='Divorced'>Divorced</option>
                  </Select>
                  {touched.maritalStatus && errors.maritalStatus && (
                    <FormErrorMessage>{errors.maritalStatus}</FormErrorMessage>
                  )}
                </FormControl>
                <Flex flexDirection={"column"} gap={3} width={["100%", "100%", "40%", "30%"]} >
                  <FormControl mt={4} isInvalid={errors.marriageCertfile && touched.marriageCertfile} color="#121212" fontFamily={'inter'}>
                    <FormLabel htmlFor='marriageCertfile' fontSize={"16px"}>Marriage Certificate
                      <Flex alignItems={'center'} gap={5} >
                        <Text fontSize={'13px'} bgColor={'lightgray'} p={2} borderRadius={5} cursor={'pointer'}>
                          {
                            registeredStudents?.marriageInfo?.marriageCertificate ? "Update File" : marriageRefInfo?.marriageCertfile?.name ? "Update File"
                              : "Choose File"
                          }

                        </Text>
                      </Flex>
                    </FormLabel>
                    {
                      (!registeredStudents?.marriageInfo?.marriageCertificate && marriageRefInfo?.marriageCertfile?.name) ? (
                        <Text my={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {marriageRefInfo?.marriageCertfile?.name}</Text>
                      ) : (registeredStudents?.marriageInfo?.maritalStatus && !marriageRefInfo?.marriageCertfile?.name) ? (
                        <Text my={1} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName}</Text>
                      ) : (registeredStudents?.marriageInfo?.maritalStatus && marriageRefInfo?.marriageCertfile?.name) ? (
                        <Text my={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {marriageRefInfo?.marriageCertfile?.name}</Text>
                      )
                        : (uploadedFileName2 && !registeredStudents?.marriageInfo?.marriageCertificate && !marriageRefInfo?.marriageCertfile?.name) ? <Text mt={2} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName2}</Text> : null
                    }
                    <Input style={{display: 'none'}} type='file' name="marriageCertfile" id="marriageCertfile" fontSize={"small"} focusBorderColor="#ffffff"
                      borderRadius={'0'} borderColor={"#FFFFFF"} _hover={{ borderColor: "#FFFFFF" }}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue('marriageCertfile', file);
                        if (file) {
                          setUploadedFileName(file?.name);
                          setUploadedFileName2(file?.name);
                        }
                        else {
                          setUploadedFileName('');
                          setUploadedFileName2('');
                        }
                      }}
                      accept=".pdf, .jpg, .jpeg, .png"
                    />

                    {touched.marriageCertfile && errors.marriageCertfile && (
                      <FormErrorMessage>{errors.marriageCertfile}</FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              </Flex>
            </Box>
            <Divider border={'1px solid #D3D3D3'} my={10} />
            <Box px={['30px', '40px', '40px', '50px']}>
              <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Referee Information</Text>
              <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Referee Details Note:Applicant Referee must not necessarily be a member of Icsan</Text>
              <Grid mb={5} templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={6}>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefFullName && touched.RefFullName} color="#121212">
                    <FormLabel htmlFor='RefFullName' fontSize={'16px'}>Referee Full Name <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefFullName"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefFullName}
                    />
                    {touched.RefFullName && errors.RefFullName && (
                      <FormErrorMessage>{errors.RefFullName}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefMembershipNumber && touched.RefMembershipNumber} color="#121212">
                    <FormLabel htmlFor='RefMembershipNumber' fontSize={'16px'}>Referee membership Number</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefMembershipNumber"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefMembershipNumber}
                    />
                    {touched.RefMembershipNumber && errors.RefMembershipNumber && (
                      <FormErrorMessage>{errors.RefMembershipNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.occupation && touched.occupation} color="#121212">
                    <FormLabel htmlFor='occupation' fontSize={'16px'}>Occupation/Job Title <span style={{ color: "#D11A2A" }}>*</span> </FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="occupation"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.occupation}
                    />
                    {touched.occupation && errors.occupation && (
                      <FormErrorMessage>{errors.occupation}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefPhoneNumber && touched.RefPhoneNumber} color="#121212">
                    <FormLabel htmlFor='RefPhoneNumber' fontSize={'16px'}>Referee Phone Number <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefPhoneNumber"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefPhoneNumber}
                    />
                    {touched.RefPhoneNumber && errors.RefPhoneNumber && (
                      <FormErrorMessage>{errors.RefPhoneNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem >
                  <FormControl mt={4} isInvalid={errors.RefEmailAddress && touched.RefEmailAddress} color="#121212">
                    <FormLabel htmlFor='RefEmailAddress' fontSize={'16px'}>Referee Email Address <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefEmailAddress"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefEmailAddress}
                    />
                    {touched.RefEmailAddress && errors.RefEmailAddress && (
                      <FormErrorMessage>{errors.RefEmailAddress}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.refereeQualifications && touched.refereeQualifications}
                    color="#121212" fontFamily={'inter'}>
                    <FormLabel htmlFor='refereeQualifications' fontSize={"16px"}>Referee Qualifications</FormLabel>
                    <Select
                      name="refereeQualifications"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={'#F3F3F3'}
                      borderRadius={"8px"}
                      focusBorderColor="#ffffff"
                      borderColor={"#FFFFFF"}
                      placeholder='Select Here'
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.refereeQualifications}
                    >
                      <option value='Masters'>Masters</option>
                      <option value='Bachelors'>Bachelors</option>
                    </Select>
                    {touched.refereeQualifications && errors.refereeQualifications && (
                      <FormErrorMessage>{errors.refereeQualifications}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
            <Divider border={'1px solid #D3D3D3'} my={10} />
            <Flex flexDirection={"column"} gap={2} mt={10} mb={5} fontWeight={'400'} fontSize={'16px'} lineHeight={'18.75px'} fontFamily={'roboto'} px={['30px', '40px', '40px', '50px']}>
              <Text mb={5} color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Data Protection</Text>
              <Checkbox colorScheme='green'
                isChecked={values.dataProtectionCertify}
                onChange={(e) => setFieldValue('dataProtectionCertify', e.target.checked)}
              >
                I certify that the information given above is, to the best of my knowledge, accurate in all respects. I have read and accepted the regulations, terms and conditions for ICSAN students.
              </Checkbox>
              <Checkbox colorScheme='green'
                isChecked={values.receiveInformation}
                onChange={(e) => setFieldValue('receiveInformation', e.target.checked)}
              >
                The institute occasionally supplies mailing lists, on a restricted basis, to other approved organisations. If you prefer not to receive information by direct mail by those to whom we supply these lists, then please tick the box.
              </Checkbox>
            </Flex>
            <Flex px={['30px', '40px', '40px', '50px']} flexDirection={['column', 'row', 'row']} gap={['20px', '50px', '50px']} alignItems={'center'} mt={['50px', '80px', '100px']} mb={10}>
              <Button bg={'#A6A6A6'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={() => handleBackBTN(values)}>
                Back
              </Button>
              <Button bg={'green.500'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={() => {
                  dispatch(setMarriageRefInfo(values))
                  handleSaveRegLater(values)
                }} isLoading={saveloading} loadingText="Saving...">
                Save and register later
              </Button>
              <Button bg={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                isLoading={loading} loadingText="Submitting..." type='submit'>
                Submit Registration
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default StudtMarriageRefereeInformationAppForm;