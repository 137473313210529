import React, { useEffect } from 'react';
import { Box, Button, Flex, Text , Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import { PiExport } from "react-icons/pi";
import SortBy from '../components/SortBy';
import CustomTable from '../components/CustomTable';
import {fetchMemberPayHistory} from "../redux/slices/report2Slice";
import { useDispatch, useSelector } from 'react-redux';

const MemberModuleReport = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchMemberPayHistory());
    }, [ dispatch ]);
    const payHistory = useSelector((state) => state?.report2);

    const tableProps = {
        data: payHistory?.memberPayHistory?.data,
        title: {
            paymentREFNO: "Payment REF NO.",
            paymentDate:"Payment Date",
            transactionAmount: "Transaction Amount",
            purpose: "Purpose",
            status:"Status",
        },
        dataKeys: [
          "paymentREFNO",
          "paymentDate",
          "transactionAmount",
          "purpose",
          "status",
        ],
        
        transformRow: (item) => ({
            paymentREFNO: item &&  <Box style={{cursor:'pointer'}}>{item?.paymentReference}</Box>,
            paymentDate: item && <Box style={{cursor:'pointer'}} >{item?.paymentDate}</Box>,
            transactionAmount: item && <Box style={{cursor:'pointer'}} >{item?.transactionAmount}</Box>,
            purpose: item && <Box style={{cursor:'pointer'}} >{item?.purpose}</Box>,    
            status: item &&  <Box style={{cursor:'pointer'}} >{item?.status}</Box>,
        }),
      };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
            <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'0.5'}> Member Payment History </Text>
            <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
                <SortBy />
                <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
                fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
                >
                Export
                </Button>
            </Flex>
        </Flex>
        {
            payHistory?.error? (
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{payHistory?.error}</AlertTitle>
            </Alert>
            ) : (
            <CustomTable {...tableProps} />
            )
        }
    </Box>
  )
}

export default MemberModuleReport;