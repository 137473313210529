import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react'
import { setFormModal } from '../redux/slices/modalSlice';
import { useDispatch } from 'react-redux';


function PaymentPlanForm() {
    const dispatch = useDispatch();
    const initialValues = {
        plan: '',
        price: '',
        billingFrequency: '',
        memberCategory: '',
    };

    const handleSubmit = async (values) => {
        dispatch(setFormModal({ status: false, data: null }));
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={planSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.plan && touched.plan} color="#121212">
                                <FormLabel htmlFor='plan' fontSize={'16px'}>Name Plan</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="plan"
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    borderRadius={"0"}
                                    focusBorderColor="#ffffff"
                                    borderColor={"#FFFFFF"}
                                    value={values?.plan}
                                >
                                </Input>
                                {touched.plan && errors.plan && (
                                    <FormErrorMessage>{errors.plan}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.price && touched.price} color="#121212">
                                <FormLabel htmlFor='price' fontSize={'16px'}>Price</FormLabel>
                                <Select
                                    name="price"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    bg={'#F3F3F3'}
                                    borderRadius={"0"}
                                    focusBorderColor="#ffffff"
                                    borderColor={"#FFFFFF"}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    value={values?.price}
                                >

                                    <option value='Active'>Active</option>
                                    <option value='Inactive'>Inactive</option>
                                </Select>
                                {touched.price && errors.price && (
                                    <FormErrorMessage>{errors.price}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>

                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.billingFrequency && touched.billingFrequency} color="#121212">
                                <FormLabel htmlFor='billingFrequency' fontSize={'16px'}>Billing Frequency</FormLabel>
                                <Select
                                    name="billingFrequency"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    bg={'#F3F3F3'}
                                    borderRadius={"0"}
                                    focusBorderColor="#ffffff"
                                    borderColor={"#FFFFFF"}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    value={values?.billingFrequency}
                                >

                                    <option value='Active'>Once</option>
                                    <option value='Inactive'>Annual</option>
                                    <option value='Inactive'>Biennial</option>
                                </Select>
                                {touched.billingFrequency && errors.billingFrequency && (
                                    <FormErrorMessage>{errors.billingFrequency}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.memberCategory && touched.memberCategory} color="#121212">
                                <FormLabel htmlFor='price' fontSize={'16px'}>Member Category</FormLabel>
                                <Select
                                    name="memberCategory"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    bg={'#F3F3F3'}
                                    borderRadius={"0"}
                                    focusBorderColor="#ffffff"
                                    borderColor={"#FFFFFF"}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    value={values?.memberCategory}
                                >

                                    <option value='Active'>Active</option>
                                    <option value='Inactive'>Inactive</option>
                                </Select>
                                {touched.memberCategory && errors.memberCategory && (
                                    <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                // isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Save
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PaymentPlanForm