import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const DasboardCards = ({name, number, colors, pieChartData}) => {
    //  const colors = [color1, color2];
    // const sumTotal = pieChartData.reduce((acc, data) => acc + data.value, 0);
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const { value } = pieChartData[index];

    return (
        <text style={{fontWeight:"800", fontSize:"14px", lineHeight:"9.68px", fontFamily:"inter"}} x={x} y={y} fill="rgba(255, 255, 255, 0.5)" textAnchor="middle" dominantBaseline="middle">
        {value}
        </text>
    );
    };

  return (
    <Box bg={'#FFFFFF'} borderRadius={'20px'} p={4} h={['100%','100%','300px']} 
    sx={{
        overflowY: "scroll",
      scrollbarWidth: "none", 
      msOverflowStyle: "none", 
      "-ms-overflow-style": "none", 
      "&::-webkit-scrollbar": {
        display: "none", 
      },
    }}
    overflowY={"auto"}
    >
        <Flex justifyContent={'space-between'} alignItems={'center'} >
            <Flex flexDirection={'column'}>
                <Text color={'#4D4D4D'} letterSpacing={'-0.15px'} fontSize={'14px'} fontWeight={'500'} lineHeight={'21.41px'}>
                    {name}
                </Text>
                <Text color={'#000000'} fontSize={'24px'} fontWeight={'700'} lineHeight={'29.05px'} fontFamily={'inter'}>{number}</Text>
            </Flex>
        </Flex>
        <Flex flexDirection={['row', 'row', 'row', 'column-reverse', 'row']} gap={2} alignContent={'center'} >
            <Flex direction="column" justify="center" py={5} gap={2}>
                {
                pieChartData.map((item, index) => (
                <Flex alignItems={'center'} justifyContent={'space-between'} key={index} >
                    <Flex alignItems={'center'} gap={2}>
                        <Box bg={colors && colors[index % colors.length]} h={2} w={4} borderRadius="3px" ></Box>
                        <Text fontSize="9.75px" lineHeight={"12.61px"} color="#000000B2" fontWeight={'700'} fontFamily={'inter'}>
                            {item.value} <span style={{fontWeight:"400", }}>{item.name}</span> 
                        </Text>
                    </Flex>
                </Flex>
                ))
                }
            </Flex>
            <ResponsiveContainer width="100%" height={220} >
                <PieChart>
                    <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={62}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    cornerRadius={8}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    >
                    {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors && colors[index % colors.length]} />
                    ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            
        </Flex>
    </Box>
  )
}

export default DasboardCards;