import React,{useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Flex, Input, Alert,AlertIcon, AlertTitle  } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {addPeriodSchema} from "../utils/formValidationSchema";
import {createExamPeriod, fetchExamPeriod} from "../redux/slices/examDietPeriodSlice";
import {setloadTost} from "../redux/slices/toastSlice";

const AddPeriod = () => {
    const dispatch = useDispatch();
    const examDietDetails = useSelector((state) => state?.examDietPeriod?.examDietDetails);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const initialValues = {
        examYear: '',
        startDate:'',
        endDate:'',
    };
  
    const handleSubmit = async (values) => {
      // console.log(values);
      setLoading(true);
      setError("");
      try {
        const formData = {
          year: values?.examYear,
          startDate: values?.startDate,
          endDate: values?.endDate
        }

        const response = await dispatch(createExamPeriod({ arg: formData, id: examDietDetails?.id }));
        // console.log(response);
        if (response?.payload?.isSuccess === true){
          setLoading(false);
          dispatch(fetchExamPeriod(examDietDetails?.id));
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'Period Created', description: `${values?.examYear} period created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        } else {
          setLoading(false);
          setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create period");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to create period, please check your network");
      }finally{
        setLoading(false);
        
      }
      
    }

  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
      }
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={addPeriodSchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
        <FormControl mt={4} isInvalid={errors.examYear && touched.examYear}
              color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='examYear'  fontSize={"16px"}>Exam Year</FormLabel>
            <Input
            type="number"
            placeholder="Enter Here"
            borderRadius={"0"}
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="examYear"
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.examYear}
        />
            {touched.examYear && errors.examYear && (
              <FormErrorMessage>{errors.examYear}</FormErrorMessage>
            )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
        color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='startDate' fontSize={"16px"}> Start Date</FormLabel>
        <Input
            type="date"
            placeholder="Pick a Date"
            borderRadius={"0"}
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="startDate"
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.startDate}
        />
        {touched.startDate && errors.startDate && (
            <FormErrorMessage>{errors.startDate}</FormErrorMessage>
        )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
        color="#121212" fontFamily={'inter'}>
        <FormLabel htmlFor='endDate' fontSize={"16px"}> End Date</FormLabel>
        <Input
            type="date"
            placeholder="Pick a Date"
            borderRadius={"0"}
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="endDate"
            focusBorderColor="#ffffff"
            borderColor={"#FFFFFF"}
            _hover={{ borderColor: "#FFFFFF" }}
            _placeholder={{ color: "#B4B1B1" }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.endDate}
        />
        {touched.endDate && errors.endDate && (
            <FormErrorMessage>{errors.endDate}</FormErrorMessage>
        )}
        </FormControl>
        
      <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
        <Button
          mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
          width={"118px"}
          background={"#D11A2A"}
          _hover={{ background: " " }}
          color={"#FFFFFF"}
          type="submit"
          isLoading={loading}
          loadingText="Saving ..."
        >
          Add
        </Button>
        <Button
          mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
          width={"118px"}
          background={""}
          _hover={{ background: " " }}
          color={"#8F9BB3"}
          onClick={() => {
            dispatch(setFormModal({ status: false, data: null }));
          }}
          loadingText="Saving ..."
        >
          Cancel
        </Button>
      </Flex>
      </Form>
    )}
  </Formik>
    </>
  )
}

export default AddPeriod;