import React from 'react';
import { Box, Flex, Text, Divider, useBreakpointValue } from '@chakra-ui/react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';


const EducationPieChart = ({ details}) => {
    const orientation = useBreakpointValue({base:'horizontal', sm:'horizontal', md:'horizontal', lg:'horizontal', xl: 'vertical' });
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // const { value } = details?.pieChartData[index];

    return (
        <text style={{fontWeight:"800", fontSize:"14px", lineHeight:"9.68px", fontFamily:"inter"}} x={x} y={y} fill="rgba(255, 255, 255, 0.5)" textAnchor="middle" dominantBaseline="middle">
        {/* {value} */}
        </text>
    );
    };

  return (
    <Box bg={'#FFFFFF'} borderRadius={'20px'} py={5}>
        <Flex gap={5} flexDirection={['column', 'column', 'column','column', 'row']} justifyContent={'center'} alignItems={'center'} px={5} >
            {
                details?.map((detail, index) => (
                    <React.Fragment key={index}>
                        <Flex flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} >
                            <Flex flexDirection={'column'}>
                                <Text color={'#4D4D4D'} letterSpacing={'-0.15px'} fontSize={'14px'} fontWeight={'500'} lineHeight={'21.41px'}>
                                    {detail?.name}
                                </Text>
                                <Text color={'#000000'} fontSize={'24px'} fontWeight={'700'} lineHeight={'29.05px'} fontFamily={'inter'}>{detail?.number}</Text>
                            </Flex>
                            <Flex width={'100%'} flexDirection={['row', 'row', 'row', 'row', 'column-reverse']} gap={2} alignContent={'center'} >
                                <Flex direction="column" justifyContent="center" py={5} gap={2}>
                                    {
                                    detail?.pieChartData.map((item, index) => (
                                    <Flex alignItems={'center'} justifyContent={'space-between'} key={index} >
                                        <Flex alignItems={'center'} gap={2}>
                                            <Box bg={detail?.colors && detail?.colors[index % detail?.colors.length]} h={2} w={4} borderRadius="3px" ></Box>
                                            <Text fontSize="9.75px" lineHeight={"12.61px"} color="#000000B2" fontWeight={'700'} fontFamily={'inter'}>
                                                {item.value} <span style={{fontWeight:"400", }}>{item.name}</span> 
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    ))
                                    }
                                </Flex>
                                <ResponsiveContainer width="80%" height={200} >
                                    <PieChart>
                                        <Pie
                                        data={detail?.pieChartData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={30}
                                        outerRadius={60}
                                        fill="#8884d8"
                                        paddingAngle={5}
                                        dataKey="value"
                                        cornerRadius={8}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        >
                                        {detail?.pieChartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={detail?.colors && detail?.colors[index % detail?.colors.length]} />
                                        ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Flex>
                        </Flex>
                        {index !== details?.length - 1 && <Divider orientation= {orientation} border={'1px solid #C6C6C666'} h={[null, null, null,null,'350px']} />}
                    </React.Fragment>
                ))
            }
        </Flex>
    </Box>
       
  )
}

export default EducationPieChart;