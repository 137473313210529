import React, { useEffect } from 'react';
import { Box, Button, Flex, Text, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { PiExport } from 'react-icons/pi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import Create from '../components/layout/Create';
import SortBy from '../components/SortBy';
import DotLoader from '../components/loaders/DotLoader';
import { setloadTost } from '../redux/slices/toastSlice';
import {fetchEntryQualificationType, deleteEntryQualificationType} from "../redux/slices/entryQualificationTypeSlice";
import { useNavigate } from 'react-router-dom';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";

const EntryQualificationType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const entryQualificationType = useSelector((state) => state?.entryQualificationType);

    const createData = {
        onClick: () => { dispatch(setFormModal({status:true, title: "Add qualification Type ", type:"addQualification"})); },
        createText: "Add qualification Type ",
    }

    useEffect(() => {
        dispatch(fetchEntryQualificationType());
        dispatch(clearsearchFilter());
    }, [dispatch]);

    const handleEdit = (item) => {
        dispatch(setFormModal({status:true, title: "Add qualification Type ", type:"addQualification", data: item}));
    }

    const handleDelete = async (item) => {
        const response = await dispatch(deleteEntryQualificationType(item?.id));

        if (response?.payload?.isSuccess === true) {
            dispatch(fetchEntryQualificationType());
            dispatch(setloadTost({ title: 'Successs', description: `Qualification type Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
            dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to add qualification type"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
        }
    }

    const handleUpdate = (item) => {
      navigate(`/examsetup/entryqualificationtype/applicableexemption?id=${item?.id}&examCategoryId=${item?.examCategory?.id}`);
    }

    const tableProps = {
        data:entryQualificationType?.entryQualificationType?.data,
        title: {
          name: "Name",
          description: "Description",
          examCategory:"Exam Category",
          examSubject:" ",
          Action:'',
        },
        dataKeys: [
          "name",
          "description",
          "examCategory",
          "examSubject",
          "Action",
        ],
        transformRow: (item) => ({
          name:item && <Box style={{cursor:'pointer'}}>{item?.name}</Box>,
          description: item && <Box style={{cursor:'pointer'}}>{item?.description}</Box>,
          examCategory: item && <Box style={{cursor:'pointer'}}>{item?.examCategory?.name}</Box>,
          examSubject: item && <Box style={{cursor:'pointer'}} color={'#3064FC'} textDecoration={'underline'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} onClick={() => handleUpdate(item)}> View Exemption</Box>,
          Action:item && (
            <Menu>
                <MenuButton>
                <BsThreeDotsVertical />
                </MenuButton>
                <MenuList >
                    <MenuItem onClick={() => handleEdit(item)} >Edit </MenuItem>
                    <MenuItem onClick={() => handleDelete(item)} > Delete </MenuItem>
                </MenuList>
            </Menu>
          )
        }),
      };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Entry Qualification Type</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
     
        <Create {...createData} />
       
        <SortBy />
        <Button leftIcon={<PiExport style={{width: "15px", height: "15px"}} />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
          fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
        >
          Export
        </Button>
      </Flex>
    </Flex>
  {
    entryQualificationType?.loading ? (
      <Flex justifyContent={'center'} alignItems={'center'}>
        <DotLoader />
      </Flex>
    ): 
    (<CustomTable {...tableProps} />)
  }
  </Box>
  )
}

export default EntryQualificationType;