import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import {File_URL} from "../config/settings";
import MembershipApproveDecline from './MembershipApproveDecline';
import {updateBioDataStatus, fetchPendingBioDataApproval} from "../redux/slices/membershipManagementSlice";
import {setloadTost} from "../redux/slices/toastSlice";
import { setFormModal } from "../redux/slices/modalSlice";

function UpdateBioDataModal() {
  const dispatch = useDispatch();
  const personalDetails = useSelector((state) => state.modal.formModal.data);


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const tagData = [
    {title:"Name:", discription:personalDetails?.fullName},
    {title:"Gender:", discription:personalDetails?.gender},
    {title:"Email Address:", discription:personalDetails?.email},
    {title:"Phone Number:", discription:personalDetails?.phoneNumber},
    {title:"Date Of Birth:", discription:personalDetails?.dateOfBirth},
    {title:"Age:", discription:personalDetails?.age},
    {title:"Home Address:", discription:personalDetails?.homeAddress},
    {title:"Membership Number:", discription:personalDetails?.membershipNumber},
    {title:"Membership Grade:", discription:personalDetails?.membershipCategory},
  ]

  const handleApproval = async () => {
    setLoading(true);
    setError("");
    try {
      const response =  await dispatch(updateBioDataStatus(personalDetails?.id));
      if (response?.payload?.isSuccess === true){
        setLoading(false);
        dispatch(fetchPendingBioDataApproval());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Biodata Status Update', description: `${personalDetails?.fullName} Biodata Status Updated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      }else{
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Update Biodata status");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to Update Biodata status, please check your network");
    }finally{
      setLoading(false);
    }
  }

  const data = {
    handleApproval, 
    // handleDecline,
     error, errorsArray, loading 
  }

  return (
    <Box>
    <Flex justifyContent={'flex-start'} alignItems={'center'} my={6} >
      <Image src={`${File_URL}${personalDetails?.profilePicture}`} alt='Profile Pics' rounded={'full'} width={'183px'} height={'183px'} overflow={'hidden'} objectFit={'cover'} border={'5px solid #D11A2A'} />
    </Flex>
    <Box h={'250px'}
      sx={{
        overflowY: "scroll",
        scrollbarWidth: "none", 
        msOverflowStyle: "none", 
        "-ms-overflow-style": "none", 
        "&::-webkit-scrollbar": {
          display: "none", 
        },
      }}
      overflowY={"auto"}
    >
    {
    tagData?.map((data, index) =>(
    <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}>
      <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
      <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
    </Flex>
    ))
    }
    <MembershipApproveDecline {...data} />
    </Box>
</Box>
  )
}

export default UpdateBioDataModal