import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import {File_URL} from "../config/settings";
import NYSCApproveDecline from './NYSCApproveDecline';

const NYSCPersonalInformation = ({nyscIndividualDetails}) => {
      const tagData = [
        {title:"Fullname:", discription:nyscIndividualDetails?.personalInformation?.firstName + " " + nyscIndividualDetails?.personalInformation?.lastName + " " + nyscIndividualDetails?.personalInformation?.otherName},
        {title:"Date of Birth:", discription:nyscIndividualDetails?.personalInformation?.dateOfBirth},
        {title:"Gender:", discription:nyscIndividualDetails?.personalInformation?.gender},
        {title:"Telephone:", discription:nyscIndividualDetails?.personalInformation?.phoneNumber},
        {title:"Address:", discription:nyscIndividualDetails?.personalInformation?.homeAddress},
        {title:"Country:", discription:nyscIndividualDetails?.personalInformation?.country},
        {title:"Postcode:", discription:nyscIndividualDetails?.personalInformation?.postCode},
        {title:"State of Origin:", discription:nyscIndividualDetails?.personalInformation?.stateOfOrigin},
      ]
    
  return (
    <Box>
    <Flex justifyContent={'flex-start'} alignItems={'center'} my={6} >
      <Image src={`${File_URL}${nyscIndividualDetails?.personalInformation?.passport}`} alt='Profile Pics' rounded={'full'} width={'183px'} height={'183px'} overflow={'hidden'} objectFit={'cover'} border={'5px solid #D11A2A'} objectPosition={'center'} />
    </Flex>
    <Box h={'250px'}
      overflow={'scroll'}
    >

    {
    tagData?.map((data, index) =>(
    <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
    
    >
      <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
      <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
    </Flex>
    ))
    }
    <NYSCApproveDecline nyscIndividualDetails={nyscIndividualDetails} />
    </Box>
</Box>
  )
}

export default NYSCPersonalInformation