import React,{useState} from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {File_URL} from "../config/settings";
import MembershipApproveDecline from './MembershipApproveDecline';
import {memberUpgradeApprove, fetchMemberUpgradeApps} from "../redux/slices/membershipUpgradeSlice";
import {setloadTost} from "../redux/slices/toastSlice";
import { setFormModal } from "../redux/slices/modalSlice";

const MembershipUpgradeDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const memberDetails = useSelector((state) => state.modal.formModal?.data);

    const tagData = [
        {title:"Fullname:", discription:memberDetails?.fullName},
        {title:"Membership Number:", discription:memberDetails?.memberNumber},
        {title:"Date of Birth:", discription:memberDetails?.dateOfBirth},
        {title:"Gender:", discription:memberDetails?.gender},
        {title:"Email Address:", discription:memberDetails?.email},
        {title:"Marital Status:", discription:memberDetails?.maritalStatus},
        {title:"Current Membership:", discription:memberDetails?.currentMembership},
        {title:"Proposed Membership:", discription:memberDetails?.proposedMembership},
    ]

    const handleApproval =async () => {
        setLoading(true);
        setError("");
        try {
          const formData = {
            status:"Approve"
          };
          const response = await dispatch(memberUpgradeApprove({arg: formData, id: memberDetails?.id}));
          if (response?.payload?.isSuccess === true){
            setLoading(false);
            dispatch(fetchMemberUpgradeApps("Pending"));
            dispatch(setFormModal({ status: false, data: null }));
            dispatch(setloadTost({ title: 'Approval', description: `${memberDetails?.fullName} Approved successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
          }else{
            setLoading(false);
            setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Approve");
          }
            
        } catch (error) {
            setError(error?.payload?.message || "Failed to Approve, please check your network");
        }finally{
            setLoading(false);
        }
      };

      const handleDecline = () => {
        dispatch(
          setFormModal({ status: true, title:" Decline Form", type: "membershipdecline", data: memberDetails })
        );
      };

      const data = {
        handleApproval, handleDecline, error, errorsArray, loading 
      }


  return (
    <Box>
    <Flex justifyContent={'flex-start'} alignItems={'center'} my={6} >
      <Image src={`${File_URL}${memberDetails?.profilePicture}`} alt='Profile Pics' rounded={'full'} width={'183px'} height={'183px'} overflow={'hidden'} objectFit={'cover'} border={'5px solid #D11A2A'} objectPosition={'center'} />
    </Flex>
    <Box h={'250px'}
      // sx={{
      //   overflowY: "scroll",
      //   scrollbarWidth: "none", 
      //   msOverflowStyle: "none", 
      //   "-ms-overflow-style": "none", 
      //   "&::-webkit-scrollbar": {
      //     display: "none", 
      //   },
      // }}
      // overflowY={"auto"}
      overflow={'scroll'}
    >

    {
    tagData?.map((data, index) =>(
    <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
    
    >
      <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
      <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
    </Flex>
    ))
    }
    <MembershipApproveDecline {...data} />
    </Box>
</Box>
  )
}

export default MembershipUpgradeDetails;