import axios from "axios";
import storeInit from "../redux/store";
import { removeUser } from "../redux/slices/authSlice";

const httpRequest = async (
  url,
  method = "get",
  body = null,
  others,
  contentType = "application/json"
) => {
  const accessToken = storeInit.store.getState()?.auth?.user?.accessToken;
  
  try {
    const response = await axios({
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": `${contentType}`,
        language: "en",
      },
      ...others,
    });
    return response?.data;
    // return response;

  } catch (error) {
    if (error.response?.status === 401) {
      storeInit.store.dispatch(removeUser());
    } else {
      if (error.response?.data) {
        return error.response?.data;
      } else {
        return error;
      }
    }
  }
};

export default httpRequest;
