import React from 'react';
import { Form, Formik } from "formik";
import { Box, Button, Checkbox, FormControl, FormLabel, Grid, GridItem, Input, Select, Text, FormErrorMessage, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { NYSCEmploymentInformationAppFormSchema } from '../utils/formValidationSchema';
import {setEmploymentInfo} from "../redux/slices/NYSCRegistrationSlice";

const NYSCEmploymentInformationAppForm = ({ handlePrevious, handleNext }) => {
  const dispatch = useDispatch();
  const registeredNYSC = useSelector((state) => state?.NYSCRegistration?.registerNYSC?.data);
  const ppaInfo = useSelector((state) => state?.NYSCRegistration?.ppaInfo);
  const formInitialValues = {
    jobTitle: ppaInfo?.jobTitle || registeredNYSC?.ppaInfo?.jobTitle || '',
    department: ppaInfo?.department || registeredNYSC?.ppaInfo?.department || '',
    callUpNumber: ppaInfo?.callUpNumber || registeredNYSC?.ppaInfo?.callUpNumber || '',
    stateOfDeployment: ppaInfo?.stateOfDeployment || registeredNYSC?.ppaInfo?.stateOfDeployment || '',
    yearOfService: ppaInfo?.yearOfService || registeredNYSC?.ppaInfo?.yearOfService || '',
    employerName: ppaInfo?.employerName || registeredNYSC?.ppaInfo?.employer || '',
    employerAddress: ppaInfo?.employerAddress || registeredNYSC?.ppaInfo?.employerAddress || '',
    employerPostCode: ppaInfo?.employerPostCode || registeredNYSC?.ppaInfo?.postalCode || '',
    businessPhone: ppaInfo?.businessPhone || registeredNYSC?.ppaInfo?.phoneNumber || '',
    businessEmail: ppaInfo?.businessEmail || registeredNYSC?.ppaInfo?.emailAddress || '',
    businesscountry: ppaInfo?.businesscountry || registeredNYSC?.employmentInfo?.country || '',
    communicationAddress: ppaInfo?.communicationAddress || registeredNYSC?.ppaInfo?.primaryAddress || '',
  };
  console.log(ppaInfo);
  const handleSubmit = async (values) => {
    dispatch(setEmploymentInfo(values));
    handleNext(values);
  }

  return (
    <Box px={['30px', '40px', '40px', '50px']}>
      <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Place of Primary Assignment</Text>
      <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Please read the notes carefully before completing this form </Text>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={NYSCEmploymentInformationAppFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={6}>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.jobTitle && touched.jobTitle} color="#121212">
                  <FormLabel htmlFor='jobTitle' fontSize={'16px'}>Job Title</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="jobTitle"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.jobTitle}
                  />
                  {touched.jobTitle && errors.jobTitle && (
                    <FormErrorMessage>{errors.jobTitle}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.department && touched.department} color="#121212">
                  <FormLabel htmlFor='department' fontSize={'16px'}>Department</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="department"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.department}
                  />
                  {touched.department && errors.department && (
                    <FormErrorMessage>{errors.department}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.callUpNumber && touched.callUpNumber} color="#121212">
                  <FormLabel htmlFor='callUpNumber' fontSize={'16px'}>Call Up Number</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="callUpNumber"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.callUpNumber}
                  />
                  {touched.callUpNumber && errors.callUpNumber && (
                    <FormErrorMessage>{errors.callUpNumber}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.stateOfDeployment && touched.stateOfDeployment} color="#121212">
                  <FormLabel htmlFor='stateOfDeployment' fontSize={'16px'}>State of Deployment</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="stateOfDeployment"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.stateOfDeployment}
                  />
                  {touched.stateOfDeployment && errors.stateOfDeployment && (
                    <FormErrorMessage>{errors.stateOfDeployment}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.yearOfService && touched.yearOfService} color="#121212">
                  <FormLabel htmlFor='yearOfService' fontSize={'16px'}>Service Year</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="yearOfService"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.yearOfService}
                  />
                  {touched.yearOfService && errors.yearOfService && (
                    <FormErrorMessage>{errors.yearOfService}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.employerName && touched.employerName} color="#121212">
                  <FormLabel htmlFor='employerName' fontSize={'16px'}>Employer Name</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="employerName"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.employerName}
                  />
                  {touched.employerName && errors.employerName && (
                    <FormErrorMessage>{errors.employerName}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={[1, 1, 2]}>
                <FormControl mt={4} isInvalid={errors.employerAddress && touched.employerAddress} color="#121212">
                  <FormLabel htmlFor='employerAddress' fontSize={'16px'}>Employer Address</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="employerAddress"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.employerAddress}
                  />
                  {touched.employerAddress && errors.employerAddress && (
                    <FormErrorMessage>{errors.employerAddress}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.businesscountry && touched.businesscountry}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='businesscountry' fontSize={"16px"}>Country</FormLabel>
                  <Select
                    name="businesscountry"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"8px"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.businesscountry}
                  >

                    <option value='nigeria'>NIGERIA</option>
                    <option value='ghana'>Ghana</option>
                  </Select>
                  {touched.businesscountry && errors.businesscountry && (
                    <FormErrorMessage>{errors.businesscountry}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.employerPostCode && touched.employerPostCode} color="#121212">
                  <FormLabel htmlFor='employerPostCode' fontSize={'16px'}>Employer Post Code</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="employerPostCode"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.employerPostCode}
                  />
                  {touched.employerPostCode && errors.employerPostCode && (
                    <FormErrorMessage>{errors.employerPostCode}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mt={4} isInvalid={errors.businessPhone && touched.businessPhone} color="#121212">
                  <FormLabel htmlFor='businessPhone' fontSize={'16px'}>Business Phone</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="businessPhone"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.businessPhone}
                  />
                  {touched.businessPhone && errors.businessPhone && (
                    <FormErrorMessage>{errors.businessPhone}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem >
                <FormControl mt={4} isInvalid={errors.businessEmail && touched.businessEmail} color="#121212">
                  <FormLabel htmlFor='businessEmail' fontSize={'16px'}>Business Email Address</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="businessEmail"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.businessEmail}
                  />
                  {touched.businessEmail && errors.businessEmail && (
                    <FormErrorMessage>{errors.businessEmail}</FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
             
            </Grid>

            <Flex flexDirection={"column"} gap={2} mt={10} mb={5} fontWeight={'400'} fontSize={'16px'} lineHeight={'18.75px'} fontFamily={'roboto'}>
              <Text >PLEASE INDICATE WHICH ADDRESS WE SHOULD USE FOR COMMUNICATION (PLEASE TICK ONE)</Text>
              <Flex alignItems="center" gap={5} my={3}>
                <Checkbox
                  colorScheme='green'
                  name="communicationAddress"
                  value="Home"
                  onChange={() => setFieldValue("communicationAddress", "Home")}
                  isChecked={values.communicationAddress === "Home"}
                >
                  Home
                </Checkbox>
                <Checkbox
                  colorScheme='green'
                  ml={4}
                  name="communicationAddress"
                  value="Business"
                  onChange={() => setFieldValue("communicationAddress", "Business")}
                  isChecked={values.communicationAddress === "Business"}
                >
                  Business
                </Checkbox>
              </Flex>
            </Flex>

            <Flex flexDirection={['column', 'row', 'row']} gap={['20px', '50px', '50px']} alignItems={'center'} mt={['50px', '80px', '100px']} mb={10}>
              <Button bg={'#A6A6A6'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={handlePrevious}>
                Back
              </Button>
              <Button bg={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                type='submit'>
                Next
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default NYSCEmploymentInformationAppForm