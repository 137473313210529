export function makePayment({orderId, referenceId, successFunc, errorFunc, closeFunc  }) {
    const paymentEngine = window.RmPaymentEngine.init({
        key:"QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY=",
        processRrr: true,
        transactionId: orderId, //you are expected to generate new values for the transactionId for each transaction processing.
        channel: "CARD,USSD", //this field is used to filter what card channels you want enabled on the payment modal
        extendedData: { 
            customFields: [ 
                { 
                    name: "rrr", 
                    value: referenceId //rrr to be processed.
                } 
            ]
        },
        onSuccess: function (response) {
            console.log('callback Successful Response', response);
            successFunc(response?.paymentReference);
        },
        onError: function (response) {
            console.log('callback Error Response', response);
        },
        onClose: function () {
            console.log("closed");
        }
    });
     paymentEngine.showPaymentWidget();
}