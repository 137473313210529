import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  examDiet: [],
  examPeriod: [],
  examDietDetails: "",
  editExamDiet: "",
  editExamPeriod: "",
};

export const fetchExamDiet = createAsyncThunk(
    "examDietPeriod/fetchExamDiet",
    async () => {
      return httpRequest(`${BASE_URL}/v2/ExamDiet`);
    }
);

export const fetchExamPeriod = createAsyncThunk(
    "examDietPeriod/fetchExamPeriod",
    async (id) => {
      return httpRequest(`${BASE_URL}/v2/ExamDiet/${id}/period`);
    }
);


export const createExamDiet = createAsyncThunk(
    "examDietPeriod/createExamDiet",
    async (arg, { rejectWithValue }) => {
      try {
        const response = await httpRequest(
          `${BASE_URL}/v2/ExamDiet`,
          "post",
          arg
        );
        if (response?.response?.data) {
          throw response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
);

export const createExamPeriod = createAsyncThunk(
    "examDietPeriod/createExamPeriod",
    async ({arg, id}, { rejectWithValue }) => {
      try {
        const response = await httpRequest(
            `${BASE_URL}/v2/ExamDiet/${id}/period`,
          "post",
          arg
        );
        if (response?.response?.data) {
          throw response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
);


const examDietPeriodSlice = createSlice({
  name: "examDietPeriod",
  initialState,
  reducers: {
    setExamDietDetails: (state, { payload }) => {
      state.examDietDetails = payload;
    },
    setEditExamDiet: (state, { payload }) => {
      state.editExamDiet = payload;
    },
    setEditExamPeriod: (state, { payload }) => {
      state.editExamPeriod = payload;
    },

  },
  extraReducers: (builder) => {
    // get examDiet:
    builder.addCase(fetchExamDiet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamDiet.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examDiet = payload;
      state.error = null;
    });

    builder.addCase(fetchExamDiet.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // get examPeriod:
    builder.addCase(fetchExamPeriod.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExamPeriod.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.examPeriod = payload;
      state.error = null;
    });

    builder.addCase(fetchExamPeriod.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examDiet:
    builder.addCase(createExamDiet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamDiet.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamDiet.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create examPeriod:
    builder.addCase(createExamPeriod.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createExamPeriod.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createExamPeriod.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setExamDietDetails, setEditExamDiet, setEditExamPeriod } = examDietPeriodSlice.actions;
export default examDietPeriodSlice.reducer;