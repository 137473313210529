import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setFormModal } from '../redux/slices/modalSlice';
import { Form, Formik } from 'formik';
import { Alert, AlertIcon, AlertTitle, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { addFeeTypeSchema } from '../utils/formValidationSchema';
import { createFeeType, fetchFeeType } from '../redux/slices/feeMgtSlice';
import { setloadTost } from "../redux/slices/toastSlice";

const AddFeeTypeForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const initialValues = {
        feeType: '',
        feeStatus: '',
    };


    const handleSubmit = async (values) => {
        setLoading(true);
        setError("");
        try {
            const formData = {
                feeTypeName: values?.feeType,
                feeCode: values?.feeCode,
                isActive: true,
            };

            const response = await dispatch(createFeeType(formData));
            // console.log(response);
            if (response?.payload?.isSuccess === true) {
                setLoading(false);
                dispatch(fetchFeeType());
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Fee Type created', description: `${values?.feeType} fee type has been created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            } else {
                setLoading(false);
                setError(response?.payload?.errors?.description || "Failed to create fee type");
            }

        } catch (error) {
            setError(error?.payload?.message || "Failed to create role, please check your network");
        } finally {
            setLoading(false);
        }


    }

    return (
        <>
            {
                error && (
                    <Alert status="error" className="mt-3" mb={3}>
                        <AlertIcon />
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={addFeeTypeSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <FormControl mt={4} isInvalid={errors.feeType && touched.feeType} color="#121212">
                            <FormLabel htmlFor='feeType' fontSize={'16px'}>Fee Type</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="feeType"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.feeType}
                            />
                            {touched.feeType && errors.feeType && (
                                <FormErrorMessage>{errors.feeType}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.feeCode && touched.feeCode} color="#121212">
                            <FormLabel htmlFor='feeCode' fontSize={'16px'}>Fee Code</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="feeCode"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.feeCode}
                            />
                            {touched.feeCode && errors.feeCode && (
                                <FormErrorMessage>{errors.feeCode}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Add Fee Type
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}

            </Formik>
        </>
    )
}

export default AddFeeTypeForm