import React from 'react'
import { OutstandingDuesData } from '../data/mock';
import { Box, Button, Flex, FormControl, Select, Text } from '@chakra-ui/react';
import { PiExport } from 'react-icons/pi';
import CustomTable from './CustomTable';

const MembershipOutstandingDuesReportTable = () => {
    const tableProps = {
        data: OutstandingDuesData,
        title: {
            membershipNo: "Membership No",
            fullName: "Full Name",
            phoneNumber: "Phone Number",
            outstandingFee: "Outstanding Fee",
            memberCategory: "Member Category"
        },
        dataKeys: [
            "membershipNo",
            "fullName",
            "phoneNumber",
            "outstandingFee",
            "memberCategory",
        ],
        transformRow: (item) => ({
            membershipNo: item?.membershipNo,
            fullName: item?.fullName,
            phoneNumber: item?.phoneNumber,
            outstandingFee: item?.outstandingFee,
            memberCategory: item?.memberCategory,
        }),
    };
    return (
        <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
            <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Member Outstanding Dues Report</Text>
                <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
                    <Text cursor={'pointer'} color={'#D11A2A'} fontSize={'12px'} fontWeight={'600'} lineHeight={'16px'} textDecoration={'underline'}>DOWNLOAD TEMPLATE HERE</Text>
                    <Flex alignItems={'center'}>
                        <FormControl w={'100px'}>
                            <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    <Button leftIcon={<PiExport style={{ width: "15px", height: "15px" }} />} bg={'transparent'} _hover={{ backgroundColor: "transparent" }} _active={{ backgroundColor: "transparent" }} color={'#404040'}
                        fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'}
                    >
                        Export
                    </Button>
                </Flex>
            </Flex>
            <CustomTable {...tableProps} />
        </Box>
    )
}

export default MembershipOutstandingDuesReportTable