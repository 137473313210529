import React,{useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Flex, Input, Alert,AlertIcon, AlertTitle  } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {addCentreSchema} from "../utils/formValidationSchema";
import {setloadTost} from "../redux/slices/toastSlice";
import { fetchExamCentre} from "../redux/slices/examCentreSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const EditCenter = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const editExamCentre = useSelector((state) => state?.examCentre?.editExamCentre);

  const initialValues = {
    centreName: editExamCentre?.name || '',
    centreCode: editExamCentre?.code || '',
    address: editExamCentre?.address || '',
  };
  
  const handleSubmit = async (values) => {
    //   console.log(values);
    setLoading(true);
    setError("");
    try {
      const formData = { 
        name: values?.centreName,
        code: values?.centreCode,
        address: values?.address,
        isActive: true,
      }

      const response = await httpRequest(
        `${BASE_URL}/v2/ExamCentre/${editExamCentre?.id}/update`,
        "post",
        formData
      );
      // console.log(response);
      if (response?.isSuccess === true) {
        setLoading(false);
        dispatch(fetchExamCentre());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Centre Edited', description: `${values?.centreName} centre edited successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setLoading(false);
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to edit centre");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to edit centre, please check your network");
    }finally{
      setLoading(false);
    }
  }

  return (
      <>
    {
    error &&(
    <Alert status="error" className="mt-3" mb={3}>
        <AlertIcon />
        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
    </Alert>
    )
    }
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={addCentreSchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
       <FormControl mt={4} isInvalid={errors.centreName && touched.centreName}
            color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='centreName' fontSize={"16px"}>Centre Name</FormLabel>
            <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="centreName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.centreName}
            />
          {touched.centreName && errors.centreName && (
            <FormErrorMessage>{errors.centreName}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.centreCode && touched.centreCode}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='centreCode' fontSize={"16px"}>Centre Code</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="centreCode"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.centreCode}
          />
          {touched.centreCode && errors.centreCode && (
            <FormErrorMessage>{errors.centreCode}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.address && touched.address}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='address' fontSize={"16px"}>Address</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="address"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.address}
          />
          {touched.address && errors.address && (
            <FormErrorMessage>{errors.address}</FormErrorMessage>
          )}
        </FormControl>
        
        <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={"#D11A2A"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={loading}
            loadingText="Saving ..."
          >
            Edit
          </Button>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={""}
            _hover={{ background: " " }}
            color={"#8F9BB3"}
            onClick={() => {
              dispatch(setFormModal({ status: false, data: null }));
            }}
            loadingText="Saving ..."
          >
            Cancel
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
  </>
  )
}

export default EditCenter;