import { convertFileToBase64 } from "../utils/convertToBase64";

export const generateNYSCFormData = async (formData, isSubmitted) => {
  const passportfilebase64 = await convertFileToBase64(formData?.passportfile);
  const passportfileExtension = formData?.passportfile?.name?.split('.').pop();
  const certificatefilebase64 = await convertFileToBase64(formData?.certificatefile);
  const certificatefileExtension = formData?.certificatefile?.name?.split('.').pop();
  const marriageCertfilebase64 = await convertFileToBase64(formData?.marriageCertfile);
  const marriageCertfileExtension = formData?.marriageCertfile?.name?.split('.').pop();

  return {
    personalInfo: {
      title: formData?.title,
      passport: {
        extension: `.${passportfileExtension}`,
        data: passportfilebase64
      },
      firstName: formData?.firstname,
      lastName: formData?.surname,
      otherName: formData?.otherName,
      phoneNumber: formData?.telephone,
      dateOfBirth: formData?.DOB,
      gender: formData?.gender,
      postCode: formData?.postCode,
      country: formData?.country,
      stateOfOrigin: formData?.stateOfOrigin,
      homeAddress: formData?.address
    },
    ppaInfo: {
        jobTitle: formData?.jobTitle,
        department: formData?.department,
        callUpNumber: formData?.callUpNumber,
        stateOfDeployment: formData?.stateOfDeployment,
        yearOfService: formData?.yearOfService,
        employer: formData?.employerName,
        employerAddress: formData?.employerAddress,
        postalCode: formData?.employerPostCode,
        phoneNumber: formData?.businessPhone,
        emailAddress: formData?.businessEmail,
        country: formData?.businesscountry,
        primaryAddress: formData?.communicationAddress || "Home"
      },
    qualificationInfo: {
      entryQualificationTypeId: formData?.qualificationType,
      applicableException: formData?.exemptionType,
      certificates: [
        {
          course: formData?.course,
          schoolAttended: formData?.schoolAttended,
          levelAttained: formData?.levelAttained,
          fileAttach: {
            extension: `.${certificatefileExtension}`,
            data: certificatefilebase64
          }
        }
      ]
    },
    marriageInfo: {
      maritalStatus: formData?.maritalStatus,
      marriageCertificate: marriageCertfilebase64 ? {
        extension: `.${marriageCertfileExtension}`,
        data: marriageCertfilebase64
      } : null,
    },
    refereeInfo: {
      membershipId: formData?.RefMembershipNumber,
      fullName: formData?.RefFullName,
      email: formData?.RefEmailAddress,
      phoneNumber: formData?.RefPhoneNumber,
      qualification: formData?.refereeQualifications,
      jobTitle: formData?.occupation
    },
    isSubmitted: isSubmitted,
  };
}
