import { 
    // createAsyncThunk, 
    createSlice } from "@reduxjs/toolkit";
// import { BASE_URL } from "../../config/settings";
// import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  myDuesDetails : "",
};

const MyDuesSlice = createSlice({
  name: "MyDues",
  initialState,
  reducers: {
    setMyDuesDetails: (state, { payload }) => {
      state.myDuesDetails = payload;
    },

  },
 
});

export const { setMyDuesDetails } = MyDuesSlice.actions;
export default MyDuesSlice.reducer;