import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
    biodata: [],
    pendingBioData: [],
    approvedBioData: [],
    declinedBioData: [],
    loading: false,
    error: null,
};

export const fecthBioData = createAsyncThunk(
    "membership/fecthBioData",
    async () => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/BioData`);
    }
);

export const fecthPendingBioData = createAsyncThunk(
    "membership/fecthPendingBioData",
    async () => {
        return httpRequest(`${BASE_URL}/v2/MembershipManagement/PendingBioDataApproval`);
    }
);
export const createBioData = createAsyncThunk(
    "membership/createBioData",
    async ({arg, memberId}, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/MembershipManagement/BioDataUpdate/${memberId}/`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const membershipSlice = createSlice({
    name: "membership",
    initialState,

    extraReducers: (builder) => {
        // get all biodata
        builder.addCase(fecthBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fecthPendingBioData.pending, (state) => {
            state.loading = true;
        });
        
        builder.addCase(fecthBioData.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.biodata = payload;
            state.error = null;
        });

        builder.addCase(fecthPendingBioData.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.biodata = payload;
            state.error = null;
        });

        builder.addCase(fecthBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        
        builder.addCase(fecthPendingBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // create biodata

        builder.addCase(createBioData.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createBioData.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(createBioData.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }
});

export default membershipSlice.reducer;
