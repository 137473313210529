import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const PrintAdmissionLetter = () => {
  return (
    <>
        <Flex mt={1} flexDirection={"column"} justifyContent={'center'} alignItems={'center'} gap={5} bgColor={'white'} h={'269px'}>
            <Text color={'#3A3541DE'} fontWeight={'700'} fontSize={'16px'} lineHeight={'32px'} letterSpacing={'0.15px'}>Admission Letter</Text>
            <Flex flexDirection={['column', "row", "row"]} justifyContent={'center'} alignItems={'center'} gap={[5,10,10]}>
                <Button mb={5} w={'200px'} h={'50px'} bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
                >Print Admission Letter</Button>
            
                <Button mb={5} w={'200px'} h={'50px'} bgColor={'#22944E'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
                >Download As PDF</Button>
            </Flex>
        </Flex>
    </>
  )
}

export default PrintAdmissionLetter;