import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  Alert,
  Image,
  AlertIcon,
  AlertTitle,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { NYSCRegistrationSchema } from "../utils/formValidationSchema";
import { useDispatch } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import LoginImage from "../components/LoginImage";
import logo from "../Assets/Images/icsan-logo.svg";
import { createNYSCRegistration } from "../redux/slices/NYSCRegistrationSlice";
import { setloadTost } from "../redux/slices/toastSlice";

const NYSCRegScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [error, setError] = useState(null);
  const errorsArray = Array.isArray(error) ? error : [error];
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  };
  const handleSubmit = async (values) => {
    setError("");
    setIsLoading(true);
    try {
      const formData = {
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        password: values?.password,
      }

      const response = await dispatch(createNYSCRegistration(formData));
      // console.log(response);
      if (response?.payload?.isSuccess === true) {
        setError('');
        setIsLoading(false);
        dispatch(setloadTost({ title: 'NYSC Registration', description: 'Registration successfully, check your email', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        navigate("/");
      } else {
        setIsLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to register NYSC");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error?.payload?.message || "Failed to register NYSC, please check your network");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Flex
      minH={"100vh"}
      w="full"
      sx={{
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      overflowY={"auto"}
    >
      <LoginImage />
      <Flex
        // h={"full"}
        // w="full"
        justify={"center"}
        position={"relative"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Flex
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
          mt={5}
          px={["2rem", "3rem", "4rem"]}
        >
          <Image src={logo} alt=" icsan logo" />
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight={"400"}
            color={"rgba(30, 30, 30, 0.5)"}
          >
            INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA{" "}
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          bg={"white"}
          width={"full"}
          px={["2rem", "3rem", "4rem"]}
          pt="3rem"
          pb="2rem"
        >
          <Flex justify={"space-between"} direction={"column"} mb="1.5rem">
            <Text fontWeight={"700"} fontSize={"1.8rem"}>
              Register
            </Text>
            <Text fontSize={"14px"} pt={2}>
              Please register as an applicant
            </Text>
          </Flex>

          {error && (
            <Alert status="error" className="mt-3" mb={5}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
          )}

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={NYSCRegistrationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, handleBlur, handleChange }) => (
              <Form mt={4}>
                <FormControl
                  isInvalid={errors.email && touched.email}
                  mb={5}
                  color="black"
                >
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    placeholder="Enter email address"
                    bgColor={"#F3F3F3"}
                    borderRadius={"8px"}
                    borderColor={"transparent"}
                    mb="1rem"
                    name="email"
                    type='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={errors.phoneNumber && touched.phoneNumber}
                  mb={5}
                  color="black"
                >
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    placeholder="Enter Phone Number"
                    bgColor={"#F3F3F3"}
                    borderRadius={"8px"}
                    borderColor={"transparent"}
                    mb="1rem"
                    name="phoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={errors.password && touched.password}
                  mb={10}
                  color="black"
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder="Enter password"
                      borderRadius={"8px"}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bgColor={"#F3F3F3"}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        onClick={() => setShow(!show)}
                      >
                        {show ? (
                          <BsEye w={5} h={5} color="GrayText" />
                        ) : (
                          <BsEyeSlash color={"GrayText"} w={5} h={5} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Text fontSize={'13px'} color={'green'} fontWeight={'bold'} mt={2}>Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character either @#$_</Text>
                  {touched.password && errors.password && (
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={errors.confirmPassword && touched.confirmPassword}
                  mb={4}
                  color="black"
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      type={show2 ? "text" : "password"}
                      placeholder="Enter password"
                      borderRadius={"8px"}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bgColor={"#F3F3F3"}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        onClick={() => setShow2(!show2)}
                      >
                        {show2 ? (
                          <BsEye w={5} h={5} color="GrayText" />
                        ) : (
                          <BsEyeSlash color={"GrayText"} w={5} h={5} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {touched.confirmPassword && errors.confirmPassword && (
                    <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                  )}
                </FormControl>

                <Button
                  isLoading={isLoading}
                  loadingText="Registering ..."
                  color={"white"}
                  bgColor={"#E15763"}
                  borderRadius="12px"
                  mt={"2rem"}
                  mb="1rem"
                  w={"full"}
                  type="submit"
                  _hover={{ bgColor: "" }}
                >
                  Register
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
        <Divider py={"-10"} color={"#8A8C8E"} border={"1px solid #8A8C8E"} />
        <Text pt={6} pb={10} fontSize={"14px"} fontWeight={"400"}>
          Already a member?{" "}
          <span style={{ color: "#EC0000", cursor: "pointer" }} onClick={() => navigate('/')}>
            Login Here
          </span>
        </Text>
      </Flex>
    </Flex>
  )
}

export default NYSCRegScreen;