import React from 'react';
import { Box, Flex,Text,Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import SortBy from '../components/SortBy';
import DownloadBtn from '../components/DownloadBtn';
import FellowPendingTable from '../components/FellowPendingTable';
import FellowApprovedTable from '../components/FellowApprovedTable';
import FellowDeclinedTable from '../components/FellowDeclinedTable';
import FellowInProgressTable from '../components/FellowInProgressTable';

const Fellow = () => {

  return (
      <Box  pt={2} pb={5} minH={'100vh'} px={3}>
    <Flex bg={'#FFFFFF'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} py={2} px={3} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>Fellow Application</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
        <SortBy />
        <DownloadBtn />
      </Flex>
    </Flex>
    <Tabs variant='unstyled'>
      <TabList gap={'20px'} flexDirection={['column', 'row', 'row']}  pb={4}  
             justifyContent={['center', 'flex-start', 'flex-start']} alignItems={'center'}>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
           bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >Pending</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >Approved</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >Declined</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >In-progress</Tab>
      </TabList>

      <TabPanels bg={'#FFFFFF'} >
        <TabPanel>
          <FellowPendingTable />
        </TabPanel>
        <TabPanel>
          <FellowApprovedTable />
        </TabPanel>
        <TabPanel>
          <FellowDeclinedTable />
        </TabPanel>
        <TabPanel>
          <FellowInProgressTable />
        </TabPanel>
      </TabPanels>
    </Tabs>
    
  </Box>
  )
}

export default Fellow;