import React from 'react';
import {Box } from '@chakra-ui/react';
import {useSelector } from "react-redux";
import CustomTable from './CustomTable';

const ExamResultDetails = () => {
     const resultDetailsData = useSelector((state) => state?.examResult?.examResultDetails);
     
     const tableProps = {
        data: resultDetailsData?.tableContent,
        title: {
          studentNumber: "Student Number",
          subjectCode: "Subject Code",
          score: "Score",
          grade:"Grade",
          
        },
        dataKeys: [
          "studentNumber",
          "subjectCode",
          "score",
          "grade",
        ],
        
        transformRow: (item) => ({
          studentNumber :item && <Box style={{cursor:'pointer'}}>{item?.studentNumber}</Box>,
          subjectCode: item && <Box style={{cursor:'pointer'}}>{item?.subjectCode}</Box>,
          score:item && <Box style={{cursor:'pointer'}}>{item?.score}</Box>,
          grade:item && <Box style={{cursor:'pointer'}}>{item?.grade}</Box>,
        }),
      };

  return (
    <>
      <CustomTable {...tableProps} />
    </>
  )
}

export default ExamResultDetails;