import React from "react";
import { Box, Flex, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import CustomTable from "./CustomTable";
import DotLoader from './loaders/DotLoader';

const ExamCentreApprovedTable = ({ examCentreChangeApplication, loading, error, errorsArray }) => {


  const tableProps = {
    data: examCentreChangeApplication?.data,
    title: {
      fullName: "Fullname",
      appNo: "Application Number",
      email: "Email Address",
      oldCentre: "Old Centre",
      newCentre: "New Centre",
    },
    dataKeys: ["fullName", "appNo", "email", "oldCentre", "newCentre"],

    transformRow: (item) => ({
      fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
      appNo: <Box style={{ cursor: "pointer" }}>{item?.applicationNumber}</Box>,
      email: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
      oldCentre: <Box style={{ cursor: "pointer" }}>{item?.oldCentre}</Box>,
      newCentre: <Box style={{ cursor: "pointer" }}>{item?.newCentre}</Box>,
    }),
  };

  return (
    <>
      {loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ) : (
        <>
          {error ? (
            <Alert status="error" className="mt-3" mb={3}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
          ) : (
            <CustomTable {...tableProps} />
          )}
        </>
      )}
    </>
  );
};

export default ExamCentreApprovedTable;

