import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex,Text,Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react';
import SortBy from '../components/SortBy';
import DownloadBtn from '../components/DownloadBtn';
import StudentPendingTable from '../components/StudentPendingTable';
import StudentApprovedTable from '../components/StudentApprovedTable';
import StudentDeclinedTable from '../components/StudentDeclinedTable';
// import StudenInProgressTable from '../components/StudenInProgressTable';
import { useDispatch, useSelector } from "react-redux";
import {fetchStudentApplicationApproval} from "../redux/slices/studentApplicationApprovalSlice";

const Student = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("Pending");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllStudentApplication = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchStudentApplicationApproval(status));
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Student Applications");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Student Applications, please check your network");
    }
  }, [dispatch, status]);

  useEffect(() => {
    getAllStudentApplication();
  }, [getAllStudentApplication]);
  const studentApplication = useSelector((state) => state?.studentApplicationApproval?.students);

  return (
    <Box  pt={2} pb={5} minH={'100vh'} px={3}>
    <Flex bg={'#FFFFFF'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} py={2} px={3} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>Student Application</Text>
      <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
        <SortBy />
        <DownloadBtn />
      </Flex>
    </Flex>
    <Tabs variant='unstyled'>
      <TabList gap={'20px'} flexDirection={['column', 'row', 'row']}  pb={4}  
             justifyContent={['center', 'flex-start', 'flex-start']} alignItems={'center'}>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
           bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
          onClick={() =>  setStatus("Pending") }
        >Pending</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => setStatus("Approved") }
        >Approved</Tab>
        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        onClick={() => setStatus("Declined") }
        >Declined</Tab>
        {/* <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }}
         bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']}  borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}
        >In-progress</Tab> */}
      </TabList>

      <TabPanels bg={'#FFFFFF'} >
        <TabPanel>
          <StudentPendingTable studentApplication={studentApplication} loading={loading} errorsArray={errorsArray} error={error} />
        </TabPanel>
        <TabPanel>
          <StudentApprovedTable studentApplication={studentApplication} loading={loading} errorsArray={errorsArray} error={error} />
        </TabPanel>
        <TabPanel>
          <StudentDeclinedTable studentApplication={studentApplication} loading={loading} errorsArray={errorsArray} error={error} />
        </TabPanel>
        {/* <TabPanel>
          <StudenInProgressTable />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
    
  </Box>
  )
}

export default Student;