import React from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/Images/icsan-logo.svg";

const ResultStatement = () => {
  const navigate = useNavigate();

  return (
    <Box py={"30px"} minH={"100vh"} px={3}>
      <Flex alignItems={"center"} gap={"20px"} pb={4} mb={5} cursor={"pointer"}>
        <Box
          bg={"#F9A36238"}
          rounded={"full"}
          p={1}
          onClick={() => navigate(-1)}
        >
          <BiLeftArrowAlt color="#D8991F" size={"20px"} />
        </Box>
        <Text
          fontSize={"18px"}
          fontWeight={"700"}
          color={"#D11A2A"}
          opacity={"0.5"}
        >
          Statement Of Result
        </Text>
      </Flex>
      <Box
        bg={"#FFFFFF"}
        px={["20px", "50px", "50px"]}
        py={["20px", "30px", "30px"]}
      >
        <Flex
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={5}
          pt={7}
          pb={5}
          cursor={"pointer"}
        >
          <Button
            px={5}
            size={"sm"}
            bg={"#D11A2A"}
            color={"#FFFFFF"}
            fontWeight={"500"}
            fontSize={"14px"}
            lineHeight={"16px"}
            _hover={{ backgroundColor: "" }}
            _active={{ backgroundColor: "" }}
          >
            Print Result
          </Button>
          <Text
            color={"#D11A2A"}
            fontWeight={"500"}
            fontSize={"16px"}
            lineHeight={"16px"}
          >
            Sent to Student Email
          </Text>
        </Flex>
        <Box bg={"#D11A2A0F"} p={"30px 50px"}>
          <Flex
            flexDirection={"column"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              fontSize={["18px", "24px", "24px"]}
              lineHeight={"36.7px"}
              fontWeight={["500", "700", "700"]}
              color={"#1E1E1E"}
            >
              INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA
            </Text>
            <Image src={logo} alt="ICSAN Logo" />
          </Flex>
          <Flex justifyContent={["center", "flex-end", "flex-end"]} alignItems={"center"} py={6}>
            <Text
              border={"1px solid #D11A2A"}
              p={"10px 30px"}
              color={"#222B45"}
              fontWeight={"400"}
              fontSize={"14px"}
              lineHeight={"16px"}
            >
              6798444
            </Text>
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={["center", "flex-start", "flex-start"]}
            alignItems={["center", "flex-start", "flex-start"]}
            gap={"15px"}
            fontWeight={"700"}
            fontSize={"14px"}
            lineHeight={"21.41px"}
            color={"#000000"}
            opacity={"0.5"}
          >
            <Text>Treasure Raphael</Text>
            <Text>2023</Text>
            <Text>June</Text>
          </Flex>
          <Flex justifyContent={["center", "flex-end", "flex-end"]} alignItems={"center"} py={5}>
            <Text
              color={"#000000"}
              opacity={"0.5"}
              fontWeight={"700"}
              fontSize={"14px"}
              lineHeight={"19.07px"}
            >
              Date: 16-MAR-22
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"} py={7}>
            <Text
              color={"#1E1E1E"}
              fontWeight={"700"}
              fontSize={"16px"}
              lineHeight={"19.76px"}
              textDecoration={"underline"}
            >
              Statement Of Result
            </Text>
          </Flex>
          <Text fontWeight={'400'} fontSize={'16px'} lineHeight={'48px'} color={'#000000'}>
            I am pleased to inform you that the result of the 3rd September,
            2020 Degree Examinations show that you have satisfied all the
            requirements for the award of the Degree of Bachelor of Science
            (B.St) in Chatered Secretary Studies with Second Class Honours
            (Upper Division) · of the Institute of Chatered Secretaries and
            Administration of Nigeria. The results have been approved by Senate.
            The Degree will be conferred on you at the forthcoming Convocation
            Ceremony. Accept our congratulations.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ResultStatement;
