import React, { useEffect } from 'react';
import { Box, Button, Divider,Flex, Image, Text, } from '@chakra-ui/react';
import logo from "../Assets/Images/icsan-logo.svg";
import CustomTable from './CustomTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fecthExamResult } from '../redux/slices/examMgtSlice';

const MemberExamStatement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const examCategory = searchParams.get("ExamCategory");
  const examYear = searchParams.get("ExamYear");
  
  
  const examResultData = useSelector((state) => state?.examMgt?.examResult)

  console.log("examResultData",examResultData)

  useEffect(() => {
    dispatch(fecthExamResult({ExamCategory:examCategory, ExamPeriodId:examYear }))
  }, [dispatch, examCategory, examYear])

  const personalDetails = [
    {title: "75%-100%", description: 'DISTINCTION'},
    {title: "65%-74%", description: 'MERIT'},
    {title: "60%-64%", description: 'PASS'},
    {title: "0%-49%", description: 'FAIL'},
    {title: "", description: 'ABSENT'},
  ];

  const tableProps = {
    data: examResultData,
    title: {
      subject: "Subject",
      grade:"Grade",
    },
    dataKeys: [
      "subject",
      "grade",
    ],
    showPagination:false,
    transformRow: (item) => ({
      subject: item &&  <Box style={{cursor:'pointer'}}>{item?.subject}</Box>,
      grade: item &&  <Box style={{cursor:'pointer'}}>{item?.grade}</Box>,
    }),
  };

  return (
    <Box bgColor={'#FFFFFF'} minH={'80vh'} mt={1} px={['20px', '30px', '50px']} pt={'20px'} pb={'50px'}>
      <Flex direction={'column'} justify={'center'} alignItems={'center'} gap={4} >
        <Image src={logo} alt='ICSAN Logo' w={'121px'} h={'62px'} />
        <Text textAlign={'center'} color={'#1E1E1E'} fontSize={['16px', '20px', '24px']} fontWeight={'700'} lineHeight={'36.7px'}>INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA</Text>
        <Text textAlign={'center'} color={'#1E1E1E'} fontSize={['14px', '14px', '16px']} fontWeight={'700'} lineHeight={'24.46px'}>PROFESSIONAL EXAMINATION STATEMENT OF RESULT</Text>
      </Flex>
      <Flex mt={'60px'} direction={'column'} gap={3} color={'#1E1E1E'} fontSize={'14px'} fontWeight={'700'} lineHeight={'19.07px'}  >
        <Text>ICSAN/EXAM/2023/1103971</Text>
        <Text>Miss AHMED AISHAT</Text>
        <Text fontWeight={'400'} >2A Ajibade Olatunji close Sabo, Yaba, Lagos</Text>
      </Flex>
      <Flex mt={'30px'} mb={'50px'} direction={'column'} gap={3} color={'#1E1E1E'} fontSize={'14px'} fontWeight={'700'} lineHeight={'19.07px'}  >
        <Text fontWeight={'400'}>Dear Sir/Ma</Text>
        <Text>JUNE 2023 EXAMINATIONS- PROFESSIONAL PROGRAMME II PASSED</Text>
        <Text fontWeight={'400'} >Following the examination held on Tuesday 06 to Wednesday 07 June 2023, your result is as follows: </Text>
      </Flex>

      <CustomTable {...tableProps}/>
      <Flex w={'100%'} justifyContent={'flex-end'} mt={'50px'}>
        <Flex flexDirection={'column'} gap={3} w={['100%', '100%', '60%']}  >
          <Text alignSelf={'flex-end'} my={5} color={'#1E1E1E'} fontSize={'14px'} fontWeight={'700'} lineHeight={'19.07px'}>RESULT DEFINITION</Text>
          <Box >
            <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={'40px'} py={'20px'}
              color={'#4D4D4D'} fontFamily={'inter'} fontWeight={'600'} fontSize={'14px'} lineHeight={'16.94px'} letterSpacing={'-0.15px'}
            >
              <Text width={'50%'}>Score</Text>
              <Text width={'50%'}>Grade</Text>
            </Flex>
            <Box bgColor={'#FFFFFF'} border={'1px solid #C6C6C640'}>
            {personalDetails && personalDetails?.map((item, index) => (
                <Flex key={index} alignItems={'center'} gap={5} color={'#4D4D4D'} fontFamily={'inter'} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'} 
                  letterSpacing={'-0.15px'} border={'1px solid #DADADA'} px={'40px'} py={'20px'} >
                  <Text mb={5} width={'50%'}>{item?.title}</Text>
                  <Text mb={5} width={'50%'}>{item?.description}</Text>
                </Flex>
            ))}
            </Box>
          </Box>
        </Flex>
      </Flex>
      <Flex mt={'30px'} direction={'column'} gap={3} fontFamily={'inter'} color={'#1E1E1E'} fontSize={'14px'} fontWeight={'400'} lineHeight={'19.07px'} >
        <Text mb={2}>Yours Sincerely</Text>
        <Divider border={'0.5px solid #1E1E1E' } width={'95px'} />
        <Text mt={2}>JOHN DEO,ACIS</Text>
        <Text>Registrar/CEO</Text>
      </Flex>
      <Flex mt={'60px'} direction={'column'} gap={3} fontFamily={'inter'} color={'#1E1E1E'} fontSize={'14px'} fontWeight={'400'} lineHeight={'19.07px'} >
        <Text >This slip should be safely preserved as no other certificate will be issued until the final examinations are completed and passed.
          Any addition or cancelation renders this slip useless.
        </Text>
        <Text>NOTE: EXAMINATION RESULTS ARE CAREFULLY CHECKED AND SUBJECTED TO QUALITY ASSURANCE. THEREFORE THE INSTITUTE WILL NOT ENTER ANY CORRESPONDENCE ON THEM</Text>
      </Flex>
      <Flex flexDirection={["column", "row", "row"]} mt={12} gap={[3, 8, 10]} alignItems={"center"}>
        <Button size={'lg'} w={'180px'} bgColor={'#8A8C8E'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
          onClick={() => navigate(-1)}
        >Cancel</Button>
        <Button size={'lg'} w={'180px'} bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} borderRadius={'5px'} _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
        >Print</Button>      
      </Flex>
    </Box>
  )
} 

export default MemberExamStatement;