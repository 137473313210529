import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Image, Text , Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import logo from "../Assets/Images/icsan-logo.svg";
import Lottie from "lottie-react";
import paymentSuccess from "../Assets/lotties/Animation-payment-success.json";
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import DotLoader from '../components/loaders/DotLoader';

const StudentConfirmAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [sucess, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const caId = searchParams.get('caId');
    // console.log(`StudentConfirmAccount: ${token}, ${caId}`);

    const confirmAccount = useCallback( async() => {
        setLoading(true);
        setError("");
        try {
            const response = await httpRequest(`
                ${BASE_URL}/v2/Auth/verify-email?id=${caId}&token=${token}`,
                "post",
            );
            // console.log(response);
            if (response?.isSuccess === true){
                setLoading(false);
                setSuccess(true);
            }else{
                setLoading(false);
                setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to confirm account");
            }
        } catch (error) {
            setLoading(false);
            setError(error?.payload?.message || "Failed to confirm account, please check your network");
        }finally {
            setLoading(false);
        }

    }, [token, caId]);

    useEffect(() => {
        confirmAccount();
    }, [confirmAccount]);

  return (
    <Flex flexDirection={"column"} justifyContent={'center'} alignItems={"center"} gap={5} py={'50px'}>
        <Image src={logo} alt='Logo' />
        {
          loading ? (<DotLoader/>) :(
        <>
            {
            error &&(
            <Alert status="error" className="mt-3" mb={3} >
                <AlertIcon />
                <AlertTitle >{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            )
            }
            {
            sucess &&(
            <>
                <Lottie animationData={paymentSuccess} autoPlay={true} loop={true} style={{ width: "150px", height: "150px" }} />
                <Text color={"#515151"} fontWeight={"700"} fontSize={"24px"} lineHeight={"36.7px"}>Account Confirmed</Text>
                <Text cursor={"pointer"} textDecoration={"underline"} color={"#D11A2A"} fontWeight={"700"} fontSize={"18px"} lineHeight={"21.41px"}
                onClick={() => navigate('/')}
                >Login</Text>
            </>
            )
            }
        </>
          )
        }
       

    </Flex>
  )
}

export default StudentConfirmAccount;