import React,{useState} from 'react';
import { Button, Text, Flex, Select } from '@chakra-ui/react';
import { HiOutlineArrowLeft, HiArrowRight } from "react-icons/hi";
import {setItemPerPage} from "../../redux/slices/searchFilterSlice";
import { useDispatch } from 'react-redux';

const Pagination = ({ currentPage, setCurrentPage, totalPages, itemsPerPage , totalItems}) => {
  const [perPageValue, setPerPageValue ]  =  useState(5);
  const dispatch = useDispatch();

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleItemPerPage = (e) => {
    setPerPageValue(e.target.value);
    dispatch(setItemPerPage(parseInt(e.target.value, 10)));
  }

  return (
    <>
      {totalPages ? (
        <Flex justifyContent="center" mt={[6, 12, 12]} alignItems="center" flexDirection={["column", "row", "row"]} gap={3} >
          <Flex alignItems="center" justifyContent="center">
            <Text fontSize={["12px", "14px", "14px"]}fontWeight="400" color="#717171" lineHeight="15px">Items per page:</Text>
            <Select w={'70px'}  border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'
               onChange={handleItemPerPage} value={perPageValue}>
              <option selected value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </Select>
          </Flex>
          <Flex justifyContent="center" alignItems="center" fontSize={["12px", "14px", "14px"]}>
            {currentPage * itemsPerPage - itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalItems)} of { totalItems}
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              bg="#FFFFFF"
              color="#717171"
              fontSize={["12px", "14px", "14px"]}
              fontWeight="400"
              _hover={{ bg: "#FFFFFF"}}
              size="sm"
              leftIcon={<HiOutlineArrowLeft />}
              _active={{ bg: "#FFFFFF",  }}
            >
            </Button>

            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              bg="#FFFFFF"
              color="#717171"
              fontSize={["12px", "14px", "14px"]}
              fontWeight="400"
              _hover={{ bg: "#FFFFFF"}}
              size="sm"
              rightIcon={<HiArrowRight />}
              _active={{ bg: "#FFFFFF",  }}
            >
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default Pagination;
