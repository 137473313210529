import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Alert,
    AlertIcon,
    AlertTitle
} from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvent, setEventDetails } from '../redux/slices/eventSlice';
import { format } from 'date-fns';
import DotLoader from '../components/loaders/DotLoader';
import { setFormModal } from '../redux/slices/modalSlice';



const EventMgtMemberModule = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [filter, setFilter] = useState('upcoming'); 
    const errorsArray = Array.isArray(error) ? error : [error];

    const getAllEvent = useCallback(async () => {
        setError("");
        setLoading(true);
        try {
            const response = await dispatch(fetchEvent());
            if (response?.payload?.name === "AxiosError") {
                setLoading(false);
                setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Exam Diet, please check your request/network");
            }
        } catch (error) {
            setLoading(false);
            setError(error?.payload?.message || "Failed to get Events, please check your network");
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        getAllEvent();
    }, [dispatch, getAllEvent]);

    const eventsData = useSelector((state) => state?.event?.events?.data);

    const filteredEvents = eventsData?.filter(event => {
        const currentDate = new Date();
        if (filter === 'upcoming') {
            return new Date(event?.startDate) > currentDate;
        } else if (filter === 'past') {
            return new Date(event?.endDate) < currentDate;
        } else {
            return true;
        }
    });

    const handleViewEventDetails = (item) => {
        dispatch(setEventDetails(item));
        dispatch(setFormModal({ status: true, title: "Event Details", type: "viewEventDetails", size: "4xl" }));
    };

    console.log('events data:', eventsData);
    console.log()
    const tableProps = {
        data: filteredEvents,
        title: {
            eventName: "Event Name",
            startDate: "Start Date",
            endDate: "End Date",
            eventLocation: "Venue",
            creditPoint: "Credit Points",
            viewDetails: "",
        },
        dataKeys: [
            "eventName",
            "startDate",
            "endDate",
            "eventLocation",
            "creditPoint",
            "viewDetails",
        ],

        transformRow: (item) => ({
            eventName: item?.eventName,
            startDate: item?.startDate && format(new Date(item?.startDate), 'yyyy-MM-dd'),
            endDate: item?.endDate && format(new Date(item?.endDate), 'yyyy-MM-dd'),
            venue: <Box
                wordBreak="break-word"
            >
                {item?.eventLocation}
            </Box>,
            creditPoints: item?.creditPoint,
            viewDetails: item && (
                <Button onClick={() => handleViewEventDetails(item)} fontWeight={'bold'} fontSize={'14px'} bgColor={'#812773'} color={'white'} px={'10px'} _hover={{ bgColor: '' }} >View Details</Button>
            ),
        }),
    };



    return (
        <>
            <Box bgColor={'#FFFFFF'} mb={5}>
                <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
                    <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Events</Text>
                    <Flex flexDirection={["column", "column", "row"]} justifyContent={'center'} alignItems={'center'} gap={[3, null, null]} />
                </Flex>
                <Flex gap={5} p={3} alignItems={'center'}>
                    <Text fontWeight={500} fontSize={'16px'} color={'#000000'}>Credit Points Accumulated</Text>
                    <Text bgColor={'#005E39'} borderRadius={'5px'} color={'white'} p={1} width={'65px'} textAlign={'center'}>
                        32
                    </Text>
                </Flex>
            </Box>
            <Box bgColor={'#FFFFFF'} pt={2} pl={1}>
                <Tabs variant='unstyled' onChange={(index) => setFilter(index === 0 ? 'upcoming' : 'past')}>
                    <TabList gap={'20px'} flexDirection={['column', 'row', 'row']} pb={4} justifyContent={['center', 'flex-start', 'flex-start']} alignItems={'center'}>
                        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']} borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}>
                            Upcoming
                        </Tab>
                        <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} bg={'#E9E9E9'} w={['100%', '18%', '18%', '18%']} borderRadius={'4px'} lineHeight={'-0.25px'} color={'#54595F'}>
                            Past
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {loading ? (
                                <Flex justifyContent={'center'} alignItems={'center'}>
                                    <DotLoader />
                                </Flex>
                            ) : (
                                <>
                                    {error ? (
                                        <Alert status="error" className="mt-3" mb={3}>
                                            <AlertIcon />
                                            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                                        </Alert>
                                    ) : (
                                        <CustomTable {...tableProps} />
                                    )}
                                </>
                            )}
                        </TabPanel>
                        <TabPanel>
                            {loading ? (
                                <Flex justifyContent={'center'} alignItems={'center'}>
                                    <DotLoader />
                                </Flex>
                            ) : (
                                <>
                                    {error ? (
                                        <Alert status="error" className="mt-3" mb={3}>
                                            <AlertIcon />
                                            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                                        </Alert>
                                    ) : (
                                        <CustomTable {...tableProps} />
                                    )}
                                </>
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
        
    );
}

export default EventMgtMemberModule;
