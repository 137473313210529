import React  from 'react';
import { Box, Flex, Text, FormControl, FormErrorMessage, FormLabel, Input, Divider, Select, Grid, GridItem, Button, Checkbox } from '@chakra-ui/react';
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { NYSCMarriageRefereeInformationAppFormSchema } from '../utils/formValidationSchema';
import { setMarriageRefInfo } from "../redux/slices/NYSCRegistrationSlice";
import { setloadTost } from "../redux/slices/toastSlice";

const NYSCMarriageRefereeInformationAppForm = ({ handlePrevious, handleSaveRegLater, handleSubmit, handleEdit, loading, saveloading }) => {
  const dispatch = useDispatch();
  const registeredNYSC = useSelector((state) => state?.NYSCRegistration?.registerNYSC?.data);
  const marriageRefInfo = useSelector((state) => state?.NYSCRegistration?.marriageRefInfo);
  const initialValues = {
    // maritalStatus: marriageRefInfo?.maritalStatus || registeredNYSC?.marriageInfo?.maritalStatus || '',
    // marriageCertfile: marriageRefInfo?.marriageCertfile || registeredNYSC?.marriageInfo?.marriageCertificate || '',
    RefFullName: marriageRefInfo?.RefFullName || registeredNYSC?.refereeInfo?.fullName || '',
    RefMembershipNumber: marriageRefInfo?.RefMembershipNumber || registeredNYSC?.refereeInfo?.membershipId || '',
    occupation: marriageRefInfo?.occupation || registeredNYSC?.refereeInfo?.jobTitle || '',
    RefPhoneNumber: marriageRefInfo?.RefPhoneNumber || registeredNYSC?.refereeInfo?.phoneNumber || '',
    RefEmailAddress: marriageRefInfo?.RefEmailAddress || registeredNYSC?.refereeInfo?.email || '',
    refereeQualifications: marriageRefInfo?.refereeQualifications || registeredNYSC?.refereeInfo?.qualification || '',
    dataProtectionCertify: marriageRefInfo?.dataProtectionCertify || false,
    receiveInformation: marriageRefInfo?.receiveInformation || false,
  };
  const handleMarariageStatus = async (values) => {
    dispatch(setMarriageRefInfo(values));
    if (values.dataProtectionCertify === true && values.receiveInformation === true) {
      if (registeredNYSC) {
        handleEdit(values);
      } else {
        handleSubmit(values);
      }
    } else {
      dispatch(setloadTost({ title: 'Empty Fields', description: 'Please accept the Data protection checkboxes', status: 'warning', duration: 3000, isClosable: true, position: 'top' }));
    }
  };
  const handleBackBTN = async (values) => {
    dispatch(setMarriageRefInfo(values));
    handlePrevious();
  };

  

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NYSCMarriageRefereeInformationAppFormSchema}
        onSubmit={(values) => handleMarariageStatus(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
          <Form style={{ width: "100%" }}>
            <Box px={['30px', '40px', '40px', '50px']}>
              <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Referee Information</Text>
              <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Referee Details Note:Applicant Referee must not necessarily be a member of Icsan</Text>
              <Grid mb={5} templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={6}>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefFullName && touched.RefFullName} color="#121212">
                    <FormLabel htmlFor='RefFullName' fontSize={'16px'}>Referee Full Name <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefFullName"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefFullName}
                    />
                    {touched.RefFullName && errors.RefFullName && (
                      <FormErrorMessage>{errors.RefFullName}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefMembershipNumber && touched.RefMembershipNumber} color="#121212">
                    <FormLabel htmlFor='RefMembershipNumber' fontSize={'16px'}>Referee membership Number</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefMembershipNumber"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefMembershipNumber}
                    />
                    {touched.RefMembershipNumber && errors.RefMembershipNumber && (
                      <FormErrorMessage>{errors.RefMembershipNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.occupation && touched.occupation} color="#121212">
                    <FormLabel htmlFor='occupation' fontSize={'16px'}>Occupation/Job Title <span style={{ color: "#D11A2A" }}>*</span> </FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="occupation"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.occupation}
                    />
                    {touched.occupation && errors.occupation && (
                      <FormErrorMessage>{errors.occupation}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.RefPhoneNumber && touched.RefPhoneNumber} color="#121212">
                    <FormLabel htmlFor='RefPhoneNumber' fontSize={'16px'}>Referee Phone Number <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefPhoneNumber"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefPhoneNumber}
                    />
                    {touched.RefPhoneNumber && errors.RefPhoneNumber && (
                      <FormErrorMessage>{errors.RefPhoneNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem >
                  <FormControl mt={4} isInvalid={errors.RefEmailAddress && touched.RefEmailAddress} color="#121212">
                    <FormLabel htmlFor='RefEmailAddress' fontSize={'16px'}>Referee Email Address <span style={{ color: "#D11A2A" }}>*</span></FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="RefEmailAddress"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.RefEmailAddress}
                    />
                    {touched.RefEmailAddress && errors.RefEmailAddress && (
                      <FormErrorMessage>{errors.RefEmailAddress}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.refereeQualifications && touched.refereeQualifications}
                    color="#121212" fontFamily={'inter'}>
                    <FormLabel htmlFor='refereeQualifications' fontSize={"16px"}>Referee Qualifications</FormLabel>
                    <Select
                      name="refereeQualifications"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={'#F3F3F3'}
                      borderRadius={"8px"}
                      focusBorderColor="#ffffff"
                      borderColor={"#FFFFFF"}
                      placeholder='Select Here'
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.refereeQualifications}
                    >
                      <option value='Masters'>Masters</option>
                      <option value='Bachelors'>Bachelors</option>
                    </Select>
                    {touched.refereeQualifications && errors.refereeQualifications && (
                      <FormErrorMessage>{errors.refereeQualifications}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
            <Divider border={'1px solid #D3D3D3'} my={10} />
            <Flex flexDirection={"column"} gap={2} mt={10} mb={5} fontWeight={'400'} fontSize={'16px'} lineHeight={'18.75px'} fontFamily={'roboto'} px={['30px', '40px', '40px', '50px']}>
              <Text mb={5} color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Data Protection</Text>
              <Checkbox colorScheme='green'
                isChecked={values.dataProtectionCertify}
                onChange={(e) => setFieldValue('dataProtectionCertify', e.target.checked)}
              >
                I certify that the information given above is, to the best of my knowledge, accurate in all respects. I have read and accepted the regulations, terms and conditions for ICSAN students.
              </Checkbox>
              <Checkbox colorScheme='green'
                isChecked={values.receiveInformation}
                onChange={(e) => setFieldValue('receiveInformation', e.target.checked)}
              >
                The institute occasionally supplies mailing lists, on a restricted basis, to other approved organisations. If you prefer not to receive information by direct mail by those to whom we supply these lists, then please tick the box.
              </Checkbox>
            </Flex>
            <Flex px={['30px', '40px', '40px', '50px']} flexDirection={['column', 'row', 'row']} gap={['20px', '50px', '50px']} alignItems={'center'} mt={['50px', '80px', '100px']} mb={10}>
              <Button bg={'#A6A6A6'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={() => handleBackBTN(values)}>
                Back
              </Button>
              <Button bg={'green.500'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={() => {
                  dispatch(setMarriageRefInfo(values))
                  handleSaveRegLater(values)
                }} isLoading={saveloading} loadingText="Saving...">
                Save and register later
              </Button>
              <Button bg={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                isLoading={loading} loadingText="Submitting..." type='submit'>
                Submit Registration
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default NYSCMarriageRefereeInformationAppForm;