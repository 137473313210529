import React, { useState, useEffect, useCallback } from 'react';
import AddRolePermission from "../components/AddRolePermission";
import { useDispatch, useSelector } from 'react-redux';
import {fetchRolePermissions} from "../redux/slices/userMgtRoleSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import { setFormModal } from "../redux/slices/modalSlice";
import {setloadTost} from "../redux/slices/toastSlice";

const RolePermission = () => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const roleData = useSelector((state) => state?.userMgtRole?.roleDetails);
  const result = useSelector((state) => state?.userMgtRole?.rolePermissions?.permissions);
  // console.log("result", result, roleData?.id);

  useEffect(() => {
    dispatch(fetchRolePermissions(roleData?.id));
  }, [dispatch, roleData?.id]);

  const handleInitialPermissions = useCallback(async () => {
    const initialPermissions = {};
    const resultArray = Array.isArray(result) ? result : [result];
    resultArray.forEach(item => {
      if (item && item.value) { 
        if (item.value === 'Granted') {
          if (item.resource) {
            initialPermissions[item.module] = initialPermissions[item.module] || {};
            initialPermissions[item.module].submenu = initialPermissions[item.module].submenu || {};
            initialPermissions[item.module].submenu[item.resource] = initialPermissions[item.module].submenu[item.resource] || {};
            initialPermissions[item.module].submenu[item.resource][item.action.toLowerCase()] = 'granted';
          } else {
            initialPermissions[item.module] = initialPermissions[item.module] || {};
            initialPermissions[item.module][item.action.toLowerCase()] = 'granted';
          }
        } else if (item.value === 'Denied') {
          if (item.resource) {
            initialPermissions[item.module] = initialPermissions[item.module] || {};
            initialPermissions[item.module].submenu = initialPermissions[item.module].submenu || {};
            initialPermissions[item.module].submenu[item.resource] = initialPermissions[item.module].submenu[item.resource] || {};
            initialPermissions[item.module].submenu[item.resource][item.action.toLowerCase()] = 'denied'; 
          } else {
            initialPermissions[item.module] = initialPermissions[item.module] || {};
            initialPermissions[item.module][item.action.toLowerCase()] = 'denied'; 
          }
        }
      }
    });
    setPermissions(initialPermissions);
  }, [result]);

  useEffect(() => {
    handleInitialPermissions();
  }, [handleInitialPermissions]);

  const handleResourceCheckboxChange = (module, resource) => {
    const updatedPermissions = { ...permissions };
    if (!updatedPermissions[module]?.submenu?.[resource]) {
      updatedPermissions[module].submenu[resource] = {};
      ['view', 'create', 'edit', 'delete'].forEach(action => {
        updatedPermissions[module].submenu[resource][action] = 'granted';
      });
    } else {
      const allGranted = isAllPermissionsGranted(module, resource);
      ['view', 'create', 'edit', 'delete'].forEach(action => {
        updatedPermissions[module].submenu[resource][action] = allGranted ? 'denied' : 'granted';
      });
    }
    setPermissions(updatedPermissions);
  };

  const handlePermissionChange = (module, resource, action) => {
    const updatedPermissions = { ...permissions };
    if (resource) {
      updatedPermissions[module] = updatedPermissions[module] || {};
      updatedPermissions[module].submenu = updatedPermissions[module].submenu || {};
      updatedPermissions[module].submenu[resource] = updatedPermissions[module].submenu[resource] || {};
      updatedPermissions[module].submenu[resource][action] = updatedPermissions[module].submenu[resource][action] === 'granted' ? 'denied' : 'granted';
    } else {
      updatedPermissions[module] = updatedPermissions[module] || {};
      updatedPermissions[module][action] = updatedPermissions[module][action] === 'granted' ? 'denied' : 'granted';
    }
    setPermissions(updatedPermissions);
  };

  const isAllPermissionsGranted = (module, resource) => {
    if (!permissions[module]?.submenu?.[resource]) {
      return false;
    }
    return ['view', 'create', 'edit', 'delete'].every(action => {
      return permissions[module]?.submenu?.[resource]?.[action] === 'granted';
    });
  };

  // to transform the role permision data format that will be sent to the server.
  function transformPermissions(permissions) {
    const result = [];
    for (const module in permissions) {
      const submenu = permissions[module].submenu;
      for (const resource in submenu) {
        const actions = Object.keys(submenu[resource]);
        actions.forEach(action => {
          const value = submenu[resource][action];
          result.push({
            module: module,
            resource: resource,
            action: action.charAt(0).toUpperCase() + action.slice(1), 
            value: value.charAt(0).toUpperCase() + value.slice(1) 
          });
        });
      }
    }
  
    return result;
  }

  const handleSaveChanges = async() =>{
    const result = transformPermissions(permissions);
    // console.log("saved permisions",result);
    const rolePermissionsData = { permissions: result };
    // console.log("rolePermissionsData",rolePermissionsData);
    setLoading(true);
    setError("");
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Role/${roleData?.id}/permissions`,
        "post",
        rolePermissionsData
      );
      // console.log(response);
      if(response.isSuccess === true){
        setLoading(false);
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Permissions Updated', description: `${roleData?.name} permissions has been updated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      }else{
        setLoading(false);
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Update permissions");
      }
    }catch (error) {
      setError(error?.payload?.message || "Failed to Update permissions, please check your network");
    }finally{
      setLoading(false);
    }
  }

  const tableData = {permissions,isAllPermissionsGranted,handleResourceCheckboxChange,handlePermissionChange, handleSaveChanges, loading, error }

  return (
    <>
      <AddRolePermission {...tableData} />
    </>
  );
};

export default RolePermission;