import React from 'react';
import emptyImg from "../Assets/Images/user-empty-img.svg";
import { Flex, Image, Text } from '@chakra-ui/react';
import Create from './layout/Create';

const UserEmptyState = ({createData,text}) => {

  return (
    <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} >
      <Image src={emptyImg} alt='Empty Image' mt={5} />
      <Text color={'#121212'} fontSize={'24px'} fontWeight={'500'}>No {text} Added Yet</Text>
      <Text color={'#515151'} fontSize={'16px'} fontWeight={'400'} lineHeight={'25.6px'} pt={4} pb={12}>Click on the button below to add {text}</Text>
      <Create {...createData} />
    </Flex>
  )
}

export default UserEmptyState;