import React from "react";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import CustomTable from "../components/CustomTable";
import { studentData } from "../data/mock";

const FellowInProgressTable = () => {
    const dispatch = useDispatch();
    
    const handleUpdate = (item) => {
      dispatch(setFormModal({ status: true, title: "Member Application Details", type: "individualDetails", size:"3xl", data:item }));
    };
  
    const tableProps = {
      data: studentData,
      title: {
        fullName: "Fullname",
        appNo: "Application Number",
        email: "Email Address",
        dateRegistered: "Date Registered",
        view: "",
      },
      dataKeys: ["fullName", "appNo", "email", "dateRegistered", "view"],
  
      transformRow: (item) => ({
        fullName: <Box style={{ cursor: "pointer" }}>{item?.fullName}</Box>,
        appNo: <Box style={{ cursor: "pointer" }}>{item?.appNo}</Box>,
        email: <Box style={{ cursor: "pointer" }}>{item?.email}</Box>,
        dateRegistered: (
          <Box style={{ cursor: "pointer" }}>{item?.dateRegistered}</Box>
        ),
        view: (
          <Box
            style={{ cursor: "pointer" }}
            color={"#FFFFFF"}
            bg={"#812773"}
            borderRadius={"5px"}
            p={"10px 15px"}
            textAlign={"center"}
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"16.94px"}
            fontFamily={"inter"}
            onClick={() => handleUpdate(item)}
          >
            View Details
          </Box>
        ),
      }),
    };

  return (
    <>
      <CustomTable {...tableProps} />
    </>
  )
}

export default FellowInProgressTable;