import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  // Image,
  Text,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal } from "../../redux/slices/modalSlice";

const ConfirmModal = () => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useDispatch();
  const confirmModal = useSelector((state) => state.modal.confirmModal);

  const confirmed = async () => {
    
    dispatch(setConfirmModal({ status: false, payload: null }));
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={confirmModal?.status}
        onClose={() => {
          dispatch(setConfirmModal({ status: false, payload: null }));
        }}
        size={confirmModal?.size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={10} mt={10}>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* <Image src={modalImg} alt="" w={"92px"} h={"92px"} /> */}
              <Text
                fontSize={"24px"}
                fontWeight={"700"}
                color={"#515151"}
                textTransform={"capitalize"}
                pt={3}
              >
                {confirmModal?.title}
              </Text>
              <Text
                fontSize={"16px"}
                fontWeight={"400"}
                color={"#515151"}
                pt={3}
                lineHeight={"24px"}
                letterSpacing={"-0.64px"}
              >
                {confirmModal?.desc}
              </Text>
              <Button
                mt={3}
                borderRadius={"5px"}
                color={"#FFFFFF"}
                bg={"#22944E"}
                _hover={{ bg: "#22944E" }}
                p={"15px 50px 15px 50px"}
                maxW={"340px"}
                onClick={confirmed}
              >
                {confirmModal?.buttonText}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
