import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input,  Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { paymentSchema } from '../utils/formValidationSchema';
import PaymentReport from '../components/PaymentReport';

function PaymentTypeReport() {
    const [toggleReport, setToggleReport] = useState(false);

    const initialValues = {
        applicantNo: '',
        fullName: '',
        emailAddress: '',
    };

    const handleSubmit = async (values) => {
        setToggleReport(!toggleReport)
    }
    return (
        <>
            <Box bgColor={'white'} height={["550px", "", '270px']} px={'30px'} py={'20px'} pb={'10px'} >
                <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={paymentSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                        errors, touched, values, handleBlur, handleChange
                    }) => (
                        <Form>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.applicantNo && touched.applicantNo} color="#121212">
                                    <FormLabel htmlFor='applicantNo' fontSize={'16px'}>Applicant No</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="applicantNo"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.membershipNo}
                                    />
                                    {touched.applicantNo && errors.applicantNo && (
                                        <FormErrorMessage>{errors.applicantNo}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.fullName && touched.fullName} color="#121212">
                                    <FormLabel htmlFor='fullName' fontSize={'16px'}>Full Name</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="fullName"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.membershipNo}
                                    />
                                    {touched.fullName && errors.fullName && (
                                        <FormErrorMessage>{errors.fullName}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.emailAddress && touched.emailAddress} color="#121212">
                                    <FormLabel htmlFor='emailAddress' fontSize={'16px'}>Email Address</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="emailAddress"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.membershipNo}
                                    />
                                    {touched.emailAddress && errors.emailAddress && (
                                        <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl></FormControl>
                                <FormControl></FormControl>
                            </Flex>
                            <Flex justifyContent={'flex-end'} alignItems={'center'}>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                    width={"118px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                // isLoading={loading}
                                // loadingText="Saving ..."
                                >
                                    Fetch Report
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
            {
                toggleReport ? (<PaymentReport />) : null
            }
        </>
    )
}

export default PaymentTypeReport