import React, { useState } from 'react';
import { Button, Divider, Flex , Alert,AlertIcon, AlertTitle} from "@chakra-ui/react";
import { setFormModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import {setloadTost} from "../redux/slices/toastSlice";
import { approveNYSCApplication, fetchNYSCApplicationApproval } from '../redux/slices/NYSCApplicationApprovalSlice';

const NYSCApproveDecline = ({ nyscIndividualDetails}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const handleApproval =async () => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        id: nyscIndividualDetails?.id,
        status:"Approve"
      };
      const response = await dispatch(approveNYSCApplication(formData));
      // console.log(response);
      if (response?.payload?.isSuccess === true){
        setLoading(false);
        dispatch(fetchNYSCApplicationApproval("Pending"));
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Approval', description: `${nyscIndividualDetails?.personalInformation?.firstName} ${nyscIndividualDetails?.personalInformation?.lastName} Approved successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      }else{
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Approve");
      }
        
    } catch (error) {
        setError(error?.payload?.message || "Failed to Approve, please check your network");
    }finally{
        setLoading(false);
    }
  };

  const handleDecline = () => {
    dispatch(
      setFormModal({ status: true, type: "nyscdecline", data: nyscIndividualDetails })
      );
    };
    // console.log("individualDetails for approve", individualDetails);

  return (
    
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
    }
      <Divider border={"1px solid #E3E3E3"} />
      <Flex
        flexDirection={["column", "row", "row"]}
        justifyContent={"center"}
        alignItems={"center"}
        gap={["20px", "50px", "50px"]}
        my={6}
        fontSize={"16px"}
        fontWeight={"500"}
        fontFamily={"roboto"}
        letterSpacing={"-0.25px"}
        lineHeight={"18.75px"}
      >
        <Button
          bg={"#D11A2A"}
          color={"#FFFFFF"}
          borderRadius={"3px"}
          w={"170px"}
          _hover={{ backgroundColor: "" }}
          _active={{ backgroundColor: "" }}
          onClick={handleApproval}
          isLoading={loading}
          loadingText="Approving ..."
        >
          Approve
        </Button>
        <Button
          bg={"#FFE6E8"}
          color={"#D11A2A"}
          borderRadius={"3px"}
          w={"170px"}
          _hover={{ backgroundColor: "" }}
          _active={{ backgroundColor: "" }}
          onClick={handleDecline}
        >
          Decline
        </Button>
      </Flex>
    </> 
  )
}

export default NYSCApproveDecline