import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchExamCentre } from '../redux/slices/examCentreSlice';
import { AddCenterChange } from '../redux/slices/examMgtSlice'
import { setloadTost } from '../redux/slices/toastSlice';


const NewExamCentreChange = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  console.log(loading , errorsArray)
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name')
  const address = searchParams.get('address')
  const examApplicationId = searchParams.get('examApplicationId')
  const examCentresData = useSelector((state) => state?.examCentre?.examCentre?.data);
  console.log('examCentresData', examCentresData)

  const initialValues = {
    examCenter: `${address} , ${name}` || '',
    newexamCenter: '',
  }

  useEffect(() => {
    dispatch(fetchExamCentre())
  }, [dispatch])

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log('values', values)
    try {
      const formData =
      {
        examApplicationId: examApplicationId,
        examCentreId: values?.newexamCenter
      }
      const response = await dispatch(AddCenterChange(formData))
      console.log('response', response)
      if (response?.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(setloadTost({ title: 'Exam Center Changed', description: `Pending Approval`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to change exam centre");
      }
    } catch (error) {
      setError(error?.payload?.message || "Failed to change exam centre, please check your network");
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <Box py={'30px'} bgColor={'#FFFFFF'}  >
        <Flex boxShadow='base' rounded='md' bg='white' alignItems={'center'} px={'30px'} >
          <BiLeftArrowAlt onClick={() => navigate(-1)} cursor={'pointer'} color="#D8991F" size={'20px'} />
          <Text color={'#D11A2A'} p={3} fontWeight={700} fontSize={'18px'} bgColor={'#FFFFFF'} >Change Exam Centre</Text>
        </Flex>
      </Box>

      <Box border={'1px solid #C6C6C6'} >
        <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
          <Text color={'white'}
            fontSize={'14px'} fontWeight={700}
          >Select New Centre Below</Text>
        </Flex>

        <Box px={10} pb={5}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}>
            {({ errors, touched, values, handleBlur, handleChange }) => (
              <Form>
                <FormControl mt={10} isInvalid={errors.examCenter && touched.examCenter}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='examCenter' fontSize={"16px"}>Previous Centre</FormLabel>
                  <Input
                    name="examCenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    focusBorderColor="#8A8C8E"
                    borderRadius={'8px'}
                    h={'60px'}
                    borderColor={"#8A8C8E"}
                    placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examCenter}
                    readOnly
                  />
                  {touched.examCenter && errors.examCenter && (
                    <FormErrorMessage>{errors.examCenter}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={10} isInvalid={errors.newexamCenter && touched.newexamCenter}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='newexamCenter' fontSize={"16px"}>New Centre</FormLabel>
                  <Select
                    name="newexamCenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    focusBorderColor="#8A8C8E"
                    borderRadius={'8px'}
                    h={'60px'}
                    borderColor={"#8A8C8E"}
                    placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.newexamCenter}
                  >
                    {
                      examCentresData?.map((item, index) => (
                        <option key={index} value={item?.id}>{item?.address},{item?.name}</option>

                      ))
                    }
                  </Select>
                  {touched.newexamCenter && errors.newexamCenter && (
                    <FormErrorMessage>{errors.newexamCenter}</FormErrorMessage>
                  )}
                </FormControl>
                <Button
                  bgColor={'#D11A2A'}
                  ml={'50px'}
                  mt={'40px'}
                  type='submit'
                // onClick={handleSubmit}
                >
                  <Text
                    fontWeight={600}
                    color={'white'}
                    fontSize={'14px'}
                  >
                    Submit
                  </Text>
                </Button>
              </Form>
            )}

          </Formik>

        </Box>

      </Box>
    </>
  )
}

export default NewExamCentreChange