import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { HiDocumentText } from "react-icons/hi";
import {File_URL} from "../config/settings";
import { Link } from 'react-router-dom';
import NYSCApproveDecline from './NYSCApproveDecline';

const NYSCDocumentsUpload = ({nyscIndividualDetails}) => {
    const tagData2 = [
        {title:"Degree Certificate:", discription:"View Photo", data:nyscIndividualDetails?.qualificationInformation?.certificates[0]?.fileAttach},
        {title:"Marriage Certificate:", discription:"View Photo", data:nyscIndividualDetails?.marriageInformation?.marriageCertificate},
      ]
  return (
    <>
    <Box h={'60vh'}
    overflow={'scroll'}
  >
  {
  tagData2?.map((data, index) =>(
  <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
  
  >
    <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data?.title}</Text>
    <Flex width={'65%'} gap={2}>
      {
        data?.data ? (
          <Link to={`${File_URL}${data?.data}` } target="_blank" style={{color: '#D11A2A', textDecoration: 'none'}} >
            <Text color={'#D11A2A'} opacity={'0.5'} lineHeight={'21.09px'} textDecoration={"underline"} cursor={'pointer'}>{data?.discription} </Text>
          </Link>
        ): (
          <Text color={'#D11A2A'} opacity={'0.5'} lineHeight={'21.09px'} textDecoration={"underline"} cursor={'pointer'}>{data?.discription} </Text>
        )
      }
      <HiDocumentText style={{color: '#D11A2A',}} />
    </Flex>
  </Flex>
  ))
  }
  <NYSCApproveDecline nyscIndividualDetails={nyscIndividualDetails} />
  </Box>
</>
  )
}

export default NYSCDocumentsUpload