import React,{ useCallback, useEffect, useState }  from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import heroImg from "../Assets/Images/memberupgrade-hero-img.svg";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import { useDispatch, useSelector } from 'react-redux';
import {setFeepaymentId, setFeePaymentDetail} from "../redux/slices/studentApplicationApprovalSlice";
import { setloadTost } from '../redux/slices/toastSlice';
import { setFormModal } from '../redux/slices/modalSlice';
import { useNavigate } from 'react-router-dom';
import { removeUser } from "../redux/slices/authSlice";

const SubmittedApprovedAppllications = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    console.log(errorsArray);
    const paymentConfirmation = useSelector((state) => state?.studentApplicationApproval?.paymentConfirmation);

    const signOut = () => {
        dispatch(removeUser());
        localStorage.removeItem("persist:root");
        navigate("/")
    }

    const appFeePayment  = useCallback( async () => {
        const response = await httpRequest(`${BASE_URL}/v2/Application/fee-payment`, 'post');
        if (response?.isSuccess === true) {
            dispatch(setFeepaymentId( response?.feePaymentId));
        }else{
            console.log(response?.payload?.data?.errors?.map((errors) => errors) || response?.payload?.data?.error?.description || "Failed to get fee payment");
        }

    }, [dispatch]);

    useEffect(() =>{
        appFeePayment();
    }, [appFeePayment]);

    const feePaymentId = useSelector((state) => state?.studentApplicationApproval?.feepaymentId);

    const handlMakePayment = async () => {
        setLoading(true);
        setError("");
        try {
            const response = await httpRequest(`${BASE_URL}/v2/Payment/${feePaymentId}/generate-remita`, 'post');
            // console.log("response",response);
            if (response?.isSuccess === true) {
                setLoading(false);
                dispatch(setFeePaymentDetail(response));
                dispatch(setFormModal({ status: true, title:"Payment Details",  type: "regPaymentDetails"}));
            }else{
                setLoading(false);
                setError(response?.payload?.data?.errors?.map((errors) => errors) || response?.payload?.data?.error?.description || "Failed to get fee payment");
                dispatch(setloadTost({ title: 'Error', description: `${response?.response?.data?.error?.description} `, status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
            }
        } catch (error) {
            setError(error?.payload?.message || "Failed to get fee payment, please check your request/network");
        }finally{
            setLoading(false);
        }
    }

  return (
    <Box mx={'40px'} mt={'20px'} borderRadius={'20px'} bgGradient="linear(to-r, #D11A2A, #FFDDE1)">
        <Flex px={10} py={6} flexDirection={['column', 'row', 'row']} justifyContent={['center', 'space-between', 'space-between']} alignItems={'center'} gap={'20px'}>
            {
                paymentConfirmation === true ? (
                    <Flex flexDirection={'column'} fontFamily={'inter'} gap={3} color={'#FFFFFF'} letterSpacing={'-0.15px'}>
                        <Text fontWeight={'500'} fontSize={'16px'} lineHeight={'16.94px'}>Congaratulations!, Your payment has been processed successfully </Text>
                        <Text mt={1} fontWeight={'500'} fontSize={'16px'} lineHeight={'16.94px'}>Please Proceed to Login to your Member Profile</Text>
                        <Button mt={3} borderRadius={'12px'} bgColor={'#7D2B32'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'21.41px'}
                            _hover={{ bgColor: '' }} _active={{ bgColor: '' }} onClick={signOut}
                        >
                            Login
                        </Button>
                    </Flex>
                ): (
                    <Flex flexDirection={'column'} fontFamily={'inter'} gap={3} color={'#FFFFFF'} letterSpacing={'-0.15px'}>
                        <Text fontWeight={'700'} fontSize={'20px'} lineHeight={'21.78px'}>Hello Applicant</Text>
                        <Text fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'}>Your application has been approved successfully </Text>
                        <Text mt={1} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'}>Please Proceed to make your Payment</Text>
                        <Button mt={3} borderRadius={'12px'} bgColor={'#7D2B32'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'}
                            _hover={{ bgColor: '' }} _active={{ bgColor: '' }} onClick={handlMakePayment} isLoading={loading}
                            loadingText="Loading ..."
                        >
                            Make Payment
                        </Button>
                    </Flex>
                )
            }
        <Image src={heroImg} alt='Student Hero Image' h={'230px'} w={'250px'} />
        </Flex>
    </Box>
  )
}

export default SubmittedApprovedAppllications;