import React, { useState , useEffect} from 'react';
import { Box, Flex, Image, Text, FormControl, FormErrorMessage, FormLabel, Input, Button, Divider, Select, Grid, GridItem,  } from '@chakra-ui/react';
import { Form, Formik } from "formik";
import pics from "../Assets/Images/default-std-profile1.png";
import { useDispatch, useSelector } from 'react-redux';
// import {studtPersonalInformationAppFormSchema} from "../utils/formValidationSchema";
import {File_URL} from "../config/settings";
import {setPersonalInfo} from "../redux/slices/studentRegistrationSlice";
import * as yup from "yup";

const StudtPersonalInformationAppForm = ({handleCancel, handleNext}) => {
  const dispatch = useDispatch();
  const registeredStudents = useSelector((state) => state?.studentRegistration?.registerStudent?.data);
  const personalInfo = useSelector((state) => state?.studentRegistration?.personalInfo);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileName2, setUploadedFileName2] = useState('');

  const formInitialValues = {
    passportfile:personalInfo?.passportfile || registeredStudents?.personalInfo?.passport || '',
    title: personalInfo?.title || registeredStudents?.personalInfo?.title  || '',
    surname: personalInfo?.surname || registeredStudents?.personalInfo?.lastName || '',
    firstname: personalInfo?.firstname || registeredStudents?.personalInfo?.firstName || '',
    otherName: personalInfo?.otherName || registeredStudents?.personalInfo?.otherName || '',
    DOB: personalInfo?.DOB || registeredStudents?.personalInfo?.dateOfBirth || '',
    gender: personalInfo?.gender || registeredStudents?.personalInfo?.gender || '',
    telephone: personalInfo?.telephone || registeredStudents?.personalInfo?.phoneNumber || '',
    postCode: personalInfo?.postCode || registeredStudents?.personalInfo?.postCode || '',
    country: personalInfo?.country || registeredStudents?.personalInfo?.country || '',
    address: personalInfo?.address || registeredStudents?.personalInfo?.homeAddress || '',
    stateOfOrigin: personalInfo?.stateOfOrigin || registeredStudents?.personalInfo?.stateOfOrigin || '',
  };

  useEffect(() => {
    if (registeredStudents?.personalInfo?.passport) {
      const fileName = registeredStudents?.personalInfo?.passport.split('/').pop(); // Extract file name from URL
      setUploadedFileName(fileName);
    }
  }, [registeredStudents?.personalInfo?.passport, formInitialValues?.passportfile]);

  const handleSubmit = async (values) => {
    dispatch(setPersonalInfo(values));
    handleNext(values);
  }

  const studtPersonalInformationAppFormSchema = yup.object().shape({
    passportfile: yup.mixed().test(
      "fileType",
      "Unsupported file format, Only png, jpg , jpeg, files are allowed",
      function (value) {
        const passportExists = registeredStudents?.personalInfo?.passport;
        if (!passportExists && value) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
          return supportedFormats.includes(value.type);
        } else if (uploadedFileName2 && value && passportExists) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
    .test('fileSize', 'File too large', function (value) {
      const passportExists = registeredStudents?.personalInfo?.passport;
      if (!passportExists && value) {
        return value.size <= 150000;
      } else if (uploadedFileName2 ) {
        return value.size <= 150000;
      }
      return true;
    })
    .required('Passport photo is required'),
    title: yup.string().required('Title is required'),
    surname: yup.string().required('Surname is required'),
    firstname: yup.string().required('Firstname is required'),
    otherName: yup.string().required('Othername is required'),
    DOB: yup.date().required('Date of Birth is required'),
    gender: yup.string().required('Gender is required'),
    telephone: yup.string().required('Telephone number is required'),
    postCode: yup.string().required('Post code is required'),
    country: yup.string().required('Country is required'),
    stateOfOrigin: yup.string().required('State of Origin is required'),
    address: yup.string().required('Home address is required'),
  });

  return (
    <Box mb={'100px'}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={studtPersonalInformationAppFormSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <Flex flexDirection={'column'}  gap={3} alignItems={'flex-start'} px={['30px', '40px', '40px', '50px']} >
                <Text mb={2} color={'#7A7A7A'} fontSize={'16px'} fontWeight={'600'} lineHeight={'18.75px'} fontFamily={'roboto'}>Please upload a passport photo</Text>
                <Box h={'130px'} w={'130px'} borderRadius={'5px'} border={'2px solid #C4C4C4'} overflow={'hidden'} >
                  <Image src={registeredStudents?.personalInfo?.passport ? `${File_URL}${registeredStudents?.personalInfo?.passport}` : pics} alt='Passport Photo' h={'130px'} w={'130px'} overflow={'hidden'} objectFit={'cover'}  />
                </Box>
                <FormControl isInvalid={errors.passportfile && touched.passportfile} color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='passportfile'>
                  <Flex alignItems={'center'} gap={5} >
                    <Text fontSize={'13px'} bgColor={'lightgray'} p={2} borderRadius={5} cursor={'pointer'}>
                      {
                        registeredStudents?.personalInfo?.passport ? "Update File" : personalInfo?.passportfile?.name ? "Update File"
                          : "Choose File"
                      }

                    </Text>
                  </Flex>
                </FormLabel>
                {
                  (!registeredStudents?.personalInfo?.passport && personalInfo?.passportfile?.name) ? (
                    <Text mt={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {personalInfo?.passportfile?.name}</Text>
                  ) : (registeredStudents?.personalInfo?.passport && !personalInfo?.passportfile?.name) ? (
                    <Text mt={2} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName}</Text>
                  ) : (registeredStudents?.personalInfo?.passport && personalInfo?.passportfile?.name) ? (
                    <Text mt={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {personalInfo?.passportfile?.name}</Text>
                  )
                    : (uploadedFileName2 && !registeredStudents?.personalInfo?.passport && !personalInfo?.passportfile?.name) ? <Text mt={2} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName2}</Text> : null

                }
                <Input style={{ display: 'none' }} ml={'-18px'} type='file' name="passportfile" id="passportfile" fontSize={"small"} focusBorderColor="#ffffff"
                  borderRadius={'0'} borderColor={"#FFFFFF"} _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    let file = event.currentTarget.files[0];
                    setFieldValue('passportfile', file);
                    if (file) {
                      setUploadedFileName(file?.name);
                      setUploadedFileName2(file?.name);
                    }
                    else {
                      setUploadedFileName('');
                      setUploadedFileName2('');
                    }
                  }}
                  accept="*/*"
                />

                {touched.passportfile && errors.passportfile && (
                  <FormErrorMessage>{errors.passportfile}</FormErrorMessage>
                )}
              </FormControl>

                <Flex flexDirection={'column'}  justifyContent={'flex-start'} alignItems={"flex-start"} gap={3} >
                  <Text color={'#D11A2A'} fontWeight={'400'} fontSize={'13px'} lineHeight={'15.23px'} fontFamily={'roboto'}>picture must not be more than 100kb, only .jpg and .png files are acceptable</Text>
                </Flex>
              </Flex>
              <Divider border={'1px solid #D3D3D3'} my={10} />
              <Box px={['30px', '40px', '40px', '50px']}>
                <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Personal Information</Text>
                <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Please read the notes carefully before completing this form </Text>
                <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={6}>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.title && touched.title}
                        color="#121212" fontFamily={'inter'}>
                      <FormLabel htmlFor='title'  fontSize={"16px"}>Title </FormLabel>
                      <Select
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          bg={'#F3F3F3'}
                          borderRadius={"8px"}
                          focusBorderColor="#ffffff"
                          borderColor={"#FFFFFF"}
                          placeholder='Select Here'
                          _hover={{ borderColor: "#FFFFFF" }}
                          _placeholder={{ color: "#B4B1B1" }}
                          value={values?.title}
                        >
                          
                        <option value='Mr'>Mr.</option>
                        <option  value='Mrs'>Mrs.</option>
                        <option  value='Miss'>Miss.</option>
                        
                        </Select>
                      {touched.title && errors.title && (
                        <FormErrorMessage>{errors.title}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.surname && touched.surname} color="#121212">
                      <FormLabel htmlFor='surname' fontSize={'16px'}>Surname</FormLabel>
                      <Input
                        placeholder="Enter Here"
                        fontSize={"small"}
                        bg={'#F3F3F3'}
                        name="surname"
                        focusBorderColor="#ffffff"
                        borderRadius={'8px'}
                        borderColor={"#FFFFFF"}
                        _placeholder={{ color: "#B4B1B1" }}
                        _hover={{ borderColor: "#FFFFFF" }}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.surname}
                      />
                      {touched.surname && errors.surname && (
                        <FormErrorMessage>{errors.surname}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.firstname && touched.firstname} color="#121212">
                    <FormLabel htmlFor='firstname' fontSize={'16px'}>Firstname</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="firstname"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.firstname}
                    />
                    {touched.firstname && errors.firstname && (
                      <FormErrorMessage>{errors.firstname}</FormErrorMessage>
                    )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.otherName && touched.otherName} color="#121212">
                    <FormLabel htmlFor='otherName' fontSize={'16px'}>Other Name</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="otherName"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.otherName}
                    />
                    {touched.otherName && errors.otherName && (
                      <FormErrorMessage>{errors.otherName}</FormErrorMessage>
                    )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.DOB && touched.DOB}
                      color="#121212" fontFamily={'inter'}>
                      <FormLabel htmlFor='DOB' fontSize={"16px"}>Date of Birth</FormLabel>
                      <Input
                        type="date"
                        placeholder="Pick a Date"
                        borderRadius={"8px"}
                        fontSize={"small"}
                        bg={'#F3F3F3'}
                        name="DOB"
                        focusBorderColor="#ffffff"
                        borderColor={"#FFFFFF"}
                        _hover={{ borderColor: "#FFFFFF" }}
                        _placeholder={{ color: "#B4B1B1" }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.DOB}
                      />
                      {touched.DOB && errors.DOB && (
                          <FormErrorMessage>{errors.DOB}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.gender && touched.gender}
                        color="#121212" fontFamily={'inter'}>
                      <FormLabel htmlFor='gender'  fontSize={"16px"}>Gender </FormLabel>
                      <Select
                          name="gender"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          bg={'#F3F3F3'}
                          borderRadius={"8px"}
                          focusBorderColor="#ffffff"
                          borderColor={"#FFFFFF"}
                          placeholder='Select Here'
                          _hover={{ borderColor: "#FFFFFF" }}
                          _placeholder={{ color: "#B4B1B1" }}
                          value={values?.gender}
                        >
                          
                        <option value='male'>Male</option>
                        <option  value='female'>Female</option>
                        </Select>
                      {touched.gender && errors.gender && (
                        <FormErrorMessage>{errors.gender}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.telephone && touched.telephone} color="#121212">
                    <FormLabel htmlFor='telephone' fontSize={'16px'}>Telephone Number</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="telephone"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.telephone}
                    />
                    {touched.telephone && errors.telephone && (
                      <FormErrorMessage>{errors.telephone}</FormErrorMessage>
                    )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.postCode && touched.postCode} color="#121212">
                      <FormLabel htmlFor='postCode' fontSize={'16px'}>Post Code</FormLabel>
                      <Input
                        placeholder="Enter Here"
                        fontSize={"small"}
                        bg={'#F3F3F3'}
                        name="postCode"
                        focusBorderColor="#ffffff"
                        borderRadius={'8px'}
                        borderColor={"#FFFFFF"}
                        _placeholder={{ color: "#B4B1B1" }}
                        _hover={{ borderColor: "#FFFFFF" }}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.postCode}
                      />
                      {touched.postCode && errors.postCode && (
                        <FormErrorMessage>{errors.postCode}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mt={4} isInvalid={errors.country && touched.country}
                        color="#121212" fontFamily={'inter'}>
                      <FormLabel htmlFor='country'  fontSize={"16px"}>Country</FormLabel>
                      <Select
                          name="country"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          bg={'#F3F3F3'}
                          borderRadius={"8px"}
                          focusBorderColor="#ffffff"
                          borderColor={"#FFFFFF"}
                          placeholder='Select Here'
                          _hover={{ borderColor: "#FFFFFF" }}
                          _placeholder={{ color: "#B4B1B1" }}
                          value={values?.country}
                        >
                          
                        <option value='nigeria'>NIGERIA</option>
                        <option  value='ghana'>Ghana</option>
                        </Select>
                      {touched.country && errors.country && (
                        <FormErrorMessage>{errors.country}</FormErrorMessage>
                      )}
                    </FormControl>
                  </GridItem>
                  <GridItem >
                    <FormControl mt={4} isInvalid={errors.stateOfOrigin && touched.stateOfOrigin} color="#121212">
                    <FormLabel htmlFor='stateOfOrigin' fontSize={'16px'}>State of Origin</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="stateOfOrigin"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.stateOfOrigin}
                    />
                    {touched.stateOfOrigin && errors.stateOfOrigin && (
                      <FormErrorMessage>{errors.stateOfOrigin}</FormErrorMessage>
                    )}
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[ 1, 1,2]}>
                    <FormControl mt={4} isInvalid={errors.address && touched.address} color="#121212">
                    <FormLabel htmlFor='address' fontSize={'16px'}>Home Address</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="address"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.address}
                    />
                    {touched.address && errors.address && (
                      <FormErrorMessage>{errors.address}</FormErrorMessage>
                    )}
                    </FormControl>
                  </GridItem>

                </Grid>
              </Box>
              <Flex px={['30px', '40px', '40px', '50px']} flexDirection={['column', 'row', 'row']} gap={['20px', '50px', '50px']} alignItems={'center'} mt={['50px','80px', '100px']} mb={10}>
                <Button bg={'#A6A6A6'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                  w={'200px'} textAlign={'center'} _active={{bg: '', }} _hover={{bg: '', }}
                  onClick={handleCancel} >
                  Cancel
                </Button>
                <Button bg={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'16px'}
                  w={'200px'} textAlign={'center'} _active={{bg: '', }} _hover={{bg: '', }}
                  type="submit">
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
  )
}

export default StudtPersonalInformationAppForm