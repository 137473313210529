import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import loginBg from "../Assets/Images/login-img1.svg";

const LoginImage = () => {
  return (
    <Box
      flexBasis={[0, 0, "40%", "50%"]}
      display={["none", "none","none", "block", "block"]}
      flexShrink={"0"}
      bgImage={loginBg}
      bgPosition={"center"}
      bgRepeat={"no-repeat"}
    >
      <Flex
        bg={"rgba(47, 6, 8, 0.82)"}
        // p={8}
        gap={3}
        direction={"column"}
        h="full"
        justify={"center"}
        alignItems={"center"}
      >
        <Text
          fontSize={["16px", "20px", "24px", "36px"]}
          fontWeight={"700"}
          color={"white"}
          lineHeight={"37.8px"}
          px={[4, 5, 5, 6, 8]}
          textAlign={"center"}
        >
          Welcome To ICSAN Portal
        </Text>
        <Text
          textAlign={"center"}
          color={"white"}
          fontSize={["16px", "16px", "18px"]}
          fontWeight={"500"}
        >
          A convenient way to access your membership information online
        </Text>
      </Flex>
    </Box>
  );
};

export default LoginImage;
