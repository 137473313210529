import React, { useEffect } from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchExamination } from '../redux/slices/examMgtSlice';
import { useDispatch, useSelector } from 'react-redux';
import { File_URL } from "../config/settings";


const ExamDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const applicationId = searchParams.get("applicationId");
    const examinationDetails = useSelector((state) => state?.examMgt?.examApplicationById)

    useEffect(() => {
        dispatch(fetchExamination(applicationId))
    }, [dispatch, applicationId])

    const list = [
        { title: 'Student Number:', description: examinationDetails?.studentNumber },
        { title: 'Full Name:', description: examinationDetails?.fullName },
        { title: 'Current Exam Stage:', description: examinationDetails?.currentExamStage },
        { title: 'Membership Category:', description: examinationDetails?.membershipCategory },
        { title: 'Exemption Type:', description: examinationDetails?.exemptionType },
        { title: 'Email Address:', description: examinationDetails?.email },
        { title: 'Phone Number:', description: examinationDetails?.phoneNumber },
    ]

    const tableProps = {
        data: examinationDetails?.examSubjectDetails,
        // examSubjectDetails,
        title: {
            examSubject: "Exam Subject",
            examDate: "Exam Date",
            examTime: "Start Time",
            endTime: "End Time",
        },
        dataKeys: [
            "examSubject",
            "examDate",
            "examTime",
            "endTime",
        ],
        transformRow: (item) => ({
            examSubject: item?.name,
            examDate: item?.timeTable?.examDate,
            examTime: item?.timeTable?.startTime,
            endTime: item?.timeTable?.endTime,
        })
    };
    console.log('examination Details', examinationDetails)

    return (
        <>
            <Box py={'30px'} minH={'100vh'} bgColor={'#FFFFFF'} mb={4} >
                <Flex boxShadow='base' rounded='md' bg='white' alignItems={'center'} px={'30px'} >
                    <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
                        <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                    </Box>
                    <Text color={'#D11A2A'} p={3} fontWeight={700} fontSize={'18px'} bgColor={'#FFFFFF'} >View Examination Details</Text>
                </Flex>

                <Box justifyContent={'flex-start'} alignItems={'center'} my={'50px'} px={'30px'}>
                    <Avatar src={`${File_URL}${examinationDetails?.passport}`} border={'5px solid #D11A2A'} size='2xl' />
                </Box>

                <Box border={'1px solid #C6C6C6'} mx={'30px'} >
                    <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
                        <Text color={'white'}
                            fontSize={'14px'} fontWeight={700}
                        >Personal Details</Text>
                    </Flex>
                    {list?.map((item, index) => (
                        <Flex key={index} alignItems={'center'} px={['', '15px', '20px']} pb={5} gap={['100px', null, null]}>
                            <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'15px'} fontWeight={700} w={['50%', '10%', '15%']}>
                                {item.title}
                            </Text>
                            <Text color={'rgba(81, 81, 101, 0.5)'} fontSize={'15px'} fontWeight={700} w={['50%', '100%', '85%']}>
                                {item.description}
                            </Text>
                        </Flex>
                    ))}
                </Box>

                <Box border={'1px solid #C6C6C6'} mx={'30px'} mt={5}>
                    <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
                        <Text color={'white'}
                            fontSize={'14px'} fontWeight={700}
                        >Exam Subject Details</Text>
                    </Flex>
                    <CustomTable {...tableProps} />
                </Box>

            </Box>
        </>
    )
}

export default ExamDetails;
