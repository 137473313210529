import React, { useEffect } from 'react';
import { Avatar, Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamCenterChange } from '../redux/slices/examMgtSlice';
// import ExamAppCentre from '../components/ExamAppCentre';
import { File_URL } from "../config/settings";
import { useNavigate } from 'react-router-dom';
import { BiLeftArrowAlt } from 'react-icons/bi';



const ChangeExamCentre = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const examCentreData = useSelector((state) => state?.examMgt?.centerChange)

  console.log('exam center data:', examCentreData)

  useEffect(() => {
    dispatch(fetchExamCenterChange())
  }, [dispatch])

  const list = [
    { title: 'Student Number:', description: examCentreData?.studentNumber },
    { title: 'Full Name:', description: examCentreData?.fullName },
    { title: 'Current Exam Stage:', description: examCentreData?.currentExamStage },
    { title: 'Membership Category:', description: examCentreData?.membershipCategory },
    { title: 'Exemption Type:', description: examCentreData?.exemptionType },
    { title: 'Email Address:', description: examCentreData?.email },
    { title: 'Phone Number:', description: examCentreData?.phoneNumber },
  ]

  const centers = [
    { centerName: examCentreData?.examCentreDetails?.address },
  ]

  const tableProps = {
    data: examCentreData?.examSubjectDetails,
    // examSubjectDetails,
    title: {
      examSubject: "Exam Subject",
      examDate: "Exam Date",
      examTime: "Start Time",
      endTime: "End Time",
    },
    dataKeys: [
      "examSubject",
      "examDate",
      "examTime",
      "endTime",
    ],
    transformRow: (item) => ({
      examSubject: item?.name,
      examDate: item?.timeTable?.examDate,
      examTime: item?.timeTable?.startTime,
      endTime: item?.timeTable?.endTime,
    })
  };
  const handleSubmit = async (values) => {
    navigate(`/exammanagement/newexamcentrechange?name=${examCentreData?.examCentreDetails?.name}&address=${examCentreData?.examCentreDetails?.address}&examApplicationId=${examCentreData?.examApplicationId}`);
  }

  return (
    <>
      <Box py={'30px'} minH={'100vh'} bgColor={'#FFFFFF'} mb={4} >
        <Flex boxShadow='base' rounded='md' bg='white' alignItems={'center'} px={'30px'} >
          <BiLeftArrowAlt onClick={() => navigate(-1)} cursor={'pointer'} color="#D8991F" size={'20px'} />
          <Text color={'#D11A2A'} p={3} fontWeight={700} fontSize={'18px'} bgColor={'#FFFFFF'} >Change Exam Centre</Text>
        </Flex>

        <Box justifyContent={'flex-start'} alignItems={'center'} my={'50px'} px={'30px'}>
          <Avatar src={`${File_URL}${examCentreData?.passport}`} border={'5px solid #D11A2A'} size='2xl' />
        </Box>
        <Box border={'1px solid #C6C6C6'} mx={'30px'} >
          <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
            <Text color={'white'}
              fontSize={'14px'} fontWeight={700}
            >Personal Details</Text>
          </Flex>
          {list?.map((item, index) => (
            <Flex key={index} alignItems={'center'} px={['', '15px', '20px']} pb={5} gap={['100px', null, null]}>
              <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'15px'} fontWeight={700} w={['50%', '10%', '15%']}>
                {item.title}
              </Text>
              <Text color={'rgba(81, 81, 101, 0.5)'} fontSize={'15px'} fontWeight={700} w={['50%', '100%', '85%']}>
                {item.description}
              </Text>
            </Flex>
          ))}
        </Box>

        <Box border={'1px solid #C6C6C6'} mx={'30px'} mt={5}>
          <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
            <Text color={'white'}
              fontSize={'14px'} fontWeight={700}
            >Exam Subject Details</Text>
          </Flex>
          <CustomTable {...tableProps} />
        </Box>

        {centers?.map((item, index) => (
          <Box border={'1px solid #C6C6C6'} mx={'30px'} mt={5} pb={10}>
            <Flex pl={'50px'} bgColor={'#D11A2A'} mb={'30px'} justifyContent={'flex-start'} alignItems={'center'} h={'35px'}>
              <Text color={'white'}
                fontSize={'14px'} fontWeight={700}
              >Exam Centre Details</Text>
            </Flex>
            <Flex key={index} gap={'120px'} mb={'30px'}>
              <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'15px'} fontWeight={700} pl={'50px'}>
                Exam Centre:
              </Text>
              <Text color={'rgba(81, 81, 101, 0.5)'} fontSize={'15px'} fontWeight={700}>
                {item.centerName}
              </Text>
            </Flex>
            <Divider />
            <Button
              bgColor={'#D11A2A'}
              ml={'50px'}
              mt={'40px'}
              onClick={handleSubmit}
            >
              <Text
                fontWeight={600}
                color={'white'}
                fontSize={'14px'}
              >
                Change Exam Centre
              </Text>
            </Button>

            <Text color={'#D11A2A'} pl={'50px'} fontSize={'13px'} fontWeight={700} mt={2}>Please note that you can only change exam centre two weeks before an exam</Text>
          </Box>
        ))}

      </Box>
    </>
  )
}

export default ChangeExamCentre;

