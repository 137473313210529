import React, { useEffect, useState } from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, Text, FormErrorMessage, FormLabel, Select, Flex, Input, Alert,AlertIcon, AlertTitle  } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { fetchExamPeriod } from "../redux/slices/examDietPeriodSlice";
import {setloadTost} from "../redux/slices/toastSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {fetchExamTimeTable} from "../redux/slices/examTimeTableSlice";
// import {editUploadResultSchema} from "../utils/formValidationSchema";

const EditTimetable = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const examCategoryData = useSelector((state) => state?.examCategorySubject?.examCategory?.data);
    const examDietData = useSelector((state) => state?.examDietPeriod?.examDiet?.data);
    const examPeriodData = useSelector((state) => state?.examDietPeriod?.examPeriod?.data);
    const editExamTimeTable = useSelector((state) => state?.examTimeTable?.editExamTimeTable);
    // console.log('editExamTimeTable', editExamTimeTable);

    const initialValues = {
      examYear: editExamTimeTable?.periodId||'',
      diet: editExamTimeTable?.examDietId ||'',
      category: editExamTimeTable?.examCategoryId ||'',
      file: '',
    };

    useEffect(() => {
      // Fetch exam periods when diet changes
      if (initialValues.diet) {
        dispatch(fetchExamPeriod(initialValues.diet));
      }
    }, [initialValues.diet, dispatch]);
  
    const handleSubmit = async (values) => {
      // console.log(values);
      setLoading(true);
      setError("");
      try {
        const formData = {
          ExamPeriodId: values?.examYear,
          ExamCategoryId: values?.category,
          FileUpload: values?.file
        }
        const response = await httpRequest( 
          `${BASE_URL}/v2/TimeTable/${editExamTimeTable?.id}`,
          "put",
          formData,
          null,
          "multipart/form-data",
        );
        // console.log(response);
        if (response?.isSuccess === true) {
          setLoading(false);
          dispatch(fetchExamTimeTable());
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'Time Table Edited', description: 'Time Table edited successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        } else {
          setLoading(false);
          setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to edit Time Table");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to edit Time Table, please check your network");
      }finally{
        setLoading(false);
      }
  
    }

  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
    }
    <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={editUploadResultSchema}
        onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
        <Form>
            <FormControl mt={4} isInvalid={errors.diet && touched.diet}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='diet' fontSize={"16px"}>Exam Diet</FormLabel>
                <Select
                    name="diet"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(fetchExamPeriod(e.target.value));
                      setFieldValue('examYear', ''); // Reset examYear when diet changes
                    }}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    // placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.diet}
                >
                    {examDietData && examDietData?.map((item) => (
                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                    ))}
                </Select>
                {touched.diet && errors.diet && (
                    <FormErrorMessage>{errors.diet}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.examYear && touched.examYear}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='examYear' fontSize={"16px"}>Exam Period</FormLabel>
                <Select
                    name="examYear"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    // placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examYear}
                >
                    {examPeriodData && examPeriodData?.map((item) => (
                        <option key={item?.id} value={item?.id}>{item?.startDate} to {item?.endDate}</option>
                    ))}
                </Select>
                {touched.examYear && errors.examYear && (
                    <FormErrorMessage>{errors.examYear}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.category && touched.category}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='category' fontSize={"16px"}>Exam Category</FormLabel>
              <Select
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                // placeholder='Select Here'
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.category}
              >
                {examCategoryData && examCategoryData?.map((data) => (
                    <option key={data?.id} value={data?.id}>{data?.name}</option>
                ))}
              </Select>
              {touched.category && errors.category && (
                  <FormErrorMessage>{errors.category}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.file && touched.file}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='file' fontSize={"16px"}>
                    Upload File
                </FormLabel>
                <Input
                    type='file'
                    name="file"
                    id="file"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    focusBorderColor="#ffffff"
                    borderRadius={'0'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    onBlur={handleBlur}
                    onChange={(event) => {
                        setFieldValue('file', event.currentTarget.files[0]);
                    }}
                    accept=".csv" 
                />
                <Text color={'#D11A2A'}> accepts ".csv" files</Text>
                {touched.file && errors.file && (
                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                )}
            </FormControl>

            <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
              <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={loading}
                  loadingText="Saving ..."
              >
                  Edit
              </Button>
              <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={""}
                  _hover={{ background: " " }}
                  color={"#8F9BB3"}
                  onClick={() => {
                      dispatch(setFormModal({ status: false, data: null }));
                  }}
                  loadingText="Saving ..."
              >
                  Cancel
              </Button>
            </Flex>
        </Form>
      )}
    </Formik>
  </>
  )
}

export default EditTimetable;