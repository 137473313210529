import { Box } from '@chakra-ui/react';
import React from 'react'
import CustomTable from './CustomTable';
import { paymentTypeReport } from '../data/mock';

function MemberApplicationFees() {
    const tableProps = {
        data: paymentTypeReport,
        title: {
            applicantNo: 'Applicant No',
            fullName: "Full Name",
            memberCategory: "Member Category",
            emailAddress: "Email Address",
            phoneNumber: "Phone Number",
            qualification: "Qualification",
        },
        dataKeys: [
            "applicantNo",
            "fullName",
            "memberCategory",
            "emailAddress",
            "phoneNumber",
            "qualification",
        ],
        transformRow: (item) => ({
            applicantNo: <Box style={{ cursor: 'pointer' }}>{item.applicantNo}</Box>,
            fullName: <Box style={{ cursor: 'pointer' }}>{item.memberCategory}</Box>,
            memberCategory: <Box style={{ cursor: 'pointer' }}>{item.memberCategory}</Box>,
            emailAddress: <Box style={{ cursor: 'pointer' }}>{item.emailAddress}</Box>,
            phoneNumber: <Box style={{ cursor: 'pointer' }}>{item.phoneNumber}</Box>,
            qualification: <Box style={{ cursor: 'pointer' }}>{item.qualification}</Box>,
        }),
    };
    return (
        <Box>
            <CustomTable {...tableProps} />
        </Box>
    )
}

export default MemberApplicationFees