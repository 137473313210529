import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
    allUsers: [],
    loading: false,
    error: null,
    userDetails: "",
    editUser: "",
};

export const fetchUsers = createAsyncThunk(
    "userMgtUsers/fetchUsers",
    async () => {
      return httpRequest(`${BASE_URL}/v2/Administrative`);
    }
);

export const createUser = createAsyncThunk(
    "userMgtUsers/createUser",
    async (arg, { rejectWithValue }) => {
      try {
        const response = await httpRequest(
          `${BASE_URL}/v2/Administrative`,
          "post",
          arg
        );
        if (response?.response?.data) {
          throw response;
        }
        return response;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
);

const userMgtUsersSlice = createSlice({
    name: "userMgtUsers",
    initialState,

    reducers: {
        setUserDetails: (state, {payload}) => {
            state.userDetails = payload;
        },
        setEditUser: (state, {payload}) => {
            state.editUser = payload;
        },

    },

    extraReducers:(builder) => {
        // get all users
        builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allUsers = payload;
            state.error = null;
        });

        builder.addCase(fetchUsers.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // create user
        builder.addCase(createUser.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(createUser.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        }); 
    }

});

  export default userMgtUsersSlice.reducer;
export const { setUserDetails, setEditUser } = userMgtUsersSlice.actions;