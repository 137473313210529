import React, { useEffect, useState }  from 'react';
import { Form, Formik } from "formik";
import { Alert, AlertIcon, AlertTitle, Box, Flex, Text, Avatar, FormControl, FormErrorMessage, FormLabel, Input, Button, Divider } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate, useSearchParams} from 'react-router-dom';
import {File_URL} from "../config/settings";
import {fetchBioData, updateBioData} from "../redux/slices/membershipManagementSlice";
import {setloadTost} from "../redux/slices/toastSlice";
import {fetchMemberDetails} from "../redux/slices/report2Slice";

const MemberReportProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const membershipNo = searchParams.get("membershipNo");
    const bioDataDetail = useSelector((state) => state?.membershipManagement?.bioDataDetail);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const memberDetails = useSelector((state) => state?.report2?.memberDetails);

    useEffect(() => {
        const membershipNum = {memberNumber:membershipNo}
        dispatch(fetchMemberDetails(membershipNum))
    }, [dispatch, membershipNo]);

    const initialValues = {
        fullName: bioDataDetail?.fullName || memberDetails?.fullName || "",
        membershipNo: bioDataDetail?.membershipNumber || memberDetails?.membershipNumber || "",
        emailAddress: bioDataDetail?.email || memberDetails?.email || "",
        phoneNumber: bioDataDetail?.phoneNumber || memberDetails?.phoneNumber || "",
        gender: bioDataDetail?.gender || memberDetails?.gender || "",
        qualification: bioDataDetail?.qualification|| memberDetails?.qualification || "",
        grade: bioDataDetail?.membershipCategory || memberDetails?.grade || "",
        age: bioDataDetail?.age || memberDetails?.age || "0",
        address: bioDataDetail?.address || memberDetails?.address || "",
        dob: bioDataDetail?.dateOfBirth || memberDetails?.dob || "",
    };

    const handleSubmit = async (values) => {
        const formData = {
            membershipCategory:values?.grade,
            phoneNumber: values?.phoneNumber,
            email: values?.emailAddress,
            homeAddress: values?.address,
        }
        setLoading(true);
        setError("");
        
        try {
            const response = await dispatch(updateBioData({memberId:bioDataDetail?.id, arg:formData}));
            if (response?.payload?.isSuccess === true) {
                setLoading(false);
                dispatch(fetchBioData({memberNumber:bioDataDetail?.membershipNumber, memberCategory:bioDataDetail?.membershipCategory }));
                dispatch(setloadTost({ title: 'BioData Update', description: `${bioDataDetail?.fullName} Biodata updated successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            } else {
              setLoading(false);
              setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to update Biodata");
            }
        } catch (error) {
            setError(error?.payload?.message || "Failed to update Biodata, please check your network");
        }finally{
            setLoading(false);
        }
    }

    return (
        <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
            <Flex alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
                <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
                    <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                </Box>
                <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>All Members / Members Profile</Text>
            </Flex>
            <Flex gap={'15px'} borderBottom={'1px solid #B4B1B1'} pb={7} mb={5}>
                <Avatar src={`${File_URL}${bioDataDetail?.profilePicture}`} size='xl' />
                <Flex flexDirection={'column'}>
                    <Text color={'#384D6C'} fontWeight={'700'} fontSize={'20px'}>{bioDataDetail?.fullName }</Text>
                    <Text color={'#384D6C'} fontWeight={'400'} fontSize={'20px'}>{ bioDataDetail?.membershipCategory}</Text>
                </Flex>
            </Flex>
            {
                error && (
                <Alert status="error" className="mt-3" mb={3}>
                    <AlertIcon />
                    <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
                )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ errors, touched, values, handleBlur, handleChange }) => (
                    <Form>
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.fullName && touched.fullName}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='fullName' fontSize={"16px"}>Full Name</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="fullName"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.fullName}
                                />
                                {touched.fullName && errors.fullName && (
                                    <FormErrorMessage>{errors.fullName}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.membershipNo && touched.membershipNo}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='membershipNo' fontSize={"16px"}>Membership Number</FormLabel>
                                <Input
                                    placeholder="Enter here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="membershipNo"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.membershipNo}
                                />
                                {touched.membershipNo && errors.membershipNo && (
                                    <FormErrorMessage>{errors.membershipNo}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex >
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.emailAddress && touched.emailAddress}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='emailAddress' fontSize={"16px"}>Email Address</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="emailAddress"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.emailAddress}
                                />
                                {touched.emailAddress && errors.emailAddress && (
                                    <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='phoneNumber' fontSize={"16px"}>Phone Number</FormLabel>
                                <Input
                                    placeholder="Enter here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="phoneNumber"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.phoneNumber}
                                />
                                {touched.phoneNumber && errors.phoneNumber && (
                                    <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex >
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.gender && touched.gender}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='gender' fontSize={"16px"}>Gender</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="fullName"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.gender}
                                />
                                {touched.gender && errors.gender && (
                                    <FormErrorMessage>{errors.gender}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.grade && touched.grade}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='grade' fontSize={"16px"}>Grade</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="grade"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.grade}
                                />
                                {touched.grade && errors.grade && (
                                    <FormErrorMessage>{errors.grade}</FormErrorMessage>
                                )}
                            </FormControl>
                            
                        </Flex >
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.qualification && touched.qualification}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='qualification' fontSize={"16px"}>Qualification</FormLabel>
                                <Input
                                    placeholder="Enter here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="qualification"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.qualification}
                                />
                                {touched.qualification && errors.qualification && (
                                    <FormErrorMessage>{errors.qualification}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.age && touched.age}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='age' fontSize={"16px"}>Age</FormLabel>
                                <Input
                                    placeholder="Enter here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="age"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.age}
                                />
                                {touched.age && errors.age && (
                                    <FormErrorMessage>{errors.age}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex >
                        <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={3}>
                            <FormControl mt={4} isInvalid={errors.address && touched.address}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='address' fontSize={"16px"}>Address</FormLabel>
                                <Input
                                    placeholder="Enter Here"
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="address"
                                    focusBorderColor="#ffffff"
                                    borderRadius={'0'}
                                    borderColor={"#FFFFFF"}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.address}
                                />
                                {touched.address && errors.address && (
                                    <FormErrorMessage>{errors.address}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl mt={4} isInvalid={errors.dob && touched.dob}
                                color="#121212" fontFamily={'inter'}>
                                <FormLabel htmlFor='dob' fontSize={"16px"}>Date of Birth</FormLabel>
                                <Input
                                    height={'52px'}
                                    type="date"
                                    placeholder="Pick a Date"
                                    borderRadius={"0"}
                                    fontSize={"small"}
                                    bg={'#F3F3F3'}
                                    name="dob"
                                    cursor={"pointer"}
                                    focusBorderColor="#ffffff"
                                    borderColor={"#FFFFFF"}
                                    _hover={{ borderColor: "#FFFFFF" }}
                                    _placeholder={{ color: "#B4B1B1" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.dob}
                                />
                                {touched.dob && errors.dob && (
                                    <FormErrorMessage>{errors.dob}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex >
                        <Divider py={8} borderBottom={'1px solid #B4B1B1'} />
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                        {
                            membershipNo ? (
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'}
                                    width={"176px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    onClick={() => { navigate(-1) }}
                                    loadingText="Saving ..."
                                >
                                    Close
                                </Button>
                            ) : (
                                <>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'}
                                    width={"176px"}
                                    background={""}
                                    _hover={{ background: " " }}
                                    color={"#D11A2A"}
                                    onClick={() => { navigate(-1) }}
                                    loadingText="Saving ..."
                                >
                                    Cancel
                                </Button>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'}
                                    width={"176px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                    isLoading={loading}
                                    loadingText="Saving ..."
                                >
                                    Save Changes
                                </Button>
                                </>
                            )
                        }
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default MemberReportProfile