import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  adminDashboard: [],
  educationDashboard: [],
  membershipDashboard: [],
  financeDashboard: [],
};

export const fetchAdminDashboard = createAsyncThunk(
  "dashboard/fetchAdminDashboard",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Dashboard/admin`);
  }
);


export const fetchEducationDashboard = createAsyncThunk(
  "dashboard/fetchEducationDashboard",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Dashboard/education-officer`);
  }
);

export const fetchMembershipDashboard = createAsyncThunk(
  "dashboard/fetchMembershipDashboard",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Dashboard/membership-officer`);
  }
);

export const fetchFinanceDashboard = createAsyncThunk(
    "dashboard/fetchFinanceDashboard",
    async () => {
      return httpRequest(`${BASE_URL}/v2/Dashboard/finance-officer`);
    }
  );

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  extraReducers: (builder) => {
    //get admin dashboard:
    builder.addCase(fetchAdminDashboard.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAdminDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.adminDashboard = payload;
      state.error = payload?.error?.description || null;
    });

    builder.addCase(fetchAdminDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get education dashboard:
    builder.addCase(fetchEducationDashboard.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEducationDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.educationDashboard = payload;
      state.error = payload?.error?.description || null;
    });

    builder.addCase(fetchEducationDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get membership dashboard:
    builder.addCase(fetchMembershipDashboard.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMembershipDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.membershipDashboard = payload;
      state.error = payload?.error?.description || null;
    });

    builder.addCase(fetchMembershipDashboard.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //get finance dashboard:
    builder.addCase(fetchFinanceDashboard.pending, (state) => {
        state.loading = true;
      });
  
      builder.addCase(fetchFinanceDashboard.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.financeDashboard = payload;
        state.error = payload?.error?.description || null;
      });
  
      builder.addCase(fetchFinanceDashboard.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
      });
  },
});

export default dashboardSlice.reducer;
