import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, FormControl, FormErrorMessage, FormLabel, Input, Divider, Select, List, ListItem, ListIcon, Grid, GridItem, Button } from '@chakra-ui/react';
import { Form, Formik } from "formik";
import { TbPointFilled } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
// import {NYSCQualificationInformationAppFormSchema} from '../utils/formValidationSchema';
import { setQualificationInfo } from "../redux/slices/NYSCRegistrationSlice";
import * as yup from "yup";
import {fetchEntryQualificationType} from "../redux/slices/entryQualificationTypeSlice";


const NYSCQualificationInformationAppForm = ({ handlePrevious, handleNext }) => {
  const dispatch = useDispatch();
  const registeredNYSC = useSelector((state) => state?.NYSCRegistration?.registerNYSC?.data);
  const qualificationInfo = useSelector((state) => state?.NYSCRegistration?.qualificationInfo);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileName2, setUploadedFileName2] = useState('');
  const qualificationTypeData = useSelector((state) => state?.entryQualificationType?.entryQualificationType?.data);
  useEffect(() => {
    dispatch(fetchEntryQualificationType());
  }, [dispatch]);

  const initialValues = {
    qualificationType: qualificationInfo?.qualificationType || registeredNYSC?.qualificationInfo?.entryQualificationType || "",
    exemptionType: qualificationInfo?.exemptionType || registeredNYSC?.qualificationInfo?.applicableException || "",
    course: qualificationInfo?.course || registeredNYSC?.qualificationInfo?.certificates[0]?.course || "",
    schoolAttended: qualificationInfo?.schoolAttended || registeredNYSC?.qualificationInfo?.certificates[0]?.schoolAttended || "",
    levelAttained: qualificationInfo?.levelAttained || registeredNYSC?.qualificationInfo?.certificates[0]?.levelAttained || "",
    certificatefile: qualificationInfo?.certificatefile || registeredNYSC?.qualificationInfo?.certificates[0]?.fileAttach || "",
  };

  useEffect(() => {
    if (registeredNYSC?.qualificationInfo) {
      const fileName = registeredNYSC?.qualificationInfo?.certificates[0]?.fileAttach.split('/').pop(); // Extract file name from URL
      setUploadedFileName(fileName);
    }
  }, [registeredNYSC?.qualificationInfo, initialValues?.certificatefile]);

  const handleQualificationType = async (values) => {
    dispatch(setQualificationInfo(values));
    handleNext(values);
  };

  const NYSCQualificationInformationAppFormSchema = yup.object().shape({
    qualificationType: yup.string().required('Qualification type is required'),
    exemptionType: yup.string().required('Exemption type is required'),
    course: yup.string().required('Course is required'),
    schoolAttended: yup.string().required('School attended is required'),
    levelAttained: yup.string().required('Level attained is required'),
    certificatefile: yup.mixed().test(
      "fileType",
      "Unsupported file format, Only png, jpg , jpeg, or PDF files are allowed",
      function (value) {
        const passportExists = registeredNYSC?.qualificationInfo?.certificates;
        if (!passportExists && value) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
          return supportedFormats.includes(value.type);
        } else if (uploadedFileName2 && value && passportExists) {
          const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
          return supportedFormats.includes(value.type);
        }
        return true;
      }
    )
      .test('fileSize', 'File too large', function (value) {
        const passportExists = registeredNYSC?.qualificationInfo?.certificates;
        if (!passportExists && value) {
          return value.size <= 500000;
        } else if (uploadedFileName2) {
          return value.size <= 500000;
        }
        return true;
      })
      .required('Certificate is required'),
  });

  const listData = [
    { description: 'Please detail below the qualifications on which you are basing your application to enter the Professional Programme' },
    { description: 'Please attach authenticated copies of the certificates which form the basis of your claim.' },
    { description: 'For married female applicants, please attach evidence of change of name' },
    { description: 'Maximum size for uploaded certificate is 500KB.' },
  ]

  return (
    <Box >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NYSCQualificationInformationAppFormSchema}
        onSubmit={(values) => handleQualificationType(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
          <Form style={{ width: "100%" }}>
            <Box px={['30px', '40px', '40px', '50px']}>
              <Text color={'#121212'} fontFamily={'roboto'} fontSize={'20px'} fontWeight={'700'} lineHeight={'21px'}>Qualification Information</Text>
              <Text mt={3} mb={5} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>Please Note that related degrees includes B.sc / HND (Law ,Accounting, Finance , ACA, ACCA,CIMA, and CIBN)</Text>
              <Flex flexDirection={["column", "column", "row", "row"]} alignItems={'center'} gap={5} >
                <FormControl mt={4} isInvalid={errors.qualificationType && touched.qualificationType} width={["100%", "100%", "60%", "70%"]}
                  color="#121212" fontFamily={'inter'} >
                  <FormLabel htmlFor='qualificationType' fontSize={"16px"}>Entry Qualification Type</FormLabel>
                  <Select
                    name="qualificationType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"8px"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    placeholder='Select Here'
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.qualificationType}
                  >
                    {
                      qualificationTypeData?.map((item, index) =>(
                        <option key={index} value={item?.id}>{item?.description}</option>
                      ))
                    }
                  </Select>
                  {touched.qualificationType && errors.qualificationType && (
                    <FormErrorMessage>{errors.qualificationType}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.exemptionType && touched.exemptionType} color="#121212" width={["100%", "100%", "40%", "30%"]}>
                  <FormLabel htmlFor='exemptionType' fontSize={'16px'}>Applicable Exemption Type</FormLabel>
                  <Input
                    placeholder="Enter Here"
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="exemptionType"
                    focusBorderColor="#ffffff"
                    borderRadius={'8px'}
                    borderColor={"#FFFFFF"}
                    _placeholder={{ color: "#B4B1B1" }}
                    _hover={{ borderColor: "#FFFFFF" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.exemptionType}
                  />
                  {touched.exemptionType && errors.exemptionType && (
                    <FormErrorMessage>{errors.exemptionType}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            </Box>
            <Divider border={'1px solid #D3D3D3'} my={10} />
            <Box px={['30px', '40px', '40px', '50px']}>
              <List spacing={3}>
                {
                  listData?.map((item, index) => (
                    <ListItem key={index} color={'#7A7A7A'} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'}>
                      <ListIcon as={TbPointFilled} color="#D11A2A" />
                      {item?.description}
                    </ListItem>
                  ))
                }
              </List>
              <Grid my={10} templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={6}>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.course && touched.course}
                    color="#121212" fontFamily={'inter'}>
                    <FormLabel htmlFor='course' fontSize={"16px"}>Course</FormLabel>
                    <Input
                    type='text'
                      name="course"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={'#F3F3F3'}
                      borderRadius={"8px"}
                      focusBorderColor="#ffffff"
                      borderColor={"#FFFFFF"}
                      placeholder='Enter Here'
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.course}
                    >
                    </Input>
                    {touched.course && errors.course && (
                      <FormErrorMessage>{errors.course}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.schoolAttended && touched.schoolAttended} color="#121212">
                    <FormLabel htmlFor='schoolAttended' fontSize={'16px'}>School Attended</FormLabel>
                    <Input
                      placeholder="Enter Here"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      name="schoolAttended"
                      focusBorderColor="#ffffff"
                      borderRadius={'8px'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.schoolAttended}
                    />
                    {touched.schoolAttended && errors.schoolAttended && (
                      <FormErrorMessage>{errors.schoolAttended}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={4} isInvalid={errors.levelAttained && touched.levelAttained}
                    color="#121212" fontFamily={'inter'}>
                    <FormLabel htmlFor='levelAttained' fontSize={"16px"}>Level Attained</FormLabel>
                    <Select
                      name="levelAttained"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={'#F3F3F3'}
                      borderRadius={"8px"}
                      focusBorderColor="#ffffff"
                      borderColor={"#FFFFFF"}
                      placeholder='Select Here'
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.levelAttained}
                    >

                      <option value='Masters'>Masters</option>
                      <option value='Degree'>Degree</option>
                    </Select>
                    {touched.levelAttained && errors.levelAttained && (
                      <FormErrorMessage>{errors.levelAttained}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
              <Text mb={4} fontFamily={'roboto'} fontSize={'16px'} fontWeight={'400'} lineHeight={'18.75px'} >Certificate Obtained</Text>
              <FormControl isInvalid={errors.certificatefile && touched.certificatefile} color="#121212" fontFamily={'inter'}>
                < FormLabel htmlFor='certificatefile'>
                  <Flex alignItems={'center'} gap={5} >
                    <Text fontSize={'13px'} bgColor={'lightgray'} p={2} borderRadius={5} cursor={'pointer'}>
                      {
                        registeredNYSC?.qualificationInfo?.certificates ? "Update File" : qualificationInfo?.certificatefile?.name ? "Update File"
                          : "Choose File"
                      }
                    </Text>
                  </Flex>
                </FormLabel>
                {
                  (!registeredNYSC?.qualificationInfo?.certificates[0]?.fileAttach && qualificationInfo?.certificatefile?.name) ? (
                    <Text my={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {qualificationInfo?.certificatefile?.name}</Text>
                  ) : (registeredNYSC?.qualificationInfo?.certificates[0]?.fileAttach && !qualificationInfo?.certificatefile?.name) ? (
                    <Text my={2} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName}</Text>
                  ) : (registeredNYSC?.qualificationInfo?.certificates[0]?.fileAttach && qualificationInfo?.certificatefile?.name) ? (
                    <Text my={2} color={'#7A7A7A'} fontSize={'16px'}>Uploaded File: {qualificationInfo?.certificatefile?.name}</Text>
                  ) : (uploadedFileName2 && !registeredNYSC?.qualificationInfo?.certificates && !qualificationInfo?.certificatefile?.name) ? <Text mt={2} color={'#7A7A7A'} fontSize={'14px'}>Uploaded File: {uploadedFileName2}</Text> : null
                }
                <Input style={{ display: 'none' }} ml={'-18px'} type='file' name="certificatefile" id="certificatefile" fontSize={"small"} focusBorderColor="#ffffff"
                  borderRadius={'0'} borderColor={"#FFFFFF"} _hover={{ borderColor: "#FFFFFF" }}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    setFieldValue('certificatefile', file);
                    if (file) {
                      setUploadedFileName(file?.name);
                      setUploadedFileName2(file?.name);
                    }
                    else {
                      setUploadedFileName('');
                      setUploadedFileName2('');
                    }
                  }}
                  accept=".pdf, .jpg, .jpeg, .png"
                />

                {touched.certificatefile && errors.certificatefile && (
                  <FormErrorMessage>{errors.certificatefile}</FormErrorMessage>
                )}
              </FormControl>
              <Flex flexDirection={'column'} justifyContent={'flex-start'} alignItems={"flex-start"} gap={3} >
                <Text color={'#D11A2A'} fontWeight={'400'} fontSize={'13px'} lineHeight={'15.23px'} fontFamily={'roboto'}>Maximum size for uploaded certificate is 500KB.Acceptable formats are JPG,PNG and PDF</Text>
              </Flex>
            </Box>
            <Flex px={['30px', '40px', '40px', '50px']} flexDirection={['column', 'row', 'row']} gap={['20px', '50px', '50px']} alignItems={'center'} mt={['50px', '80px', '100px']} mb={10}>
              <Button bg={'#A6A6A6'} color={'#FFFFFF'} fontWeight={'400'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                onClick={handlePrevious}>
                Back
              </Button>
              <Button bg={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'16px'} lineHeight={'16px'}
                w={'200px'} textAlign={'center'} _active={{ bg: '', }} _hover={{ bg: '', }}
                type="submit">
                Next
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default NYSCQualificationInformationAppForm