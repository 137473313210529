// src/AdmissionLetter.js
import React from 'react';
import { Box, Heading, Image, Stack, Text } from '@chakra-ui/react';
import icsanLogo from "../Assets/Images/icsan-logo.svg";
import { Navigate } from 'react-router-dom';

const AdmissionLetter = () => {
    return (
        <Box p={6} maxW="800px" mx="auto" bg="white" boxShadow="lg" borderRadius="md">
            <Box mb={4} textAlign="center">
                <Box h="3rem" display={'flex'} alignItems={'center'} justifyContent={'center'} mb={2}>
                    <Image src={icsanLogo} onClick={() => Navigate(0)} cursor={'pointer'} alt="ICSAN Logo" />
                </Box>
                <Heading size="sm">INSTITUTE OF CHARTERED SECRETARIES AND ADMINISTRATORS OF NIGERIA</Heading>
                <Heading size="sm" mt={2}>ADMISSION LETTER</Heading>
            </Box>
            <Text mb={4}>
                2A Alhaji Otun St.<br />
                Close, Ilupeju, Lagos<br />
                www.icsan.org<br />
                icsan@gmail.com<br />
                +234876543279<br />
                <br />
                23-06-2024<br />
                Ahmed Ashraf<br />
                Plot 1124, Adamu<br />
                Remawa, Ikoyi, Lagos.<br />
            </Text>
            <Text mb={4}>
                Dear Ahmed,
            </Text>
            <Text mb={4}>
                We are pleased to inform you that you have been successfully admitted as a student of the Institute of Chartered Secretaries and Administrators of Nigeria (ICSAN). Your application and supporting documents have been thoroughly reviewed, and we are excited to welcome you to our esteemed institution.
            </Text>
            <Text mb={4}>
                As a student member of ICSAN, you will have access to a comprehensive curriculum designed to equip you with the skills and knowledge necessary to excel in the fields of corporate governance, secretarial practice, and administration. Our programs are designed to be both rigorous and rewarding, preparing you for a successful career in your chosen field.
            </Text>
            <Text mb={4}>Admission Details:</Text>
            <Stack mb={4} pl={4} spacing={2}>
                <Text>- Student ID: [Your Student ID]</Text>
                <Text>- Program Enrolled: [Program Name]</Text>
                <Text>- Start Date: [Start Date]</Text>
                <Text>- Duration: [Program Duration]</Text>
            </Stack>
            <Text mb={4}>Next Steps:</Text>
            <Stack mb={4} pl={4} spacing={2}>
                <Text>1. Orientation Session: You are required to attend the mandatory orientation session on [Orientation Date] at [Orientation Venue]. This session will provide you with important information about the program, resources, and expectations.</Text>
                <Text>2. Fee Payment: Please ensure that you complete the payment of your tuition and other applicable fees by [Payment Deadline]. You can find the detailed fee structure and payment options on our website [Institute's Website].</Text>
                <Text>3. Access to Student Portal: You will receive an email with your login credentials to access the student portal, where you can find all necessary information regarding your courses, timetable, and other student resources.</Text>
                <Text>4. Course Materials: Course materials and textbooks can be collected from the ICSAN office or accessed online through the student portal.</Text>
            </Stack>
            <Text mb={4}>
                We are confident that your time at ICSAN will be both enriching and fulfilling. Should you have any questions or require further assistance, please do not hesitate to contact our student services team at [Email Address] or [Phone Number].
            </Text>
            <Text mb={4}>
                Congratulations once again on your admission. We look forward to supporting you on your journey to becoming a chartered secretary and administrator.
            </Text>
            <Text mb={4}>Best regards,</Text>
            <Text mb={4}>[Signature]</Text>
            <Text mb={4}>
                Taiwo Adebayo<br />
                Education Manager<br />
                Institute of Chartered Secretaries and Administrators of Nigeria (ICSAN)
            </Text>
        </Box>
    );
};

export default AdmissionLetter;
