import React ,{useState} from 'react';
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { SetApprovalOrderFormSchema } from '../utils/formValidationSchema';
import { Form, Formik } from 'formik';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Select, Input, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import {fetchApprovalSettings, createApprovalSettings} from '../redux/slices/approvalSettingsSlice';
import {setloadTost} from "../redux/slices/toastSlice";

const SetApprovalOrderForm = () => {
  const dispatch = useDispatch();
  const [approvalPointValue, setApprovalPointValue] =  useState(false);
  const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

  const initialValues = {
    approvalName: '',
    maximumApprovalOrder: '',
    approvalPoint: '',
    membershipGrade: '',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
      try {
        const formData = {
          name: values?.approvalName,
          requiredApprovalLevel: parseInt (values?.maximumApprovalOrder, 10),
          membershipCategory: values?.membershipGrade,
          approvalPoint: values?.approvalPoint,
        }

        const response =  await dispatch(createApprovalSettings(formData));
        // console.log(response);
        if (response.payload?.isSuccess === true) {
          setError('');
          setLoading(false);
          dispatch(fetchApprovalSettings());
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'Approval Settings Created', description: ` ${values?.approvalName} Approval settings created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        } else {
          setLoading(false);
          setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create approval settings");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to create approval settings, please check your network");
      } finally {
        setLoading(false);
      }
  }

  return (
    <>
      {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SetApprovalOrderFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors, touched, values, handleBlur, handleChange,
        }) => {

            if(values?.approvalPoint === "OnMembershipUpgrade" || values?.approvalPoint === "OnExamCenterChange"){
              setApprovalPointValue(true);
            }else{
              setApprovalPointValue(false);
            }
          
          return(
          <Form>
              <FormControl mt={4} isInvalid={errors.approvalName && touched.approvalName} color="#121212">
              <FormLabel htmlFor='approvalName' fontSize={'16px'}>Approval Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="approvalName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.approvalName}
              />
              {touched.approvalName && errors.approvalName && (
                <FormErrorMessage>{errors.approvalName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.maximumApprovalOrder && touched.maximumApprovalOrder} color="#121212">
              <FormLabel htmlFor='maximumApprovalOrder' fontSize={'16px'}>Maximum Approval Order</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="maximumApprovalOrder"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.maximumApprovalOrder}
              />
              {touched.maximumApprovalOrder && errors.maximumApprovalOrder && (
                <FormErrorMessage>{errors.maximumApprovalOrder}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.approvalPoint && touched.approvalPoint} color="#121212">
              <FormLabel htmlFor='approvalPoint' fontSize={'16px'}>Approval Point</FormLabel>
              <Select
                name="approvalPoint"
                placeholder="Select Here"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.approvalPoint}
              >

                <option value='OnRegistration'>On Registration</option>
                <option value='OnMembershipUpgrade'>On Membership Upgrade</option>
                <option value='OnExamCenterChange'>On Exam Center Change</option>
              </Select>
              {touched.approvalPoint && errors.approvalPoint && (
                <FormErrorMessage>{errors.approvalPoint}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.membershipGrade && touched.membershipGrade} color="#121212">
              <FormLabel htmlFor='membershipGrade' fontSize={'16px'}>Membership Grade</FormLabel>
              <Select
                name="membershipGrade"
                placeholder="Select Here"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.membershipGrade}
              >
                {
                  approvalPointValue ? (
                   <option value="All"> All</option>

                  ) : (
                    <>
                      <option value='Student'>Student</option>
                      <option value='NYSC'>NYSC</option>
                      <option value='Associate'>Associate</option>
                      <option value='Fellow'>Fellow</option>
                      {/* <option value='All'>All</option> */}
                    </>
                  )
                }
                
              </Select>
              {touched.membershipGrade && errors.membershipGrade && (
                <FormErrorMessage>{errors.membershipGrade}</FormErrorMessage>
              )}
            </FormControl>
            
            <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={"#D11A2A"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Save
              </Button>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={""}
                _hover={{ background: " " }}
                color={"#8F9BB3"}
                onClick={() => {
                  dispatch(setFormModal({ status: false, data: null }));
                }}
                loadingText="Saving ..."
              >
                Cancel
              </Button>
            </Flex>
          </Form>
           )
        }}
      </Formik>
    </>
  )
}

export default SetApprovalOrderForm