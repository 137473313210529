import React from 'react'
import { useDispatch } from 'react-redux';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Flex } from "@chakra-ui/react";
// import { addEventSchema } from '../utils/formValidationSchema';
import { setFormModal } from '../redux/slices/modalSlice';

function AddProgramme() {
    const dispatch = useDispatch();
    const initialValues = {
        programmeName: '',
        startDate: '',
        endDate: '',
        status: '',
    }
    const handleSubmit = async (values) => {
    
    }
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={addEventSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <FormControl mt={4} isInvalid={errors.programmeName && touched.programmeName} color="#121212">
                            <FormLabel htmlFor='programmeName' fontSize={'16px'}>Programme Name</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="programmeName"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.programmeName}
                            />
                            {touched.programmeName && errors.programmeName && (
                                <FormErrorMessage>{errors.programmeName}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                            <Input
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="startDate"
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.startDate}
                            />
                            {touched.startDate && errors.startDate && (
                                <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                            <Input
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="endDate"
                                cursor={"pointer"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.endDate}
                            />
                            {touched.endDate && errors.endDate && (
                                <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.eventLocation && touched.eventLocation} color="#121212">
                            <FormLabel htmlFor='eventLocation' fontSize={'16px'}>Event Location</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="eventLocation"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.eventLocation}
                            />
                            {touched.eventLocation && errors.eventLocation && (
                                <FormErrorMessage>{errors.eventLocation}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.assignCreditPoint && touched.assignCreditPoint} color="#121212">
                            <FormLabel htmlFor='assignCreditPoint' fontSize={'16px'}>Assign Credit Point</FormLabel>
                            <Input
                                name="assignCreditPoint"
                                type='number'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.assignCreditPoint}
                            >
                            </Input>
                            {touched.assignCreditPoint && errors.assignCreditPoint && (
                                <FormErrorMessage>{errors.assignCreditPoint}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                // isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Add Event
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddProgramme