import storeInit from "../redux/store";

const checkPermissions = (modules, resources, actions, values) => {
  const permissions = storeInit.store.getState()?.authPermissions?.Permissions;
 if(permissions){
  let permissionArray = permissions;
  if (!Array.isArray(permissionArray)) {
    permissionArray = [permissionArray];
  }
  // Filter permissions that match the criteria
  const matchingPermissions = permissionArray.map((permission) =>
    permission?.permissions?.filter(
      (permission) =>
        permission.moduleDesc === modules &&
        permission.resourceDesc === resources &&
        permission.action === actions &&
        permission.value === values
    )
  );
  // Check if any permissions were found
  const hasMatchingPermissions = matchingPermissions.some(
    (permission) => permission?.length > 0
  );
  return hasMatchingPermissions;
 }
};

export { checkPermissions };
