import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minH="100vh"
      bg="gray.100"
      textAlign="center"
      p={4}
    >
      <Heading as="h1" fontSize="9xl" color="red" mb={4}>
        404
      </Heading>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Oops! Page Not Found
      </Text>
      <Text fontSize="lg" color="gray.500" mb={8}>
        The page you're looking for doesn't exist or has been moved.
      </Text>
      <Link to="/">
        <Button
          colorScheme="red"
          size="lg"
          _hover={{ bg: 'red' }}
        >
          Go Back to Login 
        </Button>
      </Link>
    </Box>
  );
};

export default NotFound;
