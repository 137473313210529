import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setFormModal } from '../redux/slices/modalSlice';
import {Form, Formik } from 'formik';
import { AddAnnouncementFormSchema } from '../utils/formValidationSchema';
import { Button,Text, Flex, FormControl, FormErrorMessage, FormLabel, Input, Textarea, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import { fetchAnnouncement} from '../redux/slices/announcementSlice';
import {setloadTost} from "../redux/slices/toastSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const EditAnnouncementForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const editAnnouncement = useSelector((state) => state?.announcement?.editAnnouncement);
  // console.log(editAnnouncement);
  
  const initialValues = {
    title: editAnnouncement?.title || '',
    description: editAnnouncement?.description || '',
    file: `${BASE_URL}/${editAnnouncement?.attachment}` || '',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        // id:editAnnouncement?.id,
        Title: values?.title,
        Description: values?.description,
        FileUpload: values?.file,
      }
      console.log('formData', formData);
      const response = await httpRequest(
        `${BASE_URL}/v2/Announcement/${editAnnouncement?.id}/update`,
        "post",
        formData,
        null,
        "multipart/form-data",
      );
      // console.log(response);
      if (response?.isSuccess === true) {
        setError('');
        setLoading(false);
        dispatch(fetchAnnouncement());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Announcement Updated', description: 'Announcement updated successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setLoading(false);
        setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to Edit announcement");
      }
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to Edit announcement, please check your network");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
            <AlertIcon />
            <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
      }
      <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={AddAnnouncementFormSchema}
          onSubmit={(values) => handleSubmit(values)}
      >
          {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                  <FormControl mt={4} isInvalid={errors.title && touched.title} color="#121212">
                      <FormLabel htmlFor='title' fontSize={'16px'}>Title</FormLabel>
                      <Input
                          placeholder="Enter Here"
                          fontSize={"small"}
                          bg={'#F3F3F3'}
                          name="title"
                          focusBorderColor="#ffffff"
                          borderRadius={'0'}
                          borderColor={"#FFFFFF"}
                          _placeholder={{ color: "#B4B1B1" }}
                          _hover={{ borderColor: "#FFFFFF" }}
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.title}
                      />
                      {touched.title && errors.title && (
                          <FormErrorMessage>{errors.title}</FormErrorMessage>
                      )}
                  </FormControl>
                  <FormControl mt={4} isInvalid={errors.description && touched.description} color="#121212">
                      <FormLabel htmlFor='description' fontSize={'16px'}>Description</FormLabel>
                      <Textarea
                          placeholder="Enter description"
                          fontSize={"small"}
                          bg={'#F3F3F3'}
                          name="description"
                          focusBorderColor="#ffffff"
                          borderRadius={'0'}
                          borderColor={"#FFFFFF"}
                          _placeholder={{ color: "#B4B1B1" }}
                          _hover={{ borderColor: "#FFFFFF" }}
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.description}
                      />
                      {touched.description && errors.description && (
                          <FormErrorMessage>{errors.description}</FormErrorMessage>
                      )}
                  </FormControl>
                  <FormControl mt={4} isInvalid={errors.file && touched.file}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='file' fontSize={"16px"}>
                      Upload File
                  </FormLabel>
                  <Input
                      type='file'
                      name="file"
                      id="file"
                      fontSize={"small"}
                      bg={'#F3F3F3'}
                      focusBorderColor="#ffffff"
                      borderRadius={'0'}
                      borderColor={"#FFFFFF"}
                      _placeholder={{ color: "#B4B1B1" }}
                      _hover={{ borderColor: "#FFFFFF" }}
                      onBlur={handleBlur}
                      onChange={(event) => {
                          setFieldValue('file', event.currentTarget.files[0]);
                      }}
                      accept=".pdf, .jpg, .jpeg, .png"
                  />
                  <Text color={'#D11A2A'}> accepts ".pdf, .jpg, .png" files</Text>
                  {touched.file && errors.file && (
                      <FormErrorMessage>{errors.file}</FormErrorMessage>
                  )}
              </FormControl>
                  <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                      <Button
                          mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                          width={"118px"}
                          background={"#D11A2A"}
                          _hover={{ background: " " }}
                          color={"#FFFFFF"}
                          type="submit"
                          isLoading={loading}
                          loadingText="Saving ..."
                      >
                          Edit
                      </Button>
                      <Button
                          mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                          width={"118px"}
                          background={""}
                          _hover={{ background: " " }}
                          color={"#8F9BB3"}
                          onClick={() => {
                              dispatch(setFormModal({ status: false, data: null }));
                          }}
                          loadingText="Saving ..."
                      >
                          Cancel
                      </Button>
                  </Flex>
              </Form>
          )}
      </Formik>
    </>
  )
}

export default EditAnnouncementForm;