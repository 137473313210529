import React from 'react';
import { Box, Flex,Text, } from '@chakra-ui/react';
import { useDispatch} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import CustomTable from '../components/CustomTable';
import {EducationExamResultData} from "../data/mock";

const EducationExamResult = () => {
    const dispatch = useDispatch();
    const handleUpdate = (item) => {
      dispatch(setFormModal({status:true, title: "Upload Result", type:"educationResult", data:item }));
    }

    const tableProps = {
        data: EducationExamResultData,
        title: {
          category:"Exam Category",
        },
        dataKeys: [
          "category",
        ],
        
        transformRow: (item) => ({
          category: <Box style={{cursor:'pointer'}} onClick={() => handleUpdate(item)}>{item?.category}</Box>,

        }),
       
      };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
      <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Upload Exam Result / Select Category to upload result</Text>
    </Flex>
    <CustomTable {...tableProps} />
  </Box>
  )
}

export default EducationExamResult;