import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Flex,  Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { setFormModal } from '../redux/slices/modalSlice';
import { MemberUpgradeFormSchema } from '../utils/formValidationSchema';
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {setloadTost} from "../redux/slices/toastSlice";
import {fetchMembershipUpgrade} from "../redux/slices/membershipUpgradeSlice";

const MemberUpgradeForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const memberProfile = useSelector((state) => state.auth?.memberProfile);
    
    const initialValues = {
        memberNo: memberProfile?.membershipId || "",
        newMembershipUpgrade: memberProfile?.nextMemberCategory|| "" ,
        uploadFile: '',
    }
    const handleSubmit =  async (values) => {
        setLoading(true);
        setError("");
        try {
            const response = await httpRequest(`${BASE_URL}/v2/MembershipUpgrade/application`, 'post', values);
            if(response?.isSuccess === true){
                setLoading(false);
                dispatch(fetchMembershipUpgrade());
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Member Upgrade', description: `Member Upgraded successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            }else{
                setLoading(false);
                setError(response?.errors?.map((errors) => errors) || response?.error?.description || "Failed to Upgrade member");
            }
            
        } catch (error) {
            setError(error?.payload?.message || "Failed to upgrade member, please check your network");
        }finally{
            setLoading(false);
        }
    }

    return (
        <>
            {
            error &&(
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={MemberUpgradeFormSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <FormControl mt={4} isInvalid={errors.memberNo && touched.memberNo} color="#121212">
                            <FormLabel htmlFor='memberNo' fontSize={'16px'}>Member No</FormLabel>
                            <Input
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="memberNo"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.memberNo}
                                readOnly
                            />
                            {touched.memberNo && errors.memberNo && (
                                <FormErrorMessage>{errors.memberNo}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.newMembershipUpgrade && touched.newMembershipUpgrade} color="#121212">
                            <FormLabel htmlFor='newMembershipUpgrade' fontSize={'16px'}>New Membership Upgrade</FormLabel>
                            <Input
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="newMembershipUpgrade"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.newMembershipUpgrade}
                                readOnly
                            />
                            {touched.newMembershipUpgrade && errors.newMembershipUpgrade && (
                                <FormErrorMessage>{errors.newMembershipUpgrade}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.uploadFile && touched.uploadFile} color="#121212">
                            <FormLabel htmlFor='uploadFile' fontSize={'16px'}>Upload File</FormLabel>
                            <Input
                                placeholder="Add Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="uploadFile"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="file"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.uploadFile}
                            />
                            {touched.uploadFile && errors.uploadFile && (
                                <FormErrorMessage>{errors.uploadFile}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Submit
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default MemberUpgradeForm