import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Box, Flex, Text, Divider, Select } from '@chakra-ui/react';

const RoundedBar = (props) => {
  const { x, y, width, height } = props;
  const radius = 9; 
  return (
    <g>
      <path d={`M${x},${y + height} L${x},${y + radius} Q${x},${y} ${x + radius},${y} L${x + width - radius},${y} 
        Q${x + width},${y} ${x + width},${y + radius} L${x + width},${y + height} Z`} fill={props.fill} />
    </g>
  );
};

const formatYAxis = (tickValue) => {
  return tickValue !== 0 ? tickValue + 'k' : tickValue;
};

const MembershipDashboardBarChart = ({ membershipbarChartData = [] }) => {
  const [selectedYear, setSelectedYear] = useState("This Year");

  const filteredData = membershipbarChartData.filter(item =>
    selectedYear === "This Year" ? item.year === new Date().getFullYear() : item.year === new Date().getFullYear() - 1
  );

  return (
    <Flex direction="column" alignItems="flex-start" bg={'#FFFFFF'} borderRadius={'20px'} pr={3}>
      <Flex py={3} pl={5} justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
        <Text fontSize={'14px'} fontWeight={'500'} letterSpacing={'-0.154px'} color={'#4D4D4D'}>
          Total Membership Upgrade
        </Text>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={5}>
          <Select
            w={'100px'}
            border={'0'}
            focusBorderColor='white'
            color={'#1E1E1E'}
            opacity={'0.5'}
            fontSize={'12px'}
            fontWeight={'400'}
            fontFamily={'inter'}
            lineHeight={'14.52px'}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option>This Year</option>
            <option>Last Year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex w="100%" direction={['column', 'column', 'row']} py={4} alignItems={'center'}>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart data={filteredData} margin={{ top: 20, right: 10, left: 10, bottom: 5 }}>
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} tickFormatter={formatYAxis} />
            <Tooltip />
            <Bar dataKey="acceptedCount" shape={<RoundedBar />} fill="#D11A2A" barSize={15} />
            <Bar dataKey="rejectedCount" shape={<RoundedBar />} fill="#D7343930" barSize={15} />
          </BarChart>
        </ResponsiveContainer>
        <Divider orientation="vertical" border={'1px solid #E4E5E7'} mr={2} h={"90%"} />
        <Flex direction="column" justify="center">
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#D11A2A" h={2} w={4} borderRadius="3px"></Box>
            <Text fontSize="sm" color="#4D4D4D">Accepted</Text>
          </Flex>
          <Flex alignItems={'center'} gap={2}>
            <Box bg="#D7343930" h={2} w={4} borderRadius="3px"></Box>
            <Text fontSize="sm" color="#4D4D4D">Rejected</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MembershipDashboardBarChart;
