import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import statusHttpRequest from "../../utils/statusHttpRequest";


const initialState = {
    loading: false,
    error: null,
    registerNYSC: [],
    personalInfo: "",
    ppaInfo: "",
    qualificationInfo: "",
    marriageRefInfo: "",
};

export const createNYSCRegistration = createAsyncThunk(
    "NYSCRegistration/createNYSCRegistration",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Application/nysc-registration`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const createNYSCApplicationForm = createAsyncThunk(
    "NYSCRegistration/createNYSCApplicationForm",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Application/nysc`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const editNYSCApplicationForm = createAsyncThunk(
    "NYSCRegistration/createNYSCApplicationForm",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Application/nysc`,
                "put",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchNYSCApplication = createAsyncThunk(
    "NYSCRegistration/fetchNYSCApplication",
    async () => {
        return statusHttpRequest(`${BASE_URL}/v2/Application/nysc`,);
    }
);

const NYSCRegistrationSlice = createSlice({
    name: "NYSCRegistration",
    initialState,
    reducers: {
        setPersonalInfo: (state, { payload }) => {
            state.personalInfo = payload;
        },
        setEmploymentInfo: (state, { payload }) => {
            state.ppaInfo = payload;
        },
        setQualificationInfo: (state, { payload }) => {
            state.qualificationInfo = payload;
        },
        setMarriageRefInfo: (state, { payload }) => {
            state.marriageRefInfo = payload;
        },
    },

    extraReducers: (builder) => {
        //Register nysc:
        builder.addCase(createNYSCRegistration.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createNYSCRegistration.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.registerNYSC = payload;
            state.error = null;
        });
        builder.addCase(createNYSCRegistration.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });


        // Create nysc Application Form:
        builder.addCase(createNYSCApplicationForm.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createNYSCApplicationForm.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(createNYSCApplicationForm.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // get nysc Application Form:
        builder.addCase(fetchNYSCApplication.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchNYSCApplication.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.registerNYSC = payload;
            state.error = null;
        });
        builder.addCase(fetchNYSCApplication.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

    }

});

export const { setPersonalInfo, setEmploymentInfo, setQualificationInfo, setMarriageRefInfo } = NYSCRegistrationSlice.actions;
export default NYSCRegistrationSlice.reducer;