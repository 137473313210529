import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  roles: [],
  rolePermissions: [],
  roleDetails : "",
  editRole: "",
};

export const fetchRoles = createAsyncThunk(
  "role/fetchRoles",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Role`);
  }
);

export const fetchRolePermissions = createAsyncThunk(
  "role/fetchRolePermissions",
  async (roleId) => {
    return httpRequest(`${BASE_URL}/v2/Role/${roleId}/permissions`);
  }
);

export const createRole = createAsyncThunk(
  "role/createRole",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Role`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const userMgtRoleSlice = createSlice({
    name:"role",
    initialState,
    reducers: {
      setRoleDetails: (state, {payload}) => {
        state.roleDetails = payload;
      },
      setEditRole: (state, {payload}) => {
        state.editRole = payload;
    },

  },

    extraReducers:(builder) => {
      // get all roles :
      builder.addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.roles = payload;
        state.error = null;
      });
      builder.addCase(fetchRoles.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
      });

      // create role :
      builder.addCase(createRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createRole.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      });
      builder.addCase(createRole.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
      });

      // get role permissions :
      builder.addCase(fetchRolePermissions.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchRolePermissions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rolePermissions = payload;
        state.error = null;
      });
      builder.addCase(fetchRolePermissions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.error?.description;
      })

    }

})

export default userMgtRoleSlice.reducer;
export const {setRoleDetails, setEditRole} = userMgtRoleSlice.actions;