import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Create from '../components/layout/Create';
import CustomTable from '../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {fetchApprovalWorkflow, setEditApprovalWorkflow} from '../redux/slices/approvalWorkFlowSlice';
import {fetchApprovalSettings} from '../redux/slices/approvalSettingsSlice';
import {fetchRoles} from '../redux/slices/userMgtRoleSlice';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";

function ApprovalWorkflow() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const getAllApprovalWorkflow = useCallback( async() => {
        setLoading(true);
        setError("");
        try {
        const response = await dispatch(fetchApprovalWorkflow());
        // console.log('approval workflow response', response);
        if (response?.payload?.name === "AxiosError"){
            setLoading(false);
            setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get approval workflow, please check your request/network");
        }
        setLoading(false);
        
        } catch (error) {
        setError(error?.payload?.message || "Failed to get approval workflow, please check your network");
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(clearsearchFilter());
        getAllApprovalWorkflow();
        dispatch(fetchApprovalSettings());
        dispatch(fetchRoles());
    }, [dispatch, getAllApprovalWorkflow]);

    const addApprovalWorkflowPermission = checkPermissions( 'Approval', 'Approval Workflow', 'Create', 'Granted' );
    const editApprovalWorkflowPermission = checkPermissions( 'Approval', 'Approval Workflow', 'Edit', 'Granted' );
    const deleteApprovalWorkflowPermission = checkPermissions( 'Approval', 'Approval Workflow', 'Delete', 'Granted' );
    
    const createData = {
        onClick: () => { dispatch(setFormModal({ status: true, title: "Add Approval Level", type: "AddApprovalOrderForm" , })); },
        createText: "Add Approval Level",
    }

    const workflowData  = useSelector(state => state?.approvalWorkFlow?.approvalWorkflow?.data);
    // console.log('approvalWorkflow', workflowData);

    const handleEdit = (item) => {
        dispatch(setEditApprovalWorkflow(item));
        dispatch(setFormModal({status:true, title: "Edit Approval Workflow", type:"EditApprovalWorkflow"}));
    }

    const handleDelete = async (item) => {
        // console.log(item);
        setError('');
       try {
          const response = await httpRequest(`${BASE_URL}/v2/ApprovalWorkflow/${item?.id}/delete`,
          'post', 
        );
        // console.log(response);
        if(response?.isSuccess === true){
          setError('');
          dispatch(fetchApprovalWorkflow());
          dispatch(setloadTost({ title: 'Approval Workflow Deleted', description: `${item?.approvalSettingName}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
       }else{
          setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description|| "Failed to delete item");
        }
        
       } catch (error) {
        setError(error?.response?.message || "Failed to delete item");
       }
  
    }

    const tableProps = {
        data: workflowData,
        title: {
            approvalName: "Approval Name",
            approvalLevel: "Approval Level",
            roleAssigned: "Role Assigned",
            approvalPoint: "Approval Point",
            memebershipGrade: "Memebership Grade",
            dateCreated: "Date Created",
            Details: "",
        },
        dataKeys: [
            "approvalName",
            "approvalLevel",
            "roleAssigned",
            "approvalPoint",
            "memebershipGrade",
            "dateCreated",
            "Details",
        ],
        transformRow: (item) => ({
            approvalName: <Box>{item?.approvalSettingName}</Box>,
            approvalLevel: item?.approvalLevel,
            roleAssigned: item?.roleName,
            approvalPoint: item?.approvalPoint,
            memebershipGrade: item?.membershipCategory,
            dateCreated: item?.createdOn && format(new Date(item.createdOn), 'yyyy-MM-dd'),
            Details: item &&  (
                <Menu px={'10px'}>
                    {
                       !editApprovalWorkflowPermission && !deleteApprovalWorkflowPermission ? null : (
                        <MenuButton>
                            <BsThreeDotsVertical />
                        </MenuButton>
                       )
                    }
                    <MenuList>
                        {
                        editApprovalWorkflowPermission ? (
                            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
                        ) : null
                        }
                        {
                        deleteApprovalWorkflowPermission ?  (
                            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
                        ) : null
                        }
                    </MenuList>
                </Menu>
            ),
        }),
    };
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Approval Workflow</Text>
        <Flex justifyContent={'center'} alignItems={'center'} flexDirection={["column", "column", "row"]} gap={[3, null, null]}>
            {
            addApprovalWorkflowPermission  ? (
                <Create {...createData} />
            ) : null
            }
            <SortBy />
        </Flex>
    </Flex>
    {
        loading ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
                <DotLoader />
            </Flex>
        ):(
            <>
                {
                error ? (
                <Alert status="error" className="mt-3" mb={3}>
                    <AlertIcon />
                    <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
                ) 
                : (<CustomTable {...tableProps} />)
                }
            </>
        )
    }
</Box>
  )
}

export default ApprovalWorkflow