import React from 'react'
import { Box, Flex, Text, Select, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { MdOutlineFileUpload } from "react-icons/md";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { studentReportData } from '../data/mock';

const StudentReport = () => {
  const tableProps = {
    data: studentReportData,
    title: {
      fullName: "Full Name",
      examCategory: "Exam Category",
      exemptionType: "Exemption Status",
      entryQualificationType: "Enrty Qualification Type",
      viewDetails: "",
    },
    dataKeys: [
      "fullName",
      "examCategory",
      "exemptionType",
      "entryQualificationType",
      "viewDetails"
    ],
    transformRow: (item) => ({
      fullName: item.fullName,
      examCategory: item.examCategory,
      exemptionType: item.exemptionType,
      entryQualificationType: item.entryQualificationType,
      viewDetails: (
        <Menu px={'10px'}>
          <MenuButton>
            <BsThreeDotsVertical />
          </MenuButton>

          <MenuList >
            <MenuItem>Edit</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  };
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'20px'} fontWeight={'700'}  color={'#121212'} opacity={'0.5'} >All Students</Text>
        <Text color={'#D11A2A'} textDecoration={'underline'} fontSize={'12px'} fontWeight={600}>DOWNLOAD TEMPLATE HERE</Text>
        <Flex alignItems={'center'} justifyContent={'center'} gap={'20px'}>
          <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
            <option value='option1'>Option 1</option>
            <option value='option2'>Option 2</option>
            <option value='option3'>Option 3</option>
          </Select>
          <Flex alignItems={'center'} _hover={{ color: '#D11A2A' }} cursor={'pointer'}>
            <MdOutlineFileUpload />
            <Text fontSize={'14px'} fontWeight={500} color={'#404040'} w={'100px'} _hover={{ color: '#D11A2A' }}>EXPORT</Text>
          </Flex>
        </Flex>
      </Flex>
      <CustomTable {...tableProps} />
    </Box>
  )
}

export default StudentReport;