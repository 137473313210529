import React, { useState } from 'react'
import ExamlistingReportTable from '../components/ExamlistingReportTable';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

function ExamListingReport() {
    const [toggleReport, setToggleReport] = useState(false);

    const initialValues = {
        membershipNo: '',
        fullName: '',
        gender: '',
        examStage: '',
        grade: '',
        examYear: '',
    };

    const handleSubmit = async (values) => {
        setToggleReport(!toggleReport)
    }

    return (
        <>
            <Box bgColor={'white'} height={["850px", "", '370px']} px={'30px'} py={'20px'} mb={5}>
                <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // validationSchema={approvalReportSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                        errors, touched, values, handleBlur, handleChange
                    }) => (

                        <Form>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.examYear && touched.examYear} color="#121212">
                                    <FormLabel htmlFor='memberType' fontSize={'16px'}>Exam Year</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="examYear"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.examYear}
                                    >
                                        <option value='2020'>2020</option>
                                        <option value='2021'>2021</option>
                                    </Select>
                                    {touched.examYear && errors.examYear && (
                                        <FormErrorMessage>{errors.examYear}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.examDiet && touched.examDiet} color="#121212">
                                    <FormLabel htmlFor='membershipLevel' fontSize={'16px'}>Exam Diet</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="examDiet"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.examDiet}
                                    >
                                        <option value='active'>active</option>
                                        <option value='inactive'>inactive</option>
                                    </Select>
                                    {touched.examDiet && errors.examDiet && (
                                        <FormErrorMessage>{errors.examDiet}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.gender && touched.membershipNo} color="#121212">
                                    <FormLabel htmlFor='membershipNo' fontSize={'16px'}>Membership Number</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="membershipNo"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.membershipNo}
                                    >
                                        <option value='active'>active</option>
                                        <option value='inactive'>inactive</option>
                                    </Select>
                                    {touched.membershipNo && errors.membershipNo && (
                                        <FormErrorMessage>{errors.membershipNo}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.examCategory && touched.examCategory} color="#121212">
                                    <FormLabel htmlFor='examCategory' fontSize={'16px'}>Exam Category</FormLabel>
                                    <Select
                                        height={'52px'}
                                        name="examCategory"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        bg={'#F3F3F3'}
                                        borderRadius={"0"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        value={values?.examCategory}
                                    >
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                    </Select>
                                    {touched.memberNumber && errors.examCategory && (
                                        <FormErrorMessage>{errors.examCategory}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                                    color="#121212" fontFamily={'inter'}>
                                    <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                                    <Input
                                        height={'52px'}
                                        type="date"
                                        placeholder="Pick a Date"
                                        borderRadius={"0"}
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="startDate"
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.startDate}
                                    />
                                    {touched.startDate && errors.startDate && (
                                        <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                                    color="#121212" fontFamily={'inter'}>
                                    <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                                    <Input
                                        height={'52px'}
                                        type="date"
                                        placeholder="Pick a Date"
                                        borderRadius={"0"}
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="endDate"
                                        cursor={"pointer"}
                                        focusBorderColor="#ffffff"
                                        borderColor={"#FFFFFF"}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.endDate}
                                    />
                                    {touched.endDate && errors.endDate && (
                                        <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                            <Flex justifyContent={'flex-end'} alignItems={'center'}>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                    width={"118px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                // isLoading={loading}
                                // loadingText="Saving ..."
                                >
                                    Fetch Report
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
            {
                toggleReport ? (<ExamlistingReportTable />) : null
            }
        </>
    )
}

export default ExamListingReport