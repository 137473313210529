import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
    fees: [],
    feeType: [],
    editFees: "",
    editFeeType: "",
    loading: false,
    error: null,
};

export const fetchFees = createAsyncThunk(
    "feeMgt/fetchFees",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Fee`);
    }
);

export const fetchFeeType = createAsyncThunk(
    "feeMgt/fetchFeeType",
    async () => {
        return httpRequest(`${BASE_URL}/v2/Fee/Type`);
    }
);

export const createFees = createAsyncThunk(
    "feeMgt/createFees",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Fee`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const createFeeType = createAsyncThunk(
    "feeMgt/createFeeType",
    async (arg, { rejectWithValue }) => {
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Fee/Type`,
                "post",
                arg
            );
            if (response?.response?.data) {
                throw response;
            }
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const feeMgtSlice = createSlice({
    name: "feeMgt",
    initialState,

    reducers: {
        setEditFees: (state, { payload }) => {
            state.editFees = payload;
        },
        setEditFeeType: (state, { payload }) => {
            state.editFeeType = payload;
        },
    },

    extraReducers: (builder) => {
        // get all fees
        builder.addCase(fetchFees.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchFees.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.fees = payload;
            state.error = null;
        });

        builder.addCase(fetchFees.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // get all fees types
        builder.addCase(fetchFeeType.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchFeeType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.feeType = payload;
            state.error = null;
        });

        builder.addCase(fetchFeeType.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

        // create fees
        builder.addCase(createFees.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createFees.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(createFees.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });

          // create fee type
          builder.addCase(createFeeType.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createFeeType.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
        });

        builder.addCase(createFeeType.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload?.error?.description;
        });
    }

});

export default feeMgtSlice.reducer;
export const { setEditFees, setEditFeeType } = feeMgtSlice.actions;