import React from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import heroImg from "../Assets/Images/student-hero-img.svg";
import CustomTable from '../components/CustomTable';
import {studentdashTableData, upcomingStudentEvent} from "../data/mock";
import UpcomingExams from '../components/UpcomingExams';
import { useSelector } from 'react-redux';

const StudentDashboard = () => {
    const member = useSelector((state) => state.auth?.memberProfile);
    const tableProps = {
        data: studentdashTableData,
        title: {
            feeName: "Fee Name",
            year: "Year",
            amountOwed: " Amount Owed",
        },
        dataKeys: [
          "feeName",
          "year",
          "amountOwed",
        ],
        transformRow: (item) => ({
            feeName: <Box style={{ cursor: "pointer" }}>{item?.feeName}</Box>,
            year: <Box style={{ cursor: "pointer" }}>{item?.year}</Box>,
            amountOwed: <Box style={{ cursor: "pointer" }}>{item?.amountOwed}</Box>,
          
        }),
    };

  return (
    <Flex flexDirection={'column'} gap={'20px'} py={'30px'} minH={'100vh'} px={3} >
        <Box borderRadius={'20px'}  bgGradient="linear(to-r, #D11A2A, #FFDDE1)">
            <Flex px={10} py={6} flexDirection={['column', 'row', 'row']} justifyContent={['center', 'space-between', 'space-between']} alignItems={'center'} gap={'20px'}>
                <Flex flexDirection={'column'} fontFamily={'inter'} gap={3} color={'#FFFFFF'} letterSpacing={'-0.15px'}>
                    <Text fontWeight={'700'} fontSize={'20px'} lineHeight={'24.2px'}>Welcome {member?.firstName + " " + member?.lastName} ({member?.membershipId})</Text>
                    <Text fontWeight={'700'} fontSize={'18px'} lineHeight={'21.78px'}>Current Grade: {member?.memberCategory}</Text>
                    <Text mt={4} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'}>You Have an outstanding payment of 10,000</Text>
                    <Button mt={3} borderRadius={'12px'} bgColor={'#7D2B32'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'}
                        _hover={{ bgColor: '' }} _active={{ bgColor: '' }}
                    >Make Payments Now!</Button>
                </Flex>
                <Image src={heroImg} alt='Student Hero Image' h={'230px'} w={'250px'} />
            </Flex>
        </Box>
        <Flex flexDirection={['column', 'row', 'row']} alignItems={'center'} gap={'20px'} my={3} >
            <Flex flexDirection={'column'} w={['100%', '70%', '70%']} bg={'#FFFFFF'} borderRadius={'20px'} p={5} gap={4} h={'400px'} 
            sx={{
                overflowY: "scroll",
                scrollbarWidth: "none", 
                msOverflowStyle: "none", 
                "-ms-overflow-style": "none", 
                "&::-webkit-scrollbar": {
                    display: "none", 
                },
                }}
                overflowY={"auto"}
            >
                <Text fontWeight={'700'} fontSize={'14px'} lineHeight={'20px'} color={'#18203A'} > My Dues</Text>
                <CustomTable {...tableProps}/>
            </Flex>
            <Flex w={['100%', '30%', '30%']} >
                <UpcomingExams showAdmin={false} upcomingEvents={upcomingStudentEvent} />
            </Flex>
        </Flex>
    </Flex>
  )
}

export default StudentDashboard;