import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { PiPlusBold } from "react-icons/pi";

const Create = ({ onClick, createText, bgColor }) => {
  return (
    <Flex
      alignItems={"center"}
      gap={5}
      flexDirection={["column", "row", "row"]}
    >
      <Button
        borderRadius={"3px"}
        bg={bgColor? bgColor: "#D11A2A"}
        rightIcon={<PiPlusBold />}
        color="white"
        onClick={onClick}
        px={5}
        _hover={{ backgroundColor: "" }}
        _active={{backgroundColor:""}} 
      >
        {createText}
      </Button>
    </Flex>
  );
};

export default Create;
