import React from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {pieChartData2} from "../data/mock";

const EducationPieCharts2 = () => {
    const colors = ["#F87B84", "#D9E2FE" ];
    const sumTotal = pieChartData2.reduce((acc, data) => acc + data.value, 0);
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const { value } = pieChartData2[index];

    return (
        <text style={{fontWeight:"800", fontSize:"14px", lineHeight:"9.68px", fontFamily:"inter"}} x={x} y={y} fill="rgba(255, 255, 255, 0.5)" textAnchor="middle" dominantBaseline="middle">
        {value}
        </text>
    );
    };

  return (
    <Box bg={'#FFFFFF'} borderRadius={'20px'} p={5} h={['100%','100%','100%','380px','376px']}
    sx={{
        overflowY: "scroll",
      scrollbarWidth: "none", 
      msOverflowStyle: "none", 
      "-ms-overflow-style": "none", 
      "&::-webkit-scrollbar": {
        display: "none", 
      },
    }}
    overflowY={"auto"}
    >
        <Flex justifyContent={'space-between'} alignItems={'center'} >
            <Flex flexDirection={'column'}>
                <Text color={'#4D4D4D'} letterSpacing={'-0.15px'} fontSize={'14px'} fontWeight={'500'} lineHeight={'21.41px'}>
                    Total members Registered per Exam type
                </Text>
                <Text mt={2} color={'#4D4D4D'} fontSize={'10px'} fontWeight={'400'} lineHeight={'13.62px'} letterSpacing={'-0.15px'}>{sumTotal} Members Registered</Text>
            </Flex>
        </Flex>
        <Flex w="100%" flexDirection={'column'} >
            <ResponsiveContainer width="100%" height={220} >
                <PieChart>
                    <Pie
                    data={pieChartData2}
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={70}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    cornerRadius={8}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    >
                    {pieChartData2.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <Divider orientation='horizontal'  border={`1px solid #C6C6C666`} />
            <Flex direction="column" justify="center" py={5} gap={2}>
            {
            pieChartData2.map((item, index) => (
            <Flex alignItems={'center'} justifyContent={'space-between'} key={index} gap={3}>
                <Flex alignItems={'center'} gap={2}>
                    <Box bg={colors[index % colors.length]} h={2} w={4} borderRadius="3px" ></Box>
                    <Text fontSize="12px" color="#000000B2" fontWeight={'700'} fontFamily={'inter'}>
                        {item.name}
                    </Text>
                </Flex>
                <Text color={'#717171'} fontSize={'12px'} fontWeight={'500'} lineHeight={'12.61px'}>{item.value} Registered students</Text>
            </Flex>
            ))
            }
        </Flex>
        </Flex>
    </Box>
  )
}

export default EducationPieCharts2;