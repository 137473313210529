import React, { useState } from 'react'
import MemberMgtReportTable from '../components/MemberMgtReportTable';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

function MemberManagementReport() {
  const [toggleReport, setToggleReport] = useState(false);
  const initialValues = {
    membershipNo: '',
    fullName: '',
    gender: '',
    qualification: '',
    memberCategory: '',
  };

  const handleSubmit = async (values) => {
    setToggleReport(!toggleReport)
  }
  return (
    <>
    <Box bgColor={'white'} height={["850px", "", '370px']} px={'30px'} py={'20px'} mb={5}>
      <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={approvalReportSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors, touched, values, handleBlur, handleChange
        }) => (

          <Form>
            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
              <FormControl mt={4} isInvalid={errors.memberNo && touched.memberNo} color="#121212">
                <FormLabel htmlFor='memberNo' fontSize={'16px'}>Member Number</FormLabel>
                <Input
                height={'52px'}
                type="text"
                placeholder="Enter Here"
                borderRadius={"0"}
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="memberNo"
                cursor={"pointer"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.memberNo}
                >
                </Input>
                {touched.memberNo && errors.memberNo && (
                  <FormErrorMessage>{errors.memberNo}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.memberNo && touched.fullName} color="#121212">
                <FormLabel htmlFor='fullName' fontSize={'16px'}>Full Name</FormLabel>
                <Input
                    height={'52px'}
                    type="text"
                    placeholder="Enter Here"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="fullName"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.fullName}
                >
                </Input>
                {touched.fullName && errors.fullName && (
                  <FormErrorMessage>{errors.fullName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.gender && touched.gender} color="#121212">
                <FormLabel htmlFor='gender' fontSize={'16px'}>Gender</FormLabel>
                <Select
                  height={'52px'}
                  name="gender"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={'#F3F3F3'}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.gender}
                >
                  <option value='member'>Male</option>
                  <option value='member'>Female</option>


                </Select>
                {touched.gender && errors.gender && (
                  <FormErrorMessage>{errors.gender}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
              <FormControl mt={4} isInvalid={errors.email && touched.email} color="#121212">
                <FormLabel htmlFor='email' fontSize={'16px'}>Email</FormLabel>
                <Input
                     height={'52px'}
                     type="email"
                     placeholder="Enter Here"
                     borderRadius={"0"}
                     fontSize={"small"}
                     bg={'#F3F3F3'}
                     name="email"
                     cursor={"pointer"}
                     focusBorderColor="#ffffff"
                     borderColor={"#FFFFFF"}
                     _hover={{ borderColor: "#FFFFFF" }}
                     _placeholder={{ color: "#B4B1B1" }}
                     onBlur={handleBlur}
                     onChange={handleChange}
                     value={values?.email}
                >
                </Input>
                {touched.email && errors.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl></FormControl>
              <FormControl>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                // isLoading={loading}
                // loadingText="Saving ..."
                >
                  Fetch Report
                </Button>
              </FormControl>
            </Flex>
            {/* <Flex justifyContent={'flex-end'} alignItems={'center'}>
          </Flex> */}
          </Form>
        )}
      </Formik>
    </Box>
    {
      toggleReport ? (<MemberMgtReportTable />) : null
    }
  </>
  )
}

export default MemberManagementReport