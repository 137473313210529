import { Box, Flex 
    // Button
 } from '@chakra-ui/react';
import React from 'react'
import CustomTable from './CustomTable';
import DotLoader from '../components/loaders/DotLoader';
// import { useDispatch } from 'react-redux';
// import { setFormModal } from '../redux/slices/modalSlice';

function DeclinedTable({memberUpgradeApps, isloading}) {
    // const dispatch = useDispatch();
    // const handleUpdate = (item) => {
    //   dispatch(setFormModal({ status: true, title: "Member Upgrade Details", type: "individualDetails", size:"3xl", data:item }));
    // };

    const tableProps = {
        data: memberUpgradeApps?.data,
        title: {
            fullName: "Full Name",
            memberNo: "Memeber NO",
            emailAddress: "Email Address",
            currentMembership: "Current Membership",
            ProposedMembership: "Proposed Membership",
            // viewDetails: "",
        },
        dataKeys: [
            "fullName",
            "memberNo",
            "emailAddress",
            "currentMembership",
            "ProposedMembership",
            // "viewDetails",
        ],
        transformRow: (item) => ({
            fullName: <Box style={{ cursor: 'pointer' }}>{item?.fullName}</Box>,
            memberNo: <Box style={{ cursor: 'pointer' }}>{item?.memberNumber}</Box>,
            emailAddress: <Box style={{ cursor: 'pointer' }}>{item?.email}</Box>,
            currentMembership: <Box style={{ cursor: 'pointer' }}>{item?.currentMembership}</Box>,
            ProposedMembership: <Box style={{ cursor: 'pointer' }}>{item?.proposedMembership}</Box>,
            // viewDetails: (
            //     <Button fontWeight={'bold'} fontSize={'14px'} bgColor={'#812773'} color={'white'} px={'10px'} _hover={{ bgColor: '' }}  onClick={() => handleUpdate(item)}>View Details</Button>
            // ),
        }),
    };
    return (
        <Box>
        {
            isloading ? (
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <DotLoader />
                </Flex>
            ):(
                <CustomTable {...tableProps} />
            )
        }
        </Box>
    )
}

export default DeclinedTable