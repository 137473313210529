import React, { useState } from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, Textarea, Alert, AlertIcon, AlertTitle, Select } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { emailFormSchema } from '../utils/formValidationSchema';
import { Form, Formik } from 'formik';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { setloadTost } from "../redux/slices/toastSlice";
import { createMailNotification } from "../redux/slices/mailNotificationSlice";
// import { Select } from 'chakra-react-select';

const Email = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const initialValues = {
    memberCategory:"",
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    message: ''
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    setError("");
    try {
      const fromData = {
        // memberEmails: values?.to,
        membershipCategory: values?.memberCategory,
        cc: values?.cc,
        bcc: values?.bcc,
        subject: values?.subject,
        message: values?.message
      };
      const response = await dispatch(createMailNotification(fromData));
      if (response?.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(setloadTost({ title: 'Email Sent', description: 'Email sent successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        actions.resetForm(); // Reset the form fields
      } else {
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to send email");
      }
    } catch (error) {
      setError(error?.payload?.message || "Failed to send email, please check your network");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={emailFormSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({
          errors, touched, values, handleBlur, handleChange, setFieldValue
        }) => (
          <Form>
            <Box bgColor={'white'} p={3} px={5}>
              <Text fontSize={'18px'} fontWeight={700} px={5} py={5} color={'#D11A2A'} opacity={'50%'}>Send Email</Text>
              {/* <FormControl mt={4} isInvalid={errors.to && touched.to} color="#121212">
                <FormLabel htmlFor='to' px={5} fontSize={'16px'} color={'#222B45'} opacity={'0.5'} fontWeight={700}>TO</FormLabel>
                <ReactMultiEmail
                  style={{ backgroundColor: "#F9F8F8", borderRadius: "5px", borderColor: "#DADADA", color: "#B4B1B1", fontSize: "16px" }}
                  _placeholder={{ color: "#1C1C1C33", backgroundColor: "#F9F8F8", fontSize: "16px", lineHeight: '24.46px', fontWeight: "400" }}
                  emails={values.to}
                  onChange={(emails) => setFieldValue('to', emails)}
                  validateEmail={email => isEmail(email)}
                  getLabel={(email, index, removeEmail) => (
                    <Box data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                    </Box>
                  )}
                  placeholder="Add Contacts"
                />
                {touched.to && errors.to && (
                  <FormErrorMessage>{errors.to}</FormErrorMessage>
                )}
              </FormControl> */}
               <FormControl mt={4} isInvalid={errors.memberCategory && touched.memberCategory} color="#121212">
                  <FormLabel htmlFor='memberCategory' fontSize={'16px'}>To</FormLabel>
                  <Select
                      name="memberCategory"
                      placeholder='Select Member Category'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bg={'#F3F3F3'}
                      // borderRadius={"0"}
                      focusBorderColor="#ffffff"
                      borderColor={"#FFFFFF"}
                      _hover={{ borderColor: "#FFFFFF" }}
                      _placeholder={{ color: "#B4B1B1" }}
                      value={values?.memberCategory}
                  >
                    <option value='Student'>Student</option>
                    <option value='NYSC'>NYSC</option>
                    <option value='Graduate'>Graduate</option>
                    <option value='Associate'>Associate</option>
                    <option value='Fellow'>Fellow</option>
                    <option value='Retiree'>Retiree</option>
                  </Select>
                  {touched.memberCategory && errors.memberCategory && (
                      <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                  )}
              </FormControl>
              <Box p={3} px={5} border={'1px solid #DADADA'} borderRadius={'5px'} marginTop={10}>
                <Text color={'#222B45'} opacity={'0.5'} fontWeight={700} fontSize={'18px'}>Compose Email</Text>
                <FormControl mt={4} isInvalid={errors.cc && touched.cc} color="#121212">
                  <FormLabel htmlFor='cc' fontSize={'16px'}></FormLabel>
                  <ReactMultiEmail
                    style={{ backgroundColor: "#F9F8F8", borderRadius: "5px", borderColor: "#DADADA", color: "#B4B1B1", fontSize: "16px" }}
                    _placeholder={{ color: "#1C1C1C33", backgroundColor: "#F9F8F8", fontSize: "16px", lineHeight: '24.46px', fontWeight: "400" }}
                    emails={values.cc}
                    onChange={(emails) => setFieldValue('cc', emails)}
                    validateEmail={email => isEmail(email)}
                    getLabel={(email, index, removeEmail) => (
                      <Box data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                      </Box>
                    )}
                    placeholder="cc"
                  />
                  {touched.cc && errors.cc && (
                    <FormErrorMessage>{errors.cc}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.bcc && touched.bcc} color="#121212">
                  <FormLabel htmlFor='bcc' fontSize={'16px'}></FormLabel>
                  <ReactMultiEmail
                    style={{ backgroundColor: "#F9F8F8", borderRadius: "5px", borderColor: "#DADADA", color: "#B4B1B1", fontSize: "16px" }}
                    _placeholder={{ color: "#1C1C1C33", backgroundColor: "#F9F8F8", fontSize: "16px", lineHeight: '24.46px', fontWeight: "400" }}
                    emails={values.bcc}
                    onChange={(emails) => setFieldValue('bcc', emails)}
                    validateEmail={email => isEmail(email)}
                    getLabel={(email, index, removeEmail) => (
                      <Box data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                      </Box>
                    )}
                    placeholder="bcc"
                  />
                  {touched.bcc && errors.bcc && (
                    <FormErrorMessage>{errors.bcc}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.subject && touched.subject} color="#121212">
                  <FormLabel htmlFor='subject' fontSize={'16px'}></FormLabel>
                  <Input
                    placeholder="Subject"
                    fontSize={"small"}
                    bg={'#F9F8F8'}
                    name="subject"
                    focusBorderColor="#ffffff"
                    borderRadius={'5px'}
                    borderColor={"#DADADA"}
                    _placeholder={{ color: "#1C1C1C33", backgroundColor: "#F9F8F8", fontSize: "16px", lineHeight: '24.46px', fontWeight: "400" }}
                    _hover={{ borderColor: "#DADADA" }}
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.subject}
                  />
                  {touched.subject && errors.subject && (
                    <FormErrorMessage>{errors.subject}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.message && touched.message} color="#121212">
                  <FormLabel htmlFor='message' fontSize={'16px'}></FormLabel>
                  <Textarea
                    minH={'200px'}
                    placeholder="Message"
                    fontSize={"small"}
                    bg={'#F9F8F8'}
                    name="message"
                    focusBorderColor="#ffffff"
                    borderRadius={'5px'}
                    borderColor={"#DADADA"}
                    _placeholder={{ color: "#1C1C1C33", backgroundColor: "#F9F8F8", fontSize: "16px", lineHeight: '24.46px', fontWeight: "400" }}
                    _hover={{ borderColor: "#DADADA" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.message}
                  />
                  {touched.message && errors.message && (
                    <FormErrorMessage>{errors.message}</FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Flex justifyContent={'end'} alignItems={"center"} gap={5}>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={loading}
                  loadingText="Sending Email..."
                >
                  Send Email
                </Button>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={""}
                  _hover={{ background: " " }}
                  color={"#8F9BB3"}
                  onClick={() => {
                    dispatch(setFormModal({ status: false, data: null }));
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Email;
