import React, { useCallback, useEffect, useState } from "react";
import { Alert, AlertIcon, AlertTitle, Avatar, Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

const EventDetails = ({ showAttendees = true }) => { // Add a prop to control attendee visibility
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const eventDetailsData = useSelector((state) => state?.event?.eventDetails);

    const getAttendees = useCallback(async () => {
        setError("");
        try {
            const response = await httpRequest(
                `${BASE_URL}/v2/Event/${eventDetailsData?.id}/attendees`
            );
            console.log(response);
        } catch (error) {
            setError(error?.payload?.message || "Failed to fetch attendees, please check your network");
        }
    }, [eventDetailsData?.id]);

    useEffect(() => {
        getAttendees();
    }, [dispatch, getAttendees]);

    const list = [
        { title: 'Description:', description: eventDetailsData?.description },
        { title: 'Start Date:', description: eventDetailsData?.startDate && format(new Date(eventDetailsData?.startDate), 'yyyy-MM-dd') },
        { title: 'End Date:', description: eventDetailsData?.endDate && format(new Date(eventDetailsData?.endDate), 'yyyy-MM-dd') },
        { title: 'Registration Link:', description: eventDetailsData?.registrationLink },
        { title: 'Guest Speaker:', description: eventDetailsData?.guestSpeaker },
        { title: 'Event Location:', description: eventDetailsData?.eventLocation },
        { title: 'Status:', description: eventDetailsData?.status },
    ];

    const list4 = [
        { attendeesName: 'Raphael Treasure', dateRegistered: '04-08-2023', attendeeType: 'Student' },
        { attendeesName: 'Emma Grace', dateRegistered: '04-08-2023', attendeeType: 'Student' },
        { attendeesName: 'Fredrick Chuma', dateRegistered: '04-08-2023', attendeeType: 'Member' },
        { attendeesName: 'Ojo Adebola', dateRegistered: '04-08-2023', attendeeType: 'Non-member' },
        { attendeesName: 'Raphael Treasure', dateRegistered: '04-08-2023', attendeeType: 'Non-member' },
    ];

    return (
        <>
            {error && (
                <Alert status="error" className="mt-3" mb={3}>
                    <AlertIcon />
                    <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
            )}
            <Box bgColor={'white'} h={'100%'}>
                <Flex justifyContent={'flex-start'} pl={'50px'}
                    alignItems={'center'} mx={'-24px'}
                    h={'80px'} bgColor={'#EFC3CD'} mb={'30px'}>
                    <Text
                        color={'#D11A2A'}
                        fontSize={['18px', '20px', '24px']}
                        fontWeight={'600'}
                        pl={['2px', null, null]}
                    >
                        {eventDetailsData?.eventName}
                    </Text>
                </Flex>
                {list?.map((item, index) => (
                    <Flex key={index} alignItems={'center'} px={['', '15px', '20px']} pb={5} gap={['3', null, null]}>
                        <Text color={'rgba(81, 81, 101, 0.5)'} fontSize={'16px'} fontWeight={700} w={['40%', '10%', '15%']}>
                            {item.title}
                        </Text>
                        <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'14px'} fontWeight={700} w={['70%', '100%', '85%']}>
                            {item.description}
                        </Text>
                    </Flex>
                ))}
                <Divider border={'1px solid #B4B1B1'} mt={10} />
                {showAttendees && ( // Conditionally render attendees section
                    <>
                        <Text color={'#D11A2A'} fontSize={'20px'} fontWeight={600} pl={'50px'} mt={'20px'} pb={'30px'}>
                            Attendees
                        </Text>
                        <Flex alignItems={'center'} gap={['75px', '90px', '250px']} px={'20px'} pt={'10px'} pb={'10px'} mb={'10px'} bgColor={'#F9F8F8'} mx={'-24px'}>
                            <Text pl={12} fontSize={'14px'} color={'#222B45'} fontWeight={700}>Name</Text>
                            <Text fontSize={'14px'} color={'#222B45'} fontWeight={700}>Date Registered</Text>
                        </Flex>
                        {list4?.map((item, index) => (
                            <Flex key={index} alignItems={'center'} px={'20px'} pb={5} gap={[5, null, null]}>
                                <Avatar name='Ryan Florence' size={"xs"} src='https://bit.ly/ryan-florence' />
                                <Flex flexDirection={'column'} w={'30%'} justifyContent={'center'} mt={'20px'} ml={[null, null, 1]}>
                                    <Text color={'rgba(30, 30, 30, 0.5)'} fontSize={'16px'} fontWeight={700}>
                                        {item.attendeesName}
                                    </Text>
                                    <Text fontSize={'12px'} color={'rgba(113, 113, 113, 0.5)'} fontWeight={700}>
                                        {item.attendeeType}
                                    </Text>
                                </Flex>
                                <Text color={'rgba(0, 0, 0, 0.5)'} fontSize={'14px'} fontWeight={700} ml={[null, '40px', '-2px']}>
                                    {item.dateRegistered}
                                </Text>
                            </Flex>
                        ))}
                    </>
                )}
            </Box>

        </>
    );
};

export default EventDetails;
