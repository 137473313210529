import React from 'react';
import { Box, Flex, Text, Select, FormControl, Button } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { PiExport } from 'react-icons/pi';

function MemberMgtDuesTable(memberDuesReport) {
  console.log("memberDuesReport", memberDuesReport)
    const tableProps = {
        data: memberDuesReport?.memberDuesReport,

        title: {
            membershipNo: 'Membership No',
            fullName: "Full Name",
            memberCategory: "Member Category",
            feeDescription: "Fee Description",
            amountPaid: "Anount Paid",
            amountOwed: "Amount Owed",
            total: 'Total Amount'
        },
        dataKeys: [
            "membershipNo",
            "fullName",
            "memberCategory",
            "feeDescription",
            "amountPaid",
            "amountOwed",
            "total",
        ],
        transformRow: (item) => ({
            membershipNo: <Box style={{ cursor: 'pointer' }}>{item?.membershipNumber}</Box>,
            fullName: <Box style={{ cursor: 'pointer' }}>{item?.fullName}</Box>,
            memberCategory: <Box style={{ cursor: 'pointer' }}>{item?.memberCategory}</Box>,
            feeDescription: <Box style={{ cursor: 'pointer' }}>{item?.feeDescription}</Box>,
            amountPaid: <Box style={{ cursor: 'pointer' }}>{item?.amountPaid}</Box>,
            amountOwed: <Box style={{ cursor: 'pointer' }}>{item?.amountOwed}</Box>,
            total: <Box style={{ cursor: 'pointer' }}>{item?.totalAmount}</Box>,
          }),
    };
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
          <Text fontSize={'20px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'} >Member Management Dues Report</Text>
          <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
            <Flex alignItems={'center'}>
              <FormControl w={'100px'}>
                <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </Select>
              </FormControl>
            </Flex>
            <Button leftIcon={<PiExport style={{ width: "15px", height: "15px" }} />} bg={'transparent'} _hover={{ backgroundColor: "transparent" }} _active={{ backgroundColor: "transparent" }} color={'#404040'}
              fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'}
            >
              Export
            </Button>
          </Flex>
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
  )
}

export default MemberMgtDuesTable