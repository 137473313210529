import React, { useCallback, useEffect, useState }  from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Alert,AlertIcon, AlertTitle  } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { setFormModal } from '../redux/slices/modalSlice';
import Create from '../components/layout/Create';
import CustomTable from '../components/CustomTable';
import { checkPermissions} from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import {fetchDepartment, setEditDepartment} from '../redux/slices/departmentMgtSlice';
import DotLoader from '../components/loaders/DotLoader';
import { BASE_URL } from "../config/settings";
import {setloadTost} from "../redux/slices/toastSlice";
import httpRequest from "../utils/httpRequest";

function DepartmentManagement() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllDepartments = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchDepartment());
      // console.log('examCentre response', response);
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Department, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get Department, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllDepartments();
  }, [dispatch, getAllDepartments]);

  const addDepartmentPermission = checkPermissions( 'Department Management', 'Department Management', 'Create', 'Granted' );
  const editDepartmentPermission = checkPermissions( 'Department Management', 'Department Management', 'Edit', 'Granted' );
  const deleteDepartmentPermission = checkPermissions( 'Department Management', 'Department Management', 'Delete', 'Granted' );
  const departmentDate = useSelector((state) => state?.departmentMgt?.department?.data);

  const createData = {
    onClick: () => { dispatch(setFormModal({ status: true, title: "Add Department", type: "addDepartment", })); },
    createText: "Add Department",
  }
  const handleEdit = (item) => {
    dispatch(setEditDepartment(item));
    dispatch(setFormModal({status:true, title: "Edit Department", type:"editDepartment"}));
  }

  const handleDelete = async (item) => {
    // console.log(item);
    setError('');
   try {
      const response = await httpRequest(`${BASE_URL}/v2/Department/${item?.id}/delete`,
      'post', 
    );
    // console.log(response);
    if(response?.isSuccess === true){
      setError('');
      dispatch(fetchDepartment());
      dispatch(setloadTost({ title: 'Department Deleted', description: `${item?.name}  Deleted successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
   }else{
      setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to delete Department");
    }
    
   } catch (error) {
    setError(error?.response?.message || "Failed to delete Department");
   }

}

  const tableProps = {
    data: departmentDate,
    title: {
      departmentName: "Department Name",
      departmentCode: "Department Code",
      deptStatus: "Status",
      Details: "",
    },
    dataKeys: [
      "departmentName",
      "departmentCode",
      "deptStatus",
      "Details",
    ],
    transformRow: (item) => ({
      departmentName: item && <Box>{item?.name}</Box>,
      departmentCode: item && <Box>{item?.code}</Box>,
      deptStatus: item && <Box color={item?.isActive ? "#3064FC" : "#D11A2A"} >{item?.isActive ? "Active" : "Inactive"}</Box>,
      Details: item && (
        <Menu px={'10px'}>
          {
            !editDepartmentPermission && !deleteDepartmentPermission ? null : (
              <MenuButton>
                <BsThreeDotsVertical />
              </MenuButton>
            ) 
          }
          <MenuList>
            {
              editDepartmentPermission ? (
                <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
              ) : null
            }
            {
              deleteDepartmentPermission ? (
                <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
              ) : null
            }
          </MenuList>
        </Menu>
      ),
    }),
  };

  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Department Management</Text>
        <Flex flexDirection={["column", "column", "row"]} gap={[3, null, null]}  justifyContent={'center'} alignItems={'center'} >
          {
            addDepartmentPermission ? (
              <Create {...createData} />
            ) : null
          }
          <SortBy />
        </Flex>
    </Flex>
    {
      loading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <DotLoader />
        </Flex>
      ): (
        <>
          {
          error ? (
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            ) 
            : (<CustomTable {...tableProps} />)
          }
        </>
      )
    }
</Box>
  )
}

export default DepartmentManagement