import React, { useState } from 'react';
import { Box, Button, Flex, Image, ListItem, OrderedList, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import interLogo from "../Assets/Images/interswitch_group_logo.svg.svg";
import paystackLogo from "../Assets/Images/paystackLogo.svg";
import remitalogo from "../Assets/Images/remita-logo.svg";
import { setFormModal } from '../redux/slices/modalSlice';
import { setloadTost } from '../redux/slices/toastSlice';
import {makePayment} from "../components/RemitaPayment";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import {setPaymentConfirmation} from  "../redux/slices/studentApplicationApprovalSlice";

const MemberProfilePaymentModal = () => {
    const dispatch = useDispatch();
    const paymentDetailsData = useSelector((state) => state?.myDues?.myDuesDetails);
    const paymentTypeData = [
        { title: "Remita", logo: remitalogo },
        { title: "Interswitch", logo: interLogo },
        { title: "Paystack", logo: paystackLogo }
    ]

    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const handlePaymentOptionChange = (value) => {
        setSelectedPaymentOption(value);
    };

    const successFunc = async(referenceId) => {
        try {
            const response = await httpRequest(`${BASE_URL}/v2/Payment/confirm-remita-payment/${referenceId}`, "post" );
            if(!response?.isSuccess === true) {
                dispatch(setloadTost({ title: 'Error', description: `${response?.response?.data?.error?.description} `, status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
            }else{
                dispatch(setloadTost({ title: 'Confirmation', description: `Verified Successfully `, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
                dispatch(setPaymentConfirmation(true));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmitPayment = () => {
        if (selectedPaymentOption !== null) {
            if(selectedPaymentOption === "Remita") {
                makePayment({orderId: paymentDetailsData?.orderId, referenceId: paymentDetailsData?.referenceId, successFunc: successFunc});
                dispatch(setFormModal({ status: false, data: null }));
            }
        }else {
            dispatch(setloadTost({ title: 'Empty Field', description: `Please select a payment option `, status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
        }
    }

    return (
        <Box py={'20px'} minH={'100vh'} maxWidth={'auto'}>
            <Box mt={5}>
                <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={['20px', '40px', '40px']} py={'20px'}>
                    <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >Please Note</Text>
                </Flex>
                <Box bgColor={'#FFFFFF'} px={['20px', '40px', '40px']} py={'30px'}>
                    <Flex flexDirection={['column', 'column', 'column', 'row']} gap={['20px', '20px', '20px', '150px']}>
                        <Flex width={['100%', '100%', '100%', '30%']}>
                            <OrderedList fontSize={'13px'} fontWeight={'400'} lineHeight={'19.88px'} color={'#222B45'}>
                                <ListItem mb={4}>
                                    Please ensure your card is enabled for online payment by your bank before proceeding
                                </ListItem>
                                <ListItem mb={4}>
                                    A transaction may fail due to insufficient funds, wrong credentials, card being blacklisted at issuer bank or network failure.
                                    In the event of a network failure, kindly check with your bank for any debits, before attempting again
                                </ListItem>
                                <ListItem mb={4}>
                                    For Visa Card kindly use Remita Platform.
                                </ListItem>
                            </OrderedList>
                        </Flex>
                        <Flex flexDirection={'column'} width={['100%', '100%', '100%', '70%']} gap={4} >
                            <Text textAlign={'center'} color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'}>Use any of the options below to test the payment flow</Text>
                            <RadioGroup value={selectedPaymentOption} onChange={handlePaymentOptionChange}>
                                {paymentTypeData && paymentTypeData?.map((item, index) => (
                                    <Flex key={index} justifyContent={'space-between'} alignItems={'center'} gap={5} p={5} w={'full'} border={'1px solid #C4C4C4'} borderRadius={'5px'} mb={4}>
                                        <Flex alignItems={'center'} gap={5} width={'full'}>
                                            <Radio value={item.title} colorScheme='green'></Radio>
                                            <Text color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'} >{item?.title}</Text>
                                        </Flex>
                                        <Image src={item?.logo} alt='payment logo' width={['150px', '200px', '300px', '100px']} h={'40px'} />
                                    </Flex>
                                ))}
                            </RadioGroup>
                            <Button bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'} borderRadius={'10px'}
                                _hover={{ backgroundColor: "" }} _active={{ backgroundColor: "" }} onClick={handleSubmitPayment}
                            >
                                Pay 
                            </Button>
                            <Text textAlign={'center'} color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'}>Use another card</Text>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}

export default MemberProfilePaymentModal;