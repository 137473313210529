import { Box } from '@chakra-ui/react';
import React from 'react'
import CustomTable from './CustomTable';
import { paymentTypeReport } from '../data/mock';

function EventApplicationFees() {
    const tableProps = {
        data: paymentTypeReport,
        title: {
            fullName: 'Applicant Full Name',
            phoneNumber: "Phone Number",
            emailAddress: "Email Address",
            dateApplied: 'Date Applied',
            paymentStatus: 'Payment Status',
        },
        dataKeys: [
            "fullName",
            "phoneNumber",
            "emailAddress",
            "dateApplied",
            "paymentStatus",
        ],
        transformRow: (item) => ({
            fullName: <Box style={{ cursor: 'pointer' }}>{item.fullName}</Box>,
            phoneNumber: <Box style={{ cursor: 'pointer' }}>{item.phoneNumber}</Box>,
            emailAddress: <Box style={{ cursor: 'pointer' }}>{item.emailAddress}</Box>,
            dateApplied: <Box style={{ cursor: 'pointer' }}>{item.dateApplied}</Box>,
            paymentStatus: <Box style={{ cursor: 'pointer' }}>{item.paymentStatus}</Box>,
        }),
    };
    return (
        <Box>
            <CustomTable {...tableProps} />
        </Box>
    )
}

export default EventApplicationFees