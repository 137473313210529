import React, { useState } from "react";
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import LoginImage from "../components/LoginImage";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate,useSearchParams} from "react-router-dom";
import { setloadTost } from '../redux/slices/toastSlice';
import {resetPassword} from '../redux/slices/authSlice';
import { useDispatch } from "react-redux";

const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const caId = searchParams.get("caId");
  const token = searchParams.get("token");
  
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const newPasswordSchema = yup.object().shape({
    password: yup.string().required("You must enter password"),
    confirmPassword: yup
      .string()
      .required("Please retype your password.")
      .oneOf([yup.ref("password")], "Your passwords do not match."),
  });

  const handleSubmit = async (values) => {
    console.log("values", values);
    const formData = {
      id: caId,
      token: token,
      password: values?.password,
    }
    console.log("formData", formData);
    const response = await dispatch(resetPassword(formData));
    console.log("response", response);
    if (response?.payload?.isSuccess === true) {
      dispatch(setloadTost({ title: 'Successs', description: `Password reset successfully, please Login`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      navigate("/");
    }else{
      dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to reset password"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
    }
  };

  return (
    <Flex w="full" h="100vh" overflow={"hidden"}>
      <LoginImage />
      <Flex h={"full"} w="full" justify={"center"} position={"relative"} alignItems={'center'}>
        <Flex w={["90%", "80%", "90%", "80%"]}>
          <Flex
            direction={"column"}
            bg={"white"}
            width={"full"}
            px={"1.5rem"}
            py="2rem"
          >

            <BiArrowBack
              size={"1.5rem"}
              style={{ marginBottom: "2rem", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />

            <Flex justify={"space-between"} direction={"column"} mb="1.5rem">
              <Text fontWeight={"700"} fontSize={"1.8rem"}>
                Reset Password
              </Text>
              <Text fontSize={"sm"}>
                Enter a unique password to secure your account
              </Text>
            </Flex>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={newPasswordSchema}
              onSubmit={(values) => handleSubmit(values)}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors, touched, handleBlur, handleChange, isSubmitting, }) => (
                <Form mt={4}>
                  <FormControl
                    isInvalid={errors.password && touched.password}
                    mb={5}
                    color="black"
                  >
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="lg">
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your new password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        bgColor={"#F3F3F3"}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          background={"none"}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <BsEye w={5} h={5} color="GrayText" />
                          ) : (
                            <BsEyeSlash color={"GrayText"} w={5} h={5} />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {touched.password && errors.password && (
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={
                      errors.confirmPassword && touched.confirmPassword
                    }
                    mb={5}
                    color="black"
                  >
                    <FormLabel>Confirm Password</FormLabel>
                    <InputGroup size="lg">
                      <Input
                        pr="4.5rem"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm your new password"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        bgColor={"#F3F3F3"}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          background={"none"}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? (
                            <BsEye w={5} h={5} color="GrayText" />
                          ) : (
                            <BsEyeSlash color={"GrayText"} w={5} h={5} />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormErrorMessage>
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <Button
                    isLoading={isSubmitting}
                    color={"white"}
                    bgColor={"#D11A2A"}
                    borderRadius="8px"
                    mt={"4rem"}
                    mb="1rem"
                    w={"full"}
                    type="submit"
                    _hover={{backgroundColor: ""}}
                  >
                    Send
                  </Button>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
      </Flex>

    </Flex>
  );
};

export default NewPassword;
