import React from 'react';
import { Box, Checkbox, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Flex, Button, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

const AddRolePermission = ({ permissions, isAllPermissionsGranted, handleResourceCheckboxChange, handlePermissionChange, handleSaveChanges, loading, error }) => {
    const errorsArray = Array.isArray(error) ? error : [error];

    return (
        <Box pb={8} maxH={'85vh'}>
            {
              error && (
                <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
              )
            }
            <Text color={'#898989'} fontFamily={'inter'} fontSize={'16px'} fontWeight={'400'} pt={2} pb={10}>
              This role has the following permissions
            </Text>
            <Flex justifyContent={'flex-end'}>
              <TableContainer >
                <Table>
                <Thead>
                  <Tr>
                    <Th style={{ border: 'none' }}></Th>
                    <Th style={{ border: 'none' }} px={['15px', '22px', '22px']}>View</Th>
                    <Th style={{ border: 'none' }} px={['15px', '22px', '22px']} >Create</Th>
                    <Th style={{ border: 'none' }} px={['15px', '22px', '22px']}>Edit</Th>
                    <Th style={{ border: 'none' }} px={['15px', '22px', '22px']}>Delete</Th>
                  </Tr>
                </Thead>
                </Table>
              </TableContainer>
            </Flex>
            <Box overflowY="auto" maxH="calc(80vh - 200px)"> 
              <TableContainer>
                <Table >
                  {/* <Thead display={{ base: 'flex', sm: 'flex', md: 'none', }} >
                    <Tr>
                      <Th style={{ border: 'none' }}></Th>
                      <Th style={{ border: 'none' }}>View</Th>
                      <Th style={{ border: 'none' }}>Create</Th>
                      <Th style={{ border: 'none' }}>Edit</Th>
                      <Th style={{ border: 'none' }}>Delete</Th>
                    </Tr>
                  </Thead> */}
                  <Tbody>
                    {Object.keys(permissions).map(module => (
                      <React.Fragment key={module}>
                        <Tr>
                            <Td style={{ border: 'none' }}>{module}</Td>
                        </Tr>
                        {permissions[module]?.submenu && Object.keys(permissions[module].submenu).map(resource => (
                          <Tr key={`${module}-${resource}`}>
                            <Td style={{ border: 'none', paddingLeft: '30px' }}>
                              <Checkbox
                                size='md'
                                colorScheme='red'
                                isChecked={isAllPermissionsGranted(module, resource)}
                                onChange={() => handleResourceCheckboxChange(module, resource)}
                              >
                                {resource}
                              </Checkbox>
                            </Td>
                            {['view', 'create', 'edit', 'delete'].map(action => (
                                <Td key={action} style={{ border: 'none' }}>
                                  <Checkbox
                                    size='md'
                                    colorScheme='red'
                                    isChecked={permissions[module]?.submenu?.[resource]?.[action] === 'granted'}
                                    onChange={() => handlePermissionChange(module, resource, action)}
                                  />
                                </Td>
                            ))}
                          </Tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Flex my={5} justifyContent={'flex-end'} alignContent={'center'}>
                <Button borderRadius={"3px"} bg={"#D11A2A"} color="white" px={5} _hover={{ backgroundColor: "" }} _active={{ backgroundColor: "" }}
                    onClick={handleSaveChanges}
                    isLoading={loading}
                    loadingText="Saving ..."
                >Save Changes</Button>
            </Flex>
            {/* <Text>Selected Permissions: {JSON.stringify(permissions)}</Text> */}
        </Box>
    )
}

export default AddRolePermission;
