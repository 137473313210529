import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  approvalWorkflow: [],
  editApprovalWorkflow: "",
};

export const fetchApprovalWorkflow = createAsyncThunk(
  "approvalWorkflow/fetchApprovalWorkflow",
  async () => {
    return httpRequest(`${BASE_URL}/v2/ApprovalWorkflow`);
  }
);

export const createApprovalWorkflow = createAsyncThunk(
  "approvalWorkflow/createApprovalWorkflow",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/ApprovalWorkflow`,
        "post",
        arg,
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


const approvalWorkFlowSlice = createSlice({
  name: "approvalWorkflow",
  initialState,
  reducers: {
    setEditApprovalWorkflow: (state, { payload }) => {
      state.editApprovalWorkflow = payload;
    },

  },
  extraReducers: (builder) => {
    // get approval workflow:
    builder.addCase(fetchApprovalWorkflow.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchApprovalWorkflow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.approvalWorkflow = payload;
      state.error = null;
    });

    builder.addCase(fetchApprovalWorkflow.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // create approval workflow:
    builder.addCase(createApprovalWorkflow.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createApprovalWorkflow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createApprovalWorkflow.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

  }
});

export const { setEditApprovalWorkflow } = approvalWorkFlowSlice.actions;
export default approvalWorkFlowSlice.reducer;