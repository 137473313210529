import React, { useState } from 'react';
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Flex, Textarea, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { addEventSchema } from '../../utils/formValidationSchema';
import { setFormModal } from '../../redux/slices/modalSlice';
import {  fetchEvent } from '../../redux/slices/eventSlice';
import httpRequest from '../../utils/httpRequest';
import { BASE_URL } from '../../config/settings';
import { setloadTost } from '../../redux/slices/toastSlice';
import { format } from 'date-fns';

function EditEventForm() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    const editEvent = useSelector((state) => state?.event?.editEvents);

    // console.log(editEvent)
    const initialValues = {
        eventName: editEvent?.eventName || '',
        description: editEvent?.description || '',
        registrationLink: editEvent?.registrationLink || '',
        startDate:  editEvent?.startDate ? format(new Date(editEvent?.startDate), 'yyyy-MM-dd')  : '',
        endDate:  editEvent?.endDate ? format(new Date(editEvent?.endDate), 'yyyy-MM-dd') : '',
        eventLocation: editEvent?.eventLocation || '',
        guestSpeaker: editEvent?.guestSpeaker || '',
        assignCreditPoint: editEvent?.creditPoint || '',
    };
    const handleSubmit = async (values) => {
        setLoading(true);
        setError("");
        try {
            const formData = {
                eventName: values?.eventName,
                eventDescription: values?.description,
                registrationLink: values?.registrationLink,
                startDate: values?.startDate,
                endDate: values?.endDate,
                location: values?.eventLocation,
                guestSpeaker: values?.guestSpeaker,
                creditPoint: values?.assignCreditPoint,
                isActive: true,
            };
            const response = await httpRequest(
                `${BASE_URL}/v2/Event/${editEvent?.id}/update`,
                "post",
                formData
            );

            // console.log(response);
            if (response?.isSuccess === true) {
                setLoading(false);
                dispatch(fetchEvent());
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Event edited', description: `${values?.eventName} event name has been created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            } else {
                setLoading(false);
                setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to edit event");
            } 

        } catch (error) {
            setError(error?.payload?.message || "Failed to edit event, please check your network");
        } finally {
            setLoading(false);
        }


    }
    return (
        <>
            {
                error && (
                    <Alert status="error" className="mt-3" mb={3}>
                        <AlertIcon />
                        <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                    </Alert>
                )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={addEventSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <FormControl mt={4} isInvalid={errors.eventName && touched.eventName} color="#121212">
                            <FormLabel htmlFor='eventName' fontSize={'16px'}>Event Name</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="eventName"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.eventName}
                            />
                            {touched.eventName && errors.eventName && (
                                <FormErrorMessage>{errors.eventName}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.description && touched.description} color="#121212">
                            <FormLabel htmlFor='description' fontSize={'16px'}>Description</FormLabel>
                            <Textarea
                                placeholder="Enter description"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="description"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.description}
                            />
                            {touched.description && errors.description && (
                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.registrationLink && touched.registrationLink} color="#121212">
                            <FormLabel htmlFor='registrationLink' fontSize={'16px'}>Registration Link</FormLabel>
                            <Input
                                placeholder="Add Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="registrationLink"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.registrationLink}
                            />
                            {touched.registrationLink && errors.registrationLink && (
                                <FormErrorMessage>{errors.registrationLink}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.startDate && touched.startDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='startDate' fontSize={"16px"}>Start Date</FormLabel>
                            <Input
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="startDate"
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.startDate}
                            />
                            {touched.startDate && errors.startDate && (
                                <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.endDate && touched.endDate}
                            color="#121212" fontFamily={'inter'}>
                            <FormLabel htmlFor='endDate' fontSize={"16px"}>End Date</FormLabel>
                            <Input
                                type="date"
                                placeholder="Pick a Date"
                                borderRadius={"0"}
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="endDate"
                                cursor={"pointer"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.endDate}
                            />
                            {touched.endDate && errors.endDate && (
                                <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.eventLocation && touched.eventLocation} color="#121212">
                            <FormLabel htmlFor='eventLocation' fontSize={'16px'}>Event Location</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="eventLocation"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.eventLocation}
                            />
                            {touched.eventLocation && errors.eventLocation && (
                                <FormErrorMessage>{errors.eventLocation}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.guestSpeaker && touched.guestSpeaker} color="#121212">
                            <FormLabel htmlFor='guestSpeaker' fontSize={'16px'}>Guest Speaker</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="guestSpeaker"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.guestSpeaker}
                            />
                            {touched.guestSpeaker && errors.guestSpeaker && (
                                <FormErrorMessage>{errors.guestSpeaker}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.assignCreditPoint && touched.assignCreditPoint} color="#121212">
                            <FormLabel htmlFor='assignCreditPoint' fontSize={'16px'}>Assign Credit Point</FormLabel>
                            <Input
                                name="assignCreditPoint"
                                type='number'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.assignCreditPoint}
                            >
                            </Input>
                            {touched.assignCreditPoint && errors.assignCreditPoint && (
                                <FormErrorMessage>{errors.assignCreditPoint}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.amount && touched.amount} color="#121212">
                            <FormLabel htmlFor='amount' fontSize={'16px'}>Amount</FormLabel>
                            <Input
                            placeholder="Enter amount"
                                name="amount"
                                fontSize={"small"}
                                type='number'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                bg={'#F3F3F3'}
                                borderRadius={"0"}
                                focusBorderColor="#ffffff"
                                borderColor={"#FFFFFF"}
                                _hover={{ borderColor: "#FFFFFF" }}
                                _placeholder={{ color: "#B4B1B1" }}
                                value={values?.amount}
                            >
                            </Input>
                            {touched.amount && errors.amount && (
                                <FormErrorMessage>{errors.amount}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Edit Event
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default EditEventForm