import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";
import statusHttpRequest from "../../utils/statusHttpRequest";


const initialState = {
  loading: false,
  error: null,
  registerStudent: [],
  personalInfo: "",
  employmentInfo: "",
  qualificationInfo: "",
  marriageRefInfo: "",
};
  
export const createStudentRegistration = createAsyncThunk(
  "studentRegistration/createStudentRegistration",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Application/student-registration`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createStudentApplicationForm = createAsyncThunk(
  "studentRegistration/createStudentApplicationForm",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Application/student`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editStudentApplicationForm = createAsyncThunk(
  "studentRegistration/createStudentApplicationForm",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Application/student`,
        "put",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchStudentApplication = createAsyncThunk(
  "studentRegistration/fetchStudentApplication",
  async () => {
    return statusHttpRequest(`${BASE_URL}/v2/Application/student`,);
  }
);

const studentRegistrationSlice = createSlice({
    name: "studentRegistration",
    initialState,
    reducers: {
      setPersonalInfo: (state, {payload}) => {
        state.personalInfo = payload;
      },
      setEmploymentInfo: (state, {payload}) => {
        state.employmentInfo = payload;
      },
      setQualificationInfo: (state, {payload}) => {
        state.qualificationInfo = payload;
      },
      setMarriageRefInfo: (state, {payload}) => {
        console.log('payload' ,payload)
        state.marriageRefInfo = payload;
      },
    },

    extraReducers: (builder) => {
        // Register Student:
        builder.addCase(createStudentRegistration.pending, (state) => {
          state.loading = true;
        });
        builder.addCase(createStudentRegistration.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
        });
        builder.addCase(createStudentRegistration.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload?.error?.description;
        });

        // Create Student Application Form:
        builder.addCase(createStudentApplicationForm.pending, (state) => {
          state.loading = true;
        });
        builder.addCase(createStudentApplicationForm.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
        });
        builder.addCase(createStudentApplicationForm.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload?.error?.description;
        });

        // get Student Application Form:
        builder.addCase(fetchStudentApplication.pending, (state) => {
          state.loading = true;
        });
        builder.addCase(fetchStudentApplication.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.registerStudent = payload;
          state.error = null;
        });
        builder.addCase(fetchStudentApplication.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload?.error?.description;
        });
    }

});

export const { setPersonalInfo, setEmploymentInfo, setQualificationInfo, setMarriageRefInfo} = studentRegistrationSlice.actions;
export default  studentRegistrationSlice.reducer;