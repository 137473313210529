import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Badge,
  SimpleGrid,
} from "@chakra-ui/react";

const MemberDuesManagementProfile = () => {
  return (
    <Box bg="white" p={6} borderRadius="lg" boxShadow="md" width="100%">
      <Flex alignItems="center" gap={8}>
        {/* Profile Image */}
        <Box border={"1px solid gray"} p={10} py={"50px"}>
          <Box
            border="4px solid red"
            borderRadius="full"
            overflow="hidden"
            w="150px"
            h="150px"
            flexShrink={0}
          >
            <Image
              src="https://via.placeholder.com/150"
              alt="Profile Picture"
              boxSize="full"
              objectFit="cover"
              mx={"auto"}
            />
          </Box>
        </Box>

        {/* Member Information */}
        <Box border={"1px solid black"} flex="1" p={"12px"}>
          <Box bg="pink.50" borderRadius="md" mb={4} p={4} w={'100%'}>
            <Text fontSize="lg" fontWeight="bold" color="gray.700">
              Member Information
            </Text>
          </Box>

          <SimpleGrid pl={5}>
            <Flex gap={"150px"}>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Member Name
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  John Oladiran
                </Text>
              </Text>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Memebership Type
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  Graduate
                </Text>
              </Text>
              <Badge
                fontSize="lg"
                colorScheme="green"
                px={6}
                py={3}
                borderRadius="md"
                textAlign="center"
                // ml={"90px"}
              >
                45,000
              </Badge>
            </Flex>
            <Flex gap={"120px"} pt={10}>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Last Payment Date
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  10-12-2023
                </Text>
              </Text>
              <Text>
                <Text as="span" color="gray.500" mr={2}>
                  Last Payment Amount
                </Text>
                <br />
                <Text as="span" fontWeight="bold">
                  15,000
                </Text>
              </Text>
            </Flex>
          </SimpleGrid>
        </Box>
      </Flex>
    </Box>
  );
};



export default MemberDuesManagementProfile;
