import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import AdminDashboard from './AdminDashboard';
import { useDispatch } from 'react-redux';
import EducationOMDashboard from './EducationOMDashboard';
import MembershipOMDashboard from './MembershipOMDashboard';
import { checkPermissions } from '../components/CheckPermission';
import { clearsearchFilter } from "../redux/slices/searchFilterSlice";
import { removeUser } from "../redux/slices/authSlice";
import { fetchUsers } from "../redux/slices/userMgtUsersSlice";
import { useNavigate } from 'react-router-dom';
import DotLoader from '../components/loaders/DotLoader';
import FinanceDashboard from './FinanceDashboard';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  if (errorsArray[0] === 'Unauthorize User') {
    dispatch(removeUser());
    localStorage.removeItem("persist:root");
    navigate('/');
  }

  const getAllUser = useCallback(async () => {
    setError("");
    setLoading(true);
    try {
      const response = await dispatch(fetchUsers());
      if (response?.payload?.name === "AxiosError") {
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get users, please check your request/network");
      }

    } catch (error) {
      setLoading(false);
      setError(error?.payload?.message || "Failed to get users, please check your network");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllUser();
  }, [dispatch, getAllUser]);

  const adminDashboard = checkPermissions('Dashboard', 'Admin Dashboard', 'View', 'Granted');
  const educationDashboard = checkPermissions('Dashboard', 'Education Dashboard', 'View', 'Granted');
  const membershipDashboard = checkPermissions('Dashboard', 'Membership Dashboard', 'View', 'Granted');
  const financeDashboard = checkPermissions('Dashboard', 'Finance Dashboard', 'View', 'Granted');

  return (
      <Box py={'30px'} minH={'100vh'}>
        <Flex flexDirection={['column', 'column', 'row']} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
          <Text fontSize={'18px'} fontWeight={'500'} fontFamily={'Roboto'} color={'brand.primary'}>WELCOME</Text>
        </Flex>
        {
          loading ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <DotLoader />
            </Flex>
          ) : (
            <>
              {adminDashboard ? (<AdminDashboard />) : null}
              {educationDashboard ? (<EducationOMDashboard />) : null}
              {membershipDashboard ? (<MembershipOMDashboard />) : null}
              {financeDashboard ? (<FinanceDashboard />) : null}
              {(!adminDashboard && !educationDashboard && !membershipDashboard && !financeDashboard) ? (<AdminDashboard />) : null}
            </>
          )
        }
      </Box>
  )
}

export default Dashboard;