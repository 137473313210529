import React from 'react'
import { Box, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { FaBarcode } from 'react-icons/fa6'
import icsanLogo from "../Assets/Images/icsan-logo.svg";
import profile from "../Assets/Images/student-profile-pics.png";
import { useSelector } from 'react-redux';



function EmembershipCardModal() {
    const personalDetails = useSelector((state) => state.modal.formModal.data)

    const list = [
        { about: 'REG Number:', name: personalDetails?.regNumber },
        { about: 'Email:', name: personalDetails?.email },
        { about: 'Phone Number:', name: personalDetails?.phoneNumber },
        { about: 'Date of Birth:', name: personalDetails?.dob },
    ]
    return (
        <>
            <Box mx={'-24px'} >
                <Box bgColor={'#EFC3CD'} >
                    <Image src={icsanLogo} alt="ICSAN Logo" pl={5} pt={5}/>
                </Box>
                <svg width="448" height="155" viewBox="0 0 416 191" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M202.239 166.761C85.7276 166.163 -50.0536 207.936 -133.556 170.435C-216.919 132.995 -150.497 70.6631 -123.83 20.3201C-104.644 -15.899 -64.8961 -46.8672 -5.00321 -72.2456C54.5306 -97.4719 123.816 -121.289 207.009 -119.866C290.143 -118.445 361.414 -93.4938 416.888 -65.0328C469.447 -38.0679 485.329 -4.09485 500.505 30.7106C521.846 79.6549 597.467 138.926 521.024 173.795C444.771 208.577 312.708 167.328 202.239 166.761Z" fill="#EFC3CD" stroke="#D11A2A" stroke-width="5" />
                </svg>
                <Flex justifyContent={'center'} alignItems={'center'} mt={'-70px'}>
                    <Image src={profile} alt='Profile Pics' rounded={'full'} width={'93px'} height={'93px'} overflow={'hidden'} objectFit={'cover'} border={'5px solid white'} />
                </Flex>
            </Box>
            <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} mb={5} mt={'10px'}>
                <Flex gap={1}>
                    <Text fontSize={'20px'} fontWeight={700} color={'rgba(30, 30, 30, 0.5)'}>{personalDetails?.memberName}</Text>
                    {/* <Text fontSize={'20px'} fontWeight={700} color={'rgba(209, 26, 42, 0.5)'}>Oladiran</Text> */}
                </Flex>
                <Text fontSize={'13px'} fontWeight={500} color={'rgba(30, 30, 30, 0.5)'}>Associate</Text>
            </Flex>
            {
                list?.map((item, index) => (
                    <Flex key={index} alignItems={'center'} pb={5} p={3}>
                        <Text w={'40%'} color={'#D11A2A'} opacity={'0.5'} fontSize={'16px'} fontWeight={700}>{item.about}</Text>
                        <Text w={'60%'} color={'#1E1E1E'} opacity={'0.5'} fontSize={'14px'} fontWeight={500}>{item.name}</Text>
                    </Flex>
                ))
            }
            <Divider border={'1px solid #B4B1B1'} w={'100%'} mt={5} />

            <Flex mt={5} w={'900px'} gap={'3px'}>
                <FaBarcode /> <FaBarcode /> <FaBarcode /> <FaBarcode /> <FaBarcode />
            </Flex>

            <Flex bgColor={'#EFC3CD'} mx={'-24px'} height={'40px'} borderRadius={7} alignItems={'center'} justifyContent={'center'} mb={'-50px'} mt={7} color={'rgba(18, 18, 18, 0.5)'} fontSize={'12px'} fontWeight={'700'}>
                <Text>The person here identified is a registered member of ICSAN</Text>
            </Flex>
        </>
    )
}

export default EmembershipCardModal