import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import DotLoader from "./loaders/DotLoader";
import { useSelector } from "react-redux";
import Pagination from "./layout/Pagination";

const CustomTable = ({
  sn = true,
  data,
  dataKeys,
  transformRow,
  theadBg,
  pageNumber,
  pageCount,
  title,
  isloading,
  variant = true,
  onClickRowAction,
  showPagination = true
}) => {
  let initialCount =
    pageNumber && pageCount ? pageNumber * pageCount - pageCount : 0;

  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const searchTerm = useSelector((state) => state?.searchFilter?.searchData);
  const filterTerm = useSelector((state) => state?.searchFilter?.filterData);
  const itemsPage = useSelector((state) => state?.searchFilter?.itemPerPage);
  const itemsPerPage = itemsPage ? itemsPage : 5;

  useEffect(() => {
    setCurrentPage(1); // Reset current page to 1 whenever search term changes
  }, [searchTerm, itemsPage]);

  const totalPages = showPagination ? Math.ceil(filteredData?.length / itemsPerPage) : Math.ceil(filteredData?.length);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedData = showPagination ? filteredData?.slice(startIndex, endIndex) : filteredData;
  const paginationData = {
    currentPage,
    setCurrentPage,
    totalPages,
    itemsPerPage,
    totalItems: filteredData?.length,
  };

  useEffect(() => {
    setLoading(true);
    const dataArray = Array.isArray(data) ? data : [data];
    if (dataArray) {
      let filtered = dataArray.filter((row) => {
        if (!searchTerm && !filterTerm) {
          setLoading(false);
          setFilteredData(data);
        }
        // Filter based on searchTerm
        if (searchTerm && searchTerm !== "") {
          setLoading(false);
          // Check each field in the row
          for (const field in row) {
            if (Object.hasOwnProperty.call(row, field)) {
              const value = row[field];
              // If the value is an array, check each item in the array
              if (Array.isArray(value)) {
                for (const item of value) {
                  // Check if any field in the nested object contains the searchTerm
                  for (const nestedField in item) {
                    if (Object.hasOwnProperty.call(item, nestedField)) {
                      const nestedValue = item[nestedField];
                      if (
                        nestedValue &&
                        nestedValue
                          .toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return true; // If found, include the row in filtered results
                      }
                    }
                  }
                }
              } else {
                // For non-array values, check if the value contains the searchTerm
                const stringValue = value && value.toString().toLowerCase();
                if (
                  stringValue &&
                  stringValue.includes(searchTerm.toLowerCase())
                ) {
                  return true; // If found, include the row in filtered results
                }
              }
            }
          }
          return false; // If searchTerm not found in any field, exclude the row
        }
        // Filter based on filterTerm
        if (filterTerm && filterTerm !== "") {
          // Check if filterTerm is contained in dataKeys
          setLoading(false);
          // console.log(dataKeys);
          if (!dataKeys.includes(filterTerm)) {
            return false;
          }
          // Check if any row in the column corresponding to filterTerm is empty or null
          const columnValues = row[filterTerm];
          if (
            !columnValues ||
            (Array.isArray(columnValues) && columnValues.length === 0)
          ) {
            return false;
          }
        }
        return true;
      });
      setFilteredData(filtered);
    }
    setLoading(false);
  }, [data, searchTerm, filterTerm, dataKeys, itemsPage]);

  return (
    <>
      <TableContainer className="text-xs font-light"
        sx={{
          overflowY: "scroll",
          scrollbarWidth: "none", 
          msOverflowStyle: "none", 
          "-ms-overflow-style": "none", 
          "&::-webkit-scrollbar": {
              display: "none", 
          },
          }}
          overflowY={"auto"} 
      >
        <Table>
          <Thead background={theadBg ? theadBg : "#D7343914"} width={"100%"}>
            <Tr color="#515151">
              {sn && (
                <Th style={{ maxWidth: 70, paddingLeft: 15 }} color="#515151">
                  s/n
                </Th>
              )}
              {dataKeys && dataKeys?.length > 0  &&
                dataKeys?.map((item, titleKey) => (
                  <Th key={titleKey} color="#515151">
                    {title[item]}
                  </Th>
                ))}
            </Tr>
          </Thead>
          <Tbody>
            {slicedData && slicedData?.length > 0 ? (
              slicedData?.map((row, rowIndex) => {
                const rowObj = transformRow ? transformRow(row) : row;
                initialCount++;
                return (
                  <React.Fragment key={initialCount}>
                    <Tr
                      onClick={onClickRowAction}
                      cursor={onClickRowAction && "pointer"}
                      // _hover={{ backgroundColor: "#F4FFFB" }}
                      bg={variant && rowIndex % 2 === 1 ? "#F9F8F8" : ""}
                    >
                      {sn && (
                        <Td
                          style={{ maxWidth: 70, paddingLeft: 15 }}
                          color={"#515151"}
                          lineHeight={"20px"}
                        >
                          <b>{initialCount}.</b>
                        </Td>
                      )}
                      {Object.keys(rowObj).map((key, columnIndex) => {
                        const cellData = rowObj[key];
                        return (
                          <Td key={`${rowIndex}-${columnIndex}`}>
                            {Array.isArray(cellData)
                              ? cellData.map((item, itemIndex) => (
                                  <div
                                    key={`${rowIndex}-${columnIndex}-${itemIndex}`}
                                  >
                                    {item}
                                  </div>
                                ))
                              : cellData}
                          </Td>
                        );
                      })}
                    </Tr>
                  </React.Fragment>
                );
              })
            ) : loading || isloading? (
              <Tr>
                <Td
                  style={{textAlign: "center" }}
                  colSpan={
                    slicedData && sn
                      ? Object.keys(dataKeys)?.length + 1
                      : Object.keys(dataKeys)?.length
                  }
                >
                  <div className="text-center" style={{ width: "100%" }}>
                    <DotLoader />
                  </div>
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td
                  style={{ color: "red", textAlign: "center" }}
                  colSpan={sn ? dataKeys?.length + 1 : dataKeys?.length}
                >
                  No data found.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {(slicedData && slicedData?.length > 0) && showPagination  ? (
        <Pagination {...paginationData} />
      ) : null}

    </>
  );
};

export default CustomTable;
