import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import MemberMgtDuesTable from "../components/MemberMgtDuesTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberDuesReport } from "../redux/slices/report2Slice";
import { setloadTost } from "../redux/slices/toastSlice";

const MemberDuesMGT = () => {
  const dispatch = useDispatch();
  const memberDuesReport = useSelector((state) => state?.report2);
  console.log("memberDuesReport", memberDuesReport);

  const initialValues = {
    membershipCategory: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values, actions) => {
    const response = await dispatch(
      fetchMemberDuesReport({
        membershipCategory: values?.membershipCategory,
        startDate: values?.startDate,
        endDate: values?.endDate,
      })
    );
    if (response?.payload?.isSuccess === false) {
      dispatch(
        setloadTost({
          title: "Error",
          description:
            response?.payload?.errors?.map((errors) => errors) ||
            response?.payload?.error?.description ||
            "Failed to get report",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
    } else {
      dispatch(
        setloadTost({
          title: "Success",
          description: `report generated successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        })
      );
      actions.resetForm();
    }
  };

  return (
    <>
      <Box
        bgColor={"white"}
        height={["850px", "", "370px"]}
        px={"30px"}
        py={"20px"}
        mb={5}
      >
        <Text color={"#3A3541DE"} fontSize={"16px"} fontWeight={500}>
          Search Filters
        </Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={approvalReportSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl
                  mt={4}
                  isInvalid={
                    errors.membershipCategory && touched.membershipCategory
                  }
                  color="#121212"
                >
                  <FormLabel htmlFor="membershipCategory" fontSize={"16px"}>
                    Member Number
                  </FormLabel>
                  <Select
                    placeholder="Select Member Category"
                    height={"52px"}
                    name="membershipCategory"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={"#F3F3F3"}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.membershipCategory}
                  >
                    <option value="NYSC">NYSC</option>
                    <option value="Associate">Associate</option>
                    <option value="Student">Student</option>
                    <option value="Fellow">Fellow</option>
                    <option value="Graduate">Graduate</option>
                  </Select>
                  {touched.membershipCategory && errors.membershipCategory && (
                    <FormErrorMessage>
                      {errors.membershipCategory}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.startDate && touched.startDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="startDate" fontSize={"16px"}>
                    Start Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="startDate"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.startDate}
                  />
                  {touched.startDate && errors.startDate && (
                    <FormErrorMessage>{errors.startDate}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  mt={4}
                  isInvalid={errors.endDate && touched.endDate}
                  color="#121212"
                  fontFamily={"inter"}
                >
                  <FormLabel htmlFor="endDate" fontSize={"16px"}>
                    End Date
                  </FormLabel>
                  <Input
                    height={"52px"}
                    type="date"
                    placeholder="Pick a Date"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={"#F3F3F3"}
                    name="endDate"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.endDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <FormErrorMessage>{errors.endDate}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems={"center"}
                gap={3}
              >
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl></FormControl>
                <FormControl>
                  <Button
                    mt={10}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    width={"118px"}
                    background={"#D11A2A"}
                    _hover={{ background: " " }}
                    color={"#FFFFFF"}
                    type="submit"
                    isLoading={memberDuesReport?.loading}
                    loadingText="fetching ..."
                  >
                    Fetch Report
                  </Button>
                </FormControl>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      {memberDuesReport?.memberDuesReport?.data 
      ? <MemberMgtDuesTable memberDuesReport={memberDuesReport?.memberDuesReport?.data}  /> 
      : null}
    </>
  );
};

export default MemberDuesMGT;
