import React from 'react';
import { Divider, Flex, Image, Text, Box, Progress, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepTitle, StepSeparator, StepNumber } from '@chakra-ui/react';
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from 'react-router';

const NYSCApplicationFormHeaderNew = ({logo, signOut, steps, activeStep, progressPercent, orientation }) => {
    const navigate = useNavigate();

  return (
     <>
      <Flex bg={'#D734390A'} h={'100px'} alignItems={'center'} px={['30px', '40px', '40px', '50px']}>
        <Image src={logo} alt='logo' onClick={() => navigate(0)} cursor={'pointer'} />
        <Text fontWeight={'400'} fontSize={'12px'} lineHeight={'18.35px'} color={'#1E1E1E'} opacity={'0.5'}>INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA</Text>
      </Flex>
      <Divider border={'2px solid #A6A6A6'} opacity={'0.2'} />
      <Flex alignItems={'center'} px={['30px', '40px', '40px', '50px']} py={'20px'} gap={'10px'} cursor={'pointer'} onClick={signOut}>
        <MdOutlineArrowBack style={{ width: '20px', height: '20px' }} />
        <Text color={'#54595F'} fontWeight={'400'} fontSize={'18px'} lineHeight={'21.09px'} >Logout</Text>
      </Flex>
      <Divider border={'2px solid #A6A6A6'} opacity={'0.2'} />
        <>
        <Flex px={['20px', '30px', '50px']} py={'20px'}>
            <Text fontWeight={'700'} fontSize={['24px', '28px', '32px']} lineHeight={'33.6px'} color={'#121212'} fontFamily={'roboto'}>NYSC Application Form</Text>
        </Flex>
        <Divider border={'2px solid #A6A6A6'} opacity={'0.2'}/>
        <Box >
            <Stepper index={activeStep} px={['30px', '40px', '40px', '50px']} py={'30px'} colorScheme='red' orientation={orientation}>
            {steps.map((step, index) => (
                <Step key={index}
                //  onClick={() => setActiveStep(index)} 
                cursor={'pointer'} >
                <StepIndicator  >
                    <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                    />
                </StepIndicator>
                <Box flexShrink='0'>
                    <StepTitle color={activeStep === index || activeStep > index ? "#D11A2A" : "#B4B1B1"} fontSize={'16px'} fontWeight={'500'} lineHeight={'18.75px'} fontFamily={'roboto'} >{step.title}</StepTitle>
                </Box>
                <StepSeparator />
                </Step>
            ))}
            </Stepper>
            <Progress
            colorScheme='red'
            value={progressPercent}
            height='3px'
            width='full'
            />
            <Box mt={'50px'} mb={'20px'}>
            {steps[activeStep].form}
            </Box>
        </Box>
        </>
     

    </>
  )
}

export default NYSCApplicationFormHeaderNew;