import React from "react";
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import LoginImage from "../components/LoginImage";
import { BiArrowBack } from "react-icons/bi";
import { setloadTost } from '../redux/slices/toastSlice';
import {forgotPassword} from '../redux/slices/authSlice';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required("You must enter email address"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    console.log("values", values);
    const response = await dispatch(forgotPassword(values));
    console.log("response", response);
    if (response?.payload?.isSuccess === true) {
      dispatch(setloadTost({ title: 'Successs', description: `Please check your email to reset your password`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
    }else{
      dispatch(setloadTost({ title: 'Failed', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to send forgot password email"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
    }
  };

  return (
    <Flex w="full" h="100vh" overflow={"hidden"}>
      <LoginImage />
      <Flex h={"full"} w="full" justify={"center"} position={"relative"} alignItems={"center"}>
        <Flex w={["90%", "80%", "90%", "80%"]}>
          <Flex
            direction={"column"}
            bg={"white"}
            width={"full"}
            px={"1.5rem"}
            py="2rem"
          >
            <BiArrowBack
              size={"1.5rem"}
              style={{ marginBottom: "2rem", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />

            <Flex justify={"space-between"} direction={"column"} mb="1.5rem">
              <Text fontWeight={"700"} fontSize={"1.8rem"}>
                Forgot Password?
              </Text>
              <Text fontSize={"1.25rem"}>
                Enter your email address to reset password
              </Text>
            </Flex>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={forgotPasswordSchema }
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, handleBlur, handleChange, isSubmitting, }) => (
                <Form mt={4}>
                  <FormControl
                    isInvalid={errors.email && touched.email}
                    mb={5}
                    color="black"
                  >
                    <FormLabel>Email</FormLabel>
                    <Input
                      required
                      placeholder="Enter email address"
                      bgColor={"#F3F3F3"}
                      borderRadius={0}
                      borderColor={"transparent"}
                      mb="1rem"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Button
                    isLoading={isSubmitting}
                    color={"white"}
                    bgColor={"#D11A2A"}
                    borderRadius="8px"
                    mt={"4rem"}
                    mb="1rem"
                    w={"full"}
                    type="submit"
                    _hover={{backgroundColor: ""}}
                  >
                  Send
                  </Button>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
      </Flex>

    </Flex>
  );
};

export default ForgotPassword;
