import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { Form, Formik } from 'formik';
import { addDepartmentSchema } from '../utils/formValidationSchema';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import {fetchDepartment, createDepartment} from '../redux/slices/departmentMgtSlice';
import {setloadTost} from "../redux/slices/toastSlice";

function AddDepartment() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const initialValues = {
        departmentName: '',
        departmentCode: '',
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        setError("");
        try {

            const formData = {
                name: values?.departmentName,
                code: values?.departmentCode
            };

            const response = await dispatch(createDepartment(formData));
            // console.log(response);
            if (response?.payload?.isSuccess === true){
                setLoading(false);
                dispatch(fetchDepartment());
                dispatch(setFormModal({ status: false, data: null }));
                dispatch(setloadTost({ title: 'Department Created', description: `${values?.departmentName} department created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            }else{
                setLoading(false);
                setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create department");
            }
            
        } catch (error) {
            setError(error?.payload?.message || "Failed to create department, please check your network");
        }finally{
            setLoading(false);
        }
    }
    return (
        <>
            {
            error &&(
            <Alert status="error" className="mt-3" mb={3}>
                <AlertIcon />
                <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
            )
            }
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={addDepartmentSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    errors, touched, values, handleBlur, handleChange
                }) => (
                    <Form>
                        <FormControl mt={4} isInvalid={errors.departmentName && touched.departmentName} color="#121212">
                            <FormLabel htmlFor='departmentName' fontSize={'16px'}>Department Name</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="departmentName"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.departmentName}
                            />
                            {touched.departmentName && errors.departmentName && (
                                <FormErrorMessage>{errors.departmentName}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl mt={4} isInvalid={errors.departmentCode && touched.departmentCode} color="#121212">
                            <FormLabel htmlFor='departmentCode' fontSize={'16px'}>Department Code</FormLabel>
                            <Input
                                placeholder="Enter Here"
                                fontSize={"small"}
                                bg={'#F3F3F3'}
                                name="departmentCode"
                                focusBorderColor="#ffffff"
                                borderRadius={'0'}
                                borderColor={"#FFFFFF"}
                                _placeholder={{ color: "#B4B1B1" }}
                                _hover={{ borderColor: "#FFFFFF" }}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.departmentCode}
                            />
                            {touched.departmentCode && errors.departmentCode && (
                                <FormErrorMessage>{errors.departmentCode}</FormErrorMessage>
                            )}
                        </FormControl>
                        <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={"#D11A2A"}
                                _hover={{ background: " " }}
                                color={"#FFFFFF"}
                                type="submit"
                                isLoading={loading}
                                loadingText="Saving ..."
                            >
                                Save 
                            </Button>
                            <Button
                                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                width={"118px"}
                                background={""}
                                _hover={{ background: " " }}
                                color={"#8F9BB3"}
                                onClick={() => {
                                    dispatch(setFormModal({ status: false, data: null }));
                                }}
                                loadingText="Saving ..."
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </Form>
                )}

            </Formik>
        </>
    )
}

export default AddDepartment