import React from 'react';
import { Form, Formik } from "formik";
import { Box, Flex, Text, Avatar, FormControl, FormErrorMessage, FormLabel, Input, Button, Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {File_URL} from "../config/settings";

const Profile = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.userProfile);
  const member = useSelector((state) => state.auth?.memberProfile);
  const initialValues = {
    firstName: user?.firstName || member?.firstName,
    lastName: user?.lastName || member?.lastName,
    email: user?.email || member?.email,
    phoneNumber: user?.phoneNumber || member?.phoneNumber,
    roleAssigned: user?.role || member?.role,
    status: user?.department || member?.memberCategory,
    staffId: user?.staffId || member?.membershipId,
    profilePicture: user?.profilePicture || member?.profilePicture,
};

const handleSubmit = async (values) => {
    // console.log(values);
}
  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={5}>
      <Flex  alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={4} mb={5} cursor={'pointer'}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#222B45'} opacity={'0.5'}>User Profile</Text>
      </Flex>
      <Flex flexDirection={["column", "column", "row"]} gap={6} justifyContent={'space-between'}  borderBottom={'1px solid #B4B1B1'} pb={7} mb={2}>
        <Flex flexDirection={'column'} gap={5}>
          <Flex gap={'15px'} >
            <Avatar src={`${File_URL}${initialValues?.profilePicture}`} size='2xl' />
            <Flex flexDirection={'column'}>
              <Text color={'#384D6C'} fontWeight={'700'} fontSize={'20px'}>{initialValues?.firstName + " " + initialValues?.lastName}</Text>
              <Text color={'#384D6C'} fontWeight={'400'} fontSize={'20px'}>{initialValues?.roleAssigned}</Text>
            </Flex>
          </Flex>
          <FormControl>
            <Input type="file" id='profilePics' name='profilePics' w={"90%"}
              accept="image/*"
            />
          </FormControl>
          <Box w={"60%"} size={'sm'} bg={'#D11A2A'} color={'#FFFFFF'} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'} textAlign={'center'} py={'10px'} px={5} cursor={'pointer'}
            borderRadius={'8px'}>
              Upload 
          </Box>
        </Flex>
        <Flex flexDirection={["column", "row", "row"]} gap={10} >
          <Box>
            <Button borderRadius={'8px'} size={'lg'} bg={'#FFFFFF'} color={'#D11A2A'} border={'1px solid #D11A2A'} fontSize={'14px'} fontWeight={'700'} lineHeight={'21px'} _hover={{backgroundColor: ""}} _active={{backgroundColor: ""}}
              onClick={() => navigate('/profile/change-password')}
            > Change Password
            </Button>
          </Box>
        </Flex>
      </Flex>

    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={}
      onSubmit={(values) => handleSubmit(values)}
      >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={[null,3, 5]}>
            <FormControl mt={4} isInvalid={errors.firstName && touched.firstName}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='firstName' fontSize={"16px"}>First Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="firstName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="tel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.firstName}
                readOnly 
              />
              {touched.firstName && errors.firstName && (
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.lastName && touched.lastName}
                color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='lastName' fontSize={"16px"}>Last Name</FormLabel>
              <Input
                placeholder="Enter Here "
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="lastName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.lastName}
                readOnly 
              />
              {touched.lastName && errors.lastName && (
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={[null,3, 5]}>
              <FormControl mt={4} isInvalid={errors.email && touched.email}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='email' fontSize={"16px"}> Email Address</FormLabel>
                <Input
                    type="email"
                    placeholder="Enter Amount"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="email"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.email}
                    readOnly 
                />
                {touched.email && errors.email && (
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.phoneNumber && touched.phoneNumber}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='phoneNumber' fontSize={"16px"}> Phone Number</FormLabel>
                <Input
                    type="text"
                    placeholder="Enter Amount"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="phoneNumber"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.phoneNumber}
                    readOnly 
                />
                {touched.phoneNumber && errors.phoneNumber && (
                    <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                )}
              </FormControl>
          </Flex>
          <Flex flexDirection={["column", "row", "row"]} alignItems={"center"} gap={[null,3, 5]}>
            <FormControl mt={4} isInvalid={errors.roleAssigned && touched.roleAssigned}
                color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='roleAssigned' fontSize={"16px"}> Role Assigned</FormLabel>
                <Input
                    type="text"
                    placeholder="Enter Amount"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="roleAssigned"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.roleAssigned}
                    readOnly 
                />
                {touched.roleAssigned && errors.roleAssigned && (
                    <FormErrorMessage>{errors.roleAssigned}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.status && touched.status}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='status'  fontSize={"16px"}>{  member?.memberCategory? "Member Category" : "Department"  }</FormLabel>
                <Input
                    type="text"
                    placeholder="Enter Amount"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="status"
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.status}
                    readOnly 
                />
                {touched.status && errors.status && (
                  <FormErrorMessage>{errors.status}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.staffId && touched.staffId}
                  color="#121212" fontFamily={'inter'}>
                <FormLabel htmlFor='staffId' fontSize={"16px"}>{member?.membershipId ? "Member ID" : "Staff ID"  }</FormLabel>
                <Input
                  placeholder="Enter Here "
                  fontSize={"small"}
                  bg={'#F3F3F3'}
                  name="staffId"
                  focusBorderColor="#ffffff"
                  borderRadius={'0'}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.staffId}
                  readOnly 
                />
                {touched.staffId && errors.staffId && (
                  <FormErrorMessage>{errors.staffId}</FormErrorMessage>
                )}
            </FormControl>
          </Flex>
          <Divider py={8} borderBottom={'1px solid #B4B1B1'}/>
          {/* <Box >
            <Text color={'#D73439'} fontSize={'16px'} fontWeight={'700'} lineHeight={'24.46px'} opacity={'0.5'} py={4}>LOGIN ACTIVITY</Text>
            {
              loginActData?.map((item, index) => (
                <Flex flexDirection={'column'} pb={3} key={index}>
                  <Text color={'#384D6C'} fontSize={'20px'} fontWeight={'700'} lineHeight={'30.58px'}>{item?.activity}</Text>
                  <Text color={'#384D6C'} fontSize={'16px'} fontWeight={'400'} lineHeight={'24.46px'}>{item.time}</Text>
                </Flex>
              ))
            }
              
          </Box> */}
        </Form>
      )}
    </Formik>
    </Box>
  )
}

export default Profile;