import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import MembershipOutstandingDuesReportTable from '../components/MembershipOutstandingDuesReportTable';

const MemberOutstandingDuesReport = () => {
    const [toggleReport, setToggleReport] = useState(false);

    const initialValues = {
        memberCategory: '',
        membershipNo: '',
        surname: '',
    };

    const handleSubmit = async (values) => {
        setToggleReport(!toggleReport)
    }

    return (
        <>
            <Box bgColor={'white'} height={["550px", "", '270px']} px={'30px'} py={'20px'} pb={'10px'} >
                <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // validationSchema={paymentSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                        errors, touched, values, handleBlur, handleChange
                    }) => (
                        <Form>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl mt={4} isInvalid={errors.memberCategory && touched.memberCategory} color="#121212">
                                    <FormLabel htmlFor='memberCategory' fontSize={'16px'}>Member Category</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="memberCategory"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.memberCategory}
                                    />
                                    {touched.memberCategory && errors.memberCategory && (
                                        <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.membershipNo && touched.membershipNo} color="#121212">
                                    <FormLabel htmlFor='membershipNo' fontSize={'16px'}>Membership No</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="membershipNo"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.membershipNo}
                                    />
                                    {touched.membershipNo && errors.membershipNo && (
                                        <FormErrorMessage>{errors.membershipNo}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl mt={4} isInvalid={errors.surname && touched.surname} color="#121212">
                                    <FormLabel htmlFor='surname' fontSize={'16px'}>Surname</FormLabel>
                                    <Input
                                        placeholder="Enter Here"
                                        fontSize={"small"}
                                        bg={'#F3F3F3'}
                                        name="membershipNo"
                                        focusBorderColor="#ffffff"
                                        borderRadius={'0'}
                                        borderColor={"#FFFFFF"}
                                        _placeholder={{ color: "#B4B1B1" }}
                                        _hover={{ borderColor: "#FFFFFF" }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.membershipNo}
                                    />
                                    {touched.surname && errors.surname && (
                                        <FormErrorMessage>{errors.surname}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <FormControl></FormControl>
                                <FormControl></FormControl>
                            </Flex>
                            <Flex justifyContent={'flex-end'} alignItems={'center'}>
                                <Button
                                    mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                                    width={"118px"}
                                    background={"#D11A2A"}
                                    _hover={{ background: " " }}
                                    color={"#FFFFFF"}
                                    type="submit"
                                // isLoading={loading}
                                // loadingText="Saving ..."
                                >
                                    Fetch Report
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Box mt={5}>
                {
                    toggleReport ? (<MembershipOutstandingDuesReportTable />) : null
                }
            </Box>
        </>
    )
}

export default MemberOutstandingDuesReport