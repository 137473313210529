import { Flex, Text, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DashboardLineChart = ({ lineChartData = [] }) => {
  const [selectedYear, setSelectedYear] = useState("This Year");

 
  const filteredData = lineChartData?.filter(item =>
    selectedYear === "This Year" ? item.year === new Date().getFullYear() : item.year === new Date().getFullYear() - 1
  ) || [];

  return (
    <Flex direction="column" alignItems="flex-start" bg={'#FFFFFF'} borderRadius={'20px'} pr={2}>
      <Flex py={3} pl={5} pr={3} justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
        <Text fontSize={'14px'} fontWeight={'500'} letterSpacing={'-0.154px'} color={'#4D4D4D'}>
          Total Members
        </Text>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={5}>
          <Select
            w={'100px'}
            border={'0'}
            focusBorderColor='white'
            color={'#1E1E1E'}
            opacity={'0.5'}
            fontSize={'12px'}
            fontWeight={'400'}
            fontFamily={'inter'}
            lineHeight={'14.52px'}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option>This Year</option>
            <option>Last Year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex w="100%" direction="row">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 0" vertical={false} strokeWidth={0.2} />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#FFC7D4" strokeWidth={3} dot={{ fill: '#A8C5DA', stroke: 'transparent', r: 4 }} />
          </LineChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
};

export default DashboardLineChart;
