import React from 'react';
import { Button, Divider, Flex , Alert,AlertIcon, AlertTitle} from "@chakra-ui/react";

const MembershipApproveDecline = ({handleApproval, handleDecline, error, errorsArray, loading }) => {
  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
    }
      <Divider border={"1px solid #E3E3E3"} />
      <Flex
        flexDirection={["column", "row", "row"]}
        justifyContent={"center"}
        alignItems={"center"}
        gap={["20px", "50px", "50px"]}
        my={6}
        fontSize={"16px"}
        fontWeight={"500"}
        fontFamily={"roboto"}
        letterSpacing={"-0.25px"}
        lineHeight={"18.75px"}
      >
        <Button
          bg={"#D11A2A"}
          color={"#FFFFFF"}
          borderRadius={"3px"}
          w={"170px"}
          _hover={{ backgroundColor: "" }}
          _active={{ backgroundColor: "" }}
          onClick={handleApproval}
          isLoading={loading}
          loadingText="Approving ..."
        >
          Approve
        </Button>
        <Button
          bg={"#FFE6E8"}
          color={"#D11A2A"}
          borderRadius={"3px"}
          w={"170px"}
          _hover={{ backgroundColor: "" }}
          _active={{ backgroundColor: "" }}
          onClick={handleDecline}
        >
          Decline
        </Button>
      </Flex>
    </>
  )
}

export default MembershipApproveDecline