import { Button, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddApproveCenterChange, fetchExamCenterChangeApplication } from '../redux/slices/examMgtSlice'
import { setloadTost } from '../redux/slices/toastSlice'
import { setFormModal } from '../redux/slices/modalSlice'

const ExamIndividualDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const examIndividualDetails = useSelector((state) => state?.examMgt?.ExamCenterChangeDetails)
    console.log('examIndividualDetails', examIndividualDetails)

    const examIndividualDetailsData = [
        { title: "Fullname:", discription: examIndividualDetails?.fullName },
        { title: "Email:", discription: examIndividualDetails?.email },
        { title: "Application Number:", discription: examIndividualDetails?.applicationNumber },
        { title: "Student Number:", discription: examIndividualDetails?.studentNumber },
        { title: "Membership Category:", discription: examIndividualDetails?.membershipCategory },
        { title: "Current Exam Stage:", discription: examIndividualDetails?.currentExamStage },
        { title: "Old Exam Centre:", discription: examIndividualDetails?.oldCentre },
        { title: "New Exam Centre:", discription: examIndividualDetails?.newCentre },
    ]

    const handleApproval = async () => {
        const formData = {
            status: "Approve",
            comment: "",
        }
        setLoading(true);
        const response = await dispatch(AddApproveCenterChange({ id: examIndividualDetails?.id, arg: formData }))
        console.log('response', response)
        if (response?.payload?.isSuccess === true) {
            setLoading(false);
            dispatch(fetchExamCenterChangeApplication('Pending'))
            dispatch(setFormModal({ status: false, data: null }));
            dispatch(setloadTost({ title: 'Approval', description: ` Approved successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        } else {
            setLoading(false);
            dispatch(setloadTost({ title: 'Error', description: `${response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to Approve"}`, status: 'error', duration: 5000, isClosable: true, position: 'top' }));
        }
    }


    const handleDecline = async () => {
        dispatch(
            setFormModal({ status: true, type: "examDecline", data: examIndividualDetails })
        );
    }
    return (
        <>
            {
                examIndividualDetailsData?.map((data, index) => (
                    <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={5} key={index} gap={[6, 3, 3]}

                    >
                        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
                        <Text width={'65%'} color={'#7A7A7A'} lineHeight={'21.09px'} >{data.discription}</Text>
                    </Flex>
                ))
            }
            <Flex
                flexDirection={["column", "row", "row"]}
                justifyContent={"center"}
                alignItems={"center"}
                gap={["20px", "50px", "50px"]}
                my={10}
                fontSize={"16px"}
                fontWeight={"500"}
                fontFamily={"roboto"}
                letterSpacing={"-0.25px"}
                lineHeight={"18.75px"}
            >
                <Button
                    bg={"#D11A2A"}
                    color={"#FFFFFF"}
                    borderRadius={"3px"}
                    w={"170px"}
                    _hover={{ backgroundColor: "" }}
                    _active={{ backgroundColor: "" }}
                    onClick={handleApproval}
                    isLoading={loading}
                    loadingText="Approving ..."
                >
                    Approve
                </Button>
                <Button
                    bg={"#FFE6E8"}
                    color={"#D11A2A"}
                    borderRadius={"3px"}
                    w={"170px"}
                    _hover={{ backgroundColor: "" }}
                    _active={{ backgroundColor: "" }}
                    onClick={handleDecline}
                >
                    Decline
                </Button>
            </Flex>
        </>
    )
}

export default ExamIndividualDetails