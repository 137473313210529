import React from 'react'
import NYSCApproveDecline from './NYSCApproveDecline'
import { Box, Flex, Text } from '@chakra-ui/react'

const NYSCEmployementInformation = ({nyscIndividualDetails}) => {
    const tagData = [
        {title:"Company Name:", discription:nyscIndividualDetails?.ppaInformation?.name},
        {title:"Job Title:", discription:nyscIndividualDetails?.ppaInformation?.jobTitle},
        {title:"Department:", discription:nyscIndividualDetails?.ppaInformation?.department},
        {title:"Country:", discription:nyscIndividualDetails?.ppaInformation?.country},
        {title:"Email Address:", discription:nyscIndividualDetails?.ppaInformation?.email},
        {title:"Primary Address:", discription:nyscIndividualDetails?.ppaInformation?.primaryAddress},
        {title:"Address", discription:nyscIndividualDetails?.ppaInformation?.address},
        {title:"Telephone:", discription:nyscIndividualDetails?.ppaInformation?.phoneNumber},
        {title:"Postal Code:", discription:nyscIndividualDetails?.ppaInformation?.postalCode},
      ]
    return (
      <>
      <Box h={'60vh'}
        overflow={'scroll'}
      >
      {/* <Text color={'#121212'} fontWeight={'600'} fontSize={'18px'} fontFamily={'roboto'} lineHeight={'18.9px'} textDecoration={'underline'}>Present Appointment Of Applicant</Text> */}
      {
      tagData?.map((data, index) =>(
      <Flex px={[1, null, null]} my={5} fontSize={'18px'} fontWeight={'500'} fontFamily={'roboto'} justifyContent={'flex-start'} alignItems={'center'} mb={4} key={index} gap={[6, 3, 3]}
      
      >
        <Text width={'35%'} color={'#121212'} fontWeight={'400'} lineHeight={'18.9px'}  >{data.title}</Text>
        <Text  width={'65%'} color={'#7A7A7A'}  lineHeight={'21.09px'} >{data.discription}</Text>
      </Flex>
      ))
      }
  
      <NYSCApproveDecline nyscIndividualDetails={nyscIndividualDetails} />
      </Box>
    </>
  )
}

export default NYSCEmployementInformation