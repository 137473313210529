import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  user: null,
  loading: false,
  error: null,
  userProfile: null,
  memberProfile: null,
};

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Administrative/profile`);
  }
);

export const fetchMemberUserProfile = createAsyncThunk(
  "user/fetchMemberUserProfile",
  async () => {
    return httpRequest(`${BASE_URL}/v2/MembershipManagement/profile`);
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Auth/forgot-password`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${BASE_URL}/v2/Auth/reset-password`,
        "post",
        arg
      );
      if (response?.response?.data) {
        throw response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addLoginUser: (state, { payload }) => {
      state.user = payload;
    },
    removeUser: (state) => {
      state.user = null;
    },

  },

  extraReducers: (builder) => {
    //Adminstrative profile:
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userProfile = payload;
      state.error = null;
    });

    builder.addCase(fetchUserProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    //Member profile:
    builder.addCase(fetchMemberUserProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMemberUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.memberProfile = payload;
      state.error = null;
    });

    builder.addCase(fetchMemberUserProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // Forgot Password:
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });

    // Reset Password:
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.error?.description;
    });
  }

});

export default authSlice.reducer;
export const { removeUser, addLoginUser} = authSlice.actions;
