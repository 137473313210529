import React, { useEffect } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import {fetchPendingBioDataApproval} from "../redux/slices/membershipManagementSlice";
import DotLoader from '../components/loaders/DotLoader';

const UpdateBiodataChange = () => {
  const dispatch = useDispatch();
  const handleUpdate = (item) => {
    dispatch(setFormModal({ status: true, title: "Member Details", type: "updatebioData",  size:"3xl", data:item }));
  };

  useEffect(() => {
    dispatch(fetchPendingBioDataApproval());
  }, [dispatch]);

  const pendingBioDataApproval = useSelector((state) => state?.membershipManagement);

  const tableProps = {
    data: pendingBioDataApproval?.pendingBioDataApproval?.data,
    title: {
      memberName: "Member Name",
      memberCategory: "Memeber Category",
      Status: "Status",
      viewDetails: "",
    },
    dataKeys: [
      "memberName",
      "memberCategory",
      "Status",
      "viewDetails",
    ],
    transformRow: (item) => ({
      memberName: <Box style={{ cursor: 'pointer' }}>{item?.fullName}</Box>,
      memberCategory: <Box style={{ cursor: 'pointer' }}>{item?.membershipCategory}</Box>,
      Status: <Box style={{ cursor: 'pointer' }} color={getStatusColor(item?.isApproved === true ? "Approved" : "Pending")}>{item?.isApproved === true ? "Approved" : "Pending"}</Box>,
      viewDetails: (
        <Button fontWeight={'bold'} fontSize={'14px'} bgColor={'#812773'} color={'white'} px={'10px'} _hover={{ bgColor: '' }} onClick={() => handleUpdate(item)} >View Details</Button>
      ),
    }),
  };

  const getStatusColor = (Status) => {
    switch (Status) {
      case 'Pending':
        return '#FFC34F';
      case 'Approved':
        return '#005E39';
      default:
        return '';
    }
  };

  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'} opacity={'50%'}>Update Biodata Change</Text>
      </Flex>
      {
        pendingBioDataApproval?.loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <CustomTable {...tableProps} />
        )
      }
    </Box>
  )
}

export default UpdateBiodataChange;