import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useState } from 'react';
import MemberApplicationFees from './MemberApplicationFees';
import EventApplicationFees from './EventApplicationFees';
import ExamApplicationFees from './ExamApplicationFees';

function PaymentTypeTab() {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <Tabs variant='unstyled'>
            <TabList gap={3} mb={3}>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(0)}
                    style={selectedTab === 0 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Member Application Fees
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(1)}
                    style={selectedTab === 1 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Exam Application Fees
                </Tab>
                <Tab w={'226px'} borderRadius={6} h={'50px'} fontSize={'14px'} fontWeight={400}
                    onClick={() => handleTabChange(2)}
                    style={selectedTab === 2 ? { backgroundColor: '#D11A2A', color: 'white' } : { backgroundColor: '#E9E9E9' }}
                >
                    Event Application Fees
                </Tab>
            </TabList>
            <TabPanels bgColor={'white'} minH={'100vh'}>
                <TabPanel>
                    <MemberApplicationFees />
                </TabPanel>
                <TabPanel>
                    <ExamApplicationFees />
                </TabPanel>
                <TabPanel>
                    <EventApplicationFees />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default PaymentTypeTab;
