import { AiOutlineUser } from "react-icons/ai";
import { MdInsertChart } from "react-icons/md";
import { IoNotifications } from "react-icons/io5";
import { HiMiniTicket } from "react-icons/hi2";
import { CgFileDocument } from "react-icons/cg";
import { RxDashboard } from "react-icons/rx";
import { FaCalendarAlt } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { AiTwotoneMail } from "react-icons/ai";

const generateMenu = (result) => {
  // console.log('result', result );
  const menu = [
    {
      heading: "MAIN MENU",
      items: result?.reduce((acc, item) => {
        // console.log('item', item.permissions)
        item?.permissions?.map((item) => {
          if (item.action === "View" && item.value === 'Granted') {
            if (item.moduleDesc === "Dashboard") {
              const existingDashboardItem = acc.find(menuItem => menuItem.name === "Dashboard");
              if (!existingDashboardItem) {
                acc.push({
                  name: "Dashboard",
                  link: "/dashboard",
                  icon: RxDashboard,
                });
              }
            } else if (item.moduleDesc === "Announcement" || item.moduleDesc === "Email") {
              // Skip adding these items to the main menu
              return acc;
            } else {
              const existingItem = acc.find(menuItem => menuItem.name === item.moduleDesc);
              if (existingItem) {
                const existingResource = existingItem.submenu.find(submenuItem => submenuItem.name === item.resourceDesc);
                if (!existingResource) {
                  existingItem.submenu.push({
                    name: item.resourceDesc,
                    link: `/${item.moduleDesc?.replace(/\s+/g, '').toLowerCase()}/${item.resourceDesc?.replace(/\s+/g, '').toLowerCase()}`
                  });
                }
              } else {
                let icon;
                switch (item.moduleDesc) {
                  case "UserManagement":
                  case "User Management":
                    icon = MdInsertChart;
                    break;
                  case "EventManagement":
                  case "Event Management":
                    icon = HiMiniTicket;
                    break;
                  case "FeeManagement":
                  case "Fee Management":
                    icon = FaCalendarAlt;
                    break;
                  case "Approval":
                    icon = FiActivity;
                    break;
                  case "DepartmentManagement":
                  case "Department Management":
                    icon = FaCalendarAlt;
                    break;
                  case "Report":
                    icon = FaCalendarAlt;
                    break;
                  case "ExamSetup":
                  case "Exam Setup":
                    icon = CgFileDocument;
                    break;
                  case "MemberManagement":
                  case "Member Management":
                    icon = FiActivity;
                    break;
                  case "ApplicationApproval":
                  case "Application Approval":
                    icon = FiActivity;
                    break;
                  case "StatementofResult":
                  case "Statement of Result":
                    icon = CgFileDocument;
                    break;
                  case "ProgrammeManagement":
                  case "Programme Management":
                    icon = MdInsertChart;
                    break;
                  default:
                    icon = FaCalendarAlt;
                }

                acc.push({
                  name: item.moduleDesc,
                  icon: icon,
                  submenu: [
                    {
                      name: item.resourceDesc,
                      link: `/${item.moduleDesc?.replace(/\s+/g, '').toLowerCase()}/${item.resourceDesc?.replace(/\s+/g, '').toLowerCase()}`
                    }
                  ]
                });
              }
            }
          }
          return null;
        })
        return acc;
      }, [])
    },
    {
      heading: "OTHER MENU",
      items: result?.reduce((acc, item) => {
        item?.permissions?.forEach(permission => {
          if (permission?.action === "View" && permission?.value === 'Granted') {
            if (permission.moduleDesc === "Announcement" || permission.moduleDesc === "Email") {
              const existingOtherItem = acc.find(menuItem => menuItem.name === permission.moduleDesc);
              if (!existingOtherItem) {
                let icon;
                switch (permission.moduleDesc) {
                  case "Announcement":
                    icon = IoNotifications;
                    break;
                  case "Email":
                    icon = AiTwotoneMail;
                    break;
                  default:
                    icon = IoNotifications;
                }
                acc.push({
                  name: permission.moduleDesc,
                  icon: icon,
                  link: `/${permission.moduleDesc?.replace(/\s+/g, '').toLowerCase()}`
                });
              }
            }
          }
        });
        return acc;
      }, [])
      // .concat({ name: "Entry Qualification Type", link: "examsetup/entryqualificationtype", icon: CgFileDocument })
      .concat({ name: "Profile", link: "/profile", icon: AiOutlineUser })
    },
  ]
  return menu;
}

export const menu2 =  [
  {
    heading: "MAIN MENU",
    items:[
      {
        name: "Dashboard",
        link: "/studentdashboard",
        icon: RxDashboard,
      },
      {
        name: "My Dues",
        link: "/mydues",
        icon: HiMiniTicket,
      },
      {
        name: "Exam Management",
        icon: FaCalendarAlt,
        submenu: [
          {
            name: "Exam Application",
            link: "/exammanagement/examapplication",
          },
          {
            name: "Change Exam Centre",
            link: "/exammanagement/changeexamcentre",
          },
          {
            name: "Print Exam Docket",
            link: "/exammanagement/printexamdocket",
          },
          {
            name: "View Exam Result",
            link: "/exammanagement/viewexamresult",
          }
        ]
      },      
      {
        name: "Print Admission Letter",
        link: "/printadmissionletter",
        icon:  CgFileDocument,
      },
      {
        name: "Member Upgrade",
        link: "/memberupgrade",
        icon: HiMiniTicket,
      },
      {
        name: " Events",
        link: "/memberprofile-events",
        icon: HiMiniTicket,
      },
      {
        name: "Report",
        link: "/report/memberpaymenthistoryreport",
        icon: FaCalendarAlt,
      }
    ]
  },
  {
    heading: "OTHER MENU",
    items: [
      { name: "Announcement", link: "/announcement", icon: IoNotifications },
      { name: "Profile", link: "/profile", icon: AiOutlineUser },
    ],
  },
]


export { generateMenu };