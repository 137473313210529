import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { setFormModal } from '../redux/slices/modalSlice';
import {setMyDuesDetails} from "../redux/slices/myDuesSlice";

const RegistrationPaymentDetails = () => {
  const dispatch = useDispatch();
  const feeDetails = useSelector((state) => state?.studentApplicationApproval?.feePaymentDetail);
  const feeData = [
    {title:"Name: ", description: feeDetails?.firstName + " " + feeDetails?.lastName},
    {title:"Description: ", description: feeDetails?.description},
    {title:"Email: ", description: feeDetails?.email},
    {title:"Admin Fee: ", description: feeDetails?.adminFee},
    {title:"Amount: ", description: feeDetails?.amount},
  ]

  const handleProceed = () => {
    dispatch(setMyDuesDetails(feeDetails));
    dispatch(setFormModal({ status: true, title:"Payment",  type: "memberProfilePayment", size: "3xl"}));
  }

  return (
    <Box mt={5}>
    {feeData?.map((item, index) => (
      <Flex key={index} alignItems={"center"} gap={3} mb={3}>
        <Text width={'30%'} fontSize={"14px"} fontWeight={"600"}>{item?.title}</Text>
        <Text width={'70%'} fontSize={"14px"} fontWeight={"400"}>{item?.description}</Text>
      </Flex>
    ))}
    <Flex justifyContent={"center"} my={10}>
      <Button mt={3} borderRadius={'8px'} bgColor={'#7D2B32'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'}
        _hover={{ bgColor: '' }} _active={{ bgColor: '' }} onClick={handleProceed}
      >
       Proceed
      </Button>
    </Flex>
    </Box>
  )
}

export default RegistrationPaymentDetails;