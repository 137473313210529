import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/settings";
import httpRequest from "../../utils/httpRequest";

const initialState = {
  loading: false,
  error: null,
  Permissions:'',
};

export const allPermissions = createAsyncThunk(
  "authPermissions/allPermissions",
  async () => {
    return httpRequest(`${BASE_URL}/v2/Auth/permissions`);
  }
);

const authPermissionsSlice = createSlice({
  name: "authPermissions",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(allPermissions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allPermissions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.Permissions = payload;
    });
    builder.addCase(allPermissions.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message;
    });
  }
});

export default authPermissionsSlice.reducer;

