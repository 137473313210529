import React, {useState} from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Flex, Select, Input, Alert,AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {addCategorySchema} from "../utils/formValidationSchema";
import {fetchExamCategory,createExamCategory} from "../redux/slices/examCategorySubjectSlice";
import {setloadTost} from "../redux/slices/toastSlice";
const AddCategory = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];

    const initialValues = {
        categoryName: '',
        categoryCode: '',
        examStage: '',
    };
  
    const handleSubmit = async (values) => {
      setLoading(true);
      setError("");
      try {

        const formData = {
          name: values?.categoryName,
          code: values?.categoryCode,
          examStage: Number(values?.examStage),
        };
        
        const response = await dispatch(createExamCategory(formData));
        if(response?.payload?.isSuccess === true){
          setLoading(false);
          dispatch(fetchExamCategory(1000));
          dispatch(setFormModal({ status: false, data: null }));
          dispatch(setloadTost({ title: 'Category Created', description: `${values?.categoryName} category created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
        }else{
          setLoading(false);
          setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create category");
        }
        
      } catch (error) {
        setError(error?.payload?.message || "Failed to create category, please check your network");
      }finally{
        setLoading(false);
      }
      
    }

  return (
    <>
    {
      error &&(
      <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
      </Alert>
      )
      }
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={addCategorySchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange }) => (
      <Form>
       <FormControl mt={4} isInvalid={errors.categoryName && touched.categoryName}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='categoryName' fontSize={"16px"}>Category Name</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="categoryName"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.categoryName}
          />
          {touched.categoryName && errors.categoryName && (
            <FormErrorMessage>{errors.categoryName}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.categoryCode && touched.categoryCode}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='categoryCode' fontSize={"16px"}>Category Code</FormLabel>
          <Input
            placeholder="Enter Here"
            fontSize={"small"}
            bg={'#F3F3F3'}
            name="categoryCode"
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.categoryCode}
          />
          {touched.categoryCode && errors.categoryCode && (
            <FormErrorMessage>{errors.categoryCode}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
              mt={4}
              isInvalid={errors.examStage && touched.examStage}
              color="#121212"
            >
              <FormLabel htmlFor="feeType" fontSize={"16px"}>
                Exam Stage
              </FormLabel>
              <Select
                name="examStage"
                placeholder="Select Exam  Stage"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={"#F3F3F3"}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.examStage}
              >
                <option value="0">FOUNDATION stage 1</option>
                <option value="1">PRE PROFESSONAL stage 2</option>
                <option value="2">PROFESSIONAL 1 stage 3</option>
                <option value="3">PROFESSIONAL 2 stage 4</option>
              </Select>
              {touched.examStage && errors.examStage && (
                <FormErrorMessage>{errors.examStage}</FormErrorMessage>
              )}
        </FormControl>
        <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={"#D11A2A"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            isLoading={loading}
            loadingText="Saving ..."
          >
            Add
          </Button>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={""}
            _hover={{ background: " " }}
            color={"#8F9BB3"}
            onClick={() => {
              dispatch(setFormModal({ status: false, data: null }));
            }}
            loadingText="Saving ..."
          >
            Cancel
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
  </>
  )
}

export default AddCategory;