import React, { useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import EducationPieChart from "../components/EducationPieChart";
import EducationBarCharts from "../components/EducationBarCharts";
import EducationPieCharts2 from "../components/EducationPieCharts2";
import { useDispatch, useSelector } from "react-redux";
import { fetchEducationDashboard } from "../redux/slices/dashboardSlice";

const EducationOMDashboard = () => {
  const dispatch = useDispatch();
  const educationDashboardData = useSelector(
    (state) => state?.dashboard?.educationDashboard
  );
  console.log("educationDashboardData", educationDashboardData);
  useEffect(() => {
    dispatch(fetchEducationDashboard());
  }, [dispatch]);

  const data = [
    {
      name: "Total Applicants For Student",
      number:
        (educationDashboardData?.totalApplicant?.student?.pending || 0) +
        (educationDashboardData?.totalApplicant?.student?.approved || 0) +
        (educationDashboardData?.totalApplicant?.student?.declined || 0),
      colors: ["#7F3183", "#F17D8B", "#EBE3EB"],
      pieChartData: [
        {
          name: "Pending",
          value: educationDashboardData?.totalApplicant?.student?.pending,
        },
        {
          name: "Approved",
          value: educationDashboardData?.totalApplicant?.student?.approved,
        },
        {
          name: "Declined ",
          value: educationDashboardData?.totalApplicant?.student?.declined,
        },
      ],
    },
    {
      name: "Total Applicants For NYSC",
      number:
        (educationDashboardData?.totalApplicant?.nysc?.pending || 0) +
        (educationDashboardData?.totalApplicant?.nysc?.approved || 0) +
        (educationDashboardData?.totalApplicant?.nysc?.declined || 0),
      colors: ["#7F3183", "#F17D8B", "#EBE3EB"],
      pieChartData: [
        {
          name: "Pending",
          value: educationDashboardData?.totalApplicant?.nysc?.pending,
        },
        {
          name: "Approved",
          value: educationDashboardData?.totalApplicant?.nysc?.approved,
        },
        {
          name: "Declined ",
          value: educationDashboardData?.totalApplicant?.nysc?.declined,
        },
      ],
    },
    {
      name: "Total Applicants For Graduate",
      number: 20,
      colors: ["#A0B6FD", "#EBE3EB"],
      pieChartData: [
        { name: "Approved", value: 15 },
        { name: "Declined ", value: 5 },
      ],
    },
    {
      name: "Total Applicants For Associate",
      number: 20,
      colors: ["#18203A", "#EBE3EB"],
      pieChartData: [
        { name: "Approved", value: 15 },
        { name: "Declined ", value: 5 },
      ],
    },
    {
      name: "Total Applicants For Fellow",
      number: 35,
      colors: ["#E9BF72", "#EBE3EB"],
      pieChartData: [
        { name: "Approved", value: 25 },
        { name: "Declined ", value: 10 },
      ],
    },
  ];

  return (
    <Flex
      flexDirection={"column"}
      gap={"20px"}
      py={"30px"}
      minH={"100vh"}
      px={3}
    >
      <Box w={["100%", "100%", "100%"]}>
        <EducationPieChart details={data} />
      </Box>
      <Flex
        mt={6}
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={"center"}
        gap={"20px"}
      >
        <Box w={["100%", "100%", "100%", "70%", "75%"]}>
          <EducationBarCharts
            barChartData={educationDashboardData?.totalApplicantPerMonth}
          />
        </Box>
        <Box w={["100%", "100%", "100%", "30%", "25%"]}>
          <EducationPieCharts2 />
        </Box>
      </Flex>
    </Flex>
  );
};

export default EducationOMDashboard;
