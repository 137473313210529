import React from 'react';
import { Box, Flex,Text, Menu, MenuButton, MenuItem, MenuList,  } from '@chakra-ui/react';
import Create from '../components/layout/Create';
import {BsThreeDotsVertical} from 'react-icons/bs';
import { useDispatch} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import CustomTable from '../components/CustomTable';
import {EduExamResultDetailsData} from "../data/mock";
import DownloadBtn from '../components/DownloadBtn';
import SortBy from '../components/SortBy';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const EduExamResultDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const createData = {
        onClick: () => { dispatch(setFormModal({status:true, title: "Upload Result ", type:"educationResult"})); },
        createText: "Upload Result ",
      }
      const handleUpdate = (item) => {
        
      }
      const handleEdit = (item) => {
        
      }
    
      const tableProps = {
        data: EduExamResultDetailsData,
        title: {
          studentNo: "Student No",
          examNo: "Exam No",
          subjectCode:"Subject Code",
          grade:" Grade",
          view:" ",
          Action:'',
        },
        dataKeys: [
          "studentNo",
          "examNo",
          "subjectCode",
          "grade",
          "view",
          "Action",
        ],
        
        transformRow: (item) => ({
            studentNo: <Box style={{cursor:'pointer'}}>{item?.studentNo}</Box>,
            examNo: <Box style={{cursor:'pointer'}}>{item?.examNo}</Box>,
            subjectCode: <Box style={{cursor:'pointer'}}>{item?.subjectCode}</Box>,
            grade: <Box style={{cursor:'pointer'}}>{item?.grade}</Box>,
            view: <Box style={{cursor:'pointer'}} color={'#3064FC'} textDecoration={'underline'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} onClick={() => handleUpdate(item)}>View File</Box>,
          Action:(
            <Menu>
              <MenuButton>
                  <BsThreeDotsVertical />
              </MenuButton>
                  
              <MenuList >
                  <MenuItem 
                  onClick={() => handleEdit(item)}
                  >Edit </MenuItem>
                  <MenuItem > Delete </MenuItem>
              </MenuList>
            </Menu>
          )
        }),
       
      };

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Flex alignItems={'center'} gap={'20px'} onClick={() => navigate(-1)}  cursor={'pointer'}>
            <Box bg={'#F9A36238'} rounded={'full'} p={1}>
                <BiLeftArrowAlt color="#D8991F" size={'20px'} />
            </Box>
            <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}> Upload Exam Result</Text>
        </Flex>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[3, null, null]}>
          <Text cursor={'pointer'} color={'#D11A2A'} fontSize={'12px'} fontWeight={'600'} lineHeight={'16px'} textDecoration={'underline'}>DOWNLOAD TEMPLATE HERE</Text>
          <Create {...createData} />
          <SortBy show={true} />
          <DownloadBtn />
        </Flex>
      </Flex>
      <CustomTable {...tableProps} />
    </Box>
  )
}

export default EduExamResultDetails;