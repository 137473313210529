import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getExamApplications } from '../redux/slices/examMgtSlice';
import CustomTable from '../components/CustomTable';
import Create from '../components/layout/Create';


const ExamApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examApplications = useSelector((state) => state?.examMgt)
  console.log('examApplications', examApplications?.examApplications?.data)

  useEffect(() => {
    dispatch(getExamApplications())
  }, [dispatch])

  const createData = {
    onClick: () => {
      navigate("/exammanagement/examappcentre");
    },
    createText: "Apply For Exam",
  };

  const viewExamApplication = (item) => {
    navigate(`/examapplications/view-exam-details?applicationId=${item?.id}`)
  }

  const tableProps = {
    data: examApplications?.examApplications?.data,
    title: {
      examYear: "Exam Year",
      examDiet: "Exam Diet",
      examCategory: "Exam Category",
      examType: "Exam Type",
      status: "Status",
      viewDetails: "",
    },
    dataKeys: [
      "examYear",
      "examDiet",
      "examCategory",
      "examType",
      "status",
      "viewDetails",
    ],
    isloading: examApplications?.loading,
    transformRow: (item) => ({
      examYear: item?.examYear,
      examDiet: item?.examDiet,
      examCategory: item?.examCategory,
      examType: item?.examType,
      status: item?.status,
      viewDetails: <Box onClick={() => viewExamApplication(item)} textDecoration={'underline'} color={'#3064FC'} cursor={'pointer'}>View Details</Box>
    }),
  };

  return (
    <>
      <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
          <Text fontSize={'20px'} fontWeight={'700'} color={'rgba(209, 26, 42, 0.5)'} >All Examinations</Text>
          <Create {...createData} />
        </Flex>
        <CustomTable {...tableProps} />
      </Box>
    </>
  )
}

export default ExamApplication;