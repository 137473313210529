import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import DetailsCard from "../components/DetailsCard";
import DashboardBarChart from "../components/DashboardBarChart";
import { HiUserGroup } from "react-icons/hi2";
import { PiSquaresFourBold } from "react-icons/pi";
import DashboardLineChart from "../components/DashboardLineChart";
import UpcomingExams from "../components/UpcomingExams";
import { fetchAdminDashboard } from "../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const adminDashboardData = useSelector(
    (state) => state?.dashboard?.adminDashboard
  );
 


  useEffect(() => {
    dispatch(fetchAdminDashboard());
  }, [dispatch]);
  const details = [
    {
      name: "Total Student",
      number: adminDashboardData?.memberCategoryCount?.totalStudent,
      bgcolor: "#EFC3CD87",
      icon: <HiUserGroup size={"1.5rem"} color="#D11A2A" />,
    },
    {
      name: "Total NYSC",
      number: adminDashboardData?.memberCategoryCount?.totalNYSC,
      bgcolor: "#758EFE40",
      icon: <PiSquaresFourBold size={"1.5rem"} color="#3064FC" />,
    },
    {
      name: "Total Graduate",
      number: adminDashboardData?.memberCategoryCount?.totalGraduate,
      bgcolor: "#CD73D142",
      icon: <HiUserGroup size={"1.5rem"} color="#6C1270" />,
    },
    {
      name: "Total Associate",
      number: adminDashboardData?.memberCategoryCount?.totalAssociate,
      bgcolor: "#222B455C",
      icon: <PiSquaresFourBold size={"1.5rem"} color="#000000" />,
    },
    {
      name: "Total Fellows",
      number: adminDashboardData?.memberCategoryCount?.totalFellow,
      bgcolor: "#FFC34F5C",
      icon: <PiSquaresFourBold size={"1.5rem"} color="#000000" />,
    },
  ];

  
  return (
    <Flex
      flexDirection={"column"}
      gap={"20px"}
      py={"30px"}
      minH={"100vh"}
      px={3}
    >
      <Flex
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "flex-start"]}
        alignItems={"center"}
        gap={"20px"}
      >
        <Box w={["100%", "100%", "100%"]}>
          <DetailsCard details={details} />
        </Box>
      </Flex>
      <Flex
        mt={6}
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={["center", "center", "space-between"]}
        alignItems={"center"}
        gap={"20px"}
      >
        <Box w={["100%", "100%", "100%", "60%", "70%"]}>
          <DashboardLineChart
            lineChartData={adminDashboardData?.memberCountPerMonth}
          />
        </Box>
        <Box width={["100%", "100%", "100%", "40%", "30%"]}>
          <UpcomingExams
            upcomingEvents={adminDashboardData?.events}
            title={"Upcoming Events"}
          />
        </Box>
      </Flex>
      <DashboardBarChart barChartData={adminDashboardData?.memberUpgradeCounts} />
    </Flex>
  );
};

export default AdminDashboard;
