import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  Alert,
  Image,
  AlertIcon,
  AlertTitle,
  InputGroup,
  InputRightElement,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { signInSchema } from "../utils/formValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { addLoginUser, fetchUserProfile, fetchMemberUserProfile } from "../redux/slices/authSlice";
import { allPermissions } from "../redux/slices/authPermissionsSlice";
import { signIn } from "../services/authService";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import LoginImage from "../components/LoginImage";
import logo from "../Assets/Images/icsan-logo.svg";

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const errorsArray = Array.isArray(error) ? error : [error];
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth?.user?.userType);

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (auth?.user) {
      if (userType === "Administrative") {
        return navigate("/dashboard");
      } else if (userType === "StudentApplicant") {
        return navigate("/student-application-form");

      } else if (userType === "NYSCApplicant") {
        return navigate("/nysc-application-form");
      }
      else if(userType === "Member" ) {
        return navigate("/studentdashboard");
      }
    }
  }, [auth?.user, navigate, userType]);


  const handleSubmit = async (values) => {
    setError("");
    setIsLoading(true);
    try {
      const response = await signIn(values);
      if (response?.isSuccess === true) {
        setIsLoading(false);
        dispatch(addLoginUser(response));
        dispatch(allPermissions());
        dispatch(fetchUserProfile());
        dispatch(fetchMemberUserProfile());
        if (userType === "Administrative") {
          return navigate("/dashboard");
        } else if (userType === "StudentApplicant") {
          return navigate("/student-application-form");

        } else if (userType === "NYSCApplicant") {
          return navigate("/nysc-application-form");
        }
        else if(userType === "Member" ) {
          return navigate("/studentdashboard");
        }
      } else {
        setIsLoading(false);
        setError(
          response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description ||  response?.error?.description||
          "Failed to login, please try again"
        );
      }
    } catch (error) {
      setError(error?.message);
    } finally {
      setIsLoading(false);
    }

  };

  return (
    <Flex
      minH={"100vh"}
      w="full"
      sx={{
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      overflowY={"auto"}
    >
      <LoginImage />
      <Flex
        // h={"full"}
        // w="full"
        justify={"center"}
        position={"relative"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Flex
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
          mt={5}
          px={["2rem", "3rem", "4rem"]}
        >
          <Image src={logo} alt=" icsan logo" />
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight={"400"}
            color={"rgba(30, 30, 30, 0.5)"}
          >
            INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA{" "}
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          bg={"white"}
          width={"full"}
          px={["2rem", "3rem", "4rem"]}
          pt="3rem"
          pb="2rem"
        >
          <Flex justify={"space-between"} direction={"column"} mb="1.5rem">
            <Text fontWeight={"700"} fontSize={"1.8rem"}>
              Login
            </Text>
            <Text fontSize={"14px"} pt={2}>
              Kindly fill in your login details below to login into your Account
            </Text>
          </Flex>

          {error && (
            <Alert status="error" className="mt-3" mb={5}>
              <AlertIcon />
              <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
            </Alert>
          )}

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={signInSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, handleBlur, handleChange }) => (
              <Form mt={4}>
                <FormControl
                  isInvalid={errors.email && touched.email}
                  mb={5}
                  color="black"
                >
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Enter email address"
                    bgColor={"#F3F3F3"}
                    borderRadius={"8px"}
                    borderColor={"transparent"}
                    mb="1rem"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  isInvalid={errors.password && touched.password}
                  // mb={5}
                  color="black"
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder="Enter password"
                      borderRadius={"8px"}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      bgColor={"#F3F3F3"}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        background={"none"}
                        onClick={() => setShow(!show)}
                      >
                        {show ? (
                          <BsEye w={5} h={5} color="GrayText" />
                        ) : (
                          <BsEyeSlash color={"GrayText"} w={5} h={5} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {touched.password && errors.password && (
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  )}
                </FormControl>
                <Flex justify={"flex-end"}>
                  <Button
                    color={"#EC0000"}
                    variant={"ghost"}
                    alignItems={"center"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    alignSelf={"start"}
                    p={0}
                    _hover={{ bg: "transparent" }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    <Text>Forgot password</Text>
                  </Button>
                </Flex>

                <Button
                  isLoading={isLoading}
                  loadingText="Logging in ..."
                  color={"white"}
                  bgColor={"#E15763"}
                  borderRadius="12px"
                  mt={"2rem"}
                  mb="1rem"
                  w={"full"}
                  type="submit"
                  _hover={{ bgColor: "" }}
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
        <Divider py={"-10"} color={"#8A8C8E"} border={"1px solid #8A8C8E"} />
        <Text pt={6} pb={10} fontSize={"14px"} fontWeight={"400"}>
          Not a member yet?{" "}
          <span style={{ color: "#EC0000", cursor: "pointer" }} >
            <Menu>
              <MenuButton>Register Here</MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate('/student-registration')} color="black">As Student</MenuItem>
                <MenuItem onClick={() => navigate('/nysc-registration')} color="black">As NYSC</MenuItem>
              </MenuList>
            </Menu>
          </span>
        </Text>
      </Flex>
    </Flex>
  );
};

export default Login;
