import React from 'react'
import { Form, Formik } from 'formik';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import {statmentOfResultSchema} from "../utils/formValidationSchema";
import { useNavigate } from 'react-router-dom';

const StatementOfResult = () => {
  const navigate  = useNavigate();
  const initialValues = {
    examNumber: '',
    examYear: '',
    examDiet: '',
  };

  const handleSubmit = async (values) => {
    
    navigate('/statementofresult/resultStatement');
  }

  return (
    <>
        <Box bgColor={'white'} height={["500px","",'270px']} px={'30px'} py={'20px'} mb={5}>
        <Text color={'#3A3541DE'} fontSize={'16px'} fontWeight={500}>Search Filters</Text>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={statmentOfResultSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            errors, touched, values, handleBlur, handleChange
          }) => (

            <Form>
              <Flex flexDirection={["column","column","row"]} alignItems={"center"} gap={3}>
                <FormControl mt={4} isInvalid={errors.examNumber && touched.examNumber}
                  color="#121212" fontFamily={'inter'}>
                  <FormLabel htmlFor='examNumber' fontSize={"16px"}>Exam Number</FormLabel>
                  <Input
                    height={'52px'}
                    type="text"
                    placeholder="Enter Here"
                    borderRadius={"0"}
                    fontSize={"small"}
                    bg={'#F3F3F3'}
                    name="examNumber"
                    cursor={"pointer"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.examNumber}
                  />
                  {touched.examNumber && errors.examNumber && (
                    <FormErrorMessage>{errors.examNumber}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.examYear && touched.examYear} color="#121212">
                  <FormLabel htmlFor='examYear' fontSize={'16px'}>Exam year</FormLabel>
                  <Select
                    height={'52px'}
                    name="examYear"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Select Here'
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examYear}
                  >
                    <option value='2023'>2023</option>
                    <option value='2024'>2024</option>
                  </Select>
                  {touched.examYear && errors.examYear && (
                    <FormErrorMessage>{errors.examYear}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={4} isInvalid={errors.examDiet && touched.examDiet} color="#121212">
                  <FormLabel htmlFor='examDiet' fontSize={'16px'}>Exam Diet</FormLabel>
                  <Select
                    height={'52px'}
                    name="examDiet"
                    placeholder='Select Here'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={'#F3F3F3'}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examDiet}
                  >
                    <option value='June'>June</option>
                    <option value='November'>November</option>
                  </Select>
                  {touched.examDiet && errors.examDiet && (
                    <FormErrorMessage>{errors.examDiet}</FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  _active={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                // isLoading={loading}
                // loadingText="Saving ..."
                >
                  Fetch Report
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default StatementOfResult;