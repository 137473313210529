export const convertFileToBase64 = (file) => {
  if (typeof file !== 'object' || file === null) {
    return Promise.resolve(file);
  }
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
    reader.onerror = error => reject(error);
  });
};