import React, { useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import logo from "../Assets/Images/icsan-logo.svg";
import StudtPersonalInformationAppForm from '../components/StudtPersonalInformationAppForm';
import StudtEmploymentInformationAppForm from '../components/StudtEmploymentInformationAppForm';
import StudtQualificationInformationAppForm from '../components/StudtQualificationInformationAppForm';
import StudtMarriageRefereeInformationAppForm from '../components/StudtMarriageRefereeInformationAppForm';
import { setloadTost } from "../redux/slices/toastSlice";
import { createStudentApplicationForm, editStudentApplicationForm } from "../redux/slices/studentRegistrationSlice";
import { useDispatch } from 'react-redux';
import { removeUser } from "../redux/slices/authSlice";
import { useNavigate } from 'react-router-dom';
import {generateStudentFormData} from "../components/generateFormData";
import StudentApplicationFormHeaderNew from '../components/StudentApplicationFormHeaderNew';

const StudentApplicationFormNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saveloading, setSaveloading] = useState(false);
    const [error, setError] = useState("");
    // const [formSave, setFormSave] = useState(false);
    // const [noData, setNoData] = useState(false);
    const errorsArray = Array.isArray(error) ? error : [error];
    let updatedFormDatas;
    let updatedFormDatasave;
    let updatedFormDatasubmit;
    const [formData, setFormData] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const signOut = () => {
        dispatch(removeUser());
        localStorage.removeItem("persist:root");
        navigate("/");
    };

    const handleNext = (data) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...data
        }));
    
        if (activeStep < max) {
          setActiveStep(activeStep + 1)
        }
      };
      const handlePrevious = () => {
        if (activeStep > 0) {
          setActiveStep(activeStep - 1)
        }
      };
    
      const handleCancel = () => { };
    
      const handleSubmit = async (data) => {
        setFormData((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            ...data
          };
          return updatedFormDatas = updatedFormData;
        });
        if (updatedFormDatas) {
          try {
            const studentFormData = await generateStudentFormData(updatedFormDatas, true);
            setLoading(true);
            setError("");
            const response = await dispatch(createStudentApplicationForm(studentFormData));
            if (response?.payload?.isSuccess === true) {
              setLoading(false);
              setError('');
              dispatch(setloadTost({ title: 'Application Submitted', description: 'Student Application submitted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
              navigate("/");
            } else {
              setLoading(false);
              setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create student application");
            }
          } catch (error) {
            setError(error?.payload?.message || "Failed to create student application, please check your network");
          } finally {
            setLoading(false);
          }
        }
      };
      const handleSaveRegLater = async (data) => {
        setFormData((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            ...data
          };
          return updatedFormDatasave = updatedFormData;
        });
        if (updatedFormDatasave) {
          try {
            const studentFormData = await generateStudentFormData(updatedFormDatasave, false);
            setSaveloading(true);
            setError("");
            const response = await dispatch(createStudentApplicationForm(studentFormData));
            if (response?.payload?.isSuccess === true) {
              setSaveloading(false);
              setError('');
              dispatch(setloadTost({ title: 'Application Saved', description: 'Student Application saved successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
            } else {
              setSaveloading(false);
              setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create student application");
            }
          } catch (error) {
            setError(error?.payload?.message || "Failed to save application, please check your network");
          } finally {
            setSaveloading(false);
          }
        }
      };
    
      const handleEdit = async (data) => {
        setFormData((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            ...data
          };
          return updatedFormDatasubmit = updatedFormData;
        });
        if (updatedFormDatasubmit) {
          try {
            const studentFormData = await generateStudentFormData(updatedFormDatasubmit, true);
            setLoading(true);
            setError("");
            const response = await dispatch(editStudentApplicationForm(studentFormData));
            if (response?.payload?.isSuccess === true) {
              setLoading(false);
              setError('');
              dispatch(setloadTost({ title: 'Application Submitted', description: 'Student Application submitted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
              navigate("/");
            } else {
              setLoading(false);
              setError(response?.payload?.errors?.map((errors) => errors) || response?.payload?.error?.description || "Failed to create student application");
            }
          } catch (error) {
            setError(error?.payload?.message || "Failed to create student application, please check your network");
          } finally {
            setLoading(false);
          }
        }
      }
    
      const steps = [
        { title: "Personal Information", form: <StudtPersonalInformationAppForm handleNext={handleNext} handleCancel={handleCancel} /> },
        { title: "Employment Information", form: <StudtEmploymentInformationAppForm handlePrevious={handlePrevious} handleNext={handleNext} /> },
        { title: "Qualification Information", form: <StudtQualificationInformationAppForm handlePrevious={handlePrevious} handleNext={handleNext} /> },
        { title: "Marriage/Referee Information", form: <StudtMarriageRefereeInformationAppForm handleSaveRegLater={handleSaveRegLater} handleSubmit={handleSubmit} handleEdit={handleEdit} loading={loading} saveloading={saveloading} handlePrevious={handlePrevious} /> },
      ];
      const max = steps.length - 1;
      const progressPercent = (activeStep / max) * 100;
      const orientation = useBreakpointValue({ base: 'vertical', md: 'vertical', lg: 'horizontal' });
      const appData = {
        logo, signOut, error, errorsArray, steps, activeStep, setActiveStep, progressPercent, orientation,
        //  formSave, noData, 
         formData,
      };


  return (
    <StudentApplicationFormHeaderNew {...appData} />
  )
}

export default StudentApplicationFormNew