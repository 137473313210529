import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import heroImg from "../Assets/Images/memberupgrade-hero-img.svg";
import CustomTable from '../components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';
import { fetchMembershipUpgrade } from "../redux/slices/membershipUpgradeSlice";
import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";
import { setloadTost } from '../redux/slices/toastSlice';
import { fetchMemberUserProfile } from "../redux/slices/authSlice";
import { setFeePaymentDetail } from "../redux/slices/studentApplicationApprovalSlice";
import DotLoader from '../components/loaders/DotLoader';

const MemberUpgrade = () => {
    const dispatch = useDispatch();
    const memberProfile = useSelector((state) => state.auth?.memberProfile);
    const paymentConfirmation = useSelector((state) => state?.studentApplicationApproval?.paymentConfirmation);
    const [loadingButtons, setLoadingButtons] = useState({});
    const [error, setError] = useState("");
    const errorsArray = Array.isArray(error) ? error : [error];
    console.log(errorsArray);

    const handleModal = () => {
        dispatch(setFormModal({ status: true, title: "New Member Upgrade", type: "NewMemberUpgrade", size: "md" })); 
    };

    useEffect(() => {
        dispatch(fetchMembershipUpgrade());
        dispatch(fetchMemberUserProfile());
    }, [dispatch, paymentConfirmation]);

    const membershipUpgrade = useSelector((state) => state?.membershipUpgrade?.membershipUpgrade?.data);
    const letLoading = useSelector((state) => state?.membershipUpgrade?.loading);
    const handlePayment = async (item) => {
        const feePaymentId = item?.feePaymentId;
        setLoadingButtons(prevState => ({ ...prevState, [feePaymentId]: true }));
        setError("");
        try {
            const response = await httpRequest(`${BASE_URL}/v2/Payment/${feePaymentId}/generate-remita`, 'post');
            // console.log("response", response);
            if (response?.isSuccess === true) {
                dispatch(setFeePaymentDetail(response));
                dispatch(setFormModal({ status: true, title: "Payment Details", type: "regPaymentDetails" }));
            } else {
                setError(response?.payload?.data?.errors?.map((errors) => errors) || response?.payload?.data?.error?.description || "Failed to get fee payment");
                dispatch(setloadTost({ title: 'Error', description: `${response?.response?.data?.error?.description}`, status: 'warning', duration: 5000, isClosable: true, position: 'top' }));
            }
        } catch (error) {
            setError(error?.payload?.message || "Failed to get fee payment, please check your request/network");
        } finally {
            setLoadingButtons(prevState => ({ ...prevState, [feePaymentId]: false }));
        }
    };

    const tableProps = {
        data: membershipUpgrade,
        title: {
            date: "Date",
            currentGrade: "Current Grade",
            nextUpgrade: "Next Upgrade",
            membershipUpgradeStatus: "Status",
            payment: "Payment",
            action: "Action",
        },
        dataKeys: [
            "date",
            "currentGrade",
            "nextUpgrade",
            "membershipUpgradeStatus",
            "payment",
            "action",
        ],
        isLoading:letLoading,
        transformRow: (item) => ({
            date: <Box style={{ cursor: "pointer" }}>{item?.dateInitiated}</Box>,
            currentGrade: <Box style={{ cursor: "pointer" }}>{item?.currentGrade}</Box>,
            nextUpgrade: <Box style={{ cursor: "pointer" }}>{item?.nextGrade}</Box>,
            membershipUpgradeStatus: <Box style={{ cursor: "pointer" }} color={getStatusColor(item?.status)}>{item?.status}</Box>,
            payment: <Box style={{ cursor: "pointer" }} color={getPaymentColor(item?.paymentStatus)}>{item?.paymentStatus}</Box>,
            action: (
                <Button
                    isDisabled={(item?.status === "Pending" || item?.status === "Declined") && item?.paymentStatus === "Not Started"}
                    fontWeight={'bold'}
                    fontSize={'14px'}
                    bgColor={'transparent'}
                    _hover={{ bgColor: 'transparent' }}
                    _focus={{ bgColor: 'transparent' }}
                    style={{ cursor: "pointer" }}
                    textDecoration={'underline'}
                    color={'#3064FC'}
                    onClick={() => handlePayment(item)}
                    isLoading={loadingButtons[item?.feePaymentId] || false}
                    loadingText="Loading ..."
                >
                    proceed to pay
                </Button>
            ),
        })
    };

    const getPaymentColor = (payment) => {
        switch (payment) {
            case 'Unpaid':
                return '#ABABAB';
            case 'Paid':
                return '#0A8655';
            default:
                return '#ABABAB';
        }
    };

    const getStatusColor = (membershipUpgradeStatus) => {
        switch (membershipUpgradeStatus) {
            case 'Approved':
                return '#005E39';
            case 'Rejected':
                return '#D11A2A';
            default:
                return '';
        }
    };

    return (
        <Flex flexDirection={'column'} gap={'20px'} py={'30px'} minH={'100vh'} px={3} >
            <Box borderRadius={'20px'} bgGradient="linear(to-r, #D11A2A, #FFDDE1)">
                <Flex px={10} py={6} flexDirection={['column', 'row', 'row']} justifyContent={['center', 'space-between', 'space-between']} alignItems={'center'} gap={'20px'}>
                    <Flex flexDirection={'column'} fontFamily={'inter'} gap={3} color={'#FFFFFF'} letterSpacing={'-0.15px'}>
                        <Text fontWeight={'700'} fontSize={'20px'} lineHeight={'21.78px'}>Current Grade: {memberProfile?.memberCategory}</Text>
                        <Text fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'}>You are now eligible to upgrade your membership grade</Text>
                        <Text mt={1} fontWeight={'500'} fontSize={'14px'} lineHeight={'16.94px'} textDecoration={'underline'}>Click to see New Grade Information</Text>
                        <Button mt={3} borderRadius={'12px'} bgColor={'#7D2B32'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'}
                            _hover={{ bgColor: '' }} _active={{ bgColor: '' }} onClick={handleModal}
                        >
                            Upgrade Now!
                        </Button>
                    </Flex>
                    <Image src={heroImg} alt='Student Hero Image' h={'230px'} w={'250px'} />
                </Flex>
            </Box>
            <Flex flexDirection={['column', 'row', 'row']} alignItems={'center'} gap={'20px'} my={3} >
                <Flex flexDirection={'column'} w={'100%'} bg={'#FFFFFF'} borderRadius={'20px'} p={5} gap={4} h={'400px'}
                    sx={{
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "-ms-overflow-style": "none",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    overflowY={"auto"}
                >
                    <Text fontWeight={'700'} fontSize={'14px'} lineHeight={'20px'} color={'#18203A'}>My Membership Upgrade</Text>
                    {
                        letLoading ? (
                            <Flex justifyContent={'center'} alignItems={'center'}>
                                <DotLoader />
                            </Flex>
                        ) : (
                            <CustomTable {...tableProps} />
                        )
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};

export default MemberUpgrade;
