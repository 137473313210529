import React from 'react'
import { Box, Flex, Text, Select, Button, FormControl } from '@chakra-ui/react';
import { cardReportData } from '../data/mock';
import CustomTable from '../components/CustomTable';
import { PiExport } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { setFormModal } from '../redux/slices/modalSlice';

const EMembershipCardReport = () => {
  const dispatch = useDispatch()
  const handleMembershipUpgradeModal = (item) => {
    dispatch(setFormModal({ status: true, title: "Membership Card", type: "emembershipcard", size: 'md' , data:item  }));
  }

  const tableProps = {
    data: cardReportData,
    title: {
      memberName: "Member Name",
      regNumber: "Member REG Number",
      paymentDate: "Payment Date",
      amountPaid: "Amount Paid",
      feeName: " Fee Name ",
      viewDetails: "",
    },
    dataKeys: [
      "memberName",
      "regNumber",
      "paymentDate",
      "amountPaid",
      "feeName",
      "viewDetails",
    ],
    transformRow: (item) => ({
      memberName: item?.memberName,
      regNumber: item?.regNumber,
      paymentDate: item?.paymentDate,
      amountPaid: item?.amountPaid,
      feeName: item?.feeName,
      viewDetails: (
        <Button onClick={() => handleMembershipUpgradeModal(item)} fontWeight={'500'} fontSize={'14px'}  bgColor={'white'} color={'#3064FC'} textDecoration={'underline'} opacity={'100%'} px={'10px'} _hover={{ bgColor: '' }} >View Membership Card</Button>
      ),
    }),
  };
  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={ 'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'}  color={'rgba(209, 26, 42, 0.5)'} >E-membership Card Report</Text>
        <Flex flexDirection={["column", "column", "row"]} alignItems={'center'} gap={[null, null, null]}>
          <Flex alignItems={'center'}>
            <FormControl w={'100px'}>
              <Select placeholder='sort by' border={'0'} color={'#404040'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'} focusBorderColor='white'>
                <option value='option1'>Option 1</option>
                <option value='option2'>Option 2</option>
                <option value='option3'>Option 3</option>
              </Select>
            </FormControl>
          </Flex>
          <Button leftIcon={<PiExport style={{ width: "15px", height: "15px" }} />} bg={'transparent'} _hover={{ backgroundColor: "transparent" }} _active={{ backgroundColor: "transparent" }} color={'#404040'}
            fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'}
          >
            Export
          </Button>
        </Flex>
      </Flex>
      <CustomTable {...tableProps} />
    </Box>
  )
}

export default EMembershipCardReport;