import React from 'react'
import { useSelector } from 'react-redux'
import NYSCPersonalInformation from './NYSCPersonalInformation';
import { TabList, Tab, TabPanels, TabPanel, Tabs } from '@chakra-ui/react';
import NYSCEmployementInformation from './NYSCEmployementInformation';
import NYSCQualificationInformation from './NYSCQualificationInformation';
import NYSCMarriageRefereeInfo from './NYSCMarriageRefereeInfo';
import NYSCDocumentsUpload from './NYSCDocumentsUpload';

const NYSCIndividualDetails = () => {
    const nyscIndividualDetails = useSelector((state) => state.modal.formModal?.data);
    console.log(nyscIndividualDetails)
  return (
   <>
    <Tabs variant='unstyled' pt={5}>
        <TabList gap={'20px'} pb={4} 
            sx={{
                overflowY: "scroll",
                scrollbarWidth: "none", 
                msOverflowStyle: "none", 
                "-ms-overflow-style": "none", 
                "&::-webkit-scrollbar": {
                    display: "none", 
                },
            }}
            overflowY={"auto"}
        >
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Personal Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >PPA Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Qualifications Information</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']} borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Marriage/Referee Info</Tab>
            <Tab _selected={{ color: '#FFFFFF', bg: '#D11A2A' }} lineHeight={'16.41px'} whiteSpace="nowrap" p={'15px'}
            bg={'#E9E9E9'} w={['100%', '40%', '40%', '40%']}  borderRadius={'4px'} letterSpacing={'-0.25px'} color={'#54595F'}
            >Documents Upload</Tab>
        </TabList>

        <TabPanels bg={'#FFFFFF'} >
            <TabPanel>
                <NYSCPersonalInformation nyscIndividualDetails={nyscIndividualDetails} />
            </TabPanel>
            <TabPanel>
                <NYSCEmployementInformation nyscIndividualDetails={nyscIndividualDetails} />
            </TabPanel>
            <TabPanel>
                <NYSCQualificationInformation nyscIndividualDetails={nyscIndividualDetails} />
            </TabPanel>
            <TabPanel>
                <NYSCMarriageRefereeInfo nyscIndividualDetails={nyscIndividualDetails} />
            </TabPanel>
            <TabPanel>
                <NYSCDocumentsUpload nyscIndividualDetails={nyscIndividualDetails} />
            </TabPanel>
        </TabPanels>
    </Tabs>
    </>
  )
}

export default NYSCIndividualDetails