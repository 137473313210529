import React from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Select, Flex, Input } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import {educationUploadResultSchema} from "../utils/formValidationSchema";
import { useNavigate } from 'react-router-dom';

const UploadEdcucationResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = {
        examYear: '',
        diet: '',
        file: '',
    };
  
    const handleSubmit = async (values) => {
    //   console.log(values);
      dispatch(setFormModal({ status: false, data: null }));
      navigate('/examsetup/educationexamresult/details');
    }

  return (
    <>
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={educationUploadResultSchema}
    onSubmit={(values) => handleSubmit(values)}
    >
    {({ errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
      <Form>
        <FormControl mt={4} isInvalid={errors.examYear && touched.examYear}
              color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='examYear'  fontSize={"16px"}>Exam Year</FormLabel>
            <Select
                name="examYear"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                placeholder='Select Here'
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.examYear}
              >
                
              <option value='2023'>2023</option>
              <option  value='2024'>2024</option>
              
              </Select>
            {touched.examYear && errors.examYear && (
              <FormErrorMessage>{errors.examYear}</FormErrorMessage>
            )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.diet && touched.diet}
              color="#121212" fontFamily={'inter'}>
            <FormLabel htmlFor='diet'  fontSize={"16px"}>Exam Diet</FormLabel>
            <Select
                name="diet"
                onBlur={handleBlur}
                onChange={handleChange}
                bg={'#F3F3F3'}
                borderRadius={"0"}
                focusBorderColor="#ffffff"
                borderColor={"#FFFFFF"}
                placeholder='Select Here'
                _hover={{ borderColor: "#FFFFFF" }}
                _placeholder={{ color: "#B4B1B1" }}
                value={values?.diet}
              >
                
              <option value='January'>January</option>
              <option  value='March'>March</option>
              <option  value='May'>May</option>
              <option  value='June'>June</option>
              <option  value='December'>December</option>
              
              </Select>
            {touched.diet && errors.diet && (
              <FormErrorMessage>{errors.diet}</FormErrorMessage>
            )}
        </FormControl>
        <FormControl mt={4} isInvalid={errors.file && touched.file}
            color="#121212" fontFamily={'inter'}>
          <FormLabel htmlFor='file' fontSize={"16px"}>
            Upload File
          </FormLabel>
          <Input 
            type='file'
            name="file"
            id="file"
            fontSize={"small"}
            bg={'#F3F3F3'}
            focusBorderColor="#ffffff"
            borderRadius={'0'}
            borderColor={"#FFFFFF"}
            _placeholder={{ color: "#B4B1B1" }}
            _hover={{ borderColor: "#FFFFFF" }}
            onBlur={handleBlur}
            onChange={(event) => {
              setFieldValue('file', event.currentTarget.files[0]);
            }}
            accept="*/*" 
          />
          {touched.file && errors.file && (
            <FormErrorMessage>{errors.file}</FormErrorMessage>
          )}
        </FormControl>
        
        <Flex  justifyContent={'center'} alignItems={"center"} gap={5}>
          <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={"#D11A2A"}
            _hover={{ background: " " }}
            color={"#FFFFFF"}
            type="submit"
            // isLoading={loading}
            loadingText="Saving ..."
            >
            Upload 
            </Button>
            <Button
            mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
            width={"118px"}
            background={""}
            _hover={{ background: " " }}
            color={"#8F9BB3"}
            onClick={() => {
                dispatch(setFormModal({ status: false, data: null }));
            }}
            loadingText="Saving ..."
          >
            Cancel
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
  </>
  )
}

export default UploadEdcucationResult;