import React from 'react';
import {Box } from '@chakra-ui/react';
import {useSelector } from "react-redux";
import CustomTable from './CustomTable';


const ExamTimeTableDetails = () => {
    const timeTableData =  useSelector((state) => state?.examTimeTable?.examTimeTableDetails);
    // console.log(timeTableData);

    const tableProps = {
        data: timeTableData?.tableContent,
        title: {
            subjectCode: "Subject Code",
            examDate: "Exam Date",
            startTime: "Start Time",
            endTime:"End Time",
          
        },
        dataKeys: [
          "subjectCode",
          "examDate",
          "startTime",
          "endTime",
        ],
        
        transformRow: (item) => ({
            subjectCode :item && <Box style={{cursor:'pointer'}}>{item?.subjectCode}</Box>,
            examDate:item && <Box style={{cursor:'pointer'}}>{item?.examDate}</Box>,
            startTime:item && <Box style={{cursor:'pointer'}}>{item?.startTime}</Box>,
            endTime:item && <Box style={{cursor:'pointer'}}>{item?.endTime}</Box>,
        }),
      };

  return (
    <>
        <CustomTable {...tableProps} />
    </>
  )
}

export default ExamTimeTableDetails;