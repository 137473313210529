import React, { useState } from 'react';
import { Form, Formik } from "formik";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Flex, Textarea, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { addRoleSchema } from "../utils/formValidationSchema";
import { createRole, fetchRoles } from "../redux/slices/userMgtRoleSlice";
import { setloadTost } from "../redux/slices/toastSlice";

const AddRole = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const initialValues = {
    roleName: '',
    description: '',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        name: values?.roleName,
        description: values?.description
      };

      const response = await dispatch(createRole(formData));
      // console.log(response);
      if (response?.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(fetchRoles());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(setloadTost({ title: 'Role created', description: `${values?.roleName} role has been created successfully`, status: 'success', duration: 5000, isClosable: true, position: 'top' }));
      } else {
        setLoading(false);
        setError(response?.payload?.errors?.map((errors) => errors)  || response?.payload?.errors?.description || "Failed to create role");
      }

    } catch (error) {
      setError(error?.payload?.message || "Failed to create role, please check your network");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {
        error &&(
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
        </Alert>
        )
      }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addRoleSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>

            <FormControl mt={4} isInvalid={errors.roleName && touched.roleName}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='roleName' fontSize={"16px"}>Role Name</FormLabel>
              <Input
                placeholder="Enter Here"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="roleName"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.roleName}
              />
              {touched.roleName && errors.roleName && (
                <FormErrorMessage>{errors.roleName}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt={4} isInvalid={errors.description && touched.description}
              color="#121212" fontFamily={'inter'}>
              <FormLabel htmlFor='description' fontSize={"16px"}>Description</FormLabel>
              <Textarea
                placeholder="Enter description"
                fontSize={"small"}
                bg={'#F3F3F3'}
                name="description"
                focusBorderColor="#ffffff"
                borderRadius={'0'}
                borderColor={"#FFFFFF"}
                _placeholder={{ color: "#B4B1B1" }}
                _hover={{ borderColor: "#FFFFFF" }}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.description}
              />
              {touched.description && errors.description && (
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              )}
            </FormControl>

            <Flex justifyContent={'center'} alignItems={"center"} gap={5}>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={"#D11A2A"}
                _hover={{ background: " " }}
                color={"#FFFFFF"}
                type="submit"
                isLoading={loading}
                loadingText="Saving ..."
              >
                Add Role
              </Button>
              <Button
                mt={10} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                width={"118px"}
                background={""}
                _hover={{ background: " " }}
                color={"#8F9BB3"}
                onClick={() => {
                  dispatch(setFormModal({ status: false, data: null }));
                }}
                loadingText="Saving ..."
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddRole;