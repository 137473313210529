import React, { useState } from 'react';
import { Box, Button, Flex, Image, ListItem, OrderedList, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import interLogo from "../Assets/Images/interswitch_group_logo.svg.svg";
import visaLogo from "../Assets/Images/visa_inc_logo.svg.svg";
import interIcon from "../Assets/Images/interswitch_group_icon.png.svg";
import etranzactLogo from "../Assets/Images/etranzact_logo.png.svg";
import { setFormModal } from '../redux/slices/modalSlice';

const DuesPaymentDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myDuesDetailsData = useSelector((state) => state?.myDues?.myDuesDetails);
    const feeNames = myDuesDetailsData && myDuesDetailsData?.map(item => item?.feeName).join(', ');
    const totalAmount = myDuesDetailsData && myDuesDetailsData?.map(item => parseInt(item?.amountOwed)).reduce((acc, curr) => acc + curr, 0);

    const paymentDuesDetails = [
        {title: "Payment Description:", description: feeNames},
        {title: "Transaction Amount:", description: totalAmount},
        {title: "Technology Usage Fee:", description: 'Dues'},
        {title: "Transaction Ref NO:", description: '43578299876'},
    ];

    const paymentTypeData = [
        {title: "Interswitch", logo: interLogo},
        {title: "Visa", logo: visaLogo},
        {title: "Interswitch Pay Direct", logo: interIcon},
        {title: "eTranzact Payoutlet", logo: etranzactLogo},
    ]

    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const handlePaymentOptionChange = (value) => {
        setSelectedPaymentOption(value);
    };

    const handleSubmitPayment = () => {
        if(selectedPaymentOption !== null){
            dispatch(setFormModal({status:true, title: "Dues", type:"PaymentSuccessModal", data: "PaymentSuccessModal"}));
        }
    }
    
  return (
    <Box py={'20px'} minH={'100vh'}>
        <Box>
            <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={['20px', '40px', '40px']} py={'20px'}>
                <Box bg={'#F9A36238'} rounded={'full'} p={1} onClick={() => navigate(-1)}>
                    <BiLeftArrowAlt color="#D8991F" size={'20px'} />
                </Box>
                <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >Payment Details</Text>
            </Flex>
            <Box bgColor={'#FFFFFF'} px={['20px', '40px', '40px']} py={'30px'}>
            {paymentDuesDetails && paymentDuesDetails?.map((item, index) => (
                <Flex key={index} alignItems={'center'} gap={5} >
                    <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'30%'}>{item?.title}</Text>
                    <Text mb={5} color={'#000000'} fontWeight={'700'} fontSize={'16px'} lineHeight={'24.46px'} opacity={'0.5'} width={'70%'}>{item?.description}</Text>
                </Flex>
            ))}
            </Box>
        </Box>
        <Box mt={5}>
            <Flex alignItems={'center'} gap={3} bgColor={'#D7343926'} px={['20px', '40px', '40px']} py={'20px'}>
                <Text color={'#222B45'} fontWeight={'700'} fontSize={'14px'} lineHeight={'16px'} >Please Note</Text>
            </Flex>
            <Box bgColor={'#FFFFFF'} px={['20px', '40px', '40px']} py={'30px'}>
                <Flex  flexDirection={['column', 'column','column', 'row']} gap={['20px', '20px', '20px', '150px']}>
                    <Flex width={['100%', '100%','100%', '30%']}>
                        <OrderedList fontSize={'13px'} fontWeight={'400'} lineHeight={'19.88px'} color={'#222B45'}>
                            <ListItem mb={4}>
                                Please ensure your card is enabled for online payment by your bank before proceeding
                            </ListItem>
                            <ListItem mb={4}>
                            A transaction may fail due to insufficient funds, wrong credentials, card being blacklisted at issuer bank or network failure. 
                            In the event of a network failure, kindly check with your bank for any debits, before attempting again
                            </ListItem>
                            <ListItem mb={4}>
                            For Visa Card kindly use Remita Platform.
                            </ListItem>
                        </OrderedList>
                    </Flex>
                    <Flex flexDirection={'column'} width={['100%', '100%','100%', '70%']} gap={4} >
                        <Text textAlign={'center'} color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'}>Use any of the options below to test the payment flow</Text>
                        <RadioGroup value={selectedPaymentOption} onChange={handlePaymentOptionChange}>
                            {paymentTypeData && paymentTypeData?.map((item, index) => (
                                <Flex key={index} justifyContent={'space-between'} alignItems={'center'} gap={5} p={5} w={'full'} border={'1px solid #C4C4C4'} borderRadius={'5px'} mb={4}>
                                    <Flex alignItems={'center'} gap={5} width={'full'}>
                                        <Radio value={item.title} colorScheme='green'></Radio>
                                        <Text color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'} >{item?.title}</Text>
                                    </Flex>
                                    <Image src={item?.logo} alt='payment logo' width={['150px', '200px', '300px','300px']} h={'40px'}/>
                                </Flex>
                            ))}
                        </RadioGroup>
                        <Button bgColor={'#D11A2A'} color={'#FFFFFF'} fontWeight={'700'} fontSize={'14px'} lineHeight={'21.41px'} borderRadius={'10px'}
                            _hover={{backgroundColor: ""}} _active={{backgroundColor: ""}} onClick={handleSubmitPayment}
                        >
                            Pay NGN {totalAmount}
                        </Button>
                        <Text textAlign={'center'} color={'#222B45'} fontWeight={'400'} fontSize={'14px'} lineHeight={'21.41px'}>Use another card</Text>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    </Box>
  )
}

export default DuesPaymentDetails;