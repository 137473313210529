import { Avatar, Box, Button, Center, Circle, Divider, Flex, Menu, MenuButton, MenuItem, Text, MenuList,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineBell} from "react-icons/ai";
// import { MdSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../redux/slices/authSlice";
import SearchField from "../SearchField";
import { FaChevronDown } from "react-icons/fa6";
import {File_URL} from "../../config/settings";


const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const signOut = () => {
    dispatch(removeUser());
    localStorage.removeItem("persist:root");
    navigate("/")
  }
  const auth = useSelector((state) => state.auth?.userProfile);
  const member = useSelector((state) => state.auth?.memberProfile);
  const profile = {
    firstName: auth?.firstName  || member?.firstName ,
    lastName: auth?.lastName || member?.lastName,
    role: auth?.role || member?.memberCategory,
  }
  return (
    <Flex
      gap={2}
      h="5rem"
      bg="white"
      px={4}
      pr="5rem"
      alignItems={"center"}
      position={"sticky"}
      top={0}
      zIndex={90}
      boxShadow={"0px 2px 10px 0px rgba(198, 198, 198, 0.25)"}
      justifyContent={"flex-end"}
      display={["none", "none", "none", "flex"]}
    >
    
      <SearchField />

      <Flex
        minW={"18rem"}
        justify={"space-between"}
        alignItems={"center"}
        h="full"
      >
        <Box
          position={"relative"}
          // onClick={() => navigate("/notifications")}
          cursor={"pointer"}
        >
          <Circle
            position={"absolute"}
            top={".2rem"}
            right={".3rem"}
            bg={"red"}
            size={".3rem"}
          />
          <AiOutlineBell size={"1.5rem"}  cursor={"pointer"} />
        </Box>
        {/* <MdSettings
          size={"1.5rem"}
          cursor={"pointer"}
          onClick={() => navigate("profile-settings")}
        /> */}
        <Center h="70%">
          <Divider orientation="vertical" />
        </Center>
        <Flex
            alignItems={"center"}
            // gap={4}
            cursor={"pointer"}
          >
            <Avatar
              name={profile?.firstName + " " + profile?.lastName}
              src={`${File_URL}${auth?.profileImg}`}
              border={"1.5px solid #D73439"}
              color={"#D73439"}
            />
            <Flex direction={"column"} lineHeight={1}>
              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} minW={"auto"} bg={"none"}
                _hover={{ bg: "none" }} justifyContent={"start"} _active={{ bg: "none" }}
                sx={{ "& > span:first-child": { flexGrow: 0 } }}  >
                  <Text fontWeight={"500"}>{profile?.firstName + " " + profile?.lastName}</Text>
                 <Text fontSize={"sm"} color="#847F7F"> {profile?.role}</Text>
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
                  <MenuItem onClick={signOut}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
