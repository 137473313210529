import React from 'react';
import { TfiImport } from "react-icons/tfi";
import { Button } from '@chakra-ui/react';

const DownloadBtn = () => {
  return (
    <Button rightIcon={<TfiImport />} bg={'transparent'} _hover={{backgroundColor:"transparent"}} _active={{backgroundColor:"transparent"}} color={'#404040'}
        fontFamily={'inter'} fontSize={'12px'} fontWeight={'500'} lineHeight={'24px'} letterSpacing={'0.4px'} 
    >
        CSV download
    </Button>
  )
}

export default DownloadBtn;