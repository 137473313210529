import axios from "axios";
import storeInit from "../redux/store";

const statusHttpRequest =async (
    url,
    method = "get",
    body = null,
    others,
    contentType = "application/json"
  ) => {
    const accessToken = storeInit.store.getState()?.auth?.user?.accessToken;
    // console.log('accessToken:', accessToken);
    
    try {
      const response = await axios({
        url,
        method,
        data: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": `${contentType}`,
          language: "en",
        },
        ...others,
      });
    //   return response?.data;
      return response;
  
    } catch (error) {
      // console.log(error);
      return error;
    }
  };

export default statusHttpRequest;